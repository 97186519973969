import { Button } from 'antd'

/**
 * Renders footer with cancel and save buttons in a color picker.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.onCancel - The callback function for the cancel button.
 * @param {function} props.onSave - The callback function for the save button.
 * @returns {JSX.Element} - The rendered Footer component.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const Footer = ({ onCancel = () => null, onSave = () => null }) => {
  return (
    <div className="color-picker__footer">
      <Button
        type="primary"
        className="ant-btn-primary__outlined ant-btn-ttu"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button type="primary" className="ant-btn-primary ant-btn-ttu" onClick={onSave}>
        Save
      </Button>
    </div>
  )
}
