import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet-async'
import { Form, message } from 'antd'
import { useSelector } from 'react-redux'

import LayoutLoading from '../../../components/verification/LayoutLoading'
import VerificationLayout from '../../../components/verification/VerificationLayout'
import { SubIndustriesItems } from '../../../components/verification/steps/sub-industries/SubIndustriesItems'
import { VerificationModal } from '../../../components/ui/modals/VerificationModal'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { history } from '../../../index'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { ErrorMessage } from '../../../models'
import { AsyncMessage } from '../../../models/Messages'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'

import { ProfileProgressAbstractFactory } from '../../../models/Domains/ProfileProgress/ProfileProgressAbstractFactory.interface'

const CompanySubIndustries = () => {
  const pageTitle = 'Company Sub-Industries'
  const { userType } = useParams()
  const { user } = useSelector((state) => state.user)
  const [loading, setLoading] = useState(true)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isSubIndustrisNotificationsOn, setIsSubIndustrisNotificationsOn] = useState(
    false,
  )
  const [industries, setIndustries] = useState([])
  const [selectedSubIndustries, setSelectedSubIndustries] = useState([])
  const [progress, setProgress] = useState(0)

  const handleCloseModal = () => {
    setIsShowModal(false)
  }

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/company-sub-industries', null, 'get', true).then(
      (res) => {
        if (res.data.success) {
          setIndustries(res.data.industries)
          if (res.data.selectedSubIndustries) {
            const selected = []
            res.data.selectedSubIndustries.forEach((val) => {
              selected.push(val.sub_industry)
            })
            setSelectedSubIndustries([...selected])
          }
          res.data.notifications.length > 0 && setIsSubIndustrisNotificationsOn(true)

          const progressFactory = ProfileProgressAbstractFactory.getFactory('role')
          const progressBuilder = new progressFactory({
            userType:
              userType !== 'service-provider' ? userType : 'service-provider-general',
            user: { ...user, steps: res.data.steps },
          })
          const progress = progressBuilder.getProgress()
          setProgress(progress)

          setLoading(false)
        }
      },
    )
  }, [])

  const onFinish = async (values) => {
    if (selectedSubIndustries.length < 1) {
      message.error('Please, select sub-industries before submit')
    } else {
      try {
        message.loading({ content: AsyncMessage.loading, key: 'save' })
        await axiosRequest(
          '/api/v1/on-boarding/company-sub-industries',
          {
            subIndustries: selectedSubIndustries,
            notification_on: values.subIndustryNotifications,
          },
          'post',
          true,
        ).then((res) => {
          if (res.data.success) {
            updateAccountStatus(3, user.id)
            message.destroy()
          }
        })
      } catch (error) {
        message.error(ErrorMessage.profile.submition.updateGeneric)
      } finally {
        if (userType === 'buyer') {
          setIsShowModal(true)
        } else {
          history.push(`/secured/verification/${userType}/brands`)
        }
      }
    }
  }

  const onFinishFailed = () => {
    message.error('Something goes wrong, contact support...')
  }

  if (loading) return <LayoutLoading />

  return (
    <>
      <VerificationModal isShow={isShowModal} onCancel={handleCloseModal} />
      <Helmet title={pageTitle} />
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          subIndustryNotifications: isSubIndustrisNotificationsOn,
        }}
      >
        <VerificationLayout progress={progress} currentStep="companySubIndustries">
          <h1>
            {userType === 'buyer'
              ? 'Interested Sub-Industries'
              : 'Company Sub-Industries'}
          </h1>
          <p className="mb-4">
            <strong>
              {userType === 'seller'
                ? 'Select the sub-industries you trade in:'
                : 'What are your preferred sub-industries for sourcing?'}
            </strong>
          </p>
          <div className="sub-industries__wrapper">
            {industries.length > 0 &&
              industries.map((industry) => {
                return (
                  <div className="row industry__item" key={industry.industry_details.id}>
                    <div className="col-12 col-md-3 mb-4 mb-md-0 text-center">
                      <div className="industry__label">
                        <div className="industry__icon">
                          <img
                            src={industry.industry_details.icon}
                            alt={industry.industry_details.name}
                          />
                        </div>
                        <h4 className="industry__name">
                          {industry.industry_details.name}
                        </h4>
                      </div>
                    </div>
                    <div className="col-12 col-md-9">
                      <SubIndustriesItems
                        industry={industry}
                        selected={selectedSubIndustries}
                        handleSelect={setSelectedSubIndustries}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
        </VerificationLayout>
      </Form>
    </>
  )
}

export default CompanySubIndustries
