import { Progress } from "antd";

import './ProgressBar.scss'

const ProgressBar = ({percent}) => {
  return (
    <Progress percent={percent} showInfo={false} strokeColor='#00897B' trailColor="#F7F7F7"/>
  )
}

export default ProgressBar
