import { CompanyIdentity } from '../pages/dashboard'
import DashboardPage from '../pages/dashboard/DashboardPage'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/dashboard',
    Component: DashboardPage,
  },
  {
    path: '/dashboard/company-identity',
    Component: CompanyIdentity,
  },
]
