import { ProfileProgressBuilder } from './ProfileProgressBuilder.interface'

export class LogisticsServiceProfileProgressBuilder extends ProfileProgressBuilder {
  getAllFields() {
    const allFields = [
      'businessType',
      'groupageShipments',
      'palletType',
      'shipmentQuantities',
      'shipmentType',
      'extra_logistic_services',
    ]

    return allFields
  }

  getCompletedFields() {
    const allFields = this.getAllFields()
    const completedFields = []

    if (!this.user.serviceProvider.logistic_service_provider) return completedFields

    allFields.forEach((field) => {
      if (field === 'businessType') {
        const {
          manages_b2b,
          manages_b2c,
        } = this.user.serviceProvider.logistic_service_provider.shipment_service
        if (manages_b2b || manages_b2c) {
          completedFields.push(field)
        }
      }

      if (field === 'groupageShipments') {
        const {
          groupage_ship,
        } = this.user.serviceProvider.logistic_service_provider.shipment_service
        if (typeof groupage_ship === 'boolean') {
          completedFields.push(field)
        }
      }

      if (field === 'palletType') {
        const {
          manages_eur1,
          manages_eur3,
          manages_eur6,
        } = this.user.serviceProvider.logistic_service_provider.shipment_service
        if (manages_eur1 || manages_eur3 || manages_eur6) {
          completedFields.push(field)
        }
      }

      if (field === 'shipmentQuantities') {
        const {
          min_single_boxes,
          min_pallets,
          min_container,
        } = this.user.serviceProvider.logistic_service_provider.shipment_service
        if (min_single_boxes || min_pallets || min_container) {
          completedFields.push(field)
        }
      }

      if (field === 'shipmentType') {
        const {
          air_dry_ship,
          air_insulated_ship,
          air_reefer_ship,
          boat_dry_ship,
        } = this.user.serviceProvider.logistic_service_provider.shipment_service
        if (air_dry_ship || air_insulated_ship || air_reefer_ship || boat_dry_ship) {
          completedFields.push(field)
        }
      }

      if (field === 'extra_logistic_services') {
        const {
          has_distribution,
          has_invoicing,
          has_warehousing,
        } = this.user.serviceProvider.logistic_service_provider.extra_logistic
        if (has_distribution || has_invoicing || has_warehousing) {
          completedFields.push(field)
        }
      }
    })

    return completedFields
  }
}
