import { useEffect, useState } from 'react'

import axiosRequest from '../../useAxiosRequest'

export const useServiceProviderCompanyAdditionalInformation = (isActive = false) => {
  const [turnover, setTurnover] = useState('')
  const [companyDescription, setCompanyDescription] = useState('')
  const [companyInformation, setCompanyInformation] = useState({})

  const saveDescription = async () => {
    try {
      const body = {
        companyName: companyInformation.name,
        establishmentDate: companyInformation.establishment_date,
        companyEmail: companyInformation.email,
        companyWebsite: companyInformation.website,
        companyIsStock: companyInformation.is_stock,
        companyCountry: companyInformation.country,
        companyCity: companyInformation.city,
        companyZip: companyInformation.zip,
        companyStreet: companyInformation.address,
        companyRegistrationNumber: companyInformation.registration_number,
        companyVATnumber: companyInformation.vat_number,
        description: companyDescription,
        additionalCompanies: companyInformation.get_branches || [],
      }
      await axiosRequest('/api/v1/on-boarding/company-info', body, 'post', true)
    } catch (error) {
      console.log('Failed to update Company Description')
    }
  }

  const saveTurnover = async () => {
    try {
      const body = {
        turnover,
        export: companyInformation.export,
        exportCountries: companyInformation.export_countries || [1],
      }
      await axiosRequest('/api/v1/on-boarding/sales-presence', body, 'post', true)
    } catch (error) {
      console.log('Failed to update Company Turnover')
    }
  }

  const save = async () => {
    try {
      await saveDescription()
      await saveTurnover()
    } catch (error) {
      console.log('Failed to update Company Information')
      throw new Error('Failed to update business information')
    }
  }

  // useEffect(() => {
  //   if (isActive) {
  //     getCompanyInformation()
  //   }
  // }, [isActive, getCompanyInformation])

  useEffect(() => {
    console.log(companyInformation)
    if (companyInformation) {
      setCompanyDescription(companyInformation.description)
      setTurnover(companyInformation.turnover)
    }
  }, [companyInformation])

  return {
    turnover,
    setTurnover,
    companyDescription,
    setCompanyDescription,
    setCompanyInformation,
    saveServiceProvider: save,
  }
}

// {"companyName":"TradeToWorld Testing Development","establishmentDate":"2024","companyEmail":"test@email.com","companyWebsite":"aaaa","companyIsStock":false,"companyCountry":"Albania","companyCity":"abc","companyZip":"abc","companyStreet":"abc","companyRegistrationNumber":"abc","companyVATnumber":null,"description":"asddddasdasdaaaasd","additionalCompanies":[]}
