import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

export const useDashboardHeader = () => {
  const { user, subscription } = useSelector((state) => state.user)

  const [userInfo, setUserInfo] = useState({
    userName: user.first_name,
    userAvatar: user.avatar,
    userCompany: user.get_user_company?.name || 'Company',
    userSubscription: subscription
      ? subscription.subscription.subscription_plan.name.toLowerCase()
      : 'free',
  })

  useEffect(() => {
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      userCompany: user.get_user_company?.name || '',
    }))
  }, [userInfo.userSubscription, user.get_user_company])

  return {
    userInfo,
  }
}
