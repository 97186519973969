import { Button } from 'antd'

export const Footer = ({ onCancel, onOk, isOk, isConfirmation }) => {
  return (
    <div className="d-flex px-2">
      <Button
        type="ghost"
        className="ant-btn-primary__outlined ant-btn-ttu w-100 h-100"
        onClick={onCancel}
      >
        {isConfirmation ? 'Go back' :'Cancel'}
      </Button>
      <Button
        disabled={!isOk}
        type="primary"
        className="ant-btn-primary ant-btn-ttu w-100 h-100"
        onClick={onOk}
      >
        {isConfirmation ? 'Create' : 'Confirm'}
      </Button>
    </div>
  )
}
