import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import ReactQuill from 'react-quill'
import { Form, Input, Radio, Checkbox } from 'antd'

import { removeHTMLTags } from '../../../util/functions/removeHTMLTags'

import { TagController } from './components/TagController/TagController'
import { SelectBrand } from './components/SelectBrand'
// import { MultiSelect } from '../../common/forms/MultiSelect'
// import { SelectBrand } from './components/SelectBrand'

const ProductInformation = ({ title = null, product, updateProduct, brands }) => {
  const { Item } = Form
  const [description, setDescription] = useState(product.description || '')

  const handleDescription = (value) => {
    setDescription(value)
    updateProduct('description', value)
  }

  useEffect(() => console.log(description), [description])

  return (
    <>
      <div className="row product-information">
        <div className="col-12 col-md-6">
          <Item
            name="name"
            label="Product Name"
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <Input onChange={(e) => updateProduct('name', e.target.value)} />
          </Item>
        </div>
        <div className="col-12 col-md-6">
          <SelectBrand
            brandOpts={brands}
            product={product}
            updateProduct={updateProduct}
          />
        </div>
        <div className="col-6">
          <Form.Item
            name="inventoryCondition"
            label="What is the current inventory condition of the product?"
          >
            <Radio.Group
              onChange={(e) => updateProduct('inventoryCondition', e.target.value)}
              value={product.inventoryCondition}
              defaultValue={product.inventoryCondition || 'new'}
            >
              <Radio value={'new'}>New</Radio>
              <Radio value={'leftover'}>Leftover</Radio>
              <Radio value={'used'}>Used</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="col-6">
          <Form.Item
            name="isProductCustomizable"
            label="Is this product customizable (and/or available for private label)?"
          >
            <Radio.Group
              onChange={(e) => updateProduct('isProductCustomizable', e.target.value)}
              value={product.isProductCustomizable}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        {product.isProductCustomizable && (
          <div className="col-12 mb-4">
            <p className="mb-1">
              Choose which options will be customizable in the product:
            </p>
            <div className="row">
              <div className="col-12 col-md-3 col-lg-2">
                <Checkbox
                  defaultChecked={product.customizableOptions.packaging}
                  onChange={(e) => {
                    updateProduct('customizableOptions.packaging', e.target.checked)
                  }}
                >
                  Packaging
                </Checkbox>
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <Checkbox
                  defaultChecked={product.customizableOptions.productFeatures}
                  onChange={(e) => {
                    updateProduct('customizableOptions.productFeatures', e.target.checked)
                  }}
                >
                  Product Features
                </Checkbox>
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <Checkbox
                  defaultChecked={product.customizableOptions.labelBrand}
                  onChange={(e) => {
                    updateProduct('customizableOptions.labelBrand', e.target.checked)
                  }}
                >
                  Label/Brand
                </Checkbox>
              </div>
            </div>
          </div>
        )}
        <div className="col-12 mb-4">
          <p>Product Description</p>
          <ReactQuill
            value={description}
            // defaultValue={product.description}
            onChange={(value) => handleDescription(value)}
            theme="snow"
          />
          <div className="counter">{removeHTMLTags(description).length} / 1500</div>
        </div>
        <div className="col-12">
          <TagController updateProduct={updateProduct} product={product} />
        </div>
      </div>
    </>
  )
}

export default ProductInformation
