import { useState } from 'react'
import { Select } from 'antd'
import _ from 'lodash'

import { TagList } from '../../pages/product-upload/components/TagController/TagList'

/**
 * @description MultiSelect component is a custom component that uses the Ant Design Select component to create a multi-select dropdown. It also uses the TagList component to display the selected options as tags.
 * @param {Array} options - The list of options to be displayed in the dropdown
 * @param {Array} defaultValue - The default selected options
 * @param {String} valueKey - The key to be used as the value for the option
 * @param {String} nameKey - The key to be used as the label for the option
 * @param {Function} onSelectCallback - The callback function to be called when an option is selected
 * @param {Function} onDeselectCallback - The callback function to be called when an option is deselected
 * @returns {JSX.Element} A multi-select dropdown component
 * @example
 * <MultiSelect
 *  options={brandOpts}
 *  nameKey={'name'}
 *  valueKey={'id'}
 *  onSelectCallback={(value) => handleSelect(parseInt(value.id, 10))}
 *  onDeselectCallback={(value) => handleDeselect(parseInt(value.id, 10))}
 *   defaultValue={product['brands'].map((brand) =>
 *   brandOpts.find((b) => b.id === brand),
 *  )}
 * />
 * @see https://ant.design/components/select/
 *
 * @todo Add prop types
 * @todo Add tests
 * @todo Add storybook
 *
 * @version 1.0.0
 * @since 1.0.0
 * @category Presentational Components/Common
 * @component
 *
 * @requires react
 * @requires antd
 * @requires lodash
 * @requires TagList
 *
 * @author Rafael Rapizo Nery
 */
export const MultiSelect = ({
  options = [],
  defaultValue = [],
  valueKey,
  nameKey,
  onSelectCallback = () => null,
  onDeselectCallback = () => null,
  placeholder = "Brands*"
}) => {
  const [selected, setSelected] = useState(defaultValue)

  const onSelect = (value) => {
    const valueObj = options.find((opt) => opt[valueKey] === value)

    setSelected(_.uniq([...selected, valueObj]))
    onSelectCallback(valueObj)
  }

  const onDeselect = (value) => {
    const valueObj = options.find((opt) => opt[valueKey] === value)
    const newSelected = selected.filter((val) => val[valueKey] !== value)

    setSelected(_.uniq(newSelected))
    onDeselectCallback(valueObj)
  }

  return (
    <>
      <Select
        removeIcon={
          <span role="img" aria-label="close" className="anticon anticon-close">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="close"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
            </svg>
          </span>
        }
        showSearch={false}
        placeholder={placeholder}
        // defaultValue={''}
        onSelect={onSelect}
        onDeselect={onDeselect}
      >
        {options.length > 0 &&
          options.map((opt) => {
            return (
              <Select.Option
                value={opt[valueKey]}
                key={opt[valueKey]}
                disabled={selected.includes((s) => s[valueKey] === opt[valueKey])}
              >
                {opt[nameKey]}
              </Select.Option>
            )
          })}
      </Select>
      <TagList
        tags={selected.map((s) => ({ key: s[valueKey], label: s[nameKey] }))}
        handleDelete={onDeselect}
      />
    </>
  )
}
