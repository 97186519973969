import React from 'react'
import { Skeleton } from 'antd'

import VerificationLayout from './VerificationLayout'
import { useSelector } from 'react-redux'

const LayoutLoading = () => {
  const user = useSelector((state) => state.user)


  return (
    <VerificationLayout progress={0} currentStep="personalInfo" loading={true} user={user}>
      <Skeleton active />
    </VerificationLayout>
  )
}

export default LayoutLoading
