import { Select, Input } from 'antd'

export function OrderParams({ handlePricesInputs, index, price, extra = false }) {
  if (extra) return null
  else
    return (
      <div className="col-12 mb-3">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <p className="mb-3">
              <strong>Measuring unit</strong>
            </p>
            <Select
              className="w-100"
              value={price.order_measure}
              defaultValue={price.order_measure}
              onChange={(val) => handlePricesInputs(index, 'order_measure', val)}
            >
              <Select.Option value={'unit'}>Unit</Select.Option>
              <Select.Option value={'gram'}>Gr</Select.Option>
              <Select.Option value={'kilogram'}>Kg</Select.Option>
              <Select.Option value={'ounce'}>Ounce</Select.Option>
              <Select.Option value={'pound'}>Lb</Select.Option>
              <Select.Option value={'liter'}>Liter</Select.Option>
              <Select.Option value={'gallon'}>Gallon</Select.Option>
              <Select.Option value={'ton'}>Ton (imperial)</Select.Option>
              <Select.Option value={'metric ton'}>Metric Ton</Select.Option>
            </Select>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <p className="mb-3">
                <strong>Minimum Order Quantity</strong>
              </p>
              <div className="col-11 col-md-6 col-lg-6">
                <Input
                  disabled={!!price.minimalOrderValue && price.minimalOrderValue !== "0.00"}
                  defaultValue={
                    price.minimalOrderUnits !== 0 ? price.minimalOrderUnits : null
                  }
                  placeholder="Order quantity"
                  onChange={(e) =>
                    handlePricesInputs(index, 'minimalOrderUnits', e.target.value)
                  }
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <Input
                  disabled={!!price.minimalOrderUnits}
                  defaultValue={
                    price.minimalOrderValue !== "0.00" ? price.minimalOrderValue : null
                  }
                  placeholder="Order value"
                  onChange={(e) =>
                    handlePricesInputs(index, 'minimalOrderValue', e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
