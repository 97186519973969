import { Button } from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'

import TutorialOverlay from '../../modals/TutorialOverlay/TutorialOverlay'

import { useModal } from '../../../../hooks/ui/Modal/useModal.js'
import { useTutorialButton } from '../../../../hooks/ui/useTutorialButton/useTutorialButton.js'

import './TutorialButton.scss'

const TutorialButton = ({ tutorials = [], activeTutorial = null }) => {
  const { isTutorialEmpty, showTutorialButton } = useTutorialButton()
  const { isVisible, show, hide } = useModal()

  return (
    <div className={`tutorial-button${showTutorialButton() ? '' : '--hidden'}`}>
      <Button disabled={isTutorialEmpty(tutorials)} type="primary" onClick={show}>
        <PlayCircleOutlined className="play-icon" />
        TUTORIAL
      </Button>
      {isVisible && (
        <TutorialOverlay
          isModalOpen={isVisible}
          handleOnCancel={hide}
          tutorials={tutorials}
          activeTutorial={activeTutorial}
        />
      )}
    </div>
  )
}

export default TutorialButton
