export const Badge = ({ isActive = false, className, count }) => {
  return (
    <>
      {isActive && (
        <div className={`badge ${className}`}>
          <p>{count}</p>
        </div>
      )}
    </>
  )
}
