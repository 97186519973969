import React from 'react'
import { Link } from 'react-router-dom'

import Filters from '../../components/pages/marketplace/explore/Filters'
import Header from '../../components/pages/marketplace/explore/Header'

import { brandsFilters } from '../../data/marketplace-filters'

import brand1 from '../../assets/temp/brand1.png'
import brand2 from '../../assets/temp/brand2.png'
import brand3 from '../../assets/temp/brand3.png'
import brand4 from '../../assets/temp/brand4.png'
import storeIcon from '../../assets/badges/store.png'

const BrandsPage = () => {
  return (
    <div className="container">
      <div className="products-list">
        <div className="filters">
          <Filters filters={brandsFilters} />
        </div>
        <div className="products">
          <Header
            title="Explore products"
            view={'grid'}
            setView={() => {}}
            hideViewToggle
          />
          <div className="brands-list">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="brand">
                  <div className="brand-image">
                    <Link to="#">
                      <img src={brand1} alt="" />
                    </Link>
                  </div>
                  <h2 className="brand-name">
                    <Link to="#">Brand name</Link>
                  </h2>
                  <p>
                    <img src={storeIcon} alt="" /> <span>Seller Name</span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="brand">
                  <div className="brand-image">
                    <Link to="#">
                      <img src={brand2} alt="" />
                    </Link>
                  </div>
                  <h2 className="brand-name">
                    <Link to="#">Brand name</Link>
                  </h2>
                  <p>
                    <img src={storeIcon} alt="" /> <span>Seller Name</span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="brand">
                  <div className="brand-image">
                    <Link to="#">
                      <img src={brand3} alt="" />
                    </Link>
                  </div>
                  <h2 className="brand-name">
                    <Link to="#">Brand name</Link>
                  </h2>
                  <p>
                    <img src={storeIcon} alt="" /> <span>Seller Name</span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="brand">
                  <div className="brand-image">
                    <Link to="#">
                      <img src={brand4} alt="" />
                    </Link>
                  </div>
                  <h2 className="brand-name">
                    <Link to="#">Brand name</Link>
                  </h2>
                  <p>
                    <img src={storeIcon} alt="" /> <span>Seller Name</span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="brand">
                  <div className="brand-image">
                    <Link to="#">
                      <img src={brand3} alt="" />
                    </Link>
                  </div>
                  <h2 className="brand-name">
                    <Link to="#">Brand name</Link>
                  </h2>
                  <p>
                    <img src={storeIcon} alt="" /> <span>Seller Name</span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="brand">
                  <div className="brand-image">
                    <Link to="#">
                      <img src={brand2} alt="" />
                    </Link>
                  </div>
                  <h2 className="brand-name">
                    <Link to="#">Brand name</Link>
                  </h2>
                  <p>
                    <img src={storeIcon} alt="" /> <span>Seller Name</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandsPage
