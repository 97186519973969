import { useState, useEffect } from 'react'

/**
 * @name useLoader
 * @description This hook is used to manage the loading state of a component.
 * @returns {Object} An object with the properties isLoading and setIsLoading
 * @example
 * const { isLoading, setIsLoading } = useLoader()
 * @author Rafael Rapizo Nery
 */
const useLoader = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    return () => {
      setIsLoading(false)
    }
  }, [])

  return { isLoading, setIsLoading }
}

export default useLoader
