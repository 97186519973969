import VerificationLayout from '../../../../components/verification/VerificationLayout'
import ProfileServices from './components/ProfileServices'


export const EditProfiles = () => {
  return (
    <VerificationLayout withProgress={false} withFooter={false}>
      <ProfileServices />
    </VerificationLayout>
  )
}
