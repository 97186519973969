const useApiToken = (asAxiosHeader = false, tokenName = 'apiToken') => {
  const token = localStorage.getItem(tokenName)
  if (asAxiosHeader) {
    return {
      headers: { Authorization: `Bearer ${token}` },
    }
  }
  return token
}

export default useApiToken
