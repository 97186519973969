import { lazy } from 'react'

const CompanyProfile = lazy(() => import('../pages/my-profile/CompanyProfile'))
const Settings = lazy(() => import('../pages/my-profile/Settings/index'))
const DynamicPrinting = lazy(() => import('../pages/my-profile/DynamicPrinting'))
const DynamicPrintingEditor = lazy(() =>
  import('../pages/my-profile/DynamicPrintingEditor'),
)

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/profile/company-profile',
    Component: CompanyProfile,
  },
  {
    path: '/profile/settings',
    Component: Settings,
  },
  {
    path: '/profile/dynamic-printing',
    Component: DynamicPrinting,
  },
  {
    path: '/profile/dynamic-printing/editor/:id',
    Component: DynamicPrintingEditor,
  },
]
