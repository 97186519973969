import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Helmet } from 'react-helmet-async'
import { Dropdown, Form, Input, message } from 'antd'

import axiosRequest from '../../../hooks/useAxiosRequest'

import { history } from '../../../index'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'

import LayoutLoading from '../../../components/verification/LayoutLoading'
import VerificationLayout from '../../../components/verification/VerificationLayout'
import ExportCountries from '../../../components/verification/ExportCountries'
import { InfoCircleOutlined } from '@ant-design/icons'

import { ProfileProgressAbstractFactory } from '../../../models/Domains/ProfileProgress/ProfileProgressAbstractFactory.interface'

const CompanyAdditionalInformation = (props) => {
  const [form] = Form.useForm()
  const { userType } = useParams()
  const { user } = useSelector((state) => state.user)
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [countries, setCountries] = useState([])
  const [info, setInfo] = useState({})
  const pageTitle =
    userType === 'service-provider' ? 'Geographical Data' : 'Sales & Presence'

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/company-additional-info', null, 'get', true).then(
      (res) => {
        if (res.data.success) {
          setInfo(res.data.company)
          let exportCountries = null
          if (res.data.company && res.data.company.export_countries) {
            exportCountries = JSON.parse(res.data.company.export_countries)
            if (exportCountries && exportCountries.exportCountries) {
              exportCountries = exportCountries.exportCountries
            }
          }
          setCountries(exportCountries)

          const progressFactory = ProfileProgressAbstractFactory.getFactory('role')
          const progressBuilder = new progressFactory({
            userType:
              userType !== 'service-provider' ? userType : 'service-provider-general',
            user: { ...user, steps: res.data.steps },
          })
          const progress = progressBuilder.getProgress()
          setProgress(progress)

          setLoading(false)
        }
      },
    )
  }, [])

  const onSubmit = (values) => {
    if (countries.length < 1) {
      message.error('Please, select at least 1 country')
      return null
    }
    const data = {
      ...values,
      exportCountries: countries,
    }
    message.loading({ content: 'Please, wait...', key: 'save' })
    axiosRequest('/api/v1/on-boarding/sales-presence', data, 'post', true)
      .then((res) => {
        if (res.data.success) {
          updateAccountStatus(3, user.id)
          message.destroy()
          history.push(`/secured/verification/${userType}/financial`)
        }
      })
      .catch(() => {
        message.error('Something goes wrong, try again or contact support...')
      })
  }

  const onFinishFail = () => {
    message.error('Something goes wrong, contact support...')
  }

  if (loading || !info.id) return <LayoutLoading />

  return (
    <>
      <Helmet title={pageTitle} />
      <Form
        form={form}
        onFinish={onSubmit}
        onFinishFailed={onFinishFail}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          establishmentDate: info.establishment_date,
          employees: info.employees,
          turnover: info.turnover,
          export: info.export,
          ownership: info.ownership,
        }}
      >
        <VerificationLayout currentStep="salesPresence" progress={progress}>
          <h1>{pageTitle}</h1>
          <div className="row sales-turnover">
            {userType !== 'service-provider' && (
              <>
                <div className="col-12 col-lg-3">
                  <Form.Item
                    label="Turnover"
                    name="turnover"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input placeholder="0" />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-3 sales-turnover__export">
                  <Form.Item
                    label="Export"
                    name="export"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input placeholder="0" />
                  </Form.Item>
                  <span className="">%</span>
                </div>
              </>
            )}
            <div className="col-12 position-relative ">
              <Dropdown
                className="info-dropdown"
                placement="topLeft"
                overlayStyle={{
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  boxShadow: '0 0 40px -10px rgba(#000, 0.2)',
                }}
                overlay={
                  userType !== 'service-provider' ? (
                    <p className="p-2 mb-0">
                      Countries where the company has ever sold its goods
                    </p>
                  ) : null
                }
              >
                <InfoCircleOutlined />
              </Dropdown>
              <Form.Item
                label={
                  userType === 'service-provider'
                    ? 'Geographical areas of you interest'
                    : 'Selling Markets'
                }
              >
                <ExportCountries countries={countries} setCountries={setCountries} />
              </Form.Item>
            </div>
          </div>
        </VerificationLayout>
      </Form>
    </>
  )
}

export default CompanyAdditionalInformation
