import axios from 'axios'

import useApiToken from './useApiToken'

const axiosRequest = async (path, data = null, method = 'post', withBearerToken = true) => {
  let headers = {}
  if (withBearerToken) {
    headers = {
      headers: {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        Authorization: `Bearer ${useApiToken(false)}`,
      },
    }
  }
  switch (method) {
    case 'get':
      return axios.get(process.env.REACT_APP_MAIN_API_URL + path, headers)
    case 'post':
      return axios.post(process.env.REACT_APP_MAIN_API_URL + path, data, headers)
    case 'put':
      return axios.put(process.env.REACT_APP_MAIN_API_URL + path, data, headers)
    case 'patch':
      return axios.patch(process.env.REACT_APP_MAIN_API_URL + path, data, headers)
    case 'delete':
      return axios.delete(process.env.REACT_APP_MAIN_API_URL + path, {
        data: {},
        // eslint-disable-next-line react-hooks/rules-of-hooks
        headers: { Authorization: `Bearer ${useApiToken(false)}` },
      })
    default:
      return null
  }
}

export default axiosRequest
