import React from 'react'
import { Link } from 'react-router-dom'
import { Marker, StaticGoogleMap } from 'react-static-google-map'

const CompanyInfo = ({ company }) => {
  return (
    <div className="sellerShowroom__left">
      <div className="sellerShowroom__card">
        <h3 className="sellerShowroom__title">Categories</h3>
        <div className="sellerShowroom__categories">
          {company.get_industries &&
            company.get_industries.map((industry) => (
              <div key={industry.id} className="sellerShowroom__category">
                <div className="sellerShowroom__categoryIcon">
                  <img src={industry.industry.icon} alt={industry.industry.name} />
                </div>
                <div className="sellerShowroom__categoryName">
                  {industry.industry.name}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="sellerShowroom__card">
        <h3 className="sellerShowroom__title">Company Informations</h3>
        <div className="sellerShowroom__info">
          <div className="sellerShowroom__infoWrapper">
            <div>
              <p className="mb-2">Established in:</p>
              <p className="mb-0">
                <strong>{company.establishment_date}</strong>
              </p>
            </div>
            <div className="ms-auto">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F7F7F7" />
                <path
                  d="M29.3346 29.5H26.668V17.5H13.3346V29.5H10.668V14.8333L20.0013 9.5L29.3346 14.8333V29.5ZM14.668 18.8333H25.3346V21.5H14.668V18.8333ZM14.668 22.8333H25.3346V25.5H14.668V22.8333ZM25.3346 26.8333V29.5H14.668V26.8333H25.3346Z"
                  fill="#26A69B"
                />
              </svg>
            </div>
          </div>
          <div className="sellerShowroom__infoWrapper">
            <div>
              <p className="mb-2">Employees:</p>
              <p className="mb-0">
                <strong>{company.employees}</strong>
              </p>
            </div>
            <div className="ms-auto">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F7F7F7" />
                <path
                  d="M20.2899 24.5972C22.0671 24.5972 23.5211 21.725 23.5211 19.6785C23.5211 17.632 22.0671 15.9805 20.2899 15.9805C18.5127 15.9805 17.0586 17.632 17.0586 19.6785C17.0586 21.725 18.5127 24.5972 20.2899 24.5972Z"
                  fill="#26A69B"
                />
                <path
                  d="M14.9077 15.2623C16.29 15.2623 17.421 13.1082 17.421 11.5823C17.421 10.0385 16.29 8.7998 14.9077 8.7998C13.5255 8.7998 12.3945 10.0385 12.3945 11.5823C12.3945 13.1082 13.5255 15.2623 14.9077 15.2623Z"
                  fill="#26A69B"
                />
                <path
                  d="M25.6773 15.2623C27.0595 15.2623 28.1905 13.1082 28.1905 11.5823C28.1905 10.0385 27.0595 8.7998 25.6773 8.7998C24.295 8.7998 23.1641 10.0385 23.1641 11.5823C23.1641 13.1082 24.295 15.2623 25.6773 15.2623Z"
                  fill="#26A69B"
                />
                <path
                  d="M27.1887 26.949C26.5604 26.3746 22.8265 25.3154 22.8265 25.3154L20.2953 27.1106L17.7641 25.3154C17.7641 25.3154 14.0302 26.3746 13.4019 26.949C12.7377 27.5594 12.4864 29.5879 12.3966 30.8086C12.3607 31.3292 12.7916 31.76 13.3481 31.76H27.2425C27.799 31.76 28.2299 31.3292 28.194 30.8086C28.1042 29.5879 27.8529 27.5594 27.1887 26.949Z"
                  fill="#26A69B"
                />
                <path
                  d="M16.343 19.6784C16.343 18.2961 16.9175 17.0754 17.7971 16.2496C17.2765 16.0881 16.8636 15.9624 16.8636 15.9624L14.9069 17.3806L12.9502 15.9804C12.9502 15.9804 10.06 16.8061 9.57529 17.2549C9.07265 17.7217 8.87518 19.3014 8.80338 20.2528C8.76747 20.6657 9.10855 21.0068 9.53939 21.0068H16.5046C16.3969 20.54 16.343 20.0913 16.343 19.6784Z"
                  fill="#26A69B"
                />
                <path
                  d="M27.636 15.9805L25.6793 17.3807L23.7225 15.9805C23.7225 15.9805 23.3276 16.0882 22.7891 16.2677C23.6687 17.0755 24.2431 18.3142 24.2431 19.6785C24.2431 20.0914 24.1893 20.5402 24.0816 21.0069H31.0468C31.4776 21.0069 31.8007 20.6658 31.7828 20.2529C31.711 19.3015 31.5315 17.7218 31.0109 17.255C30.5262 16.8062 27.636 15.9805 27.636 15.9805Z"
                  fill="#26A69B"
                />
              </svg>
            </div>
          </div>
          <div className="sellerShowroom__infoWrapper">
            <div>
              <p className="mb-2">Ownership:</p>
              <p className="mb-0">
                <strong style={{ fontSize: '18px' }}>{company.ownership}</strong>
              </p>
            </div>
            <div className="ms-auto">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F7F7F7" />
                <path
                  d="M33.0389 27.7533C32.4893 27.2724 31.7336 27.2724 31.1839 27.7533C27.6113 30.9137 22.3898 31.3947 18.3362 28.99H24.7257C25.5502 29.0587 26.2372 28.4404 26.2372 27.6159C26.2372 27.135 26.0998 26.7228 25.7563 26.448C25.4815 26.1731 25.1379 26.0357 24.7257 26.0357H20.1912C17.7866 24.4555 14.6262 24.4555 12.2215 26.0357H7C7.68704 28.2343 8.85502 30.2267 10.3665 31.9443H15.0384C20.8783 35.3795 28.2296 34.4177 33.1076 29.7458C33.5886 29.1274 33.5886 28.303 33.0389 27.7533Z"
                  fill="#26A69B"
                />
                <path
                  d="M27.7392 10.6351C27.7392 8.08059 25.6973 6 23.186 6C20.6746 6 18.6328 8.08059 18.6328 10.6351C18.6328 12.595 19.8734 14.4516 21.8679 15.0719V22.4251C21.8679 22.563 21.9324 22.6879 22.0401 22.7698L23.3066 23.7217C23.3842 23.782 23.4746 23.8079 23.5651 23.8079C23.6771 23.8079 23.7934 23.7648 23.8752 23.6787L24.8531 22.6621C24.9349 22.5802 24.978 22.4639 24.9737 22.3476L24.9435 21.3094C24.9392 21.1974 24.8919 21.0897 24.81 21.0122L24.082 20.3144L24.7669 19.5735C24.9306 19.4012 24.922 19.1298 24.7497 18.9704L24.0217 18.2682L24.7066 17.5273C24.866 17.355 24.8574 17.0879 24.6894 16.9242L23.9614 16.2307L24.6463 15.4941C24.7238 15.4079 24.7626 15.3002 24.7626 15.1882L24.754 14.9858C26.5287 14.3267 27.7392 12.5821 27.7392 10.6351ZM23.186 10.6566C22.2893 10.6566 21.5792 9.90811 21.5792 9.02828C21.5792 8.13227 22.303 7.39999 23.186 7.39999C24.0733 7.39999 24.7928 8.13227 24.7928 9.02828C24.7928 9.89849 24.1011 10.6566 23.186 10.6566Z"
                  fill="#26A69B"
                />
              </svg>
            </div>
          </div>
          <div className="sellerShowroom__infoWrapper">
            <div>
              <p className="mb-2">Turnover:</p>
              <p className="mb-0">
                <strong>${company.turnover}</strong>
              </p>
            </div>
            <div className="ms-auto">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F7F7F7" />
                <path
                  d="M26.6919 27.0279C26.4679 27.0279 26.2439 27.0839 26.1039 27.2239C24.3959 28.6519 22.2399 29.4639 19.9999 29.4639C14.7919 29.4639 10.5639 25.2359 10.5639 20.0279V19.1599H12.1319C12.3839 19.1599 12.5799 19.0199 12.7199 18.8239C12.8319 18.5999 12.8319 18.3479 12.6919 18.1519L10.2839 14.3999C10.1719 14.2319 9.97592 14.1479 9.72392 14.1479C9.47192 14.1479 9.27592 14.2599 9.16392 14.3999L6.81192 18.1239C6.67192 18.3199 6.67192 18.5719 6.78392 18.7959C6.89592 19.0199 7.11992 19.1319 7.37192 19.1319H8.93992V19.9999C8.93992 26.1039 13.8959 31.0599 20.0279 31.0599C22.6319 31.0599 25.1799 30.1359 27.1679 28.4559C27.5039 28.1759 27.5599 27.6439 27.2519 27.3079C27.1119 27.1399 26.9159 27.0279 26.6919 27.0279Z"
                  fill="#26A69B"
                />
                <path
                  d="M33.2172 21.2041C33.1052 20.9801 32.8812 20.8681 32.6292 20.8681H31.0612V20.0001C31.0612 13.8961 26.1052 8.91211 19.9732 8.91211C17.3692 8.91211 14.8212 9.83611 12.8332 11.5161C12.4972 11.7961 12.4412 12.3281 12.7492 12.6641C13.0292 13.0001 13.5612 13.0561 13.8972 12.7481C15.6052 11.3201 17.7612 10.5081 20.0012 10.5081C25.2092 10.5081 29.4372 14.7361 29.4372 19.9441V20.8121H27.8692C27.6172 20.8121 27.4212 20.9521 27.2812 21.1481C27.1692 21.3441 27.1692 21.6241 27.3092 21.8201L29.7172 25.6001C29.8292 25.7681 30.0532 25.8801 30.2772 25.8801C30.5012 25.8801 30.7252 25.7681 30.8372 25.6001L33.2172 21.8761C33.3292 21.6801 33.3292 21.4281 33.2172 21.2041Z"
                  fill="#26A69B"
                />
                <path
                  d="M26.1598 19.9998C26.1598 16.5838 23.3878 13.8398 19.9998 13.8398C16.5838 13.8398 13.8398 16.6118 13.8398 19.9998C13.8398 23.3878 16.6118 26.1598 19.9998 26.1598C23.4158 26.1598 26.1598 23.4158 26.1598 19.9998ZM17.5638 21.2038H16.9198C16.8078 21.2038 16.7518 21.1198 16.7518 21.0358V20.4758C16.7518 20.3638 16.8358 20.3078 16.9198 20.3078H17.3958C17.3958 20.1958 17.3958 20.1118 17.3958 20.0278C17.3958 19.9438 17.3958 19.8318 17.3958 19.7478H16.9198C16.8078 19.7478 16.7518 19.6638 16.7518 19.5798V19.0198C16.7518 18.9078 16.8358 18.8518 16.9198 18.8518H17.5638C18.0118 17.5638 19.1318 16.7238 20.3638 16.7238C21.1198 16.7238 21.8198 17.0318 22.3798 17.5918C22.4078 17.6198 22.4358 17.6758 22.4358 17.7318C22.4358 17.7878 22.4078 17.8158 22.3798 17.8718L21.7638 18.4318C21.6798 18.4878 21.5958 18.4878 21.5118 18.4318C21.1758 18.0958 20.7838 17.9278 20.3638 17.9278C19.7758 17.9278 19.2158 18.2918 18.8798 18.8798H20.4198C20.5318 18.8798 20.5878 18.9638 20.5878 19.0478V19.6078C20.5878 19.7198 20.5038 19.7758 20.4198 19.7758H18.5718C18.5718 19.8878 18.5438 19.9718 18.5438 20.0558C18.5438 20.1398 18.5438 20.2518 18.5718 20.3358H20.4198C20.5318 20.3358 20.5878 20.4198 20.5878 20.5038V21.0638C20.5878 21.1758 20.5038 21.2318 20.4198 21.2318H18.8518C19.1878 21.8198 19.7478 22.1838 20.3358 22.1838C20.7558 22.1838 21.1758 22.0158 21.4838 21.6798C21.5398 21.6238 21.6518 21.5958 21.7358 21.6798L22.3518 22.2398C22.3798 22.2678 22.4078 22.3238 22.4078 22.3798C22.4078 22.4358 22.3798 22.4638 22.3518 22.5198C21.7918 23.0798 21.0918 23.3878 20.3358 23.3878C19.1318 23.3318 18.0118 22.4918 17.5638 21.2038Z"
                  fill="#26A69B"
                />
              </svg>
            </div>
          </div>
          <div className="sellerShowroom__infoWrapper">
            <div>
              <p className="mb-2">Export:</p>
              <p className="mb-0">
                <strong>{company.export}%</strong>
              </p>
            </div>
            <div className="ms-auto">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F7F7F7" />
                <path
                  d="M28.2521 19.9737C27.5153 19.62 27.0732 18.9421 27.0143 18.1464C26.0417 18.2053 25.0985 18.4411 24.2143 18.8832L22.7407 19.5611C22.4754 19.679 22.1807 19.7674 21.886 19.7674C21.2376 19.7674 20.5892 19.4432 20.206 18.9127C19.8229 18.3822 19.705 17.6748 19.9113 17.0559C20.4418 15.3759 21.5028 13.9907 22.8291 13.0181H15.284C14.724 13.0181 14.2525 13.4896 14.2525 14.0496V15.6412H12.543C11.3936 15.6412 10.362 16.378 10.0083 17.4685L8.97678 20.5337L6.82525 20.9463C6.35368 21.0347 6 21.4474 6 21.9484V25.1904C6 25.7504 6.47157 26.222 7.03156 26.222H8.15153C8.15153 27.8135 9.44835 29.1398 11.0694 29.1398C12.6609 29.1398 13.9872 27.8135 13.9872 26.222H21.7681C21.7681 27.8135 23.0944 29.1398 24.6859 29.1398C26.2775 29.1398 27.6037 27.8135 27.6037 26.222H28.7237C29.2837 26.222 29.7553 25.7504 29.7553 25.1904V20.1211C29.6079 20.1505 29.4311 20.18 29.2542 20.18C28.9006 20.2095 28.5469 20.1211 28.2521 19.9737ZM11.0399 27.5777C10.2736 27.5777 9.65466 26.9588 9.65466 26.1925C9.65466 25.4262 10.2736 24.8073 11.0399 24.8073C11.8062 24.8073 12.4251 25.4262 12.4251 26.1925C12.4251 26.9588 11.8062 27.5777 11.0399 27.5777ZM14.2525 20.2095H10.6862L11.4525 17.8811C11.5999 17.4095 12.042 17.1148 12.543 17.1148H14.2525V20.2095ZM24.6859 27.5777C23.9196 27.5777 23.3007 26.9588 23.3007 26.1925C23.3007 25.4262 23.9196 24.8073 24.6859 24.8073C25.4522 24.8073 26.0711 25.4262 26.0711 26.1925C26.0417 26.9588 25.4227 27.5777 24.6859 27.5777Z"
                  fill="#26A69B"
                />
                <path
                  d="M33.733 14.2853L29.6067 11.1317C29.4004 10.9843 29.1352 10.9549 28.9289 11.0727C28.7226 11.1906 28.5752 11.3969 28.5752 11.6622V13.0769H27.4257C24.6553 13.0769 22.209 14.8748 21.3838 17.4979C21.2659 17.9105 21.6785 18.2642 22.0617 18.0873L23.5353 17.4094C24.8026 16.82 26.1879 16.5253 27.5731 16.5253H28.5752V17.94C28.5752 18.1757 28.7226 18.4115 28.9289 18.5294C29.0173 18.5884 29.1057 18.5884 29.2236 18.5884C29.371 18.5884 29.4889 18.5294 29.6067 18.441L33.733 15.2874C33.8803 15.1695 33.9982 14.9632 33.9982 14.7863C33.9982 14.6095 33.9098 14.4032 33.733 14.2853Z"
                  fill="#26A69B"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="sellerShowroom__card">
        <h3 className="sellerShowroom__title">Location</h3>
        <div className="sellerShowroom__location">
          <StaticGoogleMap
            size="300x300"
            zoom={12}
            className="img-fluid"
            apiKey="AIzaSyChUeLx-Y_B9wP7OZ4PItaiqrYNh4wYl6w"
          >
            <Marker
              location={`${company.address}, ${company.city}, ${company.country}`}
            />
          </StaticGoogleMap>
        </div>
      </div>
      <Link
        to={`/marketplace/company/${company.slug}`}
        className="sellerShowroom__viewCompanyFacts"
      >
        <div className="d-flex justify-content-center align-items-center">
          <div>VIEW COMPANY FACTS</div>
          <div className="ms-2">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99984 6.66309L8.58984 8.07309L13.1698 12.6631L8.58984 17.2531L9.99984 18.6631L15.9998 12.6631L9.99984 6.66309Z"
                fill="#004D40"
              />
            </svg>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default CompanyInfo
