/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { v4 } from 'uuid'
import { Helmet } from 'react-helmet-async'
import { useParams, useLocation } from 'react-router-dom'
import { Skeleton, Form, Button, notification, message } from 'antd'

import ProductInformation from '../../components/pages/product-upload/ProductInformation'
import ProductImages from '../../components/pages/product-upload/ProductImages'
import ProductAttributes from '../../components/pages/product-upload/ProductAttributes'
import ProductDetails from '../../components/pages/product-upload/ProductDetails'
import PackagingLogistic from '../../components/pages/product-upload/PackagingLogistic'
import PackagingTypes from '../../components/pages/product-upload/PackagingTypes'
import SalesTerms from '../../components/pages/product-upload/SalesTerms'

import { history } from '../../index'
import axiosRequest from '../../hooks/useAxiosRequest'
import productVariants from './ProductVariants'
import { AsyncMessage, ErrorMessage, SuccessMessage } from '../../models/Messages'
import { removeHTMLTags } from '../../util/functions/removeHTMLTags'

const pageTabs = [
  {
    key: 1,
    name: 'Product Information',
  },
  {
    key: 2,
    name: 'Product Images',
  },
  {
    key: 3,
    name: 'Product Attributes',
  },
  {
    key: 4,
    name: 'Product Details',
  },
  {
    key: 5,
    name: 'Packaging & Logistics',
  },
  {
    key: 6,
    name: 'Packaging & Logistics',
  },
  {
    key: 7,
    name: 'Sales Terms',
  },
]

const ProductUpload = () => {
  const initialProductData = {
    parentProduct: null,
    name: '',
    brands: [],
    mainImage: `${process.env.REACT_APP_TTW_CDN_URL}/web/common/product-image-placeholder.svg`,
    images: [],
    tags: [],
    description: '',
    productYear: null,
    isProductCustomizable: false,
    customizableOptions: {
      packaging: false,
      productFeatures: false,
      labelBrand: false,
    },
    subIndustries: [],
    quantity: 0,
    dimensions: {
      measure: 'In',
      width: 0,
      height: 0,
      depth: 0,
    },
    capacity: {
      measure: 'in3',
      value: 0,
    },
    weight: {
      measure: 'kg',
      value: 0,
    },
    originCountry: '',
    attributes: [],
    barcode: {
      haveBarcode: true,
      barcodeType: 'UPC',
      barcode: '',
    },
    uspCode: '',
    htsCode: '',
    packagingLogistic: {
      isColdStorage: false,
      isStackable: false,
      havePackaging: true,
      packages: [],
    },
    prices: [
      {
        id: null,
        key: 1,
        currency: 'USD',
        order_measure: 'unit',
        minimalOrderUnits: null,
        minimalOrderValue: null,
        isAvailable: false,
        availableQty: 0,
        isDropShipping: false,
        stockLocation: null,
        discount: null,
        prices: [
          {
            key: v4(),
            type: 'by_qty',
            priceValue: 0,
            minPrice: 0,
            maxPrice: 0,
            minQty: 0,
            maxQty: 0,
            discount: 0,
          },
        ],
      },
    ],
    warranty: {
      isWarranty: false,
      period: {
        timeUnit: '',
        length: 0,
        start: [],
      },
    },
    isProductAvailableNow: true,
    isDropShipping: false,
    IsAvailablePrivateLabel: false,
    inventoryCondition: 'new',
  }

  const { slug, action } = useParams()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const parentVariant = query.get('parentVariant')
  const parentVariantSetFields = query.get('parentVariantSetFields')
  const [form] = Form.useForm()
  const [uploadingStep, setUploadingStep] = useState(1)
  const [pageTitle, setPageTitle] = useState('Create new product')
  const [loading, setLoading] = useState(true)
  const [dataLoading, setDataLoading] = useState(true)
  const [product, setProductData] = useState(initialProductData)
  const [data, setData] = useState({})

  useEffect(() => {
    let stockLocations = []
    axiosRequest('/api/v1/products/data', null, 'get', true)
      .then((res) => {
        if (res.data.brands) {
          const brands = []
          const categories = []
          const attributes = []
          res.data.brands.forEach((brand) => {
            brands.push(brand)
          })
          res.data.categories.forEach((category) => {
            category.get_sub_industry.forEach((subIndustry) => {
              categories.push({
                id: subIndustry.id,
                name: subIndustry.name,
                parent: category.id,
              })
            })
          })
          res.data.attributes.forEach((attribute) => {
            attributes.push(attribute)
          })
          stockLocations = res.data.stockLocations
          setData({
            ...data,
            brands,
            categories,
            attributes,
            stockLocations: res.data.stockLocations,
            packagingTypes: res.data.packagingTypes,
          })
          setDataLoading(false)
        }
      })
      .then(() => {
        if (
          (action === 'edit' || action === 'clone' || slug === 'create-variant') &&
          slug !== 'create'
        ) {
          let productSlug = slug
          if (parentVariant) {
            productSlug = parentVariant
          }
          axiosRequest(`/api/v1/products/single/${productSlug}`, null, 'get', true)
            .then((res) => {
              if (res.data.success && res.data.product) {
                let productObj = product
                const getProduct = res.data.product
                productObj.id = getProduct.id
                productObj.parentProduct = getProduct.product_variant || null
                productObj.name = getProduct.name
                productObj.productYear = getProduct.year || null
                productObj.brands = getProduct.get_brands.map((b) => b.id) ?? []
                productObj.mainImage = getProduct.get_main_image.original || null
                const imagesArr = []
                getProduct.get_additional_images &&
                  getProduct.get_additional_images.forEach((image) => {
                    imagesArr.push(image)
                  })
                productObj.images = imagesArr
                productObj.description = getProduct.body
                productObj.originCountry = getProduct.origin_country
                productObj.isProductCustomizable = getProduct.product_data.is_customizable
                productObj.inventoryCondition = getProduct.product_data.inventory_condition
                productObj.customizableOptions = {
                  packaging: getProduct.product_data.customizable_packaging,
                  productFeatures: getProduct.product_data.customizable_product_features,
                  labelBrand: getProduct.product_data.customizable_brand_label,
                }
                productObj.subIndustries =
                  getProduct.get_sub_industries.map((c) => c.id) ?? []
                productObj.dimensions = {
                  measure: getProduct.product_data.dimension_measure,
                  width: getProduct.product_data.dimension_width,
                  height: getProduct.product_data.dimension_height,
                  depth: getProduct.product_data.dimension_depth,
                }
                productObj.capacity = {
                  measure: getProduct.product_data.capacity_measure,
                  value: getProduct.product_data.capacity_value,
                }
                productObj.weight = {
                  measure: getProduct.product_data.weight_measure,
                  value: getProduct.product_data.weight_value,
                }
                const attrArr = []
                getProduct.get_product_attributes &&
                  getProduct.get_product_attributes.forEach((attrVal) => {
                    attrArr.push({
                      uuid: v4(),
                      key: attrVal.id,
                      id: parseInt(attrVal.attribute, 10),
                      value: attrVal.value,
                      type: attrVal.get_attribute_details.type,
                      measure: attrVal.measure,
                      label: attrVal.get_attribute_details.name,
                    })
                  })
                productObj.attributes = attrArr
                productObj.barcode = {
                  haveBarcode: getProduct.product_data.product_have_barcode,
                  barcodeType: getProduct.product_data.barcode_type,
                  barcode: getProduct.product_data.barcode,
                }
                productObj.uspCode = getProduct.product_data.usp
                productObj.htsCode = getProduct.product_data.hts

                // WARRANTY -> HAS TO BE IN IF, BECAUSO NO RES FROM THE BACKEND CONTAINING INFOS SO THROWS AN UNDEFINED ERROR
                if (getProduct.warranty_period) {
                  productObj.warranty.isWarranty = true
                  productObj.warranty.period.timeUnit = getProduct.warranty_period
                  productObj.warranty.period.length = getProduct.warranty_limit
                  productObj.warranty.period.start = []

                  getProduct.warranty_from_purchase &&
                    productObj.warranty.period.start.push('purchase')
                  getProduct.warranty_from_shipping &&
                    productObj.warranty.period.start.push('shipping')
                  getProduct.warranty_from_delivery &&
                    productObj.warranty.period.start.push('delivery')
                }

                // Prepare packaging array
                const packagingArray = []
                if (getProduct.get_packaging && getProduct.get_packaging.length > 0) {
                  getProduct.get_packaging.forEach((packaging) => {
                    const variantsArray = []
                    if (packaging.get_variants && packaging.get_variants.length > 0) {
                      packaging.get_variants.forEach((variant) => {
                        variantsArray.push({
                          key: variant.key,
                          typeId: variant.type,
                          variantId: variant.variant,
                          productPerInnerPack: variant.productPerInnerPack,
                          innerPackPrice: variant.innerPackPrice,
                          innerPackBarcode: {
                            type: variant.innerPackBarcodeType,
                            value: variant.innerPackBarcodeValue,
                          },
                          innerPackDimensions: {
                            type: variant.innerPackDimensionsType,
                            width: variant.innerPackDimensionsWidth,
                            height: variant.innerPackDimensionsHeight,
                            depth: variant.innerPackDimensionsDepth,
                          },
                          innerPackVolume: {
                            type: variant.innerPackVolumeType,
                            value: variant.innerPackVolumeValue,
                          },
                          innerPackWeight: {
                            type: variant.innerPackWeightType,
                            value: variant.innerPackWeightValue,
                          },
                          innerPackWeightTotal: {
                            type: variant.innerPackWeightTotalType,
                            value: variant.innerPackWeightTotalValue,
                          },
                        })
                      })
                    }
                    packagingArray.push({
                      key: packaging.key,
                      title: packaging.name,
                      variants: variantsArray,
                    })
                  })
                }
                productObj.packagingLogistic = {
                  isColdStorage: getProduct.product_data.required_cold_storage,
                  isStackable: getProduct.product_data.is_stackable,
                  havePackaging: getProduct.product_data.have_packaging,
                  packages: packagingArray,
                }
                productObj.isProductAvailableNow = getProduct.product_data.available_now
                productObj.isDropShipping = getProduct.product_data.is_drop_shipping
                productObj.IsAvailablePrivateLabel =
                  getProduct.product_data.available_for_private_label

                /*
                 * -------------------------
                 * PRICES
                 * -------------------------
                 */
                const pricesArr = []
                getProduct.sales_terms &&
                  getProduct.sales_terms.forEach((price) => {
                    if (
                      stockLocations &&
                      !stockLocations.find((loc) => loc.id === price.location.id)
                    )
                      return

                    const prices = []

                    if (price.prices && price.prices.length > 0) {
                      price.prices.forEach((item) => {
                        prices.push({
                          id: item.id,
                          priceValue: item.price_value,
                          minPrice: item.min_price || 0,
                          maxPrice: item.max_price || 0,
                          discount: item.discount_percentage || 0,
                          minQty: item.min_qty || 0,
                          maxQty: item.max_qty || 0,
                          type: item.type || 'by_qty',
                        })
                      })
                    }
                    pricesArr.push({
                      id: price.id,
                      key: price.id,
                      order_measure: price.order_measure || 'unit',
                      currency: price.currency,
                      minimalOrderUnits: price.min_order_quantity || null,
                      minimalOrderValue: price.min_order_value || null,
                      stockLocation:
                        { ...price.location, key: price.location.id } || null,
                      isAvailable: price.is_available || false,
                      availableQty: price.available_qty || 0,
                      isDropShipping: price.is_drop_shipping || false,
                      discount: price.discount || null,
                      prices: prices,
                    })
                  })

                productObj.prices = pricesArr
                productObj.tags = []
                if (getProduct.get_tags && getProduct.get_tags.length > 0) {
                  getProduct.get_tags.forEach((tag) => {
                    productObj.tags.push({
                      label: tag.get_tag.name,
                      value: tag.get_tag.name,
                      key: tag.get_tag.id,
                    })
                  })
                }

                if(slug === 'create-variant' || action === 'clone' ) {
                  product.prices = product.prices.map((price) => ({
                    ...price,
                    id: null,
                    key: v4(),
                  }))
                }

                if (slug === 'create-variant' && parentVariant) {
                  if (parentVariantSetFields === 'true') {
                    productObj.name = ''
                    productObj.mainImage = ''
                    productObj.barcode.barcode = ''
                    productObj.images = []
                    setProductData({ ...productObj })
                  }
                  if (parentVariantSetFields === 'false') {
                    setProductData({ ...initialProductData })
                  }
                } else {
                  setProductData({ ...productObj })
                }
                setLoading(false)
              }
            })
            .catch((e) => {
              console.log(e)
              message.error(ErrorMessage.product.notFound)
            })
        } else {
          setLoading(false)
        }
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, action])

  const updateProductData = (field, value) => {
    const newProductData = product

    if (field === 'warranty.isWarranty' && value === false) {
      const warrantyReset = {
        isWarranty: false,
        period: {
          timeUnit: null,
          length: null,
          start: [],
        },
      }
      return setProductData({ ...newProductData, warranty: warrantyReset })
    }

    _.set(newProductData, field, value)
    setProductData({ ...newProductData })
  }

  const onFinish = async () => {
    if (
      removeHTMLTags(product.description) &&
      removeHTMLTags(product.description).length > 1500
    ) {
      return message.error(
        'Please, provide a description with a maximum of 1500 characters',
      )
    }

    if (product.prices.length === 0 || parseInt(product.prices[0].priceValue) === 0) {
      return message.error('Please, provide at least one price')
    }

    console.log(parentVariant, product)
    if (parentVariant) {
      updateProductData('parentProduct', parentVariant)
    }
    let url = `/api/v1/products/${product.id}`
    let method = 'patch'
    if (action === 'clone' || slug === 'create' || slug === 'create-variant') {
      url = '/api/v1/products/create'
      method = 'post'
    }
    message.loading({ content: AsyncMessage.loading, key: 'save' })

    let body = { ...product }

    // FIXME: Logic to be transfered to backend.
    body.prices.forEach((priceTerm) => {
      let { prices } = priceTerm
      if (!!prices)
        prices.forEach((priceItem) => {
          if (priceItem.option === 'avg') priceItem.maxPrice = 0
          if (priceItem.option === 'onDemand') {
            priceItem.price = 0
            priceItem.maxPrice = 0
          }
        })
    })

    // Handle warranty
    body = {
      ...body,
      warranty_period: product.warranty.period.timeUnit,
      warranty_limit: product.warranty.period.length,
      warranty_from_purchase: product.warranty.period.start.includes('purchase'),
      warranty_from_shipping: product.warranty.period.start.includes('shipping'),
      warranty_from_delivery: product.warranty.period.start.includes('delivery'),
    }

    axiosRequest(url, body, method, true)
      .then((res) => {
        message.success({ content: SuccessMessage.product.create, key: 'save' })
        if (res.data.variant) {
          history.push(`/products/post-save/variants/${res.data.variant}`)
        }
      })
      .catch((e) => {
        message.destroy()
        let errors = ''
        errors += e.response.data.message
        if (e.response.data.errors) {
          Object.keys(e.response.data.errors).forEach((error) => {
            errors += `<br />${e.response.data.errors[error]}`
          })
        }
        notification.error({
          description: <div dangerouslySetInnerHTML={{ __html: errors }} />,
          message: ErrorMessage.product.notSaved,
        })
      })
  }

  const onFinishFailed = (error) => {
    notification.error({
      description: error.errorFields[0].errors[0],
      message: 'Please, complete all required fields.',
    })
  }

  return (
    <>
      <Helmet title={pageTitle} />
      {loading || dataLoading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ ...product }}
          layout="vertical"
        >
          <div className="products-upload">
            <div className="upload-content mb-5 pb-5">
              <h1>{_.find(pageTabs, ['key', uploadingStep]).name}</h1>
              {uploadingStep === 1 && (
                <ProductInformation
                  title="Product Information"
                  product={product}
                  updateProduct={updateProductData}
                  brands={data.brands || []}
                />
              )}
              {uploadingStep === 2 && (
                <ProductImages
                  title="Product Images"
                  product={product}
                  updateProduct={updateProductData}
                />
              )}
              {uploadingStep === 3 && (
                <ProductAttributes
                  form={form}
                  title="Product Attributes"
                  product={product}
                  updateProductData={updateProductData}
                  setProductData={setProductData}
                  categories={data.categories || []}
                  attributes={data.attributes || []}
                />
              )}
              {uploadingStep === 4 && (
                <ProductDetails
                  title="Product Details"
                  product={product}
                  updateProduct={updateProductData}
                />
              )}
              {uploadingStep === 5 && (
                <PackagingLogistic
                  title="Packaging & Logistic"
                  product={product}
                  updateProduct={updateProductData}
                />
              )}
              {uploadingStep === 6 && (
                <PackagingTypes
                  product={product}
                  updateProduct={updateProductData}
                  packagingTypesData={data.packagingTypes}
                />
              )}
              {uploadingStep === 7 && (
                <SalesTerms
                  data={data.stockLocations || []}
                  product={product}
                  updateProduct={updateProductData}
                />
              )}
            </div>
            <div className="upload-navbar">
              <div className="navbar__wrapper">
                <h3>Product Upload</h3>
                <ul>
                  {pageTabs.map((tab) => {
                    return (
                      <li
                        key={tab.key}
                        className={tab.key === uploadingStep ? 'active' : ''}
                      >
                        <span
                          onClick={() => {
                            setUploadingStep(tab.key)
                          }}
                        >
                          {tab.name}
                        </span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className="upload-controls">
              <div className="next-prev-wrapper">
                <Button
                  style={{ opacity: uploadingStep === 1 ? 0 : 1 }}
                  disabled={uploadingStep === 1}
                  className="prev-btn"
                  onClick={() => setUploadingStep(uploadingStep - 1)}
                >
                  <i className="fe fe-chevron-left" /> <span>Back</span>
                </Button>
                <Button
                  style={{ opacity: uploadingStep === 7 ? 0 : 1 }}
                  disabled={uploadingStep === 7}
                  className="next-btn"
                  onClick={() => {
                    setUploadingStep(uploadingStep + 1)
                  }}
                >
                  <span>Continue</span> <i className="fe fe-chevron-right" />
                </Button>
              </div>
              <div className="next-prev-wrapper save-button-wrapper">
                <Button
                  className="next-btn mr-4"
                  onClick={() => {
                    history.push('/products/list/all')
                  }}
                >
                  <span>Cancel</span>
                </Button>
                {action === 'clone' || slug === 'create' || slug === 'create-variant' ? (
                  <Button
                    style={{ display: uploadingStep !== 7 ? 'none' : 'block' }}
                    disabled={uploadingStep !== 7}
                    htmlType="submit"
                    className="ant-btn-primary"
                  >
                    Save
                  </Button>
                ) : (
                  <Button htmlType="submit" className="ant-btn-primary">
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </>
  )
}

export default ProductUpload
