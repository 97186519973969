export class ErrorMessage {
  static title = 'Error!'

  static server = {
    generic: 'Something went wrong. Contact support or try again later.'
  }

  static profile = {
    submition: {
      updateGeneric: 'Something wrong ocurred when updating your profile, try again later or contact support.',
    }
  }

  static product = {
    notSaved: 'Product not saved. Please review the provided information.',
    notFound: 'Product not found. Reload the page and try again, please.'
  }

  static login = {
    emailUnverified: 'Please, verify your email before logging in.',
    blocked: 'Your account has been blocked. For further information contact support.'
  }

  static styles = {
    notSaved: 'Something went wrong updating your company identity. Please, contact support or try again later.'
  }
}
