import { useCallback, useState } from 'react'
import { HexColorPicker } from 'react-colorful'

import { FormatController, BlockPicker, Footer } from './'

/**
 * Color picker with hex color input, format controls, and recent color options.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.defaultColor - The default color for the color picker.
 * @param {function} props.callback - The callback function to handle color changes.
 * @param {Array} props.recentColors - An array of recently selected colors.
 * @returns {JSX.Element} - The rendered ColorPicker component.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const ColorPicker = ({ defaultColor = '#000', callback, recentColors = [] }) => {
  const [color, setColor] = useState(defaultColor)

  /**
   * Apply the selected color using the callback.
   * @function
   */
  const handleApply = useCallback(() => callback(color), [callback, color])

  /**
   * Cancel color selection and revert to default color using the callback.
   * @function
   */
  const handleCancel = useCallback(() => callback(defaultColor), [defaultColor, callback])

  return (
    <div className="color-picker">
      <HexColorPicker color={color} onChange={setColor} />
      <FormatController currentColor={color} setColor={setColor} />
      <BlockPicker
        colors={[
          '#000',
          ...recentColors,
          '#FF0000',
          '#0066FF',
          '#FAFF00',
          '#00FFD1',
          '#00FF29',
          '#FF0000',
        ]}
        setColor={setColor}
      />
      <Footer onCancel={handleCancel} onSave={handleApply} />
    </div>
  )
}
