import { useState, useEffect } from 'react'
import { Modal, Form, Checkbox, message } from 'antd'
import _ from 'lodash'

import { InterestedIndustriesModalFooter } from './InterestedIndustriesModalFooter'
import LayoutLoading from '../../verification/LayoutLoading'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { useSelector } from 'react-redux'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'

export const InterestedIndustriesModal = ({ isShow = true }) => {
  const { user } = useSelector((state) => state.user)
  const [isOpen, setIsOpen] = useState(isShow)
  const [industries, setIndustries] = useState([])
  const [company, setCompanyData] = useState({})
  const [selectedIndustries, setSelectedIndustries] = useState([])

  const handleSkip = () => {
    setIsOpen(false)
  }

  const getChannel = (userType, submit = false, values = { channel: 'tmp_channel' }) => {
    if (userType === 'seller' && submit && values) return values.channel
    else if (userType === 'seller' && !submit) return company.channel
    else if (userType === 'buyer' && submit && company.channel) return company.channel
    else if (userType === 'buyer' && submit && !company.channel)
      return user.channel ? user.channel : 'Marketer'
  }

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/company-industries', null, 'get', true).then(
      (res) => {
        if (res.data) {
          setIndustries(res.data.data)
          setCompanyData(res.data.company)
          const companyIndustries = []
          res.data.industries &&
            res.data.industries.forEach((value) => {
              companyIndustries.push(value.industry)
            })
          setSelectedIndustries([...companyIndustries])
        }
      },
    )
  }, [])

  const onCheckboxSelect = (target, value) => {
    const selected = selectedIndustries
    if (target.checked) {
      selected.push(value)
    } else {
      _.remove(selected, (n) => {
        return n === value
      })
    }
    setSelectedIndustries([...selected])
  }

  const onSubmit = () => {
    if (selectedIndustries.length < 1) {
      message.error('Please, select industries...')
    } else {
      message.loading({ content: 'Please, wait...', key: 'save' })
      axiosRequest(
        '/api/v1/on-boarding/company-industries',
        { channel: getChannel('buyer', true), industries: selectedIndustries },
        'post',
        true,
      ).then((res) => {
        if (res.data.success) {
          updateAccountStatus(3, user.id)
          message.destroy()
        }
      })
    }
    setIsOpen(false)
  }

  const onSubmitFail = () => {
    message.error('Something goes wrong, contact support...')
  }

  if (industries.length === 0) return <LayoutLoading />

  return (
    <Modal
      className="interested-industries-modal"
      centered
      visible={isOpen}
      closable={true}
      footer={<InterestedIndustriesModalFooter onSkip={handleSkip} onSubmit={onSubmit} />}
      width={872}
      title={
        <div>
          <h2>Interested Categories</h2>
          <p>
            Help us better understand your preferences! Please select 3 categories that
            interest you as a Buyer:
          </p>
        </div>
      }
      onCancel={handleSkip}
      destroyOnClose={true}
    >
      <Form
        onFinish={onSubmit}
        onFinishFailed={onSubmitFail}
        layout="vertical"
        hideRequiredMark
        className="mb-0"
      >
        <div className="company__industries">
          {industries.length > 0 &&
            industries.map((industry) => {
              return (
                <div className="text-center industry-item" key={industry.id}>
                  <Form.Item className="mb-0">
                    <Checkbox
                      value={industry.id}
                      onChange={(e) => onCheckboxSelect(e.target, industry.id)}
                      checked={selectedIndustries.includes(industry.id)}
                    >
                      <div className="industry__wrapper">
                        <div className="industry__icon">
                          <img src={industry.icon} alt={industry.name} />
                        </div>
                        <h4 className="industry__name">{industry.name}</h4>
                      </div>
                    </Checkbox>
                  </Form.Item>
                </div>
              )
            })}
        </div>
      </Form>
    </Modal>
  )
}
