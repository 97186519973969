import axios from "axios"
import { message } from "antd"

import { RestAPI } from "./RestAPI.interface"

export class ServiceTypeAPI extends RestAPI {
  static BASE_URL = `${process.env.REACT_APP_SERVICE_PROVIDER_URL}/services`

  static notifyError = (text) => {
    message.error(text)
  }

  static request = async (method, path, data = null) => {
    let response = null
    response = await axios[method](`${this.BASE_URL}${path}`, data)
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(response.data)
    } else {
      return response
    }
  }

  static get = async (path) => await this.request('get', path)

  static index = async () => {
    let services = []
    try {
      const response = await this.get('/')
      services = response.data.services
    } catch (error) {
      this.notifyError('Failed to get Services')
    } finally {
      return services
    }
  }
}
