import { ColorController } from '../models/ui/StylePicker/ColorController'
import { FontController } from '../models/ui/StylePicker/FontController'

// COLORS
const primaryColor = new ColorController(
  'Primary Color',
  'Dominant color of your identity',
).setKey('primary')
const secondaryColor = new ColorController(
  'Secondary Color',
  'Additional color of your identity',
).setKey('secondary')
const textMain = new ColorController('Text Color #1', 'The main text color').setKey(
  'text_1',
)
const textSecondary = new ColorController(
  'Text Color #2',
  'Additional text color',
).setKey('text_2')
const bgMain = new ColorController(
  'Background Color #1',
  'The main background color',
).setKey('bg_1')
const bgSecondary = new ColorController(
  'Background Color #2',
  'Additional background color',
).setKey('bg_2')
const accent = new ColorController(
  'Accent Color',
  'The color of accent and highlighted elements',
).setKey('accent')
const additional = new ColorController(
  'Additional Color',
  'The color of decorative elements, icons and underlines',
).setKey('additional')

// FONTS
const titleFont = new FontController('Title Font').setKey('title')
const bodyFont = new FontController('Text Font').setKey('body')

// EXPORTS
export const COLOR_CONTROLLERS = [
  primaryColor,
  secondaryColor,
  textMain,
  textSecondary,
  bgMain,
  bgSecondary,
  accent,
  additional,
]
export const FONT_CONTROLLERS = [titleFont, bodyFont]
