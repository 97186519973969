import React from 'react'
import { Rate } from 'antd'

import ProductManufacturer from './ProductManufacturer'

import stockIcon from '../../../../assets/badges/stock.png'
import checkIcon from '../../../../assets/badges/check.png'
import tagsIcon from '../../../../assets/badges/tags.png'

const ProductInfo = ({ product }) => {
  return (
    <div className="product-details__title">
      <h1>{product.product.name}</h1>
      <div className="subtitle">
        <div className="d-flex justify-content-start align-items-center">
          <div className="d-flex flex-column justify-content-start align-items-start">
            <div className="d-flex justify-content-start align-items-center mb-2">
              <div className="product-rate">
                <Rate disabled defaultValue={5} />
              </div>
              <div className="text" style={{ marginRight: '16px' }}>
                <span>Quality of responses (48)</span>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <div className="badges">
                <ul>
                  <li>
                    <img src={stockIcon} alt="" />
                  </li>
                  <li>
                    <img src={checkIcon} alt="" />
                  </li>
                  <li>
                    <img src={tagsIcon} alt="" />
                  </li>
                </ul>
              </div>
              <div className="text">
                <span>{product.product.product_data.ttw_code}</span>
              </div>
            </div>
          </div>
          <div className="ms-auto">
            <ProductManufacturer product={product} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductInfo
