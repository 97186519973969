import { getVerificationSteps } from '../../../data/verficationSteps'
import { ProfileProgressBuilder } from './ProfileProgressBuilder.interface'

export class ServiceProviderGeneralProfileProgressBuilder extends ProfileProgressBuilder {
  PROFILE_STEPS = [
    'personalInfo',
    'companyInfo',
    'additionalInfo',
    'salesPresence',
    'companyLogo',
    'companyIndustries',
    'companySubIndustries',
  ]

  getAllFields() {
    return getVerificationSteps('service-provider')
  }

  getCompletedFields() {
    let completedSteps = this.filterSteps()
    return completedSteps
  }

  filterSteps() {
    let steps = [...this.user.steps]
    steps = steps.filter(({ step }) => this.PROFILE_STEPS.includes(step))
    return steps
  }
}
