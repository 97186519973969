import { Form, AutoComplete } from 'antd'

export const SearchInput = ({ fetchTags, tags, handleSelect, isMax, classname = '' }) => {
  return (
    <Form.Item className={classname} name="tagsInput" label="Tags">
      <AutoComplete
        value={undefined}
        searchValue={undefined}
        allowClear
        onSearch={fetchTags}
        options={tags}
        onSelect={handleSelect}
        disabled={isMax}
      />
    </Form.Item>
  )
}
