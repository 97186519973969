import { DefaultServiceProfileProgressBuilder } from './DefaultServiceProfileProgressBuilder.class'
import { LogisticsServiceProfileProgressBuilder } from './LogisticServiceProfileProgressBuilder.class'

export class ServiceTypeProfileProgressFactory {
  profileProgressBuilder

  constructor({ service, user }) {
    switch (service.code) {
      case 'logistic-services':
        this.profileProgressBuilder = new LogisticsServiceProfileProgressBuilder({
          user,
        })
        break
      default:
        this.profileProgressBuilder = new DefaultServiceProfileProgressBuilder({ user })
    }
  }

  getProgress() {
    return this.profileProgressBuilder.build()
  }
}
