import React from 'react'

const ProductTags = ({ tags }) => {
  return (
    <div className="product-tags">
      <ul>
        <li className="text">Tags:</li>
        {tags.map((tag) => (
          <li className="tag" key={tag.id}>
            #{tag.get_tag.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ProductTags
