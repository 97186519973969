import { Button, Modal } from 'antd'

import { useServicesTypeModal } from '../../../../hooks/ui/useServicesTypeModal/useServicesTypeModal'

import SpinLoader from '../../../ui/loader/SpinLoader'
import ServicesTypeModalItem from './ServicesTypeModalItem'

import './ServicesTypeModal.scss'

const ServicesTypeModal = ({ isVisible, hide }) => {
  const { onSubmit, modalItems, isLoading, handleServiceClick } = useServicesTypeModal()

  const handleServiceSubmit = async () => {
    await onSubmit()
    hide()
  }

  return (
    <Modal
      className="dashboard-modal"
      visible={isVisible}
      footer={null}
      width={872}
      onCancel={hide}
    >
      <div className="dashboard-modal__modal-body">
        <h1 className="dashboard-modal__title">Services</h1>
        <p className="dashboard-modal__text">Select the services you provide:</p>
        <ul className="dashboard-modal__list">
          {isLoading ? (
            <SpinLoader />
          ) : (
            modalItems.map((modalItem) => (
              <ServicesTypeModalItem modalItem={modalItem} onClick={handleServiceClick} />
            ))
          )}
        </ul>
      </div>
      <div className="dashboard-modal__modal-footer">
        <Button className="modal-button" type="primary" onClick={handleServiceSubmit}>
          SUBMIT
        </Button>
        <span onClick={hide} className="dashboard-modal__modal-footer__span">
          Skip it for later
        </span>
      </div>
    </Modal>
  )
}

export default ServicesTypeModal
