import React from 'react'

import ProductTags from './ProductTags'

const ProductDescription = ({ product }) => {
  console.log(product)
  return (
    <div className="description-card">
      <h2 className="card-title">Description</h2>
      <div dangerouslySetInnerHTML={{ __html: product.product.body }} />
      <div>
        <ProductTags tags={product.product.get_tags || []} />
      </div>
      <div className="mt-0">
        <div className="product-tags mt-2">
          <ul>
            <li className="text">USP:</li>
            {product.product &&
            product.product.product_data &&
            product.product.product_data.usp ? (
              <li className="tag">{product.product.product_data.usp}</li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProductDescription
