export const DEFAULT_COLORS = new Map()
  .set('primary', '#004d40')
  .set('secondary', '#69f0ae')
  .set('text_1', '#000')
  .set('text_2', '#808080')
  .set('bg_1', '#fff')
  .set('bg_2', '#f3fff9')
  .set('accent', '#b4f7d6')
  .set('additional', '#26a69b')

export const DEFAULT_FONTS = new Map()
  .set('title', 'Open Sans')
  .set('body', 'Open Sans')
