import { Button } from "antd"

export const InterestedIndustriesModalFooter = ({onSkip, onSubmit}) => {

  return (
    <div >
      <div></div>
      <Button onClick={onSubmit} htmlType="submit" type="primary" className="ant-btn-ttu">Submit</Button>
      <p onClick={onSkip}>Skip it for later</p>
    </div>
  )
}
