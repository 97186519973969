import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { message, Skeleton, Button, Popconfirm, Pagination, Tabs } from 'antd'

import { getVerificationSteps } from '../../data/verficationSteps'

import axiosRequest from '../../hooks/useAxiosRequest'
import { history } from '../..'

import PageTitle from '../../components/ui/PageTitle'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { ProductTableRow } from './components/ProductTableRow'
import { useProductInfo } from '../../hooks/useProductInfo'
import ProductOrderManager from '../../components/ui/modals/ProductOrderManager/ProductOrderManager'

const ProductsList = () => {
  const { tab } = useParams()
  const { fieldsInfo } = useProductInfo()
  const [page, setPage] = useState(1)
  const [products, setProducts] = useState([])
  const [isVerified, setIsVerified] = useState(false)
  const [loading, setLoading] = useState(true)

  // modal visibility state
  const [editOrderVisible, setEditOrderVisible] = useState(false)

  const loadProducts = () => {
    axiosRequest(
      `/api/v1/products?page=${page}&perPage=24&&asMasterView=1` /* &perPage=10&asMasterView=1 */,
      null,
      'get',
      true,
    )
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          setProducts(res.data.products)
        }
      })
      .catch(() => {
        message.error('Something goes wrong. Contact customers support.')
      })
  }

  useEffect(() => {
    // TODO: CLEAN CODE
    axiosRequest('/api/v1/on-boarding/personal-info', null, 'get', true)
      .then((res) => {
        if (res.data.steps) {
          setLoading(false)
          const totalSteps = getVerificationSteps() // colocar um spread de roles aqui com a resposta adequada da api
          const isVerifiedUser = res.data.steps.length === totalSteps.length
          setIsVerified(isVerifiedUser)
        }
      })
      .catch(() => {
        message.error('Something goes wrong, contact support...')
      })

    setLoading(true)
    loadProducts()
  }, [page])

  // TODO: REMOVE LOGIC FROM COMPONENT
  const getIncompleteProducts = (products) => {
    const incompleteProducts = []

    for (const p of products) {
      if (fieldsInfo(p).progress >= 100) {
        continue
      } else {
        incompleteProducts.push(p)
      }
    }
    return incompleteProducts
  }

  return (
    <>
      <ProductOrderManager
        isVisible={editOrderVisible}
        handleVisibility={setEditOrderVisible}
        onClose={loadProducts}
      />
      <Helmet title="My Products" />
      <PageTitle title="My Products">
        <Link
          to="/products/create"
          className="ant-btn ant-btn-link ant-btn-sm ant-btn-primary px-2 px-lg-3"
        >
          Add New Product
        </Link>
        <Button
          onClick={() => setEditOrderVisible(true)}
          className="ant-btn ant-btn-link ant-btn-sm ant-btn-primary px-2 px-lg-3 ms-2"
        >
          Edit Product Listing
        </Button>
      </PageTitle>
      {loading || !products.data ? (
        <Skeleton active />
      ) : !isVerified && products.length < 1 ? (
        <>
          <p>To start adding products, please сomplete your Seller Profile.</p>
          <Button
            onClick={() => history.push('/secured/verification/seller/profile-info')}
            type="primary"
            className="ant-btn-ttu ant-btn--spread"
          >
            complete <i className="fe fe-chevron-right"></i>
          </Button>
        </>
      ) : (
        <>
          <Tabs defaultActiveKey={tab}>
            <Tabs.TabPane key={'all'} tab="Products">
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Progress</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>Status</th>
                    <th>Added</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {products.data &&
                    products.data.map((product) => {
                      if (product.get_variants && product.get_variants.length > 0) {
                        return (
                          <>
                            <ProductTableRow
                              loadProducts={loadProducts}
                              product={product}
                            />
                            {product.get_variants.map((variant) => {
                              return (
                                <ProductTableRow
                                  loadProducts={loadProducts}
                                  product={variant}
                                  isMaster={false}
                                />
                              )
                            })}
                          </>
                        )
                      } else {
                        return (
                          <ProductTableRow
                            loadProducts={loadProducts}
                            product={product}
                          />
                        )
                      }
                    })}
                </tbody>
              </table>
              <div className="d-flex justify-content-center">
                <div className="pagination-wrapper mt-3">
                  <Pagination
                    current={page}
                    total={products.total}
                    pageSize={24}
                    onChange={(page) => setPage(page)}
                  />
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane key={'incomplete'} tab="Incomplete">
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Missing Data</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {products.data &&
                    getIncompleteProducts(products.data).map((product) => {
                      if (product.get_variants && product.get_variants.length > 0) {
                        return (
                          <>
                            <ProductTableRow
                              loadProducts={loadProducts}
                              product={product}
                              isIncompleteRow={true}
                            />
                            {product.get_variants.map((variant) => {
                              return (
                                <ProductTableRow
                                  loadProducts={loadProducts}
                                  product={variant}
                                  isMaster={false}
                                  isIncompleteRow={true}
                                />
                              )
                            })}
                          </>
                        )
                      } else {
                        return (
                          <ProductTableRow
                            loadProducts={loadProducts}
                            product={product}
                            isIncompleteRow={true}
                          />
                        ) /* renderTableRow(product, true, true) */
                      }
                    })}
                </tbody>
              </table>
              <div className="d-flex justify-content-center">
                <div className="pagination-wrapper mt-3">
                  <Pagination
                    current={page}
                    total={products.total}
                    pageSize={30}
                    onChange={(page) => setPage(page)}
                  />
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </>
  )
}

export default ProductsList
