import React from 'react'
import { Carousel, Image } from 'antd'

const ProductCarousel = ({ images = [] }) => {
  console.log(images)
  return (
    <div className="product-gallery">
      <Carousel>
        {images.length > 0 &&
          images.map((image) => (
            <div key={image.id}>
              <Image src={image.original} />
            </div>
          ))}
      </Carousel>
    </div>
  )
}

export default ProductCarousel
