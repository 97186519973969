import React from 'react'

import ProductItem from './ProductItem'

const ProductsList = ({ products, view, gridClassName = null }) => {
  return (
    <div className="products__list">
      <div className="row">
        {products && products.length > 0
          ? products.map((product) => {
              return (
                <div
                  key={product.id}
                  className={
                    view === 'grid'
                      ? gridClassName
                        ? gridClassName
                        : 'col-12 col-md-6 col-lg-3'
                      : 'col-12'
                  }
                >
                  <ProductItem product={product} view={view} />
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}

export default ProductsList
