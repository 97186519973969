import React from 'react'

const PageTitle = ({ title, children }) => (
  <>
    {children ? (
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="default-page-title mb-0">{title}</h1>
        <div>{children}</div>
      </div>
    ) : (
      <h1 className="default-page-title">{title}</h1>
    )}
  </>
)

export default PageTitle
