import { useCallback } from 'react'
import { Colors, Fonts } from './'

/**
 * Renders a form to select brand fonts or set up brand colors.
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.type - The type of form to render ('fonts' or 'colors').
 * @returns {JSX.Element} - The rendered StyleForm component.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const StyleForm = ({ type }) => {
  const isTypeFonts = useCallback((type) => type === 'fonts', [])

  return (
    <div className="company-identity__section">
      <h5 className="company-identity__section__title">
        {isTypeFonts(type)
          ? 'Select your Brand Font:'
          : "Set-up your Brand's Color Pallete:"}
      </h5>
      <div className={`grid ${isTypeFonts(type) && 'grid--fonts'}`}>
        {isTypeFonts(type) ? <Fonts /> : <Colors />}
      </div>
    </div>
  )
}
