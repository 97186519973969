import React, { useState } from 'react'
import { Tabs } from 'antd'

import ProductCarousel from '../Carousel'
import ProductInfo from '../ProductInfo'
import ProductDescription from '../ProductDescription'
import ProductDetails from '../ProductDetails'
import ProductAttributes from '../ProductAttributes'
import ProductPrices from '../ProductPrices'
import AddToCart from '../AddToCart'
import SeekTradeSupport from '../SeekTradeSupport'
import ProductPackaging from '../ProductPackaging'

const HorizontalView = ({ product }) => {
  const [selectedPrice, setSelectedPrice] = useState(null)

  return (
    <div className="product-details">
      <div className="container">
        <div className="product-details__wrapper">
          <div className="row">
            <div className="col-12 col-md-6">
              <ProductCarousel images={product.product.get_all_images || []} />
            </div>
            <div className="col-12 col-md-6">
              <ProductInfo product={product} />
              <ProductAttributes attributes={product.options || []} />
              <ProductPrices
                prices={product.prices}
                selectedPrice={selectedPrice}
                setSelectedPrice={setSelectedPrice}
              />
              <AddToCart selectedPrice={selectedPrice} />
            </div>
            <div className="col-12">
              <SeekTradeSupport />
            </div>
          </div>
        </div>
      </div>
      <div className="product-description">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9">
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Product Description" key={1}>
                  <ProductDescription product={product} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Product Details" key={2}>
                  <ProductDetails product={product} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Packaging Details" key={3}>
                  <ProductPackaging product={product} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HorizontalView
