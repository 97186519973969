/**
 * @description Update the product object
 * @param {Object} product - The product object
 * @param {String} key - The key to be updated in the product object
 * @param {Any} value - The value to be updated
 * @returns {Object} The updated product object
 * @example
 * const product = {
 * weight: {
 * value: '0',
 * measure: 'kg'
 * }
 * const key = 'weight'
 * const value = {
 * value: '10',
 * measure: 'kg'
 * }
 * const updatedProduct = updateProduct(product, key, value)
 * console.log(updatedProduct) // { weight: { value: '10', measure: 'kg' } }
 * @category productUploadHelpers
 * @namespace Functions - Helpers - ProductUpload
 * @module updateProduct
 * @see module:updateProductWeight
 * @see module:addAttribute
 * @see module:updateAttribute
 * @see module:updateProductWeightAndAttribute
 *
 * @version 0.1.1
 * @since 0.1.1
 * @author Rafael Rapizo Nery
 */
export const updateProduct = (product, key, value) => {
  product[key] = value
  return product
}

/**
 * @description Update the product's weight
 * @param {Object} product - The product object
 * @param {String} key - The key to be updated in the weight object: 'value' or 'measure'
 * @param {String} value - The value to be updated
 * @returns {Object} The updated product object
 * @example
 * const product = {
 *  weight: {
 *   value: '0',
 *  measure: 'kg'
 * }
 * const key = 'value'
 * const value = '10'
 * const updatedProduct = updateProductWeight(product, key, value)
 * console.log(updatedProduct) // { weight: { value: '10', measure: 'kg' } }
 *
 * @version 0.1.1
 * @since 0.1.1
 * @category productUploadHelpers
 * @module updateProductWeight
 * @requires updateProduct
 * @see module:updateProduct
 *
 *
 * @author Rafael Rapizo Nery
 */
export const updateProductWeight = (product, key, value) => {
  product.weight[key] = value
  return product
}

/**
 * @description Add a new attribute to the product object
 * @param {Object} product - The product object
 * @param {Object} value - The value to be added to the attributes array
 * @returns {Object} The updated product object
 * @example
 * const product = {
 * attributes: []
 * }
 * const value = {
 * value: '1',
 * label: 'Color'
 * }
 * const updatedProduct = addAttribute(product, value)
 * console.log(updatedProduct) // { attributes: [{ id: '1', label: 'Color', value: '' }] }
 *
 * @version 0.1.1
 * @since 0.1.1
 * @category productUploadHelpers
 * @module addAttribute
 *
 * @requires updateProduct
 *
 * @author Rafael Rapizo Nery
 */
export const addAttribute = (product, attribute) => {
  const attrsArr = product.attributes
  attrsArr.push({
    ...attribute,
    id: attribute.id,
    value: attribute.value,
    label: attribute.label,
    type: attribute.type,
    measure: attribute.measure,
  })
  return updateProduct(product, 'attributes', attrsArr)
}

/**
 * @description Update the product's attribute value
 * @param {Object} product - The product object
 * @param {Number} index - The index of the attribute to be updated
 * @param {String} key - The key to be updated in the attribute object
 * @param {Any} value - The value to be updated
 * @returns {Object} The updated product object
 * @example
 * const product = {
 * attributes: [{ id: '1', label: 'Color', value: '' }]
 * }
 * const index = 0
 * const key = 'value'
 * const value = 'red'
 * const updatedProduct = updateAttribute(product, index, key, value)
 * console.log(updatedProduct) // { attributes: [{ id: '1', label: 'Color', value: 'red' }] }
 * @version 0.1.1
 * @since 0.1.1
 * @category productUploadHelpers
 * @module updateAttribute
 * @requires updateProduct
 *
 * @author Rafael Rapizo Nery
 */
export const updateAttribute = (product, index, key, value) => {
  product.attributes[index][key] = value
  return product
}

/**
 * @description Update the product's weight and attribute value
 * @param {Object} product - The product object
 * @param {String} key - The key to be updated in the weight object: 'value' or 'measure'
 * @param {String} value - The value to be updated
 * @returns {Object} The updated product object
 * @example
 * const product = {
 * weight: {
 * value: '0',
 * measure: 'kg'
 * }
 * const key = 'value'
 * const value = '10'
 * const updatedProduct = updateProductWeightAndAttribute(product, key, value)
 * console.log(updatedProduct) // { weight: { value: '10', measure: 'kg' } }
 * @version 0.1.1
 * @since 0.1.1
 * @category productUploadHelpers
 * @module updateProductWeightAndAttribute
 * @requires updateProduct
 * @requires updateAttribute
 *
 * @author Rafael Rapizo Nery
 */
export const updateProductWeightAndAttribute = (product, key, value) => {
  product.weight[key] = value

  let weightAttIndex = product.attributes.findIndex((attribute) => attribute.id === 14)

  updateAttribute(product, weightAttIndex, key, value)

  return product
}
