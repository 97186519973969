export class ProfileProgressBuilder {
  user

  constructor({ user }) {
    this.user = user
  }

  build() {
    return this.calculateProgress(this.getAllFields(), this.getCompletedFields())
  }

  calculateProgress(allFields, completedFields) {
    return ((completedFields.length / allFields.length) * 100).toFixed(2)
  }

  getAllFields() {
    throw new Error('Method not implemented')
  }

  getCompletedFields() {
    throw new Error('Method not implemented')
  }
}
