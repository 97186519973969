import React from 'react'
import _ from 'lodash'
import { message } from 'antd'

// import previewImage from '../../../assets/products-upload/upload-image.png'

import axiosRequest from '../../../hooks/useAxiosRequest'

const ProductImages = ({ product, updateProduct }) => {
  const uploadImage = (e, isMain = false) => {
    if (e.target.files && e.target.files[0]) {
      message.loading({ content: 'Please, wait...', duration: 30 })
      const formData = new FormData()
      formData.append('image', e.target.files[0])
      axiosRequest('/api/v1/images/products/upload', formData, 'post', true).then(
        (res) => {
          // console.log(res)
          if (res.data.image) {
            const { original, medium, preview, small } = JSON.parse(res.data.image)
            console.log(original, medium, preview, small)

            // console.log(JSON.parse(res.data.image))
            if (isMain) {
              updateProduct('mainImage', original)
            } else {
              const imagesArr = product.images
              imagesArr.push({original, medium, preview, extra_small: small})
              updateProduct('images', imagesArr)
            }
            message.destroy()
          }
          e.target.value = ''
        },
      )
    }
  }

  const removeAdditionalImage = (image) => {
    const imagesArr = product.images
    _.remove(imagesArr, (val) => {
      return image === val
    })
    updateProduct('images', imagesArr)
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="file-uploader">
          <div className="uploader__wrapper">
            <input
              type="file"
              className="input-file"
              accept="image/*"
              onChange={(e) => uploadImage(e, true)}
            />
            <div className="uploader__content">
              <p>
                Drag&Drop file here <br /> or <br /> <span>Browse file</span>
              </p>
            </div>
          </div>
          <div className="file-preview">
            <div className="preview">
              {product.mainImage ? (
                <img src={product.mainImage} alt="" />
              ) : (
                <img src={product.mainImage} alt="" />
              )}
            </div>
            <div className="preview-text">
              <p>
                Image must be at least 800 x 800px. <br /> File formats JPG, PNG, GIF,
                MP4, AVI, WMV, MOV
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mt-4">
        <div className="add-other-images">
          <input
            type="file"
            className="input-file"
            accept="image/*"
            onChange={(e) => uploadImage(e, false)}
          />
          <div className="file-upload-text">
            <i className="fe fe-plus" /> <span>Add additional product images</span>
          </div>
        </div>
      </div>
      <div className="col-12 mt-4">
        <div className="row images-list">
          {product.images.map((image) => (
            <div className="col-12 col-md-6 col-lg-3" key={image}>
              <div className="image-wrapper">
                <span
                  className="remove-image"
                  onClick={() => removeAdditionalImage(image)}
                >
                  <span className="fe fe-trash" />
                </span>
                <img src={image.original} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductImages
