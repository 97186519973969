export const IncompleteData = ({ fields }) => {
  /* const formatTitle = (field) => {
    switch (field) {
      case 'hts':
        return 'HTS Code'
      case 'origin_country':
        return 'Country of Origin'
      case 'name':
        return 'Name'
      case 'supplier':
        return 'Supplieer'
      case 'body':
        return 'Description'
      case 'price':
        return 'Price'
      case 'currency':
        return 'Currency'
      case 'main_category':
        return 'Category'
      case 'get_main_image':
        return 'Image'
      case 'brand':
        return 'Brand'
      case 'weight_value':
        return "Product' weight"
      case 'barcode':
        return 'Barcode'
      case 'ups':
        return 'USP Code'
      case 'image':
        return 'Main Image'
      default:
        return field
    }
  } */

  return (
    <>
      <div className="incomplete-data">
        {fields.map((f) => (
          <div className="incomplete-data__item" key={f}>
            <p className="text-capitalize">{f}</p>
          </div>
        ))}
      </div>
    </>
  )
}
