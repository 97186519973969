import React from 'react'
import { Link } from 'react-router-dom'

import logo from './../../assets/logo-marketplace.png'

const Header = ({ disableMenu = false }) => {
  return (
    <div className="marketplace-header">
      <div className="header-logo">
        <img src={logo} alt="TradeToWorld" />
      </div>
      {!disableMenu && (
        <>
          <div className="header-menu">
            <ul>
              <li>
                <Link to="/marketplace/explore">Explore</Link>
              </li>
              <li>
                <Link to="#">Categories</Link>
              </li>
              <li>
                <Link to="#">Brands</Link>
              </li>
              <li>
                <Link to="#">Markets</Link>
              </li>
              <li>
                <Link to="#">Companies</Link>
              </li>
            </ul>
          </div>
        </>
      )}
      <div className="header-right">
        <Link to="/profile/company-profile">
          <i className="fe fe-user" />
        </Link>
      </div>
    </div>
  )
}

export default Header
