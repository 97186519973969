import React, { useState, useEffect } from 'react'
import { Skeleton, Pagination } from 'antd'

import Filters from '../../components/pages/marketplace/explore/Filters'
import Header from '../../components/pages/marketplace/explore/Header'
import ProductsList from '../../components/pages/marketplace/explore/ProductsList'

import { exploreFilters } from '../../data/marketplace-filters'
import axiosRequest from '../../hooks/useAxiosRequest'

const ExplorePage = () => {
  const [page, setPage] = useState(1)
  const [view, setView] = useState('grid')
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [filters, setFilters] = useState({})
  const [paginationData, setPaginationData] = useState({})

  useEffect(() => {
    getProducts(page, filters)
  }, [filters, page])

  const getProducts = (currentPage, activeFilters) => {
    axiosRequest(`/api/v1/marketplace/products?page=${currentPage}`, null, 'get', true)
      .then((res) => {
        if (res && res.data.success) {
          setPaginationData(res.data.data)
          console.log(res.data.data)
          setProducts(res.data.data.products.data)
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleFilters = (target, value) => {
    const newFilters = filters
    newFilters[target] = value
    setFilters(newFilters)
  }

  return (
    <div className="container">
      <div className="products-list">
        <div className="filters">
          <Filters filters={exploreFilters} />
        </div>
        <div className="products">
          <Header title="Explore products" view={view} setView={setView} />
          {loading || products.length === 0 ? (
            <Skeleton active />
          ) : (
            <>
              <ProductsList
                products={products}
                view={view}
                paginationData={paginationData}
              />
              <div className="d-flex justify-content-center mt-4">
                {paginationData &&
                paginationData.products &&
                paginationData.products.per_page < paginationData.products.total ? (
                  <Pagination
                    size="small"
                    current={paginationData.products.current_page}
                    pageSize={paginationData.products.per_page}
                    total={paginationData.products.total}
                    showSizeChanger={false}
                    onChange={(page) => setPage(page)}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ExplorePage
