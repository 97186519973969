import React from "react";

export const ProgressBar = ({completed}) => {

  return (
    <div className="progress-bar__container">
      <div style={{width: `${completed}%`}} className="progress-bar__filler">
      </div>
    </div>
  )
}
