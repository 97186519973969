import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { v4 } from 'uuid'
import { Tabs, Button } from 'antd'
import SelectTypeForm from './components/packaging/SelectTypeForm'
import { PlusOutlined } from '@ant-design/icons'
import PackagesVariantsList from './components/packaging/PackagesVariantsList'
import { getPackageTotalWeight } from '../../../util/functions/packaging/getPackageTotalWeight'

const PackagingTypes = ({ product, updateProduct, packagingTypesData }) => {
  const [addIndex, setAddIndex] = useState(null)

  // useEffect(() => {
  //   console.log(product)
  // }, [product])

  if (!product.packagingLogistic.havePackaging) {
    return (
      <p>
        <strong>This product doesn't have packaging. Please, go to the next step.</strong>
      </p>
    )
  }

  const addVariantToProduct = (index, data) => {
    let newPackages = product.packagingLogistic.packages
    const findIndex = _.findIndex(newPackages, (res) => res.key === data.key)
    console.log(findIndex)
    if (findIndex >= 0) {
      newPackages[findIndex].variants.push(index)
      updateProduct('packagingLogistic.packages', newPackages)
      setAddIndex(null)
    } else {
      setAddIndex(null)
    }
  }

  const editVariant = (index, data) => {
    let newPackages = product.packagingLogistic.packages
    const findIndex = _.findIndex(newPackages, (res) => res.key === data.key)
    const findVariantIndex = _.findIndex(
      newPackages[findIndex].variants,
      (variant) => variant.key === index.key,
    )
    console.log(findVariantIndex)
    if (findVariantIndex >= 0) {
      setAddIndex(newPackages[findIndex].variants[findVariantIndex])
      newPackages[findIndex].variants.splice(findVariantIndex, 1)
      updateProduct('packagingLogistic.packages', newPackages)
    }
  }

  const deleteVariant = (index, data) => {
    let newPackages = product.packagingLogistic.packages
    const findIndex = _.findIndex(newPackages, (res) => res.key === data.key)
    const findVariantIndex = _.findIndex(
      newPackages[findIndex].variants,
      (variant) => variant.key === index.key,
    )
    if (findVariantIndex >= 0) {
      newPackages[findIndex].variants.splice(findVariantIndex, 1)
      updateProduct('packagingLogistic.packages', newPackages)
    }
  }

  const addPackageVariant = (index) => {
    const newPackageVariant = {
      key: v4(),
      typeId: null,
      variantId: null,
      productPerInnerPack: '',
      innerPackPrice: '',
      innerPackBarcode: {
        type: 'UPC',
        value: '',
      },
      innerPackDimensions: {
        type: 'In',
        width: '',
        height: '',
        depth: '',
      },
      innerPackVolume: {
        type: 'in3',
        value: '',
      },
      innerPackWeight: {
        type: 'kg',
        value: '',
      },
      innerPackWeightTotal: {
        type: 'kg',
        value: getPackageTotalWeight(product)
      }
    }
    setAddIndex(newPackageVariant)
  }

  return (
    <div className="row">
      {product.packagingLogistic.packages &&
      product.packagingLogistic.packages.length > 0 ? (
        <Tabs
          onChange={() => {
            setAddIndex(null)
          }}
        >
          {product.packagingLogistic.packages.map((singlePackage, index) => (
            <Tabs.TabPane key={singlePackage.key} tab={singlePackage.title}>
              {singlePackage.variants && singlePackage.variants.length > 0 ? (
                <div className="mb-3">
                  <PackagesVariantsList
                    list={singlePackage.variants}
                    packageData={singlePackage}
                    packagingTypesData={packagingTypesData}
                    editVariant={editVariant}
                    deleteVariant={deleteVariant}
                  />
                </div>
              ) : null}
              <div>
                <SelectTypeForm
                  index={addIndex}
                  updateIndex={setAddIndex}
                  packageData={singlePackage}
                  product={product}
                  updateProduct={updateProduct}
                  packagingTypesData={packagingTypesData}
                  addVariantToProduct={addVariantToProduct}
                />
              </div>
              {!addIndex && (
                <div className="mt-3 verification-layout">
                  <div className="add-unit">
                    <Button
                      type="dashed"
                      block
                      icon={<PlusOutlined />}
                      style={{ background: 'transparent' }}
                      onClick={() => addPackageVariant(index)}
                    >
                      Add variant of packaging
                    </Button>
                  </div>
                </div>
              )}
            </Tabs.TabPane>
          ))}
        </Tabs>
      ) : (
        <p>
          <strong className="danger-color">
            You didn't choose packages. Go to the previous step and pick up your types of
            packaging.
          </strong>
        </p>
      )}
    </div>
  )
}

export default PackagingTypes
