import { Select } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

export const ServiceTypesController = ({
  services = [],
  onRemoveService,
  onSelectService,
  selectedServices = [],
  isSelectorLoading,
}) => {
  return (
    <div className="col-12 col-lg-6 channels">
      <h1>Services</h1>
      <div className="row">
        <div className="col-12">
          <div className="channels__controller">
            <div className="col-10">
              <Select
                loading={isSelectorLoading}
                placeholder="Select a service"
                className="w-100"
                onChange={onSelectService}
                value={null}
              >
                {!isSelectorLoading &&
                  services.map((service) => {
                    return (
                      <Select.Option
                        value={service.code}
                        key={service.code}
                        disabled={selectedServices.find((s) => s.code === service.code)}
                      >
                        {service.name}
                      </Select.Option>
                    )
                  })}
              </Select>
            </div>
          </div>
          <div className="row">
            <div className="col-12 channels__list mt-3">
              {selectedServices.map((service) => (
                <div className="channels__list__card">
                  <p>{service.name}</p>
                  <CloseOutlined onClick={() => onRemoveService(service)} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
