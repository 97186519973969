import React, { useEffect, useState } from 'react'
import { Select } from 'antd'

import locationIcon from '../../../../assets/maps/location_24px.png'

const ProductPrices = ({ prices, selectedPrice, setSelectedPrice }) => {
  const [location, setLocation] = useState(Object.keys(prices)[0])

  useEffect(() => {
    console.log(location)
    if (
      prices[location] &&
      prices[location][0] &&
      prices[location][0].get_items &&
      prices[location][0].get_items[0]
    ) {
      setSelectedPrice({
        ...prices[location][0].get_items[0],
        currency_string: prices[location][0].currency_string,
      })
    }
  }, [location])

  return (
    <div className="product-prices">
      <div className="prices-select">
        <div className="d-flex justify-content-start align-items-center">
          <div className="icon">
            <img src={locationIcon} alt="" />
          </div>
          <Select
            style={{ width: '40%' }}
            defaultValue={location}
            onChange={(val) => setLocation(val)}
          >
            {Object.keys(prices).map((price) => {
              if (prices[price].length > 0) {
                return (
                  <Select.Option value={price} key={price}>
                    {price.replace(/ *\([^)]*\) */g, '')}
                  </Select.Option>
                )
              }
              return null
            })}
          </Select>
        </div>
      </div>
      <div className="prices">
        {Object.keys(prices).map((key) => {
          if (key === location) {
            if (prices[key] && prices[key][0] && prices[key][0].get_items) {
              return prices[key][0].get_items.map((item) => (
                <div
                  key={item.id}
                  className={`price-item ${
                    selectedPrice && item.id === selectedPrice.id
                      ? 'price-item__active'
                      : ''
                  }`}
                  onClick={() =>
                    setSelectedPrice({
                      ...item,
                      currency_string: prices[key][0].currency_string,
                    })
                  }
                >
                  <div className="items">{`${!item.max_qty ? '>' : ''}${item.min_qty} ${
                    item.max_qty ? `- ${item.max_qty}` : ''
                  } items`}</div>
                  <div className="price">
                    {item.discount && item.discount > 0.0
                      ? parseFloat(item.price * ((100 - item.discount) / 100)).toFixed(2)
                      : item.price}{' '}
                    {prices[key][0].currency_string}
                  </div>
                  <div className="price-sub">/ item</div>
                </div>
              ))
            }
          }
          return null
        })}
      </div>
    </div>
  )
}

export default ProductPrices
