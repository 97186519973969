import React from 'react'
import { Link } from 'react-router-dom'

const ProductAttributes = ({ attributes }) => {
  console.log(attributes)
  return (
    <div className="product-attributes">
      {Object.keys(attributes).map((key) => (
        <div className="product-attribute" key={key}>
          <div className="attr_name">{key}</div>
          <div className="attr-options">
            {attributes[key].length > 0 &&
              attributes[key].map((option) => (
                <div
                  className={`option ${option.isActive ? 'active' : 'not-active'}`}
                  key={option.id}
                >
                  <Link to={`/marketplace/product/${option.slug}`}>{option.value}</Link>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProductAttributes
