import React, { Suspense } from 'react'
import { Modal, Skeleton } from 'antd'

import useOrderManager from '../../../../hooks/ui/OrderManager/useOrderManager'

import './ProductOrderManager.scss'

const Header = React.lazy(() => import('./components/Header/Header'))
const Body = React.lazy(() => import('./components/Body/Body'))
const Footer = React.lazy(() => import('./components/Footer/Footer'))

/**
 * @description Modal to manage the order of the products in the dashboard
 * @param {object} props - props object
 * @param {boolean} props.isVisible - visibility state of the modal
 * @param {function} props.handleVisibility - function to handle the visibility of the modal
 * @returns {React.FC} - React functional component
 * @example
 * import ProductOrderManager from './ProductOrderManager'
 * <ProductOrderManager isVisible={isVisible} handleVisibility={handleVisibility} />
 * @version 0.1.1
 * @since 0.1.1
 * @category Components
 * @namespace Components - Modal - ProductOrderManager
 * @requires React, Suspense, Modal, Skeleton, useOrderManager, Header, Body, Footer
 * @author Rafael Rapizo Nery
 */
export const ProductOrderManager = ({
  isVisible,
  handleVisibility,
  onClose = () => null,
}) => {
  const { products, handleReset, handleSubmit, handleOrderChange } = useOrderManager(
    handleVisibility,
  )

  return (
    <Modal
      width={'80%'}
      bodyStyle={{ maxHeight: '80vh', overflow: 'scroll' }}
      onCancel={() => handleVisibility(false)}
      afterClose={onClose}
      visible={isVisible}
      footer={
        <Suspense fallback={<Skeleton active />}>
          <Footer
            handleCancel={() => handleVisibility(false)}
            handleReset={handleReset}
            handleSave={handleSubmit}
          />
        </Suspense>
      }
    >
      <Suspense fallback={<Skeleton active />}>
        <div title="ProductOrderManager" className="product-order-manager">
          <Header />
          <Body products={products} handleOrderChange={handleOrderChange} />
        </div>
      </Suspense>
    </Modal>
  )
}

export default ProductOrderManager
