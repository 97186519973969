import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Select } from 'antd'
import { VectorMap } from 'react-jvectormap'
import { getData, getName } from 'country-list'
import { CloseOutlined } from '@ant-design/icons'

const ExportCountries = ({ countries = [], setCountries }) => {
  const [selected, setSelected] = useState('')
  const [mapData, setMapData] = useState({})

  useEffect(() => {
    const newMapData = {}
    _.forEach(countries, (country) => {
      newMapData[country.shortCode] = 999
    })
    setMapData(newMapData)
  }, [countries])

  const onSelect = (value) => {
    console.log(countries)
    const countriesArray = countries || []
    const findDuplicate = _.find(countriesArray, (data) => data.shortCode === value)
    if (!findDuplicate) {
      countriesArray.push({ shortCode: value, name: getName(value) })
      setCountries([...countriesArray])
    }
    setSelected(value)
    setSelected('')
  }

  const onRemove = (index) => {
    const countriesArray = countries
    countriesArray.splice(index, 1)
    setCountries([...countriesArray])
  }

  useEffect(() => console.log(mapData), [mapData])

  return (
    <div className="row export-countries-map">
      <div className="col-12 col-lg-6 mb-3">
        <Select
          className="w-100"
          onSelect={(e) => onSelect(e)}
          value={selected}
          showSearch
          optionFilterProp="children"
          placeholder="Country"
        >
          <>
            {getData().map((country) => (
              <Select.Option value={country.code} key={country.code}>
                {country.name}
              </Select.Option>
            ))}
            <Select.Option value="XK" key="XK">
              Kosovo
            </Select.Option>
          </>
        </Select>
      </div>
      <div className="export-countries__tags mb-5">
        <ul className="row">
          {countries &&
            countries.map((country, index) => {
              if(!country.shortCode) return null
              return (
                <li className="me-3 mb-3 col-6 col-lg-4 col-xl-3" key={country.shortCode}>
                <div className="export-countries__item d-flex justify-content-start align-items-center">
                  <div className="flag">
                    <img
                      src={`https://flagcdn.com/${country.shortCode.toLowerCase()}.svg`}
                      width="30"
                      alt={country.name}
                    />
                  </div>
                  <div className="name">{country.name}</div>
                  <div className="close" onClick={() => onRemove(index)}>
                    <CloseOutlined />
                  </div>
                </div>
              </li>
              )
            })}
        </ul>
      </div>
      <div className="col-12 col-lg-10">
        <VectorMap
          map={'world_mill'}
          backgroundColor="transparent"
          zoomOnScroll={false}
          regionsSelectable={false}
          containerStyle={{
            width: '100%',
            height: '300px',
          }}
          zoomButtons={false}
          regionStyle={{
            initial: {
              fill: '#B2DFDB',
              'fill-opacity': 1,
              stroke: 'none',
              'stroke-width': 0,
              'stroke-opacity': 0,
            },
            hover: {
              'fill-opacity': 1,
              cursor: 'pointer',
            },
            selected: {
              'fill-opacity': 1,
              fill: '#00968A', //color for the clicked country
            },
            selectedHover: {},
          }}
          series={{
            regions: [
              {
                values: mapData, //this is your data
                scale: ['#00468F', '#00968A'],
                normalizeFunction: 'polynomial',
              },
            ],
          }}
        />
      </div>
    </div>
  )
}

export default ExportCountries
