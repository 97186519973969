import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Skeleton, message } from 'antd'

import ProductVariantItem from '../../components/pages/product-upload/components/ProductVariantItem'

import { history } from '../../index'
import axiosRequest from '../../hooks/useAxiosRequest'
import { ProductAPI } from '../../models/Domains/API/ProductAPI.class'

const ProductVariants = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState({})

  // TODO: Refactor
  const getData = () => {
    setLoading(true)
    axiosRequest(`/api/v1/products/variants/${id}`, null, 'get', true)
      .then((res) => {
        let productObj = {}
        productObj = res.data.product
        productObj.variants = res.data.variants
        setProduct(productObj)
        setLoading(false)
      })
      .catch(() => {
        history.push('/dashboard')
      })
  }

  // TODO: Refactor
  useEffect(() => {
    getData()
  }, [id])

  const deleteProduct = (id) => {
    message.loading({ content: 'Loading...', key: 'delete' })
    axiosRequest(`/api/v1/products/${id}`, null, 'delete', true)
      .then((res) => {
        if (res.data.success) {
          message.success({ content: 'Product was deleted', key: 'delete' })
          getData()
        }
      })
      .catch(() => {
        message.destroy()
        message.error('Something goes wrong, try again later.')
      })
  }

  const handleCopyProduct = async (id) => {
    try {
      await ProductAPI.copy(id)
    } catch (error) {
      console.error(error)
    } finally {
      return await getData()
    }
  }

  if (loading || !product) {
    return <Skeleton active />
  }

  return (
    <>
      <div className="products-upload w-100">
        <div className="upload-content mb-5 pb-5 w-100">
          {loading || !product.id ? (
            <Skeleton active />
          ) : (
            <div className="row">
              <div className="col-12 mb-3 products-upload__return-link">
                <Link to="/products/list/all" style={{ textTransform: 'uppercase' }}>
                  <i className="fe fe-chevron-left" />{' '}
                  <span>Back to the products list</span>
                </Link>
              </div>
              <div className="col-12 mb-4">
                <h2 className="products-upload__section-title">Master Product</h2>
                <div className="product-variants">
                  <ProductVariantItem product={product} isMaster />
                </div>
              </div>
              <div className="col-12 mb-4 d-flex align-items-start justify-content-between">
                <h2 className="products-upload__section-title">Product Variants</h2>
                <div className="d-flex gap-2">
                  <Link
                    to={`/products/create?parentVariant=${id}&parentVariantSetFields=false`}
                    className="ant-btn ant-btn-sm add-btn-link ant-btn-primary ant-btn-primary__outlined"
                    style={{ textTransform: 'uppercase', backgroundColor: '#fff' }}
                  >
                    <i className="fe fe-plus" /> Add brand new variant
                  </Link>
                  <Link
                    to={`/products/create-variant?parentVariant=${id}&parentVariantSetFields=true`}
                    className="ant-btn ant-btn-sm add-btn-link ant-btn-primary ant-btn-primary__outlined"
                    style={{ textTransform: 'uppercase', backgroundColor: '#fff' }}
                  >
                    <i className="fe fe-plus" /> Add semi-filled variant from master
                  </Link>
                </div>
              </div>
              <div className="col-12">
                <div className="product-variants">
                  {product.variants.length > 0 &&
                    product.variants.map((variant) => (
                      <ProductVariantItem
                        key={variant.id}
                        product={variant}
                        variationId={id}
                        deleteProduct={deleteProduct}
                        onCopy={() => handleCopyProduct(variant.id)}
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ProductVariants
