import { StyleController } from './StyleController'

export class ColorController extends StyleController {
  color
  type = 'color'

  constructor(title, description) {
    super(title)
    this.description = description
  }

  setColor(hex) {
    this.color = hex
    return this
  }

  getColor() {
    return this.color
  }
}
