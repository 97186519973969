import { ClientRole } from './ClientRole.interface'

export class SellerRole extends ClientRole {
  constructor() {
    super({
      title: 'Are you also a Seller?',
      btnText: 'Become a seller',
      roleType: 'SELLER',
      className: 'new-role__card--seller',
      description: 'Update your profile in order to have the ability to sell products.',
    })
  }

  updateRole(body) {
    body.is_seller = true
  }
}

// TODO: fix .class file extension
