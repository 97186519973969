import { message } from 'antd'
import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import useLoader from '../../utils/Loader/useLoader'
import axiosRequest from '../../useAxiosRequest'

import { ServiceTypeFactory } from '../../../models/Domains/ServiceType'
import { ServiceType } from '../../../models/Domains/ServiceType/ServiceType.interface'
import { ProfileProgressAbstractFactory } from '../../../models/Domains/ProfileProgress/ProfileProgressAbstractFactory.interface'

export const useProfileServices = () => {
  const { user } = useSelector((state) => state.user)
  const { isLoading, setIsLoading } = useLoader(false)
  const [services, setServices] = useState(undefined)

  const fetchGeneralProfileSteps = useCallback(async () => {
    try {
      let res = await axiosRequest('/api/v1/on-boarding/personal-info', null, 'get', true)
      if (res.data.user) {
        return res.data.steps
      }
    } catch (error) {
      console.error('Failed to fetch general profile steps')
      message.error('We could not fetch your profile progression, please try again later')
    }
  }, [])

  const buildGeneralProfileItem = useCallback(async () => {
    const item = new ServiceType({
      id: 'service-provider-general',
      code: 'service-provider-general',
      name: 'General Profile Information',
      icon: `${process.env.REACT_APP_TTW_CDN_URL}/icons/services-types/general-profile-icon.svg`,
      path: '/secured/verification/service-provider/profile-info',
    })

    const steps = await fetchGeneralProfileSteps()
    if (steps) {
      const factory = ProfileProgressAbstractFactory.getFactory('role')
      const progressBuilder = new factory({
        userType: 'service-provider-general',
        user: { ...user, steps },
      })
      const progress = progressBuilder.getProgress()
      item.setProgress(progress)
    }

    return item
  }, [user, fetchGeneralProfileSteps])

  useEffect(() => {
    if (user.serviceProvider !== undefined) {
      const { service_provider_types } = user.serviceProvider
      let services = service_provider_types.map(({ service_type }) => service_type)
      services = ServiceTypeFactory.createServicesTypes(services)
      services.map((service) => {
        let factory = ProfileProgressAbstractFactory.getFactory('service-type')
        const progress = new factory({ service, user }).getProgress()
        service.setProgress(progress)
        return service
      })

      buildGeneralProfileItem().then((generalProfileItem) => {
        console.log('generalProfileItem', generalProfileItem)
        services.unshift(generalProfileItem)
        setServices(services)
        setIsLoading(false)
      })
    }
  }, [user, setIsLoading, buildGeneralProfileItem])

  return { services, isLoading }
}
