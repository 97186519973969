import { Select, Form, Input, Checkbox, Dropdown } from 'antd'

import './_weight-controller.scss'

/**
 * @description WeightController component is a form that allows the user to input the product's weight.
 * @category Presentational Components/Pages/ProductUpload
 * @namespace Pages - ProductUpload - ProductAttributes - WeightController
 * @param {object} props
 * @param {function} props.onSelectChange - The function to handle the select change
 * @param {function} props.onInputChange - The function to handle the input change
 * @param {function} props.onCheckChange - The function to handle the checkbox change
 * @param {boolean} props.isAttribute - The boolean to check if the weight is an attribute
 * @param {boolean} props.isDisabled - The boolean to check if the weight is disabled
 * @param {object} props.value - The value object
 * @param {string} props.value.value - The weight value
 * @param {string} props.value.measure - The weight measure
 * @returns {JSX.Element} The WeightController component
 * @example
 * <WeightController
 * onSelectChange={onSelectChange}
 * onInputChange={onInputChange}
 * onCheckChange={onCheckChange}
 * isAttribute={isAttribute}
 * isDisabled={isDisabled}
 * value={value}
 * />
 * @requires antd
 * @version 0.1.1
 * @since 0.1.1
 * @author Rafael Rapizo Nery
 * @todo Add PropTypes
 * @todo Create Stories
 * @todo Create e2e tests
 */
export const WeightController = ({
  onSelectChange = (value) => null,
  onInputChange = (value) => null,
  onCheckChange = (e) => null,
  isAttribute = false,
  isDisabled = false,
  value = { value: '', measure: '' },
}) => {
  return (
    <div className="row weight-controller">
      <div className="col-12 col-md-6">
        <p title="weight-controller-title" className="mb-1 weight-controller__title">
          <strong>Weight</strong>
          <Dropdown
            overlayStyle={{
              backgroundColor: '#000',
              opacity: '0.8',
              padding: '4px',
              borderRadius: '4px',
              color: '#fff',
              fontWeight: 'bold',
            }}
            trigger={['hover']}
            placement="topCenter"
            overlay={'Check for use weight as product variant definer.'}
          >
            <Checkbox
              checked={isAttribute}
              onChange={onCheckChange}
              className="weight-controller__title__checkbox"
            />
          </Dropdown>
        </p>
        <Form.Item
          // name={['weight', 'measure']}
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Select disabled={isDisabled} onChange={onSelectChange} value={value.measure}>
            <Select.Option value="tonne">Tonne</Select.Option>
            <Select.Option value="kg">Kilogram</Select.Option>
            <Select.Option value="g">Gram</Select.Option>
            <Select.Option value="mg">Milligram</Select.Option>
            <Select.Option value="μg">Microgram</Select.Option>
            <Select.Option value="imperial ton">Imperial Ton</Select.Option>
            <Select.Option value="us ton">US Ton</Select.Option>
            <Select.Option value="stone">Stone</Select.Option>
            <Select.Option value="lb">Pound</Select.Option>
            <Select.Option value="oz">Ounce</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div className="col-12 col-md-6">
        <p className="mb-1" style={{ opacity: 0 }}>
          Weight
        </p>
        <Form.Item
          // name={['weight', 'value']}
          rules={[{ required: true, message: 'Field required' }]}
        >
          <Input
            value={value.value}
            disabled={isDisabled}
            title="weight-input"
            onChange={onInputChange}
            placeholder="Weight"
          />
        </Form.Item>
      </div>
    </div>
  )
}
