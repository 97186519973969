import React, { useState } from 'react'
import _ from 'lodash'
import { v4 } from 'uuid'
import { Radio, Input, Button, message } from 'antd'
import { CloseOutlined, DeleteOutlined, FileOutlined } from '@ant-design/icons'

import axiosRequest from '../../../../hooks/useAxiosRequest'

const UploadDocs = ({ docs, setDocs }) => {
  const [docType, setDocType] = useState('Certificate')
  const [docName, setDocName] = useState('')
  const [docFile, setDocFile] = useState(null)
  const [docFileMeta, setDocFileMeta] = useState(null)

  const selectDocType = (e) => {
    setDocType(e.target.value)
  }

  const handleDocName = (e) => {
    setDocName(e.target.value)
    // e.target.value = ''
  }

  const limitNameLength = (name) => {
    let splitName = name.slice(0, 23);
    return splitName.concat("...")
  }

  const upload = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      console.log(file)
      message.loading({ content: 'Please, wait...', key: 'save' })
      const formData = new FormData()
      formData.append('uploadDoc', file)
      axiosRequest('/api/v1/files/upload', formData, 'post', true).then((res) => {
        if (res.data.success) {
          setDocFile(res.data.file)
          if (e.target.files[0].name) {
            setDocName(e.target.files[0].name)
            setDocFileMeta({
              fileName: e.target.files[0].name,
              fileSize: e.target.files[0].size,
            })
          } else {
            setDocName('New Document')
          }
          e.target.value = ''
          message.destroy()
        }
      })
    } else {
      message.error('Incorrect file')
    }
  }

  const clearFileInput = () => {
    setDocFile(null)
  }

  const removeDocFromState = (id) => {
    const docsArr = docs
    _.remove(docsArr, (n) => {
      return n.id === id
    })
    setDocs([...docsArr])
  }

  const addDocument = (e) => {
    e.preventDefault()
    if (docFile === null || docName === '' || !docName || !docFileMeta) {
      message.warn('Required fields are missing')
    }
    const docsArr = docs
    docsArr.push({
      id: v4(),
      name: docName,
      file: docFile,
      type: docType,
      fileName: docFileMeta.fileName,
      fileSize: docFileMeta.fileSize,
      isNew: true,
    })
    setDocs([...docsArr])
    setDocName('')
    setDocFile(null)
    setDocFileMeta(null)
  }

  return (
    <div className="col-12 mb-5">
      <div className="upload-docs">
        <h2>Add new document</h2>
        <div className="docs__uploader">
          <div className="row">
            <div className="col-12">
              <div className="uploader__radio">
                <Radio.Group onChange={(e) => selectDocType(e)} value={docType}>
                  <Radio value="Patent">Patent</Radio>
                  <Radio value="Certificate">Certificate</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="col-12 col-lg-7">
              <Input
                value={docName}
                onChange={(e) => handleDocName(e)}
                placeholder="Document name"
              />
            </div>
            <div className="col-12 col-lg-5">
              <div className="uploader__upload">
                <div className="upload__placeholder">
                  {docFile === null ? (
                    <>
                      <span className="text-sm">
                        <strong>Upload File</strong> File formats PDF, DOC, XLS
                      </span>
                      <input
                        type="file"
                        onChange={(e) => upload(e)}
                        accept="application/msword, application/vnd.ms-excel, application/pdf, .doc, .docx"
                      />
                    </>
                  ) : (
                    <span className="uploaded">
                      File uploaded <CloseOutlined onClick={() => clearFileInput()} />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12">
              <Button
                className="uploader__btn"
                disabled={docFile === null || docName === '' || !docName}
                onClick={(e) => addDocument(e)}
              >
                Add Document
              </Button>
            </div>
          </div>
        </div>
        <div className="docs__list">
          {docs.length > 0 && (
            <p>
              <strong>Uploaded documents</strong>
            </p>
          )}
          {docs.length > 0 &&
            docs.map((doc) => {
              return (
                <div key={doc.id} className="doc__item">
                  <div className="doc-icon">
                    <FileOutlined />
                  </div>
                  <div className="doc-name">
                    {doc.type} - {doc.name.length >= 26 ? limitNameLength(doc.name) : doc.name}
                  </div>
                  <div className="doc-file">
                    {doc.isNew && (
                      <>
                        {doc.fileName}
                        <span> ({(doc.fileSize / 1000).toFixed(1)}MB)</span>
                      </>
                    )}
                  </div>
                  <div className="doc-remove">
                    <DeleteOutlined onClick={() => removeDocFromState(doc.id)} />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default UploadDocs
