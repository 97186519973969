import { ClientRole } from './ClientRole.interface'

export class BuyerRole extends ClientRole {
  constructor() {
    super({
      title: 'Are you also a Buyer?',
      btnText: 'Become a buyer',
      roleType: 'BUYER',
      className: 'new-role__card--buyer',
      description:
        'Update your profile in order to have the ability to purchase products.',
    })
  }

  updateRole(body) {
    body.is_buyer = true
  }
}
