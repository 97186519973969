import { getVerificationSteps } from '../../../data/verficationSteps'
import { LogisticsServiceProfileProgressBuilder } from './LogisticServiceProfileProgressBuilder.class'
import { ProfileProgressBuilder } from './ProfileProgressBuilder.interface'

export class ServiceProviderProfileProgressBuilder extends ProfileProgressBuilder {
  PROFILE_STEPS = [
    'personalInfo',
    'companyInfo',
    'additionalInfo',
    'salesPresence',
    'companyLogo',
    'companyIndustries',
    'companySubIndustries',
  ]
  servicesProgress = []

  calculateProgress(allFields, completedFields) {
    let progress = (completedFields.length / allFields.length) * 100
    progress = this.processServicesProgress({
      currentProgress: progress,
      user: this.user,
    })
    return parseInt(progress).toFixed(2)
  }

  processServicesProgress({ currentProgress, user }) {
    this.registerServiceProgress(this.getLogisticsServiceProgress())

    if (this.servicesProgress.length) {
      return (
        (currentProgress + this.servicesProgress.reduce((acc, curr) => acc + curr, 0)) /
        (1 + this.servicesProgress.length)
      )
    } else {
      return currentProgress
    }
  }

  registerServiceProgress(serviceProgress) {
    this.servicesProgress.push(parseInt(serviceProgress))
  }

  getLogisticsServiceProgress() {
    if (this.isLogisticServiceProvider()) {
      const builder = new LogisticsServiceProfileProgressBuilder({
        user: this.user,
      })
      return builder.build()
    }
  }

  isLogisticServiceProvider() {
    return this.user.serviceProvider.service_provider_types.find(
      ({ service_type_id }) => parseInt(service_type_id) === 2,
    )
  }

  getCompletedFields() {
    let completedSteps = this.filterSteps()
    return completedSteps
  }

  filterSteps() {
    let steps = [...this.user.steps]
    steps = steps.filter(({ step }) => this.PROFILE_STEPS.includes(step))
    return steps
  }

  getAllFields() {
    return getVerificationSteps('service-provider')
  }
}
