import 'video.js/dist/video-js.css'

import { useVideoPlayer } from '../../../hooks/ui/useVideoPlayer/useVideoPlayer'

import './VideoPlayer.scss'

const VideoPlayer = ({ href, videoAlt = 'video', width = '911', height = '506' }) => {
  const { videoRef, videoUrl } = useVideoPlayer(href ? href : null)

  return (
    <div className="video-container">
      {videoUrl ? (
        <video
          id="video-player"
          ref={videoRef}
          className="video-js vjs-default-skin vjs-big-play-centered custom-video-js"
          width={width}
          height={height}
          alt={videoAlt}
        />
      ) : (
        <img src={`https://placehold.co/${width}x${height}`} alt="video" />
      )}
    </div>
  )
}

export default VideoPlayer
