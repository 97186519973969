export class ServiceType {
  code = ''
  icon = ''
  id = 0
  name = ''
  path = ''
  progress = 0

  constructor({ code, icon, id, name, path }) {
    this.code = code
    this.icon = icon
    this.id = id
    this.name = name
    this.path = path
  }

  setProgress(progress) {
    this.progress = progress
  }
}
