import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'

import { ProgressBar } from './ProgressBar'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { ProfileProgressAbstractFactory } from '../../../models/Domains/ProfileProgress/ProfileProgressAbstractFactory.interface'
import { useSelector } from 'react-redux'

export const ProfileStatusCard = ({ completed, role }) => {
  const [cardInfo, setCardInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useSelector((state) => state.user)

  const mountCard = (role) => {
    const cardI = { title: '', link: '/' }

    if (role === 'BUYER') {
      cardI.title = 'Buyer Profile'
      cardI.link = '/secured/verification/buyer/profile-info'
    }
    if (role === 'SELLER') {
      cardI.title = 'Seller Profile'
      cardI.link = '/secured/verification/seller/profile-info'
    }
    if (role === 'SERVICE_PROVIDER') {
      cardI.title = 'Service Provider Profile'
      cardI.link = '/secured/verification/service-provider/profiles'
    }
    setCardInfo(cardI)
  }

  useEffect(() => {
    mountCard(role)
    axiosRequest('/api/v1/on-boarding/company-info', null, 'get', true).then((res) => {
      if (res.data.success) {
        const userType =
          role === 'SERVICE_PROVIDER' ? 'service-provider' : role.toLowerCase()

        const progressFactory = ProfileProgressAbstractFactory.getFactory('role')
        const progressBuilder = new progressFactory({
          userType,
          user: { ...user, steps: res.data.steps },
        })
        const progress = progressBuilder.getProgress()

        setCardInfo((prev) => ({
          ...prev,
          progress: parseInt(progress),
        }))
        setIsLoading(false)
      }
    })
  }, [role])

  if (cardInfo) {
    return (
      <div className="profile-status-card">
        <h4>{cardInfo.title}</h4>
        {!isLoading && (
          <>
            {cardInfo.progress < 100 && <ProgressBar completed={cardInfo.progress} />}
            <p>
              {cardInfo.progress < 100 ? (
                <>
                  Your profile completion is <span>{cardInfo.progress}%</span>
                </>
              ) : (
                'Your profile is complete!'
              )}
            </p>
          </>
        )}
        <div className="profile-status-card__complete">
          {cardInfo.progress < 100 ? (
            <Link to={cardInfo.link}>
              Complete profile <i className="fe fe-chevron-right" />
            </Link>
          ) : (
            <Link to={cardInfo.link}>
              Edit profile <i className="fe fe-chevron-right" />
            </Link>
          )}
        </div>
      </div>
    )
  }

  return null
}
