import { Skeleton } from 'antd'

import ProgressBar from '../ui/bar/ProgressBar'

export const ServiceFormSidebard = ({ title, progress, isLoading }) => {

  return (
    <div className="verification-nav">
      <div className="nav-content">
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div className="nav-progress">
              <div className="d-flex flex-column justify-content-start gap-2">
                <h5>{title}</h5>
                <div className="d-flex flex-column gap-2">
                  <ProgressBar completed={progress} percent={progress} />
                  <p>
                    {progress < 100 ? (
                      <>
                        Your profile completion is <span>{progress}%</span>
                      </>
                    ) : (
                      'Your profile is complete!'
                    )}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
