export const setWeight = ({
  type,
  units,
  total,
  primaryPackageWeight,
  displayPackageWeight,
  secondaryPackageWeight,
  packWeight
}) => {
  let totalWeight = total

  if (type === 'primary') totalWeight = totalWeight * units
  if (type === 'display' || type === 'intermediary')
    totalWeight = (totalWeight + primaryPackageWeight) * units
  if (type === 'secondary')
    totalWeight = (totalWeight + primaryPackageWeight + displayPackageWeight) * units
  if (type === 'terciary' || type === null) {
    totalWeight =
      (totalWeight +
        primaryPackageWeight +
        displayPackageWeight +
        secondaryPackageWeight) *
      units
  }

  totalWeight += packWeight

  return totalWeight
}
