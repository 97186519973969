export const stylesReducer = (
  state,
  action = { type: '', key: '', value: '', colors: {}, fonts: {} },
) => {
  switch (action.type) {
    case 'SET_COLOR':
      const updatedColors = new Map(state.colors)
      updatedColors.set(action.key, action.value)
      return { ...state, colors: updatedColors }
    case 'SET_FONT':
      const updatedFonts = new Map(state.fonts)
      updatedFonts.set(action.key, action.value)
      return { ...state, fonts: updatedFonts }
    case 'SET_ALL_COLORS':
      return { ...state, colors: action.colors }
    case 'SET_ALL_FONTS':
      return { ...state, fonts: action.fonts }
    case 'RESET':
      return { fonts: new Map(action.fonts), colors: new Map(action.colors) }
    default:
      console.error('Unkown action type | No action type provided.')
      throw new Error('Unkown action type | No action type provided.')
  }
}
