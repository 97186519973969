import LoginPage from '../pages/auth/LoginPage'
import SignUpPage from '../pages/auth/SignUpPage'

import ProfileInfo from '../pages/auth/verification/ProfileInfo'
import CompanyInfo from '../pages/auth/verification/CompanyInfo'
import CompanyLogo from '../pages/auth/verification/CompanyLogo'
import IndustriesOnboarding from '../pages/auth/verification/Industries'
import CompanySubIndustries from '../pages/auth/verification/CompanySubIndustries'
import CompanyBrands from '../pages/auth/verification/CompanyBrands'
import CompanyAdditionalInformation from '../pages/auth/verification/CompanyAdditionalInformation'
import CompanySalesPresence from '../pages/auth/verification/CompanySalesPresence'
import CompanyPhotosDocs from '../pages/auth/verification/CompanyPhotosDocs'
import EmailVerification from '../pages/auth/verification/EmailVerification'
import ForgotPassword from '../pages/auth/ForgotPassword'
import RestorePassword from '../pages/auth/RestorePassword'
import { CompanyFinancial } from '../pages/auth/verification/CompanyFinancial'
import { EditProfiles } from '../pages/auth/ServiceProvider/EditProfiles/EditProfiles'
import { Component } from 'react'
import { ShippingAndServices } from '../pages/auth/ServiceProvider/ShippingAndServices/ShippingAndServices'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/secured/login',
    Component: LoginPage,
  },
  {
    path: '/secured/forgot-password',
    Component: ForgotPassword,
  },
  {
    path: '/secured/restore-password/:slug',
    Component: RestorePassword,
  },
  {
    path: '/secured/register',
    Component: SignUpPage,
  },
  {
    path: '/secured/verification/email/:verificationStatus',
    Component: EmailVerification,
  },
  {
    path: '/secured/verification/service-provider/profiles',
    Component: EditProfiles,
  },
  {
    path: '/secured/verification/:userType/profile-info',
    Component: ProfileInfo,
  },
  {
    path: '/secured/verification/:userType/company-info',
    Component: CompanyInfo,
  },
  {
    path: '/secured/verification/:userType/company-logo',
    Component: CompanyLogo,
  },
  {
    path: '/secured/verification/:userType/company-industries',
    Component: IndustriesOnboarding,
  },
  {
    path: '/secured/verification/:userType/brands',
    Component: CompanyBrands,
  },
  {
    path: '/secured/verification/:userType/company-sub-industries',
    Component: CompanySubIndustries,
  },
  {
    path: '/secured/verification/:userType/additional-info',
    Component: CompanyAdditionalInformation,
  },
  {
    path: '/secured/verification/:userType/sales-presence',
    Component: CompanySalesPresence,
  },
  {
    path: '/secured/verification/:userType/financial',
    Component: CompanyFinancial,
  },
  {
    path: '/secured/verification/:userType/photos-docs',
    Component: CompanyPhotosDocs,
  },
  {
    path: '/secured/verification/service-provider/shipping-and-services',
    Component: ShippingAndServices
  }
]
