import { Button, Drawer, List } from 'antd'

import './TutorialListDrawer.scss'

const TutorialListDrawer = ({
  isDrawerVisible = false,
  tutorials = [],
  selectedTutorial = null,
  onClickTutorial,
  onClickBack,
}) => {
  const isTutorialActive = (tutorial) => {
    return selectedTutorial && tutorial.name === selectedTutorial.name
  }

  return (
    <Drawer
      visible={isDrawerVisible}
      placement="right"
      closable={false}
      mask={false}
      width={366}
      className="tutorial-list-drawer"
    >
      <h4 className="tutorial-list-drawer__title">Select video:</h4>
      <List
        className="tutorial-list-drawer__list"
        bordered={false}
        split={false}
        dataSource={tutorials}
        renderItem={(tutorial, index) => (
          <List.Item
            onClick={() => onClickTutorial(tutorial)}
            className={`tutorial-list-drawer__list__item${
              isTutorialActive(tutorial) ? '--active' : ''
            }`}
          >
            {`${index + 1}. ${tutorial.name}`}
          </List.Item>
        )}
      />
      <Button
        type="primary"
        className="tutorial-list-drawer__button"
        onClick={onClickBack}
      >
        BACK
      </Button>
    </Drawer>
  )
}

export default TutorialListDrawer
