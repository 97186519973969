/**
 * React component for rendering a block picker with selectable color blocks.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.colors - An array of colors to display in the block picker.
 * @param {function} props.setColor - The callback function to set the selected color.
 * @returns {JSX.Element} - The rendered BlockPicker component.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const BlockPicker = ({ colors = [], setColor }) => {
  return (
    <div className="color-picker__blocks">
      {colors.map((hex) => (
        <div
          key={`${hex}${Math.random()}`}
          className="color-picker__blocks__block"
          style={{ backgroundColor: hex }}
          onClick={() => setColor(hex)}
        ></div>
      ))}
    </div>
  )
}
