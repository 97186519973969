import { message } from 'antd'
import { API } from './API.interface'
import axios from 'axios'

export class ProductAPI extends API {
  static BASE_URL = `${process.env.REACT_APP_PRODUCTS_API_URL}/`

  static notifyError(text) {
    message.error({ content: text, duration: 4, key: 'error' })
  }

  static notifySuccess(text) {
    message.success({ content: text, duration: 4, key: 'success' })
  }

  static notifyLoading(text) {
    message.loading({ content: text, duration: 0, key: 'loading' })
  }

  static request = async (method, path, data = null) => {
    let response = null
    try {
      this.notifyLoading('Loading...')
      response = await axios[method](`${ProductAPI.BASE_URL}${path}`, data)
    } catch (error) {
      console.error(error)
      ProductAPI.notifyError('Failed to make request, please try again later')
    } finally {
      message.destroy('loading')
      return response
    }
  }

  static get = async (path) => await ProductAPI.request('get', path)

  static post = async (path, data) => await ProductAPI.request('post', path, data)

  static copy = async (id) => {
    try {
      await ProductAPI.request('post', `copy/single/${id}`)
      ProductAPI.notifySuccess('Product was copied successfully')
    } catch (error) {
      ProductAPI.notifyError('Failed to copy product, please try again later')
    } finally {
      return true
    }
  }
}
