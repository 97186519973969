import { put, call } from 'redux-saga/effects'
import { notification } from 'antd'

import axiosRequest from '../../hooks/useAxiosRequest'

export function* rollbackLogin() {
  // logout for deletion of tokens
  try {
    yield call(axiosRequest, '/api/logout', null, 'get', true)
  } catch (e) {
    return notification.warning({
      message: 'Warning',
      description: 'Something went wrong, try again later.',
    })
  } finally {
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })

    window.localStorage.clear()
  }

  // setting user general state
}
