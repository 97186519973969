import { useState } from 'react'
import { Dropdown } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'

import { ColorPicker } from '../../../components/ui/ColorPicker/ColorPicker'

/**
 * Renders a color controller with a dropdown color picker.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the color controller.
 * @param {string} props.description - The description of the color controller.
 * @param {string} props.contextKey - The key associated with the color in the context.
 * @param {string} props.defaultColor - The default color for the color controller.
 * @param {string} props.color - The current color value for the color controller.
 * @param {Array} props.recentColors - An array of recently selected colors.
 * @param {function} props.callback - The callback function to handle color changes.
 * @returns {JSX.Element} - The rendered ColorController component.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const ColorController = ({
  title = '',
  description = '',
  contextKey = null,
  defaultColor = '#000',
  color = null,
  recentColors = [],
  callback = () => null,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  /**
   * Handle color change and update the state.
   * @function
   * @param {string} hex - The hex color code.
   */
  const handleChange = (hex) => {
    callback(hex, contextKey)
    setIsDropdownVisible(false)
  }

  return (
    <div className="company-identity__section__controller row">
      <div className="col-9">
        <p className="company-identity__section__controller__title">{title}</p>
        <p className="company-identity__section__controller__description">
          {description}
        </p>
      </div>
      <div className="company-identity__section__controller__color col-3">
        <div
          className="company-identity__section__controller__color__preview"
          style={{ backgroundColor: color }}
        ></div>
        {/* TODO: IMPLEMENT BETTER SOLUTION TO REFRESH OF COLOR STATE INSIDE COLOR PICKER WHEN RESENT EVENT IS TRIGGERED */}
        <Dropdown
          overlay={
            isDropdownVisible ? (
              <ColorPicker
                recentColors={recentColors}
                callback={handleChange}
                defaultColor={defaultColor}
              />
            ) : (
              ''
            )
          }
          trigger={['click']}
          visible={isDropdownVisible}
        >
          <CaretDownOutlined onClick={() => setIsDropdownVisible(!isDropdownVisible)} />
        </Dropdown>
      </div>
    </div>
  )
}
