import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Form, Input, Button, notification } from 'antd'
import { Link } from 'react-router-dom'

import loginImg from '../../assets/onboarding/login.svg'
import axiosRequest from '../../hooks/useAxiosRequest'

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state.user.user)
  console.log(user)

  const onFormSuccess = (values) => {
    const { email } = values
    setLoading(true)
    axiosRequest(`/api/v1/auth/forgot-password`, { email }, 'post', false)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Restore password link was sent to provided email',
        })
      })
      .catch((e) => {
        notification.error({
          message: 'Error',
          description: 'Something goes wrong, please try again later',
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Helmet title="Login" />
      <div className="secured-login">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-5 order-2 order-lg-1">
              <div className="login__form">
                <h1>Forgot your Password?</h1>
                <p>
                  Remember?
                  <Link
                    to="/secured/login"
                  >
                    &nbsp;Login
                  </Link>
                </p>
                <Form
                  onFinish={onFormSuccess}
                  layout="vertical"
                  hideRequiredMark
                  className="mb-0"
                  initialValues={{
                    email: user.email ? user.email : null,
                  }}
                >
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input type="email" placeholder="Email*" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="w-100 ant-btn-ttu"
                      htmlType="submit"
                      loading={loading}
                    >
                      reset password
                    </Button>
                  </Form.Item>
                  {!user && (
                    <Form.Item className="mb-0 text-center">
                      <Link to="/secured/login">Sign In</Link>
                    </Form.Item>
                  )}
                </Form>
              </div>
            </div>
            <div className="col-12 col-lg-7 order-1 order-lg-2 mb-4 mb-lg-0">
              <div className="login__image">
                <img src={loginImg} alt="TTW Login" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
