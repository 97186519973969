import { Form, Row, Col, Checkbox, Select, Radio, Skeleton } from 'antd'

import { useShippingAndServicesForm } from '../../../../hooks/ui/useShippingAndServicesForm/useShippingAndServicesForm'

import './ShippingAndServicesForm.scss'

const ShippingAndServicesForm = () => {
  const { defaultValues } = useShippingAndServicesForm()

  if (defaultValues === undefined) return <Skeleton active />
  return (
    <>
      <h1>Shipping and Services</h1>
      <Row>
        <Form.Item
          name="businessType"
          label="Are you managing B2B and/or B2C shipments?"
          colon={false}
          initialValue={defaultValues ? defaultValues.businessType : []}
        >
          <Checkbox.Group>
            <Checkbox value={'b2b'} style={{ marginRight: 32 }}>
              B2B
            </Checkbox>
            <Checkbox value={'b2c'}>B2C</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Row>
      <Row gutter={80}>
        <Col span={13}>
          <Form.Item
            name="shipmentType"
            label="Type of Shipment"
            colon={false}
            initialValue={defaultValues ? defaultValues.shipmentType : null}
          >
            <Select placeholder="Select" style={{ width: 330 }}>
              <Select.Option value="air-dry">Air - Dry</Select.Option>
              <Select.Option value="air-reefer">Air - Reefer</Select.Option>
              <Select.Option value="air-insulated">Air - Insulated</Select.Option>
              <Select.Option value="boat-dry">Boat - Dry </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            name="groupageShipments"
            label="Do you do groupage shipments as well?"
            colon={false}
            initialValue={defaultValues ? defaultValues.groupageShipments : null}
          >
            <Radio.Group>
              <Radio value={true} style={{ marginRight: 24 }}>
                {' '}
                Yes{' '}
              </Radio>
              <Radio value={false}> No </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={13}>
          <Form.Item
            name="shipmentQuantities"
            label="Minimum Shipment Quantities"
            colon={false}
            initialValue={defaultValues ? defaultValues.shipmentQuantities : []}
          >
            <Checkbox.Group>
              <Row>
                <Checkbox value="singleBoxes" style={{ marginBottom: 12 }}>
                  Single Boxes
                </Checkbox>
              </Row>
              <Row>
                <Checkbox value="pallets" style={{ marginBottom: 12 }}>
                  Pallets
                </Checkbox>
              </Row>
              <Row>
                <Checkbox value="fullLoadContainers">Full load Containers</Checkbox>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            name="palletType"
            label="Type of Pallet"
            colon={false}
            initialValue={defaultValues ? defaultValues.palletType : []}
          >
            <Checkbox.Group>
              <Row>
                <Checkbox value="manages_eur1" style={{ marginBottom: 12 }}>
                  EUR-1 Pallet (120 x 80 cm)
                </Checkbox>
              </Row>
              <Row>
                <Checkbox value="manages_eur3" style={{ marginBottom: 12 }}>
                  EUR-3 Pallet (120 x 100 cm)
                </Checkbox>
              </Row>
              <Row>
                <Checkbox value="manages_eur6">EUR-6 Pallet (80 x 60 cm)</Checkbox>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item
          name="dangerousGoods"
          label="Do you handle Dangerous Goods?"
          colon={false}
          initialValue={defaultValues ? defaultValues.dangerousGoods : false}
        >
          <Radio.Group>
            <Radio value={true} style={{ marginRight: 24 }}>
              {' '}
              Yes{' '}
            </Radio>
            <Radio value={false}> No </Radio>
          </Radio.Group>
        </Form.Item>
      </Row>
      <Row>
        <Form.Item
          name="otherServices"
          label="Do you provide other services?"
          colon={false}
          initialValue={defaultValues ? defaultValues.otherServices : []}
        >
          <Checkbox.Group>
            <Row>
              <Checkbox value="warehousing" style={{ marginBottom: 12 }}>
                Warehousing
              </Checkbox>
            </Row>
            <Row>
              <Checkbox value="distribution" style={{ marginBottom: 12 }}>
                Distribution
              </Checkbox>
            </Row>
            <Row>
              <Checkbox value="localInvoicing">Local invoicing (Reselling)</Checkbox>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Row>
    </>
  )
}

export default ShippingAndServicesForm
