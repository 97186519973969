import { TagItem } from './TagItem'

export const TagList = ({ tags, handleDelete }) => {
  return (
    <div className="export-countries-map export-countries-map__tags mt-3">
      <div className="export-countries__tags mb-5">
        <ul className="d-flex justify-content-start align-items-center">
          {tags.map((tag) => (
            <TagItem key={tag.key} tagKey={tag.key} label={tag.label} handleDelete={handleDelete} />
          ))}
        </ul>
      </div>
    </div>
  )
}
