import ProductDetailsMarketplacePage from '../pages/marketplace/products/ProductDetails'
import ExplorePage from '../pages/marketplace/ExplorePage'
import SellersPage from '../pages/marketplace/SellersPage'
import BrandsPage from '../pages/marketplace/BrandsPage'
import CompanyFactsPage from '../pages/marketplace/company/CompanyFacts'
import SellerShowroom from '../pages/marketplace/SellerShowroom'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/marketplace/explore',
    Component: ExplorePage,
  },
  {
    path: '/marketplace/company/:slug',
    Component: CompanyFactsPage,
  },
  {
    path: '/marketplace/showroom/:slug',
    Component: SellerShowroom,
  },
  {
    path: '/marketplace/sellers',
    Component: SellersPage,
  },
  {
    path: '/marketplace/brands',
    Component: BrandsPage,
  },
  {
    path: '/marketplace/product/:slug',
    Component: ProductDetailsMarketplacePage,
  },
]
