import { ServiceType } from './ServiceType.interface'

export class LogisticsService extends ServiceType {
  constructor({ code, icon, id, name }) {
    super({
      code,
      icon,
      id,
      name,
      path: '/secured/verification/service-provider/shipping-and-services',
    })
  }
}
