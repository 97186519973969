import {  useState } from 'react'
import { Modal, message, notification } from 'antd'

import { VerificationModalFooter } from './VerificationModalFooter'

import { history } from '../../..'
import { AsyncMessage, SuccessMessage } from '../../../models/Messages'

export const VerificationModal = ({ isShow, onCancel, onSave }) => {
  const [isSaved, setIsSaved] = useState(false)

  const handleSave = () => {
    message.loading(AsyncMessage.loading)
    // tmp tmp tmp tmp
    setTimeout(() => {
      message.destroy()
      setIsSaved(true)
      notification.success({
        message: 'Congratulations!',
        description: SuccessMessage.profile.saved
      })
    }, 1630)
  }

  const handleComplete = () => {
    history.push("/profile/company-profile")
  }

  return (
    <Modal
      className="verification-modal"
      centered
      visible={isShow}
      closable={true}
      footer={
        <VerificationModalFooter
          onCancel={onCancel}
          onSave={handleSave}
          saved={isSaved}
          onLeave={handleComplete}
        />
      }
      title={isSaved ? <h4>Congratulations!</h4> : <h4>Save Profile Changes?</h4>}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <p>
        {!isSaved
          ? `Do you really want to save your profile information \n changes?`
          : 'Changes to your profile have been saved. You can go to \n the My Profile page at any time to modify or update your\n profile.'}
      </p>
    </Modal>
  )
}
