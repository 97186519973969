import React, { useEffect } from 'react'
import _ from 'lodash'
import { Checkbox, Form, Popover, Radio } from 'antd'

import PRODUCT_PACKAGES from '../../../data/packages'
import data from 'bootstrap/js/src/dom/data'

const PackagingLogistic = ({ product, updateProduct }) => {
  useEffect(() => {
    console.log(product)
  }, [product])

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <Form.Item
          name={['packagingLogistic', 'isColdStorage']}
          label="Does your product require cold storage?"
        >
          <Radio.Group
            onChange={(e) =>
              updateProduct('packagingLogistic.isColdStorage', e.target.value)
            }
            value={product.packagingLogistic.isColdStorage}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="col-12 col-md-6">
        <Form.Item
          name={['packagingLogistic', 'isStackable']}
          label="Is the product stackable?"
        >
          <Radio.Group
            onChange={(e) =>
              updateProduct('packagingLogistic.isStackable', e.target.value)
            }
            value={product.packagingLogistic.isStackable}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="col-12 col-md-6">
        <Form.Item
          name={['packagingLogistic', 'havePackaging']}
          label="Does your product have packaging?"
        >
          <Radio.Group
            onChange={(e) =>
              updateProduct('packagingLogistic.havePackaging', e.target.value)
            }
            value={product.packagingLogistic.havePackaging}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div
        className={`col-12 product-upload__packages ${
          product.packagingLogistic && product.packagingLogistic.havePackaging
            ? 'd-block'
            : 'd-block'
        }`}
      >
        <Form.Item
          label={
            <span className="d-flex justify-content-start align-items-center">
              <p className="product-upload__packages__helper">
                Pick up your types of packaging
              </p>
              <p className="ms-2 product-upload__packages__helper">
                <Popover
                  content={
                    <p className='text-left'>
                      Primary and Intermediary Packaging data<br /> are important information
                      for Buyers.<br /> A clear description is a plus. Secondary and<br /> Tertiary
                      Packaging data are fundamental<br /> to enable logistics support and
                      shipment<br /> data automation provided by TTW.
                    </p>
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    style={{ width: '18px', height: 'auto' }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </Popover>
              </p>
            </span>
          }
        >
          <div className="row mt-3">
            {PRODUCT_PACKAGES.map((productPackage) => (
              <div className="col-12 col-md-6 col-lg-3">
                <Checkbox
                  disabled={!product.packagingLogistic.havePackaging}
                  key={productPackage.title}
                  checked={
                    _.findIndex(
                      product.packagingLogistic.packages,
                      (data) => data.key === productPackage.key,
                    ) >= 0
                  }
                  className="package-type"
                  onChange={(e) => {
                    let newPackages = product.packagingLogistic.packages
                    const findIndex = _.findIndex(
                      newPackages,
                      (data) => data.key === productPackage.key,
                    )
                    if (findIndex >= 0 && !e.target.checked) {
                      newPackages.splice(findIndex, 1)
                    } else {
                      if (e.target.checked) {
                        newPackages.push({
                          key: productPackage.key,
                          title: productPackage.title,
                          variants: [],
                        })
                      }
                    }
                    const rearrangePackages = []
                    const packagesTypesArr = [
                      'primary',
                      'display',
                      'secondary',
                      'tertiary',
                    ]
                    _.forEach(packagesTypesArr, (arr) => {
                      const findIndex = _.findIndex(newPackages, (v) => v.key === arr)
                      if (findIndex >= 0) {
                        rearrangePackages.push(newPackages[findIndex])
                      }
                    })
                    updateProduct('packagingLogistic.packages', rearrangePackages)
                  }}
                >
                  <div className="type__head">
                    <div className="head__title">{productPackage.title}</div>
                  </div>
                  <div className="type__icon">{productPackage.icon}</div>
                  <div className="type__description">
                    <p className="mb-0">{productPackage.description}</p>
                  </div>
                </Checkbox>
              </div>
            ))}
          </div>
        </Form.Item>
      </div>
    </div>
  )
}

export default PackagingLogistic
