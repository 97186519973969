import React from 'react'

const ProductPackaging = ({ product }) => {
  const { get_packaging } = product.product

  if (!get_packaging) {
    return null
  }

  return (
    <div className="description-card description-card__packaging mt-4">
      <h2 className="card-title">Packaging Details</h2>
      <div className="product-details__packages">
        {get_packaging.map((packagingWrapper) => {
          console.log(packagingWrapper)
          if (packagingWrapper.get_variants && packagingWrapper.get_variants.length > 0) {
            return packagingWrapper.get_variants.map((variant) => (
              <div key={variant.id} className="package-item">
                <div className="package-item__head">
                  <div className="package-icon">
                    <img src={variant.get_variant.icon} alt={variant.get_type.name} />
                  </div>
                  <div className="package-name">
                    {variant.get_type.name}
                    {variant.innerPackPrice ? (
                      <span>${variant.innerPackPrice}</span>
                    ) : null}
                  </div>
                </div>
                <div className="package-item__content">
                  <div className="row">
                    <div className="col-6 col-md-4">
                      <p className="mb-0">
                        Product per pack:{' '}
                        <span>{variant.productPerInnerPack || '-'}</span>
                      </p>
                    </div>
                    <div className="col-6 col-md-4">
                      <p className="mb-0">
                        Pack barcode:{' '}
                        <span>
                          {variant.innerPackBarcodeType && variant.innerPackBarcodeValue
                            ? `${variant.innerPackBarcodeType} ${variant.innerPackBarcodeValue}`
                            : '-'}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 col-md-4">
                      <p>
                        Pack dimensions:{' '}
                        <span>
                          {variant.innerPackDimensionsWidth || '-'}
                          {`x${variant.innerPackDimensionsHeight}` || '-'}
                          {`x${variant.innerPackDimensionsDepth}` || '-'}{' '}
                          {variant.innerPackDimensionsType || '-'}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 col-md-4">
                      <p>
                        Pack volume:{' '}
                        <span>
                          {variant.innerPackVolumeValue || '-'}{' '}
                          {variant.innerPackVolumeType || '-'}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 col-md-4">
                      <p>
                        Pack weight:{' '}
                        <span>
                          {variant.innerPackWeightValue || '-'}{' '}
                          {variant.innerPackWeightType || '-'}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
          return null
        })}
      </div>
    </div>
  )
}

export default ProductPackaging
