import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Helmet } from 'react-helmet-async'
import { Form, Select, Input, message, Checkbox } from 'antd'

import COUNTRIES_LIST from '../../../data/countries'
import { getVerificationSteps } from '../../../data/verficationSteps'

import { history } from '../../..'
import axiosRequest from '../../../hooks/useAxiosRequest'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'

import VerificationLayout from '../../../components/verification/VerificationLayout'
import LayoutLoading from '../../../components/verification/LayoutLoading'

export const CompanyFinancial = () => {
  const pageTitle = 'Financial'
  const { user } = useSelector((state) => state.user)
  const { userType } = useParams()
  const [companyData, setCompanyData] = useState(null)
  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isCIA, setisCIA] = useState(false)
  const [selectedPayments, setSelectedPayments] = useState({
    cia: {
      wire: false,
      cheque: false,
      card: false,
    },
    lc: false,
    draft: false,
    oc: false,
  })

  const CIA_METHODS = ['Wire', 'Cheque', 'Card']

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/user-financial-info', null, 'get', true)
      .then((res) => {
        if (res.data.financialInfo) {
          // Map response
          const financialInfo = {
            bank_country: res.data.financialInfo.bank_country,
            bank_name: res.data.financialInfo.bank_name,
            is_write: res.data.financialInfo.is_write,
            is_cheque: res.data.financialInfo.is_cheque,
            is_debit_credit_card: res.data.financialInfo.is_debid_credit_card,
            is_lc: res.data.financialInfo.is_lc,
            is_draft: res.data.financialInfo.is_draft,
            is_oc: res.data.financialInfo.is_oc,
          }

          // Set form data
          const { bank_country, bank_name } = financialInfo
          setCompanyData({
            bank_country,
            bank_name,
          })

          // Set payment methods
          const {
            is_cheque,
            is_debit_credit_card,
            is_draft,
            is_lc,
            is_oc,
            is_write,
          } = financialInfo
          if (is_cheque || is_write || is_debit_credit_card) {
            setisCIA(true)
          }
          const paymentMethods = {
            cia: {
              wire: is_write,
              cheque: is_cheque,
              card: is_debit_credit_card,
            },
            lc: is_lc,
            draft: is_draft,
            oc: is_oc,
          }
          setSelectedPayments(paymentMethods)

          // Set steps
          const totalSteps =
            getVerificationSteps(userType)
          setProgress(parseInt((res.data.steps.length * 100) / totalSteps.length))
        }
        setIsLoading(false)
      })
      .catch(() => {
        message.error('Something goes wrong, contact support...')
      })
  }, [])

  const handleSelect = (e, isCIA = false) => {
    const paymentMethods = { ...selectedPayments }
    if (isCIA) {
      if (paymentMethods.cia[e.target.value.toLowerCase()]) {
        paymentMethods.cia[e.target.value.toLowerCase()] = false
      } else {
        paymentMethods.cia[e.target.value.toLowerCase()] = true
      }

      if (
        !paymentMethods.cia.card &&
        !paymentMethods.cia.cheque &&
        !paymentMethods.cia.wire &&
        isCIA
      ) {
        setisCIA(false)
      } else {
        setisCIA(true)
      }

      setSelectedPayments((prev) => ({
        ...prev,
        cia: {
          ...prev.cia,
          [e.target.value.toLowerCase()]: paymentMethods.cia[
            e.target.value.toLowerCase()
          ],
        },
      }))
    } else {
      if (paymentMethods[e.target.value]) {
        paymentMethods[e.target.value] = false
      } else {
        paymentMethods[e.target.value] = true
      }
      setSelectedPayments((prev) => ({
        ...prev,
        [e.target.value]: paymentMethods[e.target.value],
      }))
    }
  }

  const onSubmit = async (values) => {
    console.log(values)
    const body = {
      user_id: user.id,
      bank_country: values.bank_country,
      bank_name: values.bank_name,
      is_write: selectedPayments.cia.wire ? selectedPayments.cia.wire : false,
      is_cheque: selectedPayments.cia.cheque ? selectedPayments.cia.cheque : false,
      is_debid_credit_card: selectedPayments.cia.card ? selectedPayments.cia.card : false,
      is_draft: selectedPayments.draft ? selectedPayments.draft : false,
      is_lc: selectedPayments.lc ? selectedPayments.lc : false,
      is_oc: selectedPayments.oc ? selectedPayments.oc : false,
    }
    if (selectedPayments.length < 1) {
      message.error('Please, select payment methods before submit.')
    } else {
      message.loading({ content: 'Please, wait...', key: 'save' })

      axiosRequest(
        '/api/v1/on-boarding/user-financial-info', // change api url
        body,
        'post',
        true,
      ).then((res) => {
        if (res.status === 200) {
          updateAccountStatus(3, user.id)
          message.destroy()
          history.push(`/secured/verification/${userType}/photos-docs`)
        } else {
          message.destroy()
          message.error(
            'Something went wrong updating your financial information, try again later.',
          )
        }
      })
    }
    // make logic so that from response given we set the stage for the checked.
    console.log({
      paymentMethods: { ...selectedPayments },
      bank: { name: values.bank_name, location: values.bank_country },
    })
  }

  const onSubmitFail = () => {
    message.error('Please, provide all the necessary fields.')
  }

  if (isLoading) {
    return <LayoutLoading />
  }

  return (
    <>
      <Helmet title={pageTitle} />
      <Form
        onFinish={onSubmit}
        onFinishFailed={onSubmitFail}
        layout="vertical"
        hideRequiredMark
        className="mb-0"
        initialValues={{
          bank_country:
            companyData && companyData.bank_country ? companyData.bank_country : null,
          bank_name: companyData && companyData.bank_name ? companyData.bank_name : null,
        }}
      >
        <VerificationLayout currentStep="setFinancialInfo" progress={progress}>
          <div className="row financial">
            <div className="col-12">
              <h1>{pageTitle}</h1>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Form.Item
                    label="Partnership Bank Country"
                    name="bank_country"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Select placeholder="Select a country*">
                      {COUNTRIES_LIST.map((c) => (
                        <Select.Option value={c.name}>{c.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item
                    label="Bank"
                    name="bank_name"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input placeholder="Bank*" type="text" />
                  </Form.Item>
                </div>
                <div className="financial__payments">
                  <p>
                    <strong>Usual payment means</strong>
                  </p>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <div className="col-12">
                            <div className="financial__payments__check">
                              <Checkbox
                                checked={isCIA}
                                value={'cia'}
                                onChange={(e) => handleSelect(e, true)}
                              >
                                CIA (Cash In Advance)*
                              </Checkbox>
                            </div>
                          </div>
                          <div className="financial__payments__cia-methods">
                            {CIA_METHODS.map((m) => (
                              <div className="col-12">
                                <div className="financial__payments__check">
                                  <Checkbox
                                    checked={selectedPayments['cia'][m.toLowerCase()]}
                                    value={m}
                                    onChange={(e) => handleSelect(e, true)}
                                  >
                                    {m !== 'Card' ? m : 'Debit/Credit Card'}
                                  </Checkbox>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="col-12 col-md-6">
                            <div className="financial__payments__check">
                              <Checkbox
                                checked={selectedPayments['lc']}
                                value={'lc'}
                                onChange={(e) => handleSelect(e)}
                              >
                                LC (Letter of Credit)
                              </Checkbox>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="financial__payments__check">
                              <Checkbox
                                checked={selectedPayments['draft']}
                                value={'draft'}
                                onChange={(e) => handleSelect(e)}
                              >
                                Draft (D/P - D/A)
                              </Checkbox>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="financial__payments__check">
                              <Checkbox
                                checked={selectedPayments['oc']}
                                value={'oc'}
                                onChange={(e) => handleSelect(e)}
                              >
                                Open Account
                              </Checkbox>
                            </div>
                          </div>
                        </div>
                        <p className="financial__payments__disclaimer">
                          {/* get url for escrow service */}* If you chose this option,
                          please check our <a href="#">Escrow service</a>, a solution to
                          protect each parties (Seller/Buyer) in the transaction
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VerificationLayout>
      </Form>
    </>
  )
}
