import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { ErrorMessage } from '../../../models'
import { ServiceTypeAPI } from '../../../models/Domains/API/ServiceTypeAPI.class'
import { ServiceProviderAPI } from '../../../models/Domains/API/ServiceProviderAPI.class'

import useLoader from '../../utils/Loader/useLoader'

export const useServicesTypeModal = () => {
  const {
    user: { serviceProvider },
  } = useSelector((state) => state.user)
  const [modalItems, setModalItems] = useState([])
  const { isLoading, setIsLoading } = useLoader()
  const dispatch = useDispatch()

  const handleServiceClick = (code) => {
    const items = modalItems.map((item) => {
      if (item.code === code) return { ...item, isActive: !item.isActive }
      return item
    })
    setModalItems(items)
  }

  const getActiveServices = () => {
    return modalItems.filter((item) => item.isActive).map((item) => item.code)
  }
  const getBody = () => {
    return {
      updateType: 'select_services',
      id: serviceProvider.id, // <- error
      services: getActiveServices(),
      logisticServiceData: {},
      config: {
        managerType: 'generic_service_provider',
      },
    }
  }

  const onSubmit = async () => {
    message.loading({ content: 'Updating your profile...', key: 'update-services'})
    try {
      const body = getBody()
      await ServiceProviderAPI.update(body)
      message.success({ content: 'Your profile was updated successfully.', key: 'update-services'})
      dispatch({ type: 'user/LOAD_CURRENT_ACCOUNT'})
    } catch (error) {
      console.log(error)
      message.error({content: ErrorMessage.server.generic, key: 'update-services'})
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        let response = await ServiceTypeAPI.index()
        setModalItems(response.map((item) => ({ ...item, isActive: false })))
      } catch (error) {
        console.log(error)
        message.error(ErrorMessage.server.generic)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [setIsLoading])

  return { onSubmit, modalItems, isLoading, handleServiceClick }
}
