import { Button } from 'antd'

export const Footer = ({ onSave, onReset }) => {
  return (
    <div className="company-identity__footer">
      <Button type="primary" className="ant-btn-primary ant-btn-ttu" onClick={onSave}>
        save
      </Button>
      <Button
        type="ghost"
        className="ant-btn-primary__outlined ant-btn-primary__outlined--ghost ant-btn-ttu"
        onClick={onReset}
      >
        reset
      </Button>
    </div>
  )
}
