import { Select, Input } from 'antd'

import { CURRENCIES_ARRAY } from '../../../../../data/currencies'

export const PriceParams = ({ handlePricesInputs, index, data, price }) => {

  return (
    <div className="col-12 mb-3">
      <div className="row">
        {/* STOCK LOCATION CONTROLLER */}
        <div className="col-12 col-md-6">
          <p className="mb-3">
            <strong>Stock Location</strong>
          </p>
          <Select
            value={price.stockLocation ? price.stockLocation.id : null}
            defaultValue={price.stockLocation ? price.stockLocation.id : data[0].id}
            className="w-100"
            onChange={(val) => handlePricesInputs(index, 'stockLocation', val)}
          >
            {data && data.length > 0
              ? data.map((location) => {
                  return (
                    <Select.Option key={location.id} value={parseInt(location.id, 10)}>
                      {`${location.country}, ${location.city}, ${
                        location.address || 'No address'
                      } (${location.type ? location.type.toUpperCase() : 'HQ'})`}
                    </Select.Option>
                  )
                })
              : null}
          </Select>
        </div>
        {/* CURRENCY CONTROLLER */}
        <div className="col-12 col-md-3">
          <p className="mb-3">
            <strong>Currency</strong>
          </p>
          <Select
            defaultValue={price.currency}
            className="w-100"
            onChange={(val) => handlePricesInputs(index, 'currency', val)}
          >
            {CURRENCIES_ARRAY.map((currency) => (
              <Select.Option key={currency.cc} value={currency.cc}>
                {currency.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        {/* DISCOUNT CONTROLLER */}
        <div className="col-12 col-md-3">
          <p className="mb-3">
            <strong>Discount</strong>
          </p>
          <Input
            defaultValue={price.discount}
            placeholder="0%"
            suffix="%"
            onChange={(e) => handlePricesInputs(index, 'discount', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
