import { useState } from 'react'
import _ from 'lodash'

import Checkbox from 'antd/lib/checkbox/Checkbox'

export const SubIndustriesItems = ({ handleSelect, industry, selected }) => {
  const allSubIndustries = industry.industry_details.get_sub_industries
  const [isShowAll, setIsShowAll] = useState(false)


  const handleSelectedSubIndustry = (e, id) => {
    if (e.target.checked) {
      selected.push(id)
    } else {
      _.remove(selected, (n) => {
        return n === id
      })
    }
    handleSelect([...selected])
  }

  const handleSelectedAllSubIndustries = (industry) => {
    const subIndustries = [...industry.industry_details.get_sub_industries]

    if (isAllSubIndustriesSelected(industry)) {
      for (const subInd of subIndustries) {
        _.remove(selected, (sI) => sI === subInd.id)
      }
    } else {
      for (const subInd of subIndustries) {
        if (!selected.find((ind) => ind === subInd.id)) {
          selected.push(subInd.id)
        }
      }
    }
    handleSelect([...selected])
  }

  const isAllSubIndustriesSelected = (industry) => {
    const subIndustries = [...industry.industry_details.get_sub_industries]
    const subIndustriesRelation = []

    for (const subInd of subIndustries) {
      if (selected.find((selSubInd) => selSubInd === subInd.id)) {
        subIndustriesRelation.push(subInd.id)
      }
      console.log(subIndustriesRelation)
    }

    if (subIndustriesRelation.length === subIndustries.length) {
      return true
    } else {
      return false
    }
  }

  const handleShowItems = () => {
    setIsShowAll(!isShowAll)
  }

  return (
    <div className="sub-industries">
      <div className="row">
        {/* <div className="row">
          {TABS.map((tab) => (
            <div
              className={`col-3 sub-industries__tab ${
                activeTab === tab && 'col-3 sub-industries__tab--active'
              }`}
              onClick={() => handleTabSelection(tab)}
            >
              {tab}
            </div>
          ))}
          <div className="divider"></div>
        </div> */}
        <div className="col-12 col-md-6">
          <div className="sub-industries__check-all">
            <Checkbox
              checked={isAllSubIndustriesSelected(industry)}
              onChange={(e) => handleSelectedAllSubIndustries(industry)}
            >
              {isAllSubIndustriesSelected(industry) ? 'Deselect all' : 'Select all'}
            </Checkbox>
          </div>
        </div>
        {/* se nos items ativos houver mais de 15 itens, parar o map e retornar com o link de abrir */}
        {allSubIndustries.length <= 15 &&
          allSubIndustries.map((subIndustry) => {
            return (
              <div key={subIndustry.id} className="col-12 col-md-6">
                <div className="sub-industries__check">
                  <Checkbox
                    value={subIndustry.id}
                    checked={selected.includes(subIndustry.id)}
                    onChange={(e) => handleSelectedSubIndustry(e, subIndustry.id)}
                  >
                    {subIndustry.name}
                  </Checkbox>
                </div>
              </div>
            )
          })}
        {allSubIndustries.length > 15 && (
          <>
            {/* função para fechado */}
            {allSubIndustries.map((subIndustry) => {
              if (allSubIndustries.indexOf(subIndustry) >= 16 && !isShowAll) {
                return null
              }

              return (
                <div key={subIndustry.id} className="col-12 col-md-6">
                  <div className="sub-industries__check">
                    <Checkbox
                      value={subIndustry.id}
                      checked={selected.includes(subIndustry.id)}
                      onChange={(e) => handleSelectedSubIndustry(e, subIndustry.id)}
                    >
                      {subIndustry.name}
                    </Checkbox>
                  </div>
                </div>
              )
            })}
            <div>
              <div className="sub-industries__show-all" onClick={handleShowItems}>
                <p>{isShowAll ? 'Hide' : 'Show all'}</p>
                {isShowAll ? (
                  <i className="fe fe-chevron-up"></i>
                ) : (
                  <i className="fe fe-chevron-down"></i>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
