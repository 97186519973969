import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const useShippingAndServicesForm = () => {
  const { user } = useSelector((state) => state.user)
  const [defaultValues, setDefaultValues] = useState(undefined)

  const loadFormDefaultValues = useCallback(() => {
    let defaultValues = {
      shipmentType: [],
      businessType: [],
      palletType: [],
      shipmentQuantities: [],
      otherServices: [],
      groupageShipments: false,
      dangerousGoods: false,
    }

    if (user.serviceProvider && user.serviceProvider.logistic_service_provider) {
      const {
        air_dry_ship,
        air_insulated_ship,
        air_reefer_ship,
        boat_dry_ship,
        groupage_ship,
        manages_b2b,
        manages_b2c,
        manages_eur1,
        manages_eur3,
        manages_eur6,
        min_container,
        min_pallets,
        min_single_boxes,
        dangerous_ship,
      } = user.serviceProvider.logistic_service_provider.shipment_service
      const {
        has_distribution,
        has_invoicing,
        has_warehousing,
      } = user.serviceProvider.logistic_service_provider.extra_logistic

      const otherServices = []
      if (has_distribution) otherServices.push('distribution')
      if (has_invoicing) otherServices.push('localInvoicing')
      if (has_warehousing) otherServices.push('warehousing')
      defaultValues.otherServices = otherServices

      const shipmentType = []
      if (air_dry_ship) shipmentType.push('air-dry')
      if (air_insulated_ship) shipmentType.push('air-insulated')
      if (air_reefer_ship) shipmentType.push('air-reefer')
      if (boat_dry_ship) shipmentType.push('boat-dry')
      defaultValues.shipmentType = shipmentType

      const businessType = []
      if (manages_b2b) businessType.push('b2b')
      if (manages_b2c) businessType.push('b2c')
      defaultValues.businessType = businessType

      const palletType = []
      if (manages_eur1) palletType.push('manages_eur1')
      if (manages_eur3) palletType.push('manages_eur3')
      if (manages_eur6) palletType.push('manages_eur6')
      defaultValues.palletType = palletType

      const shipmentQuantities = []
      if (min_container) shipmentQuantities.push('fullLoadContainers')
      if (min_pallets) shipmentQuantities.push('pallets')
      if (min_single_boxes) shipmentQuantities.push('singleBoxes')
      defaultValues.shipmentQuantities = shipmentQuantities

      defaultValues.groupageShipments = groupage_ship
      defaultValues.dangerousGoods = dangerous_ship
    }
    return defaultValues
  }, [user])

  useEffect(() => {
    setDefaultValues(loadFormDefaultValues() || null)
  }, [loadFormDefaultValues])

  return { defaultValues }
}
