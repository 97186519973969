import { useState } from 'react'
import { message } from 'antd'

import axiosRequest from '../useAxiosRequest'

/**
 * @description Custom hook to manage product tags. It handles the state and logic of the tags in the product upload form.
 * @param {Object} product - The product object
 * @param {Function} callback - The function to update the product object
 * @returns {Object} An object containing the tags, a function to fetch tags, a function to handle the selection of a tag, a function to handle the deletion of a tag, and a function to handle the creation of a tag
 * @example
 * const { tags, isTagFound, isMax, handleNoTag, fetchTags, handleSelect, handleDelete, handleCreate } = useTagController(product, updateProduct)
 * @see https://ant.design/components/tag/
 * @see https://ant.design/components/input/
 * @see https://ant.design/components/modal/
 * @see https://ant.design/components/message/
 * @see https://ant.design/components/select/
 * @see https://ant.design/components/spin/
 *
 * @todo Add prop types
 * @todo Add tests
 * @todo Add storybook
 *
 * @version 1.0.0
 * @since 1.0.0
 * @category Hooks/UI
 * @component
 *
 * @requires react
 * @requires antd
 * @requires useAxiosRequest
 *
 * @author Rafael Rapizo Nery
 */
export const useTagController = (product, callback = () => null) => {
  const [tags, setTags] = useState([])
  let { tags: productTags } = product

  const isTagFound = (tags) => {
    return tags.length > 0
  }

  const isMaxTags = (selectedTags) => {
    return selectedTags.length >= 5
  }

  const fetchTags = async (val) => {
    // Query tag name in database.
    await axiosRequest(
      `/api/v1/product-tags/actions/autocomplete?q=${val}`,
      null,
      'get',
      true,
    ).then((res) => {
      // Response handling
      if (res.status === 200) {
        const { tags } = res.data
        if (isTagFound(tags)) {
          // Response successful with available tags
          setTags([...tags])
        } else {
          // Response successful with no available tags
          handleNoTag()
        }
      }
    })
  }

  const handleNoTag = () => {
    message.destroy()
    message.warn('No tag found.')
    setTags([])
  }

  const handleSelect = (val, option) => {
    // const { tags } = product
    productTags = [...productTags, option]
    callback('tags', productTags)
  }

  const handleCreate = async (tagName) => {
    try {
      message.loading('Loading...')
      await axiosRequest('/api/v1/product-tags', { name: tagName }, 'post', true)
        .then((res) => {
          if (res.data && res.data.tag) {
            // productTags.push({
            //   label: res.data.tag.name,
            //   value: res.data.tag.name,
            //   key: res.data.tag.id,
            // })
            let { name, id } = res.data.tag
            const newTag = {
              label: name,
              value: name,
              key: id,
            }
            productTags = [...productTags, newTag]
            callback('tags', productTags)
          }
        })
        .then(() => {
          message.destroy()
          message.success('Tag created successfully.')
        })
    } catch (error) {
      message.destroy()
      message.error('Something went wrong, contact support or try again later.')
    }
  }

  const handleDelete = (key) => {
    // no filter????.......
    // const tags = product.tags
    // const findIndex = _.findIndex(tags, (data) => data.key === key)
    // if (findIndex >= 0) {
    //   tags.splice(findIndex, 1)
    // }
    productTags = productTags.filter((tag) => tag.key !== key)
    callback('tags', productTags)
  }

  return {
    tags,
    isTagFound,
    isMax: isMaxTags(productTags),
    handleNoTag,
    fetchTags,
    handleSelect,
    handleDelete,
    handleCreate,
  }
}
