import React, { useState } from 'react'
import { Button } from 'antd'

import { useTagController } from '../../../../../hooks/ui/useTagController'

import { TagList } from './TagList'
import { SearchInput } from './SearchInput'
import { CreateTagModal } from './CreateTagModal/CreateTagModal'

/**
 * @description TagController is a component that uses the useTagController hook to manage the state and logic of the tags in the product upload form.
 * @param {Object} product - The product object
 * @param {Function} updateProduct - The function to update the product object
 * @returns {JSX.Element} A component that displays the search input, tag list, and create tag button
 * @example
 * <TagController product={product} updateProduct={updateProduct} />
 *
 * @see https://ant.design/components/button/
 * @see https://ant.design/components/input/
 * @see https://ant.design/components/tag/
 * @see https://ant.design/components/modal/
 *
 * @todo Add prop types
 * @todo Add tests
 * @todo Add storybook
 *
 * @version 1.0.0
 * @since 1.0.0
 * @category Presentational Components/Common
 * @component
 *
 * @requires react
 * @requires antd
 * @requires useTagController
 * @requires TagList
 * @requires SearchInput
 * @requires CreateTagModal
 *
 * @author Rafael Rapizo Nery
 *
 */
export const TagController = ({ product, updateProduct }) => {
  const [isShow, setIsShow] = useState(false)
  const {
    tags,
    isMax,
    fetchTags,
    handleSelect,
    handleDelete,
    handleCreate,
  } = useTagController(product, updateProduct)

  const handleModalShow = () => {
    setIsShow(!isShow)
  }

  return (
    <>
      <CreateTagModal
        isShow={isShow}
        handleShow={handleModalShow}
        callback={handleCreate}
      />
      <div className="row align-items-center">
        <SearchInput
          classname="col-lg-10"
          fetchTags={fetchTags}
          tags={tags}
          handleSelect={handleSelect}
          isMax={isMax}
        />
        <Button
          type="ghost"
          className="ant-btn-primary__outlined ant-btn-ttu mt-1 p-2 px-2 h-25 col-lg-2"
          onClick={handleModalShow}
        >
          Create tag
        </Button>
        <TagList tags={product.tags} handleDelete={handleDelete} />
      </div>
    </>
  )
}
