import { useEffect, useState } from 'react'
import { Form, Input, Select, Radio, Button, message } from 'antd'
import _ from 'lodash'

import { getPackageTotalWeight } from '../../../../../util/functions/packaging/getPackageTotalWeight'
import FormItem from 'antd/lib/form/FormItem'

const MainForm = ({
  index,
  updateIndex,
  packagingTypesData,
  addVariantToProduct,
  packageData,
  product,
}) => {
  const [variantsData, setVariantsData] = useState(null)
  const [masterPackMandatory, setMasterPackMandatory] = useState({
    unit: 'inch',
    width: null,
    height: null,
    depth: null,
    unitsPerPack: null,
  })

  useEffect(() => {
    const findIndex = _.findIndex(packagingTypesData, (data) => data.id === index.typeId)
    if (findIndex >= 0) {
      setVariantsData(packagingTypesData[findIndex].get_variants || [])
    }
  }, [index, packagingTypesData])

  const handlePackWeight = (e) => {
    updateIndex({
      ...index,
      innerPackWeight: {
        ...index.innerPackWeight,
        value: e.target.value,
      },
      innerPackWeightTotal: {
        ...index.innerPackWeightTotal,
        value: getPackageTotalWeight(product, {
          type: packageData.key,
          weight: e.target.value,
          units: index.productPerInnerPack,
        }),
      },
    })
  }
  const handleUnits = (e) => {
    updateIndex({
      ...index,
      productPerInnerPack: e.target.value,
      innerPackWeightTotal: {
        ...index.innerPackWeightTotal,
        value: getPackageTotalWeight(product, {
          type: packageData.key,
          weight: index.innerPackWeight.value,
          units: e.target.value,
        }),
      },
    })
  }

  const handleAddMasterPackage = (index, packageData, mandatoryFields) => {
    if (
      !mandatoryFields.width ||
      !mandatoryFields.height ||
      !mandatoryFields.depth ||
      !mandatoryFields.unitsPerPack
    ) {
      message.error({
        content:
          'It is mandatory to provide the capacity and dimensions of a Master Packages.',
        duration: 6,
      })
    } else {
      addVariantToProduct(index, packageData)
    }
  }

  const getNameOfPack = () => {
    if (packageData.key === 'primary') {
      return 'Primary pack'
    }
    if (packageData.key === 'display') {
      return 'Intermediary pack'
    }
    if (packageData.key === 'secondary') {
      return 'Master pack'
    }
    if (packageData.key === 'tertiary') {
      return 'Pallet'
    }
    return ''
  }

  return (
    <div className="verification-layout">
      <div className="company-units" style={{ background: 'rgba(255, 255, 255, 1)' }}>
        <div className="row">
          <Form.Item label="Packaging shapes:" className="packagingTypesVariants col-12">
            <Radio.Group
              className="w-100"
              value={index.variantId}
              onChange={(e) => {
                updateIndex({ ...index, variantId: e.target.value })
              }}
            >
              <div className="row">
                {variantsData &&
                  variantsData.map((variantData) => (
                    <div
                      className="col-6 col-lg-4 col-lg-3 col-xl-2 packagingTypesVariant"
                      key={variantData.id}
                    >
                      <Radio.Button value={variantData.id} className="w-100">
                        <div className="packagingTypesVariantWrapper">
                          <div className="packagingTypesVariantIcon">
                            <img src={variantData.icon} alt={variantData.name} />
                          </div>
                        </div>
                      </Radio.Button>
                    </div>
                  ))}
              </div>
            </Radio.Group>
          </Form.Item>
          {/* {packageData.key !== 'tertiary' && (
            <Form.Item
              label={`${getNameOfPack()} (optional)`}
              className="col-12 col-md-6"
            >
              <Input
                placeholder="Code"
                value={index.productPerInnerPack}
                onChange={(e) =>
                  updateIndex({ ...index, productPerInnerPack: e.target.value })
                }
              />
            </Form.Item>
          )} */}
          <Form.Item
            label={`Units per pack ${
              packageData.key !== 'secondary' ? '(optional)' : ''
            } `}
            className="col-12 col-md-6"
            rules={
              packageData.key !== 'secondary'
                ? null
                : [{ required: true, message: 'Field required' }]
            }
          >
            <Input
              type="number"
              min={1}
              placeholder={`Units${packageData !== 'secondary' ? '' : '*'}`}
              value={index.productPerInnerPack}
              onChange={(e) => {
                /* updateIndex({ ...index, innerPackPrice: e.target.value }) */
                setMasterPackMandatory((prev) => ({
                  ...prev,
                  unitsPerPack: e.target.value,
                }))
                handleUnits(e)
              }}
            />
          </Form.Item>
          <div className="col-12 col-md-6">
            <div className="row">
              {packageData.key !== 'tertiary' && (
                <Form.Item
                  label={`${getNameOfPack()} barcode (optional)`}
                  className="col-12"
                >
                  <div className="row">
                    <div className="col-4">
                      <Select
                        value={index.innerPackBarcode.type}
                        onChange={(val) =>
                          updateIndex({
                            ...index,
                            innerPackBarcode: {
                              ...index.innerPackBarcode,
                              type: val,
                            },
                          })
                        }
                      >
                        <Select.Option value="EAN">EAN</Select.Option>
                        <Select.Option value="UPC">UPC</Select.Option>
                        <Select.Option value="ISBN">ISBN</Select.Option>
                      </Select>
                    </div>
                    <div className="col-8">
                      <Input
                        placeholder="Barcode"
                        value={index.innerPackBarcode.value}
                        onChange={(e) =>
                          updateIndex({
                            ...index,
                            innerPackBarcode: {
                              ...index.innerPackBarcode,
                              value: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </Form.Item>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <Form.Item
                label={`${getNameOfPack()} dimensions ${
                  packageData.key !== 'secondary' ? '(optional)' : ''
                }`}
                className="col-12 col-lg-6"
              >
                <div className="row">
                  <div className="col-3">
                    <Select
                      value={index.innerPackDimensions.type}
                      onChange={(val) =>
                        updateIndex({
                          ...index,
                          innerPackDimensions: {
                            ...index.innerPackDimensions,
                            type: val,
                          },
                        })
                      }
                    >
                      <Select.Option value="Mm">Millimeter</Select.Option>
                      <Select.Option value="Cm">Centimeter</Select.Option>
                      <Select.Option value="M">Meter</Select.Option>
                      <Select.Option value="Km">Kilometer</Select.Option>
                      <Select.Option value="In">Inch</Select.Option>
                      <Select.Option value="Ft">Foot/Feet</Select.Option>
                      <Select.Option value="Yd">Yard</Select.Option>
                      <Select.Option value="Ml">Mile</Select.Option>
                    </Select>
                  </div>
                  <div className="col-3">
                    <Form.Item>
                      <Input
                        placeholder={`Width${packageData.key !== 'secondary' ? '' : '*'}`}
                        value={index.innerPackDimensions.width}
                        onChange={(e) => {
                          setMasterPackMandatory((prev) => ({
                            ...prev,

                            width: e.target.value,
                          }))
                          updateIndex({
                            ...index,
                            innerPackDimensions: {
                              ...index.innerPackDimensions,
                              width: e.target.value,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-3">
                    <Form.Item>
                      <Input
                        placeholder={`Height${
                          packageData.key === 'secondary' ? '*' : ''
                        }`}
                        value={index.innerPackDimensions.height}
                        onChange={(e) => {
                          setMasterPackMandatory((prev) => ({
                            ...prev,
                            height: e.target.value,
                          }))
                          updateIndex({
                            ...index,
                            innerPackDimensions: {
                              ...index.innerPackDimensions,
                              height: e.target.value,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-3">
                    <Form.Item>
                      <Input
                        placeholder={`Depth${packageData.key === 'secondary' ? '*' : ''}`}
                        value={index.innerPackDimensions.depth}
                        onChange={(e) => {
                          setMasterPackMandatory((prev) => ({
                            ...prev,

                            depth: e.target.value,
                          }))
                          updateIndex({
                            ...index,
                            innerPackDimensions: {
                              ...index.innerPackDimensions,
                              depth: e.target.value,
                            },
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
          {packageData.key !== 'tertiary' && (
            <div className="col-12 col-md-6">
              <div className="row">
                <Form.Item
                  label={`${getNameOfPack()} volume (optional)`}
                  className="col-12"
                >
                  <div className="row">
                    <div className="col-3">
                      <Select
                        value={index.innerPackVolume.type}
                        onChange={(val) =>
                          updateIndex({
                            ...index,
                            innerPackVolume: {
                              ...index.innerPackVolume,
                              type: val,
                            },
                          })
                        }
                      >
                        <Select.Option value="in3">
                          in <sup>3</sup>
                        </Select.Option>
                        <Select.Option value="ft3">
                          ft <sup>3</sup>
                        </Select.Option>
                        <Select.Option value="m3">
                          m <sup>3</sup>
                        </Select.Option>
                        <Select.Option value="cm3">
                          cm <sup>3</sup>
                        </Select.Option>
                      </Select>
                    </div>
                    <div className="col-9">
                      <Input
                        placeholder="Volume"
                        value={index.innerPackVolume.value}
                        onChange={(e) =>
                          updateIndex({
                            ...index,
                            innerPackVolume: {
                              ...index.innerPackVolume,
                              value: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </Form.Item>
              </div>
            </div>
          )}
          <div className="col-12 col-md-6">
            <div className="row">
              <Form.Item
                label={`${getNameOfPack()} weight (optional)`}
                className="col-12"
              >
                <div className="row">
                  <div className="col-3">
                    <Select
                      value={index.innerPackWeight.type}
                      onChange={(val) =>
                        updateIndex({
                          ...index,
                          innerPackWeight: {
                            ...index.innerPackWeight,
                            type: val,
                          },
                        })
                      }
                    >
                      <Select.Option value="kg">kg</Select.Option>
                      <Select.Option value="lb">lb</Select.Option>
                      <Select.Option value="g">g</Select.Option>
                      <Select.Option value="oz">oz</Select.Option>
                    </Select>
                  </div>
                  <div className="col-9">
                    <Input
                      placeholder="Package wieght"
                      value={index.innerPackWeight.value}
                      onChange={(e) => handlePackWeight(e)}
                    />
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="col-12 col-md-6"></div>
          <div className="col-12 col-md-6">
            <div className="row">
              <Form.Item label={`${getNameOfPack()} total weight`} className="col-12">
                <div className="row">
                  <div className="col-3">
                    <Select
                      value={index.innerPackWeightTotal.type}
                      onChange={(val) =>
                        updateIndex({
                          ...index,
                          innerPackWeightTotal: {
                            ...index.innerPackWeightTotal,
                            type: val,
                          },
                        })
                      }
                    >
                      <Select.Option value="kg">kg</Select.Option>
                      <Select.Option value="lb">lb</Select.Option>
                      <Select.Option value="g">g</Select.Option>
                      <Select.Option value="oz">oz</Select.Option>
                    </Select>
                  </div>
                  <div className="col-9">
                    <Input
                      placeholder="Total Weight"
                      value={
                        index.innerPackWeightTotal.value /* || getPackageTotalWeight() */
                      }
                      onChange={(e) =>
                        updateIndex({
                          ...index,
                          innerPackWeightTotal: {
                            ...index.innerPackWeightTotal,
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
          <Form.Item className="col-12">
            <Button
              className="ant-btn-primary"
              onClick={() => {
                if (
                  !index.typeId ||
                  index.typeId.length === 0 ||
                  !index.variantId ||
                  index.variantId.length === 0
                ) {
                  console.log('1')
                  message.error('Please, select a shape for the package.')
                  return false
                }
                if (packageData.key === 'secondary') {
                  return handleAddMasterPackage(index, packageData, masterPackMandatory)
                }
                addVariantToProduct(index, packageData)
              }}
            >
              Add Package
            </Button>
          </Form.Item>
        </div>
      </div>
    </div>
  )
}

export default MainForm
