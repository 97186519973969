import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ProfileStatusCard } from '../../components/pages/dashboard/ProfileStatusCard'

export const ProfileStatusContainer = () => {
  const user = useSelector((state) => state.user.user)
  const [profiles, setProfiles] = useState()

  const getRegisteredProfiles = (user) => {
    const profiles = []

    if (user.is_buyer) {
      profiles.push({ role: 'BUYER', completed: 33 })
    }
    if (user.is_seller) {
      profiles.push({ role: 'SELLER', completed: 68 })
    }
    if (user.is_partner && user.serviceProvider) {
      profiles.push({ role: 'SERVICE_PROVIDER', completed: 80 })
    }

    setProfiles([...profiles])
  }

  useEffect(() => {
    getRegisteredProfiles(user)
  }, [])

  if (profiles && profiles.length > 0) {
    return (
      <div
        className={
          profiles.length === 3 ? 'profile-completion--all' : 'profile-completion'
        }
      >
        {profiles.map((profile) => (
          <ProfileStatusCard
            completed={profile.completed}
            role={profile.role}
            key={profile.role}
          />
        ))}
      </div>
    )
  }
  return null
}
