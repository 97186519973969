import { PriceParams } from './sales-terms/PriceParams'
import { OrderParams } from './sales-terms/OrderParams'
import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { v4 } from 'uuid'
import { Button, notification } from 'antd'

import Prices from './sales-terms/Prices'

const SalesTerm = ({ data, product, updateProduct }) => {
  const [extraSalesTerms, setExtraSalesTerms] = useState([])

  const isExtraTerm = (key) => {
    if (extraSalesTerms.find((val) => val.key === key)) return true
    else return false
  }

  const handlePricesInputs = (index, target, value) => {
    if (target === 'stockLocation') {
      if (_.find(product.prices, (price) => price.stockLocation === value)) {
        notification.warn({
          message: 'Stock location not changed',
          description: 'You already using this stock location',
        })
      }

      // find in data the location object with the id of value
      const location = data.find((loc) => loc.id === value)
      // find in product the price object with the index of index
      const prices = product.prices
      prices[index].stockLocation = location
      updateProduct('prices', prices)
    } else {
      const prices = product.prices
      prices[index][target] = value
      updateProduct('prices', prices)
    }
  }

  const filterLocations = (salesTerms, locations) => {
    const currentLocations = salesTerms.map((term) => term.stockLocation.id)
    return locations.filter((location) => !currentLocations.includes(location.id))
  }


  const addNewSalesTerm = useCallback(() => {
    const prices = product.prices
    console.log(prices)
    const newPrice = {
      id: null,
      key: v4(),
      currency: prices[0] ? prices[0].currency : 'EUR',
      minimalOrderUnits: prices[0] ? prices[0].minimalOrderUnits : null,
      minimalOrderValue: prices[0] ? prices[0].minimalOrderValue : null,
      stockLocation: filterLocations(prices, data)[0],
      prices: [
        {
          key: v4(), 
          type: 'by_qty',
          priceValue: 0,
          minPrice: 0,
          maxPrice: 0,
          discount: 0,
          minQty: 0,
          maxQty: 0,
        },
      ],
      isAvailable: false,
      availableQty: 0,
      isDropShipping: false,
      order_measure: 'unit',
    }

    prices.length > 0 && setExtraSalesTerms((prev) => [...prev, newPrice])

    prices.push(newPrice)
    updateProduct('prices', prices)
  }, [product, data, updateProduct])

  const removeSaleTerm = (index) => {
    const prices = product.prices
    const priceDeleted = prices.splice(index, 1)

    const filteredState = extraSalesTerms.filter((val) => val.key !== priceDeleted.key)
    setExtraSalesTerms(filteredState)

    updateProduct('prices', prices)
  }

  const addNewPrice = (index) => {
    const prices = product.prices
    prices[index].prices.push({
      key: v4(),
      type: 'by_qty',
      priceValue: 0,
      minPrice: 0,
      maxPrice: 0,
      discount: 0,
      minQty: 0,
      maxQty: 0,
    })
    updateProduct('prices', prices)
  }

  const removePriceLine = (pricesIndex, priceIndex) => {
    const prices = product.prices
    prices[pricesIndex].prices.splice(priceIndex, 1)
    updateProduct('prices', prices)
  }

  return (
    <div>
      {product.prices && product.prices.length > 0
        ? product.prices.map((price, index) => (
            <div
              key={price.id}
              className={`row mb-2 sales-term ${
                isExtraTerm(price.key) && 'sales-term--extra'
              }`}
            >
              {isExtraTerm(price.key) && (
                <i className="fe fe-trash" onClick={() => removeSaleTerm(index)} />
              )}
              <OrderParams
                handlePricesInputs={handlePricesInputs}
                index={index}
                price={price}
                extra={isExtraTerm(price.key)}
              />
              <PriceParams
                handlePricesInputs={handlePricesInputs}
                index={index}
                data={data}
                price={price}
              />
              {price.prices &&
                price.prices.map((priceItem, priceIndex) => (
                  <Prices
                    key={priceItem.key || priceIndex}
                    price={priceItem}
                    product={product}
                    updateProduct={updateProduct}
                    index={index}
                    itemIndex={priceIndex}
                    removePrice={removePriceLine}
                  />
                ))}
              {price.prices && price.prices[0] && price.prices[0].type === 'by_qty' ? (
                <div className="mb-4">
                  <Button className="add-btn" onClick={() => addNewPrice(index)}>
                    <i className="fe fe-plus" />
                    <span>Add new price</span>
                  </Button>
                </div>
              ) : null}
              {!price.prices || !price.prices[0] ? (
                <div className="mb-4">
                  <Button className="add-btn" onClick={() => addNewPrice(index)}>
                    <i className="fe fe-plus" />
                    <span>Add new price</span>
                  </Button>
                </div>
              ) : null}
            </div>
          ))
        : null}
      {data && data.length >= 1 && product.prices.length < data.length ? (
        <Button className="add-btn mt-4" onClick={() => addNewSalesTerm()}>
          <i className="fe fe-plus" />
          <span>Add new Location</span>
        </Button>
      ) : null}
    </div>
  )
}

export default SalesTerm
