import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import axiosRequest from '../../useAxiosRequest'

import { useDashboardHeader } from '../useDashboardHeader/useDashboardHeader'
import { history } from '../../..'

export const useHeaderDropdownMenu = () => {
  const { userInfo } = useDashboardHeader()
  const dispatch = useDispatch()
  const activeUserType = useSelector(state => state.user.activeUserType)
  const availableUserTypes = useSelector(state => state.user.availableUserTypes)

  const [isDropdownMenuOpen, setDropdownMenuOpen] = useState(false)
  const [isSubscriptionUpdatable, setSubscriptionUpdatable] = useState(false)

  const canSubscriptionUpdate = () => {
    return ['free', 'starter', 'advanced'].includes(userInfo.userSubscription) && activeUserType === 'seller'
  }

  const handleLogout = async () => {
    await axiosRequest('/api/logout', null, 'get', true)
    localStorage.removeItem('apiToken')
    localStorage.clear()
    window.location.reload()
  }

  const handleUpgrade = () => {
    history.push('/profile/settings')
  }

  const changeActiveUserType = (type) => {
    dispatch({ type: 'user/SET_ACTIVE_USER_TYPE', payload: type })
  }

  useEffect(() => {
    setSubscriptionUpdatable(canSubscriptionUpdate())
  }, [userInfo.userSubscription])

  return {
    handleLogout,
    isDropdownMenuOpen,
    setDropdownMenuOpen,
    isSubscriptionUpdatable,
    handleUpgrade,
    activeUserType,
    availableUserTypes,
    changeActiveUserType,
  }
}
