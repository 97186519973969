import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import { Badge } from '../../common/Badge/Badge'
import HeaderDropdownMenu from './HeaderDropdownMenu'

import './HeaderExtrasMenu.scss'

import Chaticon from '../../../assets/icons/chat.svg'
import BellIcon from '../../../assets/icons/bell.svg'

// Hard coded variables for mocking
const isChatBadgeActive = false
const isBellBadgeActive = false


const HeaderExtrasMenu = ({ userInfo }) => {
  return (
    <div className="header-extras">
      <div className="header-extras__icon">
        <img className="header-extras__icon__image" src={Chaticon} alt="Chat icon" />
        <Badge isActive={isChatBadgeActive} count={null} className={'header-extras__icon__badge__chat'} />
      </div>
      <div className="header-extras__icon">
        <img
          className="header-extras__icon__image"
          src={BellIcon}
          alt="Notification Bell icon"
        />
        <Badge isActive={isBellBadgeActive} className={'header-extras__icon__badge__bell'} />
      </div>
      <Avatar
        alt="Avatar"
        size={36}
        icon={
          !userInfo.userAvatar ? <UserOutlined color="#4F4F4F" /> : userInfo.userAvatar
        }
      />
      <HeaderDropdownMenu userInfo={userInfo} />
    </div>
  )
}

export default HeaderExtrasMenu
