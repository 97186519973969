import { Spin } from 'antd'

import './SpinLoader.scss'

const SpinLoader = () => {
    return (
        <Spin className='spin-loader' size='large' />
    )
}

export default SpinLoader
