export const clampRGB = (value) => Math.max(0, Math.min(255, value))

export const parseRGB = (rgb) => {
  const r = clampRGB(rgb.r)
  const g = clampRGB(rgb.g)
  const b = clampRGB(rgb.b)

  return { r, g, b }
}

export const convertRGBValuetoHex = (value) => {
  const hex = value.toString(16).padStart(2, '0')
  return hex
}

export const RGBToHex = (rgb) => {
  const safeRGB = parseRGB(rgb)
  let hex = '#'

  for (let val in safeRGB) {
    hex += convertRGBValuetoHex(safeRGB[val])
  }

  return hex
}

export const hexToStr = (hex) => {
  return hex.replace(/^#/, '')
}

export const hexToRGB = (hex) => {
  hex = hexToStr(hex)

  let bigint = parseInt(hex, 16)
  let r = (bigint >> 16) & 255
  let g = (bigint >> 8) & 255
  let b = bigint & 255

  return { r, g, b }
}
