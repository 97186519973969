import { useState } from 'react'

export const useTutorialOverlay = (activeTutorial) => {
  const [selectedTutorial, setSelectedTutorial] = useState(activeTutorial || null)

  const selectTutorial = (tutorial = {}) => {
    setSelectedTutorial(tutorial)
  }

  const unselectTutorial = () => {
    setSelectedTutorial(null)
  }

  return { selectedTutorial, selectTutorial, unselectTutorial }
}
