import { MenuItem } from '../models/MenuItem'

import tabsIcon from '../assets/badges/tabs.svg'
import storeIcon from '../assets/badges/store.svg'

// DASHBOARD
const dashboard = new MenuItem(false, 'Dashboard', 'dashboard')
dashboard.setUrl(false, '/dashboard')
dashboard.setIcon(true, 'fe fe-grid')

// MARKETPLACE
const marketplace = new MenuItem(false, 'Marketplace', 'marketplace')
marketplace.setIcon(true, 'fe fe-globe')
marketplace.setUrl(true, 'https://tradeto.world/#/explore')

// SELLER SHOWROOM
const ss = new MenuItem(false, 'Seller Showroom', 'seller-showroom')
ss.setIcon(false, tabsIcon)
ss.setAvailableFor(true, false, false)
ss.setUserTypes(['seller'])
const ssCatalogue = new MenuItem(false, 'Catalogue', 'ss-catalogue')
ssCatalogue.setIcon(true, 'fe fe-box')
ssCatalogue.setUrl(true, 'https://tradeto.world/#/showroom/', true)
const ssFacts = new MenuItem(false, 'Company Facts', 'ss-facts')
ssFacts.setUrl(true, 'https://tradeto.world/#/company/', true)
const ssProfile = new MenuItem(false, 'Company Profile', 'ss-profile')
ssProfile.setUrl(true, 'https://tradeto.world/#/company/', true)
const ssSubItens = [ssCatalogue, ssFacts, ssProfile]
ssSubItens.forEach((s) => ss.setChilds(s))

// SELLER SHOP
const sellerShop = new MenuItem(false, 'Seller Shop', 'seller-shop')
sellerShop.setUrl(true, 'https://tradeto.world/#/shop/', true)
sellerShop.setIcon(false, storeIcon)
sellerShop.setUserTypes(['seller'])

//MY PROFILE
const myProfile = new MenuItem(false, 'My Profile', 'my-profile')
myProfile.setIcon(true, 'fe fe-user')
const companyData = new MenuItem(false, 'Company Data', 'company-data')
companyData.setUrl(false, '/profile/company-profile')
const companyIdentity = new MenuItem(false, 'Company Identity', 'company-identity')
companyIdentity.setUrl(false, '/dashboard/company-identity')
const pageBuilder = new MenuItem(false, 'Landing Page Builder', 'page-builder')
pageBuilder.setUrl(false, '/profile/company-profile')
pageBuilder.setIsEnable(false)
pageBuilder.setUserTypes(['seller'])
const PDFPrinting = new MenuItem(false, 'Dynamic Printing', 'printing')
PDFPrinting.setUrl(false, '/profile/dynamic-printing')
PDFPrinting.setIsEnable(true)
PDFPrinting.setUserTypes(['seller'])
const settings = new MenuItem(false, 'Settings', 'settings')
settings.setUrl(false, '/profile/settings')
settings.setIsEnable(true)
const myProfileSubItems = [
  companyData,
  companyIdentity,
  pageBuilder,
  PDFPrinting,
  settings,
]
myProfileSubItems.forEach((s) => myProfile.setChilds(s))

// PRODUCTS
const products = new MenuItem(false, 'Products', 'products')
products.setIcon(true, 'fe fe-box')
products.setAvailableFor(true, false, false)
products.setUserTypes(['seller'])
const allProducts = new MenuItem(false, 'All Products', 'products_list_page')
allProducts.setUrl(false, '/products/list/all')
const incompleteProducts = new MenuItem(false, 'Incomplete', 'incomplete-products')
incompleteProducts.setUrl(false, '/products/list/incomplete')
const productsSubItems = [allProducts, incompleteProducts]
productsSubItems.forEach((s) => products.setChilds(s))

// MY REQUESTS
const myReq = new MenuItem(false, 'My Requests', 'requests')
myReq.setUrl(false, '/')
myReq.setIcon(true, 'fe fe-file-text')
myReq.setIsEnable(false)
myReq.setAvailableFor(false, true, false)

// SERVICE PROVIDERS
const serviceProviders = new MenuItem(false, 'Service Providers', 'service-providers')
serviceProviders.setUrl(false, '/')
serviceProviders.setIcon(true, 'fe fe-users')
serviceProviders.setIsEnable(false)

// INTERACTIVE ROOM (CHAT)
const chat = new MenuItem(false, 'Interactive Room (Chat)', 'chat')
chat.setUrl(false, '/')
chat.setIcon(true, 'fe fe-message-square')
chat.setIsEnable(false)

const menuItems = [
  dashboard,
  marketplace,
  ss,
  sellerShop,
  myProfile,
  products,
  myReq,
  serviceProviders,
  chat,
]

export default menuItems
