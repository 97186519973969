import { useLocation } from 'react-router'

export const useHeaderBreadcrumb = () => {
  const location = useLocation()

  const replaceDashWithSpace = (path) => path.replace(/-/g, ' ')

  const buildPathArray = (pathname) => pathname.split('/')

  const formatPaths = (...path) => {
    return path.map((p) => replaceDashWithSpace(p))
  }

  const createBreadcrumbs = (pathname) => {
    const pathArray = buildPathArray(pathname)
    pathArray.shift()
    const headPath = pathArray[0]
    pathArray.shift()
    return { headPath, paths: formatPaths(...pathArray) }
  }

  return createBreadcrumbs(location.pathname)
}
