import { ProfileProgressBuilder } from "./ProfileProgressBuilder.interface";

import { getVerificationSteps } from "../../../data/verficationSteps";

export class BuyerProfileProgressBuilder extends ProfileProgressBuilder {
  getAllFields() {
    return getVerificationSteps('seller')
  }

  getCompletedFields() {
    return this.user.steps
  }
}
