import { history } from '..'

export class Redirect {
  constructor(baseUrl, params = '') {
    this.params = params
    this.baseUrl = baseUrl !== null ? baseUrl : null

    if (this.baseUrl) {
      this.isLocal = false
    } else {
      this.isLocal = true
    }
  }

  go(paramsArgs = this.params) {
    if (this.isLocal) history.push(paramsArgs)
    if (!this.isLocal)
      window.location.assign(`${this.baseUrl}${paramsArgs && paramsArgs}`)
  }

  static home() {
    history.push('/')
  }
}
