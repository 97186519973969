import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet-async'
import { Button, Form, Input, message } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import VerificationLayout from '../../../components/verification/VerificationLayout'
import LayoutLoading from '../../../components/verification/LayoutLoading'

import logoPlaceholder from '../../../assets/onboarding/brand-logo.svg'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { getVerificationSteps } from '../../../data/verficationSteps'
import { history } from '../../../index'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'

const CompanyBrands = () => {
  const [form] = Form.useForm()
  const pageTitle = 'Brands'
  const { userType } = useParams()
  const { user } = useSelector((state) => state.user)
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [branches, setCompanyBranches] = useState([])
  const [logos, setBrandLogo] = useState([])

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/company-brands', null, 'get', true).then((res) => {
      if (res.data.success && res.data.company && res.data.company.get_brands) {
        if (res.data.company.get_brands.length < 1) {
          setCompanyBranches([{ brandName: '', logo: '', required: true }])
        } else {
          const branchesArr = []
          const imagesArr = []
          res.data.company.get_brands.forEach((val, i) => {
            branchesArr.push({ brandName: val.name, logo: val.logo, id: val.id })
            imagesArr.push({ key: i, logo: val.logo })
          })
          setBrandLogo([...imagesArr])
          setCompanyBranches([...branchesArr])
        }
        const totalSteps =
          getVerificationSteps(userType)
        setProgress(parseInt((res.data.steps.length * 100) / totalSteps.length))
        setLoading(false)
      }
    })
  }, [])

  const onSubmit = (values) => {
    const brandsData = []
    values.brands &&
      values.brands.forEach((val, index) => {
        const getLogo = _.find(logos, (n) => {
          return n.key === index
        })
        brandsData.push({
          name: val.brandName,
          logo: getLogo.logo || null,
          id: val.id || null,
        })
      })
    if (brandsData.length < 1) {
      message.error('Please, fill the form and press submit')
    }
    axiosRequest(
      '/api/v1/on-boarding/company-brands',
      { logos: brandsData },
      'post',
      true,
    ).then((res) => {
      if (res.data.success) {
        updateAccountStatus(3, user.id)
        history.push(`/secured/verification/${userType}/additional-info`)
        message.destroy()
      }
    })
  }

  const onSubmitFail = () => {
    message.error('Something goes wrong, contact support...')
  }

  const onValueChange = (key, e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      message.loading({ content: 'Please, wait...', key: 'save' })
      const formData = new FormData()
      formData.append('image', file)
      axiosRequest('/api/v1/images/upload', formData, 'post', true).then((res) => {
        if (res.data.image) {
          const { original } = JSON.parse(res.data.image)
          const brandsLogos = logos
          _.remove(brandsLogos, (n) => {
            return n.key === key
          })
          brandsLogos.push({ key, logo: original })
          setBrandLogo([...brandsLogos])
          message.destroy()
          e.target.value = ''
        }
      })
    } else {
      message.error('Incorrect image')
    }
  }

  const handleRemoveBrandLogo = (key) => {
    _.remove(logos, (n) => {
      return n.key === key
    })
    console.log(logos)
    setBrandLogo([...logos])
  }

  // for test
  useEffect(() => {
    console.log(branches)
  }, [branches])

  if (branches !== null) {
    if (loading) return <LayoutLoading />
  }

  return (
    <>
      <Helmet title={pageTitle} />
      <Form
        onFinish={onSubmit}
        onFinishFailed={onSubmitFail}
        layout="vertical"
        hideRequiredMark
        className="mb-0"
        form={form}
      >
        <VerificationLayout progress={progress} currentStep="brands">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="brands-description">
                <h1>{pageTitle}</h1>
                <p>
                  <strong>Add your company's brands</strong>
                </p>
                <p className="mb-0">
                  Images must be at least 800 x 800px. File formats JPG, PNG, GIF.
                </p>
              </div>
              <Form.List name="brands" initialValue={branches}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => {
                      return (
                        <div key={fieldKey} className="brand-item">
                          <div className="brand-logo-upload">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <div className="d-flex align-items-center">
                                  <div className="image-circle">
                                    <img
                                      src={
                                        logos[
                                          _.findIndex(logos, (n) => {
                                            return n.key === key
                                          })
                                        ]
                                          ? logos[
                                              _.findIndex(logos, (n) => {
                                                return n.key === key
                                              })
                                            ].logo
                                          : logoPlaceholder
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="ms-3 brand-logo-name">
                                    Brand logo{' '}
                                    {logos[
                                      _.findIndex(logos, (n) => {
                                        return n.key === key
                                      })
                                    ] && (
                                      <span>
                                        <i
                                          onClick={() => handleRemoveBrandLogo(key)}
                                          className="fe fe-x"
                                        ></i>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="d-flex justify-content-end align-items-center">
                                  <div>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'logo']}
                                      className="mb-0"
                                    >
                                      <p className="mb-0 upload-link">
                                        Upload image
                                        <input
                                          type="file"
                                          onChange={(e) => onValueChange(key, e)}
                                          accept="image/*"
                                        />
                                      </p>
                                    </Form.Item>
                                  </div>
                                  {!branches[
                                    _.findIndex(branches, (n) => {
                                      return branches.indexOf(n) === key
                                    })
                                  ].required && (
                                    <div className="ms-3">
                                      <Form.Item className="mb-0">
                                        <DeleteOutlined
                                          className="danger-color"
                                          onClick={() => {
                                            remove(name)
                                            setCompanyBranches([
                                              ...branches.filter((b) => {
                                                console.log(branches.indexOf(b))
                                                return branches.indexOf[b] !== key - 1
                                              }),
                                            ])
                                          }}
                                        />
                                      </Form.Item>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <Form.Item
                            {...restField}
                            name={[name, 'brandName']}
                            rules={[
                              {
                                required: true,
                                message: 'Field is required',
                              },
                            ]}
                          >
                            <Input placeholder="Brand Name*" />
                          </Form.Item>
                        </div>
                      )
                    })}
                    <Form.Item className="add-unit mb-2">
                      <Button
                        type="dashed"
                        onClick={() => {
                          add({ brandName: '', logo: '', required: false })
                          setCompanyBranches((prev) => [
                            ...prev,
                            { brandName: '', logo: '', required: false },
                          ])
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add new brand
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </div>
        </VerificationLayout>
      </Form>
    </>
  )
}

export default CompanyBrands
