import React, { Suspense, useEffect } from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'

import MainSidebar from './partials/MainSidebar'
import MainFooter from './partials/MainFooter'
import MainLayoutHeader from './partials/MainLayoutHeader'
import { Skeleton } from 'antd'
import DashboardHeader from './partials/DashboardHeader'

const mapStateToProps = ({ user }) => ({ user })

const MainLayout = ({ children, user }) => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname)
  }, [location])

  return (
    <div className="ttw-layout">
      <MainSidebar user={user} />
      <div className="layout__content">
        {/* <MainLayoutHeader /> */}
        <DashboardHeader />
        <main className="ttw-main">
          <Suspense fallback={<Skeleton />}>{children}</Suspense>
        </main>
        <MainFooter />
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
