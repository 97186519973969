import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { Form, Input, Select, message } from 'antd'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import PHONE_CODES from '../../../data/phone-codes'

import axiosRequest from '../../../hooks/useAxiosRequest'

import { history } from '../../../index'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'
import { useServiceProviderProfileInfo } from '../../../hooks/ui/ProfileInfo/useServiceProviderProfileInfo'
import { ProfileProgressAbstractFactory } from '../../../models/Domains/ProfileProgress/ProfileProgressAbstractFactory.interface'

import { ChannelsController } from './components/ChannelsController'
import LayoutLoading from '../../../components/verification/LayoutLoading'
import { ServiceTypesController } from './components/ServiceTypesController'
import VerificationLayout from '../../../components/verification/VerificationLayout'


const ProfileInfo = () => {
  const pageTitle = 'Personal Information'
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [user, setUserData] = useState({})
  const [buyerChannels, setBuyerChannels] = useState([])
  const [channelVal, setChannelVal] = useState(null)
  const { userType } = useParams()
  const dispatch = useDispatch()

  const {
    services,
    saveServices,
    selectedServices,
    isSelectorLoading,
    handleRemoveService,
    handleSelectService,
  } = useServiceProviderProfileInfo(userType === 'service-provider')

  const handleSetChannel = (value, currentChannels) => {
    let channels = [...currentChannels]
    const val = value || ''

    if (channels.includes(val) || val.length <= 0) {
      return
    } else {
      channels = [...channels, val]
    }

    setBuyerChannels(() => [...channels])
    return setChannelVal(null)
  }

  const handleRemoveChannel = (value, currentChannels) => {
    let channels = [...currentChannels]
    const val = value

    channels = channels.filter((c) => c !== val)
    setBuyerChannels([...channels])
  }

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/personal-info', null, 'get', true)
      .then((res) => {
        if (res.data.user) {
          setUserData(res.data.user)
          // res.data.user.channel && setBuyerChannels([res.data.user.channel])

          const progressFactory = ProfileProgressAbstractFactory.getFactory('role')
          const progressBuilder = new progressFactory({
            userType:
            userType !== 'service-provider' ? userType : 'service-provider-general',
            user: { ...user, steps: res.data.steps },
          })
          const progress = progressBuilder.getProgress()
          setProgress(progress)
          // setLoading(false)
        }
        if (res.data.user.channel) {
          setBuyerChannels((prev) => [...prev, res.data.user.channel])
        }
      })
      .catch(() => {
        message.error('Something goes wrong, contact support...')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const onSubmit = async (values) => {
    message.loading({ content: 'Please, wait...', key: 'save' })

    try {
      if (userType === 'service-provider') {
        try {
          let updateServicesRes = await saveServices()
          if (!updateServicesRes) {
            message.error({
              content: 'Something goes wrong, contact support...',
              key: 'save',
            })
            throw new Error('Failed to update services...')
          }
        } catch (error) {
          message.error({
            content: 'Something goes wrong, contact support...',
            key: 'save',
          })
        }
      }

      let res = await axiosRequest(
        '/api/v1/on-boarding/personal-info',
        { ...values, channel: buyerChannels[0] , website: 'https://tradeto.world/' },
        'post',
        true,
      )

      if (res.data.success) {
        updateAccountStatus(3, res.data.user.id)
        dispatch({ type: 'user/LOAD_CURRENT_ACCOUNT' })
        history.push(`/secured/verification/${userType}/company-info`)
        message.destroy()
      }
    } catch (error) {
      message.error({ content: 'Something goes wrong, contact support...', key: 'save' })
    }
  }

  const onSubmitFail = () => {
    message.error('Something goes wrong, contact support...')
  }

  if (loading || !user.id) return <LayoutLoading />

  return (
    <>
      <Helmet title={pageTitle} />
      <Form
        onFinish={onSubmit}
        onFinishFailed={onSubmitFail}
        layout="vertical"
        hideRequiredMark
        className="mb-0"
        initialValues={{
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          position: user.position,
          prefix: user.name_prefix,
          phoneCode: user.phone_code,
          phone: user.phone,
          channel: null,
          ref_coupon: user.ref_coupon,
        }}
      >
        <VerificationLayout currentStep="personalInfo" progress={progress}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <h1>Profile Information</h1>
              <div className="row">
                <div className="col-4">
                  <Form.Item
                    name="prefix"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Select>
                      <Select.Option value="Mr.">Mr.</Select.Option>
                      <Select.Option value="Mrs.">Mrs.</Select.Option>
                      <Select.Option value="Ms.">Ms.</Select.Option>
                      <Select.Option value="Dr.">Dr.</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-8">
                  <Form.Item
                    name="firstName"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input placeholder="First Name*" />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Form.Item
                    name="lastName"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input placeholder="Last Name*" />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Form.Item
                    name="position"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input placeholder="Position*" />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input type="email" placeholder="Email*" />
                  </Form.Item>
                </div>
                <div className="col-4">
                  <Form.Item
                    name="phoneCode"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Select showSearch>
                      {PHONE_CODES.map((code) => {
                        return (
                          <Select.Option key={code.code} value={code.dial_code}>
                            <img
                              src={`https://flagcdn.com/${code.code.toLowerCase()}.svg`}
                              width="16"
                              alt={code.code}
                            />
                            <span className="ps-2">{code.dial_code}</span>
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-8">
                  <Form.Item
                    name="phone"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input placeholder="Phone Number*" />
                  </Form.Item>
                </div>
                {userType !== 'service-provider' && !user.ref_coupon && (
                  <div className="col-12">
                    <Form.Item
                      name="ref_coupon"
                      // TODO: Implement rules verification for Coupon Code pattern, which is also to be discussed and chosen.
                      // rules={[{ required: false, message: 'Field is required' }]}
                    >
                      <Input placeholder="Coupon Code" />
                    </Form.Item>
                  </div>
                )}
              </div>
            </div>
            {userType === 'buyer' && (
              <ChannelsController
                buyerChannels={buyerChannels}
                channelVal={channelVal}
                setChannelVal={setChannelVal}
                handleSetChannel={handleSetChannel}
                handleRemoveChannel={handleRemoveChannel}
              />
            )}
            {userType === 'service-provider' && (
              <ServiceTypesController
                isSelectorLoading={isSelectorLoading}
                onRemoveService={handleRemoveService}
                onSelectService={handleSelectService}
                selectedServices={selectedServices}
                services={services}
              />
            )}
          </div>
        </VerificationLayout>
      </Form>
    </>
  )
}

export default ProfileInfo
