import React from 'react'
// import { Form, Radio } from 'antd'

// import ProductPrices from './components/ProductPrices'
import SalesTerm from './components/SalesTerm'

const SalesTerms = ({ data, product, updateProduct }) => {
  return <SalesTerm data={data} product={product} updateProduct={updateProduct} />
}

export default SalesTerms
