import axios from 'axios'
import { message } from 'antd'
import { ErrorMessage } from '../../Messages/ErrorMessage'

import { RestAPI } from './RestAPI.interface'

export class TutorialListAPI extends RestAPI {
  static BASE_URL = process.env.REACT_APP_REST_SERVICE_URL

  static notify(content, type) {
    return message[type](content)
  }

  static notifyError(content = ErrorMessage.server.generic) {
    return this.notify(content, 'error')
  }

  static async request(method, path, data = null) {
    try {
      const url = `${this.BASE_URL}/${path}`
      const options = { method, url }
      if (data) {
        options.data = data
      }
      return await axios(options)
    } catch (error) {
      console.error(error)
      // this.notifyError()
      throw error
    }
  }

  async index(path) {
    return await TutorialListAPI.request('get', `tutorialList/${path}`)
  }

  async show(id) {
    return await TutorialListAPI.request('get', `tutorialList/${id}`)
  }

  async create(data) {
    return await TutorialListAPI.request('post', 'tutorialList', data)
  }

  async update(id, data) {
    return await TutorialListAPI.request('put', `tutorialList/${id}`, data)
  }

  async delete(id) {
    return await TutorialListAPI.request('delete', `tutorialList/${id}`)
  }
}
