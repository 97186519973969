import { useState } from 'react'

/**
 * @name useModal
 * @description This hook is used to manage the visibility of a modal.
 * @returns {object} The properties and functions to manage the modal.
 * @returns {boolean} isVisible A flag to indicate if the modal is visible.
 * @returns {function} setIsVisible A function to set the visibility of the modal.
 * @returns {function} show A function to show the modal.
 * @returns {function} hide A function to hide the modal.
 * @example
 * const { isVisible, setIsVisible, show, hide } = useModal();
 */
export const useModal = () => {
  /**
   * @name isVisible
   * @description A flag to indicate if the modal is visible.
   * @type {boolean}
   * @since 1.0.0
   */
  const [isVisible, setIsVisible] = useState(false)

  /**
   * @name show
   * @description A function to show the modal.
   * @type {function}
   * @since 1.0.0
   */
  const show = () => setIsVisible(true)

  /**
   * @name hide
   * @description A function to hide the modal.
   * @type {function}
   * @since 1.0.0
   */
  const hide = () => setIsVisible(false)

  return { isVisible, setIsVisible, show, hide }
}
