import React from 'react'

import styles from './styles.module.scss'

const MarketplaceCard = ({ children, title = null }) => {
  return (
    <div className={styles.marketplaceCard}>
      {title && (
        <div className={styles.title}>
          <h2>{title}</h2>
        </div>
      )}
      <div className={styles.body}>{children}</div>
    </div>
  )
}

export default MarketplaceCard
