const exploreFilters = [
  {
    title: 'Filter',
    range: false,
    value: '',
    placeholder: 'Search by filter',
  },
  {
    title: 'Price',
    range: true,
    min: 0,
    max: 0,
    minPlaceholder: '$0',
    maxPlaceholder: '$200',
  },
  {
    title: 'Minimal Order',
    range: false,
    value: '',
    placeholder: 'Order Quantity',
  },
  {
    title: 'Budget',
    range: false,
    value: '',
    placeholder: 'Order Budget',
  },
  {
    title: 'Product Availability',
    range: false,
    value: [],
    options: [
      { label: 'Ready to Ship', value: 'Ready to Ship' },
      { label: 'On Demand', value: 'On Demand' },
    ],
  },
  {
    title: 'Supplier Country/Region',
    range: false,
    value: [],
    options: [
      { label: 'China', value: 'China' },
      { label: 'Belgium', value: 'Belgium' },
      { label: 'Italy', value: 'Italy' },
      { label: 'United States', value: 'United States' },
      { label: 'Russia', value: 'Russia' },
    ],
  },
  {
    title: 'Warranty',
    range: false,
    value: [],
    options: [
      { label: '1 year', value: '1 year' },
      { label: '2 years', value: '2 years' },
    ],
  },
]

const sellersFilters = [
  {
    title: 'Filter',
    range: false,
    value: '',
    placeholder: 'Search by filter',
  },
  {
    title: 'Supplier Country/Region',
    range: false,
    value: [],
    options: [
      { label: 'China', value: 'China' },
      { label: 'Belgium', value: 'Belgium' },
      { label: 'Italy', value: 'Italy' },
      { label: 'United States', value: 'United States' },
      { label: 'Russia', value: 'Russia' },
    ],
  },
]

const brandsFilters = [
  {
    title: 'Filter',
    range: false,
    value: '',
    placeholder: 'Search by filter',
  },
  {
    title: 'Supplier Country/Region',
    range: false,
    value: [],
    options: [
      { label: 'China', value: 'China' },
      { label: 'Belgium', value: 'Belgium' },
      { label: 'Italy', value: 'Italy' },
      { label: 'United States', value: 'United States' },
      { label: 'Russia', value: 'Russia' },
    ],
  },
]

export { exploreFilters, sellersFilters, brandsFilters }
