import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'

const Footer = ({ currentStep, prevUrl, nextUrl }) => {
  return (
    <div className="verification-footer">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div>{nextUrl !== null && <Link to={nextUrl}>Skip it for later</Link>}</div>
          <div className='d-flex align-items-center gap-3 verification-footer__navigation'>
            <div>
              {prevUrl !== null && (
                <Link to={prevUrl}>
                  <LeftOutlined /> Back
                </Link>
              )}
            </div>
            <Button type="primary" htmlType="submit" className='ant-btn-ttu'>
              {nextUrl === null ? 'Finish' : <>Next <RightOutlined as='i' /></>}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
