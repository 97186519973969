import { Input } from 'antd'

import { hexToStr } from '../../../util/functions/colorConverter'

/**
 * React component for rendering an input for hexadecimal color values.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.currentColor - The current selected color in the color picker.
 * @param {function} props.callback - The callback function to update the selected color.
 * @returns {JSX.Element} - The rendered HexInput component.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const HexInput = ({ currentColor, callback = () => null }) => {
  /**
   * Handle input change and update the color using the callback.
   * @function
   * @param {Object} e - The input change event.
   */
  const handleInput = (e) => {
    callback(`#${e.target.value}`)
  }
  return (
    <Input
      value={hexToStr(currentColor)}
      onChange={handleInput}
      inputMode="numeric"
      prefix="#"
      maxLength={7}
    />
  )
}
