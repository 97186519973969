import React from 'react'
import { Collapse, Checkbox, Input } from 'antd'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

const Filters = ({ filters }) => {
  return (
    <div className="filters-wrapper">
      <Collapse
        defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
        expandIconPosition="right"
        expandIcon={(collapseProps) => {
          if (collapseProps.isActive) {
            return <CaretUpOutlined />
          }
          return <CaretDownOutlined />
        }}
      >
        {filters.map((filter, index) => {
          if (filter.options) {
            return (
              <Collapse.Panel key={index} header={filter.title}>
                <Checkbox.Group options={filter.options} />
              </Collapse.Panel>
            )
          } else {
            return (
              <Collapse.Panel key={index} header={filter.title}>
                <div className="row">
                  {filter.range ? (
                    <>
                      <div className="col-6">
                        <Input placeholder={filter.minPlaceholder} />
                      </div>
                      <div className="col-6">
                        <Input placeholder={filter.maxPlaceholder} />
                      </div>
                    </>
                  ) : (
                    <div className="col-12">
                      <Input placeholder={filter.placeholder} />
                    </div>
                  )}
                </div>
              </Collapse.Panel>
            )
          }
        })}
      </Collapse>
    </div>
  )
}

export default Filters
