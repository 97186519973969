export const handleUnderscore = (field = '') => {
  const formatted = field
  return formatted.replace(/_/g, ' ')
}

export const formatProductField = (fields = ['']) => {
  const formattedFields = []

  for (const f of fields) {
    let formatted = f
    if (f.match('hts')) formatted = 'HTS Code'
    if (f.match(/Package/gi)) {
      if (f.match(/productPerInnerPack/gi))
        formatted = f.replace(/productPerInnerPack/gi, 'product per pack')
      if (f.match(/innerpackprice/gi)) formatted = f.replace(/innerpackprice/gi, 'price')
      if (f.match(/innerpackbarcodevalue/gi))
        formatted = f.replace(/innerpackbarcodevalue/gi, 'barcode')
      if (f.match(/innerpackvolumevalue/gi))
        formatted = f.replace(/innerpackvolumevalue/gi, 'volume')
      if (f.match(/innerpackweightvalue/gi))
        formatted = f.replace(/innerpackweightvalue/gi, 'weight')
      if (f.match(/innerpackdimensionswidth/gi))
        formatted = f.replace(/innerpackdimensionswidth/gi, 'width')
      if (f.match(/innerpackdimensionsheight/gi))
        formatted = f.replace(/innerpackdimensionsheight/gi, 'height')
      if (f.match(/innerpackdimensionsdepth/gi))
        formatted = f.replace(/innerpackdimensionsdepth/gi, 'depth')
    }
    if (f.match('_')) formatted = handleUnderscore(formatted)

    formattedFields.push(formatted)
  }

  return formattedFields
}
