import React from 'react'
import { Link } from 'react-router-dom'
import { Rate } from 'antd'

import Filters from '../../components/pages/marketplace/explore/Filters'
import Header from '../../components/pages/marketplace/explore/Header'

import { sellersFilters } from '../../data/marketplace-filters'

import checkIcon from '../../assets/badges/check.png'
import seller1 from '../../assets/temp/seller1.png'
import seller2 from '../../assets/temp/seller2.png'
import seller3 from '../../assets/temp/seller3.png'
import seller4 from '../../assets/temp/seller4.png'

const SellersPage = () => {
  return (
    <div className="container">
      <div className="products-list">
        <div className="filters">
          <Filters filters={sellersFilters} />
        </div>
        <div className="products">
          <Header
            title="Explore products"
            view={'grid'}
            setView={() => {}}
            hideViewToggle
          />
          <div className="sellers-list">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="seller">
                  <div className="seller-image">
                    <Link to="#">
                      <img src={seller1} alt="" />
                    </Link>
                  </div>
                  <div className="sellers-badges">
                    <div className="rate">
                      <Rate defaultValue={4} />
                    </div>
                    <div className="badges">
                      <ul>
                        <li>
                          <img src={checkIcon} alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h2 className="seller-name">
                    <Link to="#">Delixi (Hangzhou) Inverter</Link>
                  </h2>
                  <div className="description">
                    <p>250,000+ for 69 Transactions</p>
                    <p>≤9h Response Time</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="seller">
                  <div className="seller-image">
                    <Link to="#">
                      <img src={seller2} alt="" />
                    </Link>
                  </div>
                  <div className="sellers-badges">
                    <div className="rate">
                      <Rate defaultValue={4} />
                    </div>
                    <div className="badges">
                      <ul>
                        <li>
                          <img src={checkIcon} alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h2 className="seller-name">
                    <Link to="#">Delixi (Hangzhou) Inverter</Link>
                  </h2>
                  <div className="description">
                    <p>250,000+ for 69 Transactions</p>
                    <p>≤9h Response Time</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="seller">
                  <div className="seller-image">
                    <Link to="#">
                      <img src={seller3} alt="" />
                    </Link>
                  </div>
                  <div className="sellers-badges">
                    <div className="rate">
                      <Rate defaultValue={4} />
                    </div>
                    <div className="badges">
                      <ul>
                        <li>
                          <img src={checkIcon} alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h2 className="seller-name">
                    <Link to="#">Delixi (Hangzhou) Inverter</Link>
                  </h2>
                  <div className="description">
                    <p>250,000+ for 69 Transactions</p>
                    <p>≤9h Response Time</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="seller">
                  <div className="seller-image">
                    <Link to="#">
                      <img src={seller4} alt="" />
                    </Link>
                  </div>
                  <div className="sellers-badges">
                    <div className="rate">
                      <Rate defaultValue={4} />
                    </div>
                    <div className="badges">
                      <ul>
                        <li>
                          <img src={checkIcon} alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h2 className="seller-name">
                    <Link to="#">Delixi (Hangzhou) Inverter</Link>
                  </h2>
                  <div className="description">
                    <p>250,000+ for 69 Transactions</p>
                    <p>≤9h Response Time</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="seller">
                  <div className="seller-image">
                    <Link to="#">
                      <img src={seller1} alt="" />
                    </Link>
                  </div>
                  <div className="sellers-badges">
                    <div className="rate">
                      <Rate defaultValue={4} />
                    </div>
                    <div className="badges">
                      <ul>
                        <li>
                          <img src={checkIcon} alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h2 className="seller-name">
                    <Link to="#">Delixi (Hangzhou) Inverter</Link>
                  </h2>
                  <div className="description">
                    <p>250,000+ for 69 Transactions</p>
                    <p>≤9h Response Time</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="seller">
                  <div className="seller-image">
                    <Link to="#">
                      <img src={seller4} alt="" />
                    </Link>
                  </div>
                  <div className="sellers-badges">
                    <div className="rate">
                      <Rate defaultValue={4} />
                    </div>
                    <div className="badges">
                      <ul>
                        <li>
                          <img src={checkIcon} alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h2 className="seller-name">
                    <Link to="#">Delixi (Hangzhou) Inverter</Link>
                  </h2>
                  <div className="description">
                    <p>250,000+ for 69 Transactions</p>
                    <p>≤9h Response Time</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellersPage
