import React, { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { Form, Input, Select, Button, Checkbox, message } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import LayoutLoading from '../../../components/verification/LayoutLoading'
import VerificationLayout from '../../../components/verification/VerificationLayout'

import COUNTRIES_LIST from '../../../data/countries'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { getVerificationSteps } from '../../../data/verficationSteps'
import { history } from '../../../index'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'
import { useSelector } from 'react-redux'

import { ProfileProgressAbstractFactory } from '../../../models/Domains/ProfileProgress/ProfileProgressAbstractFactory.interface'

const CompanyInfo = () => {
  const pageTitle = 'Company Information'
  const { userType } = useParams()
  const { user } = useSelector((state) => state.user)
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [company, setCompanyData] = useState({})
  const [companyBranches, setCompanyBranchesData] = useState([])
  const [description, setDescription] = useState('')

  const handleDescriptionChange = (text) => {
    return setDescription(text)
  }

  const fetchData = useCallback(async () => {
    // setLoading(true)
    await axiosRequest('/api/v1/on-boarding/company-info', null, 'get', true).then(
      (res) => {
        if (res.data.success && res.data.company) {
          setCompanyData(res.data.company)
          if (res.data.company.description)
            setDescription(res.data.company.description.length)

          const progressFactory = ProfileProgressAbstractFactory.getFactory('role')
          const progressBuilder = new progressFactory({
            userType:
              userType !== 'service-provider' ? userType : 'service-provider-general',
            user: { ...user, steps: res.data.steps },
          })
          const progress = progressBuilder.getProgress()
          setProgress(progress)

          if (res.data.company && res.data.company.get_branches) {
            const branches = []
            res.data.company.get_branches.forEach((branch) => {
              branches.push({
                name: branch.name,
                website: branch.website,
                email: branch.email,
                country: branch.country,
                city: branch.city,
                zip: branch.zip,
                street: branch.address,
                branch: branch.type === 'branch' ? 'checked' : undefined,
                factory: branch.type === 'factory' ? 'checked' : undefined,
                stock: branch.type === 'stock' ? 'checked' : undefined,
              })
            })
            setCompanyBranchesData(branches)
          }
        }
        setLoading(false)
      },
    )
  }, [userType])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onSubmit = (values) => {
    message.loading({ content: 'Please, wait...', key: 'save' })
    axiosRequest('/api/v1/on-boarding/company-info', values, 'post', true)
      .then((res) => {
        if (res.data.success) {
          updateAccountStatus(3, user.id)
          history.push(`/secured/verification/${userType}/company-logo`)
          message.destroy()
        }
      })
      .catch(() => {
        message.error('Something goes wrong, contact support...')
        message.destroy()
      })
  }

  const onSubmitFail = () => {
    message.error('Something goes wrong, contact support...')
  }

  if (company !== null) {
    if (loading) return <LayoutLoading />
  }

  return (
    <>
      <Helmet title={pageTitle} />
      <Form
        onFinish={onSubmit}
        onFinishFailed={onSubmitFail}
        layout="vertical"
        hideRequiredMark
        className="mb-0"
        initialValues={{
          establishmentDate:
            company && company.establishment_date ? company.establishment_date : null,
          companyName: company && company.name ? company.name : null,
          companyEmail: company && company.email ? company.email : null,
          companyWebsite: company && company.website ? company.website : null,
          companyCountry: company && company.country ? company.country : null,
          companyCity: company && company.city ? company.city : null,
          companyZip: company && company.zip ? company.zip : null,
          companyStreet: company && company.address ? company.address : null,
          companyRegistrationNumber:
            company && company.registration_number ? company.registration_number : null,
          companyVATnumber: company && company.vat_number ? company.vat_number : null,
          description: company && company.description ? company.description : null,
          companyIsStock: company && company.is_stock ? company.is_stock : false,
        }}
      >
        <VerificationLayout currentStep="companyInfo" progress={progress}>
          <div className="row">
            <div className="col-12">
              <h1>{pageTitle}</h1>
              <div className="row mb-2">
                <div className="col-12 col-lg-6">
                  <h4>About your company</h4>
                  <div className="row">
                    <div className="col-12 col-lg-6 col-xl-8">
                      <Form.Item
                        name="companyName"
                        rules={[{ required: true, message: 'Field is required' }]}
                      >
                        <Input placeholder="Company Name*" />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-4">
                      <Form.Item
                        name="establishmentDate"
                        rules={[{ required: true, message: 'Field is required' }]}
                      >
                        <Input placeholder="Establishment Date*" />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item
                        name="companyEmail"
                        rules={[{ required: true, message: 'Field is required' }]}
                      >
                        <Input type="email" placeholder="Company Email*" />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item name="companyWebsite">
                        <Input placeholder="Company Website (optional)" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="hq-header">
                    <h4>HQ Address</h4>
                    {
                      <Form.Item name="companyIsStock" className="hq-header__stock">
                        <Checkbox />
                        <p>Stock</p>
                      </Form.Item>
                    }
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Form.Item
                        name="companyCountry"
                        rules={[{ required: true, message: 'Field is required' }]}
                      >
                        <Select placeholder="Country/Region*" showSearch>
                          {COUNTRIES_LIST.map((country) => {
                            return (
                              <Select.Option value={country.name} key={country.code}>
                                {country.name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-12 col-lg-6">
                      <Form.Item
                        name="companyCity"
                        rules={[{ required: true, message: 'Field is required' }]}
                      >
                        <Input placeholder="City*" />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-lg-6">
                      <Form.Item
                        name="companyZip"
                        rules={[{ required: true, message: 'Field is required' }]}
                      >
                        <Input placeholder="Zip code*" />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item
                        name="companyStreet"
                        rules={[{ required: true, message: 'Field is required' }]}
                      >
                        <Input placeholder="Street*" />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-lg-5 pe-0">
                      <Form.Item
                        name="companyRegistrationNumber"
                        rules={[{ required: false }]}
                      >
                        <Input
                          className="placeholder-sm"
                          placeholder="Registration number"
                        />
                      </Form.Item>
                    </div>
                    <p className="col-lg-2 d-flex align-items-center justify-content-center p-0 lh-sm">
                      and/or
                    </p>
                    <div className="col-12 col-lg-5 ps-0">
                      <Form.Item name="companyVATnumber" rules={[{ required: false }]}>
                        <Input
                          className="placeholder-sm"
                          placeholder="Sales/VAT tax number"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {userType !== 'service-provider' && (
                  <div className="col-12">
                    <Form.Item
                      className="mb-2"
                      label="Company Description"
                      name="description"
                      rules={[{ required: false }]}
                    >
                      <Input.TextArea
                        placeholder="Company description"
                        rows={3}
                        maxLength={800}
                        minLength={200}
                        onChange={(e) => handleDescriptionChange(e.target.value)}
                      />
                    </Form.Item>
                    <span className="counter">{description.length} / 800</span>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.List
                    name="additionalCompanies"
                    initialValue={companyBranches || []}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        <Form.Item className="add-unit mb-2">
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Unit (Branch, Factory)
                          </Button>
                        </Form.Item>
                        {fields.map(({ key, name, fieldKey, ...restField }) => {
                          return (
                            <div key={key} className="company-units mb-4">
                              <div className="company-units__controls mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex justify-content-start align-items-center">
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'branch']}
                                      className="me-3 mb-0"
                                      valuePropName="checked"
                                    >
                                      <Checkbox>Branch</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'factory']}
                                      className="me-3 mb-0"
                                      valuePropName="checked"
                                    >
                                      <Checkbox>Factory</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'stock']}
                                      className="mb-0"
                                      valuePropName="checked"
                                    >
                                      <Checkbox>Stock</Checkbox>
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <Form.Item {...restField} className="mb-0">
                                      <DeleteOutlined
                                        className="danger-color"
                                        onClick={() => remove(name)}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-lg-6">
                                  <div className="row">
                                    <div className="col-12">
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Field is required',
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Branch Name*" />
                                      </Form.Item>
                                    </div>
                                    <div className="col-12">
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'email']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Field is required',
                                          },
                                        ]}
                                      >
                                        <Input
                                          type="email"
                                          placeholder="Company Email*"
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="col-12">
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'website']}
                                        className="mb-0"
                                      >
                                        <Input placeholder="Branch Website (optional)" />
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                  <div className="row">
                                    <div className="col-12">
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'country']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Field is required',
                                          },
                                        ]}
                                      >
                                        <Select placeholder="Country/Region*" showSearch>
                                          {COUNTRIES_LIST.map((country) => {
                                            return (
                                              <Select.Option
                                                value={country.name}
                                                key={country.code}
                                              >
                                                {country.name}
                                              </Select.Option>
                                            )
                                          })}
                                        </Select>
                                      </Form.Item>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'city']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Field is required',
                                          },
                                        ]}
                                      >
                                        <Input placeholder="City*" />
                                      </Form.Item>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'zip']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Field is required',
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Zip code*" />
                                      </Form.Item>
                                    </div>
                                    <div className="col-12">
                                      <Form.Item
                                        {...restField}
                                        className="mb-0"
                                        name={[name, 'street']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Field is required',
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Street*" />
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    )}
                  </Form.List>
                </div>
              </div>
            </div>
          </div>
        </VerificationLayout>
      </Form>
    </>
  )
}

export default CompanyInfo
