import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ClientRole, ClientRoleFactory } from '../../../models/Domains/ClientRole'

export const useNewRoleContainer = () => {
  const { user } = useSelector((state) => state.user)
  const [unusedRoles, setUnusedRoles] = useState([])

  useEffect(() => {
    let roles = ClientRoleFactory.createRoles(ClientRole.getUnusedRoles(user))
    roles = roles.map((role) => ({
      ...role,
      setNewRole: () => role.setNewRole(user)
    }))
    setUnusedRoles([...roles])
  }, [])

  return { unusedRoles }
}
