import { createPortal } from 'react-dom'
import { Input, Modal } from 'antd'
import { Footer } from './Footer'
import { useState } from 'react'
import { isString } from 'lodash'
import { InfoIcon } from '../../../../../ui/InfoIcon'

export const CreateTagModal = ({ isShow, handleShow, callback }) => {
  const [tag, setTag] = useState(null)
  const [isConfirmation, setIsConfirmation] = useState(false)

  const parseInput = (input = '') => {
    let string = input
    string = string.replace(/#/gi, '')
    string = string.replace(/\s/gi, '')
    string = string.toLowerCase()
    return string
  }

  const handleChange = (input) => {
    const name = parseInput(input)
    setTag(name)
  }

  const isTagValid = (tag) => {
    return isString(tag) && tag.length > 0
  }

  const handleReturn = () => {
    setIsConfirmation(false)
  }

  const handleCancel = () => {
    handleShow()
    setTag(null)
  }

  const handleCreate = () => {
    setIsConfirmation(true)
  }

  const handleConfirm = async () => {
    await callback(tag)
    handleShow()
    setTag(null)
  }

  const onCancel = (isConfirmation) => {
    return isConfirmation ? handleReturn : handleCancel
  }

  const onOk = (isConfirmation) => {
    return isConfirmation ? handleConfirm : handleCreate
  }

  return (
    <>
      {createPortal(
        <Modal
          destroyOnClose
          visible={isShow}
          closable={false}
          onCancel={handleShow}
          bodyStyle={{ paddingBottom: 12 }}
          footer={
            <Footer
              onCancel={onCancel(isConfirmation)}
              onOk={onOk(isConfirmation)}
              isOk={isTagValid(tag)}
              isConfirmation={isConfirmation}
            />
          }
        >
          {isConfirmation ? (
            <p className="text-center fs-6">
              Are you sure you want to create the tag #<strong>{tag}</strong>?
            </p>
          ) : (
            <>
              <div className="mb-2 d-flex align-items-center justify-content-center">
                <p className="fs-6 mb-0 me-2 ">
                  <strong>No available tag for your needs? Create one!</strong>
                </p>
                <InfoIcon
                  overlay={'Provide only the tag name, without hashtags or spacing.'}
                  placement="topCenter"
                  overlayType=''
                />
              </div>
              <Input
                type="text"
                placeholder="Create new tag..."
                value={tag}
                maxLength={20}
                onChange={(e) => handleChange(e.target.value)}
              />
            </>
          )}
        </Modal>,
        document.getElementById('root'),
      )}
    </>
  )
}
