import { useState } from 'react'
import { Select } from 'antd'

import { FormatInput } from './Input'

/**
 * Controller for color format (Hex, RGB) and displaying the corresponding input.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.currentColor - The current selected color in the color picker.
 * @param {function} props.setColor - The callback function to update the selected color.
 * @returns {JSX.Element} - The rendered FormatController component.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const FormatController = ({ currentColor, setColor }) => {
  const [format, setFormat] = useState('hex')

  return (
    <div className="color-picker__controller row">
      <div className="col-3">
        <Select
          className="color-picker__controller__format"
          value={format}
          onChange={setFormat}
        >
          <Select.Option value="hex">
            <strong>Hex</strong>
          </Select.Option>
          <Select.Option value="rgb">
            <strong>RGB</strong>
          </Select.Option>
        </Select>
      </div>
      <div
        className={`col-9 color-picker__controller__value color-picker__controller__value--${format}`}
      >
        <FormatInput currentColor={currentColor} type={format} callback={setColor} />
      </div>
    </div>
  )
}
