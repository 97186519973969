import { useLocation } from 'react-router'

export const useTutorialButton = () => {
  const location = useLocation()

  const showTutorialButton = () => {
    return !location.pathname.includes('secured/login')
  }

  const isTutorialEmpty = (tutorials) => {
    return tutorials.length === 0
  }

  return { isTutorialEmpty, showTutorialButton }
}
