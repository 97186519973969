import React from 'react'
import MarketplaceCard from '../../../ui/cards/marketplace/MarketplaceCard'
import { Carousel } from 'antd'

import styles from './carousel.module.scss'

const Docs = ({ data }) => {
  console.log(data)
  return (
    <MarketplaceCard title="Certificates & Patents">
      <div className={styles.carousel}>
        <Carousel
          dots={false}
          arrows
          draggable
          touchMove
          infinite={false}
          slidesToScroll={1}
          slidesToShow={3}
          nextArrow={
            <div>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.6"
                  cx="15"
                  cy="15"
                  r="14.5"
                  fill="white"
                  stroke="#EDEDED"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.3334 10L12.1584 11.175L15.975 15L12.1584 18.825L13.3334 20L18.3334 15L13.3334 10Z"
                  fill="#808080"
                />
              </svg>
            </div>
          }
          prevArrow={
            <div>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.6"
                  cx="15"
                  cy="15"
                  r="14.5"
                  transform="rotate(-180 15 15)"
                  fill="white"
                  stroke="#EDEDED"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.6666 20L17.8416 18.825L14.025 15L17.8416 11.175L16.6666 10L11.6666 15L16.6666 20Z"
                  fill="#808080"
                />
              </svg>
            </div>
          }
        >
          {data.map((doc) => (
            <div key={doc.id} className={styles.item}>
              <div className={styles.itemWrapper}>
                <a href={doc.path} target="_blank" rel="noreferrer noopener nofollow">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46 24L41.12 18.42L41.8 11.04L34.58 9.4L30.8 3L24 5.92L17.2 3L13.42 9.38L6.19998 11L6.87998 18.4L1.99998 24L6.87998 29.58L6.19998 36.98L13.42 38.62L17.2 45L24 42.06L30.8 44.98L34.58 38.6L41.8 36.96L41.12 29.58L46 24ZM20.18 33.44L12.58 25.82L15.54 22.86L20.18 27.52L31.88 15.78L34.84 18.74L20.18 33.44Z"
                      fill="#E2E2E2"
                    />
                  </svg>
                </a>
                <p>
                  <a href={doc.path} target="_blank" rel="noreferrer noopener nofollow">
                    {doc.name}
                  </a>
                </p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </MarketplaceCard>
  )
}

export default Docs
