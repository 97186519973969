import { message } from 'antd'

import { ClientRole } from './ClientRole.interface'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { ServiceProviderAPI } from '../API/ServiceProviderAPI.class'

export class ServiceProviderRole extends ClientRole {
  constructor() {
    super({
      title: 'Do you want to provide services?',
      btnText: 'Become a service provider',
      roleType: 'SERVICE_PROVIDER',
      className: 'new-role__card--service__provider',
      description: 'Leave a request to become a Service Provider.',
    })
  }

  async requestRoleUpdate(body) {
    try {
      const res = await axiosRequest(
        '/api/role-controller/update-role',
        body,
        'post',
        true,
      )

      if (res.status === 200) {
        const serviceProvider = await ServiceProviderAPI.create(body.id)

        if (serviceProvider) {
          message.destroy()
          message.success(`Your profile was updated successfully.`)
          // eslint-disable-next-line no-restricted-globals
          setTimeout(() => location.reload(), 1000)
        } else {
          message.destroy()
          message.error(
            `We were unable to update your profile of Service Provider at the moment, try again later.`,
          )
        }
      } else {
        message.destroy()
        message.error(
          `We were unable to update your profile of Service Provider at the moment, try again later.`,
        )
      }
    } catch (error) {
      message.destroy()
      message.error(
        `We were unable to update your profile of Service Provider at the moment, try again later.`,
      )
    }
  }

  getRoleName() {
    return 'partner'
  }

  updateRole(body) {
    body.is_partner = true
  }
}
