import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { notification, Skeleton } from 'antd'
import CompanyInfo from '../../../components/pages/marketplace/company-facts/CompanyInfo'
import SalesPresence from '../../../components/pages/marketplace/company-facts/SalesPresence'
import Brands from '../../../components/pages/marketplace/company-facts/Brands'
import Docs from '../../../components/pages/marketplace/company-facts/Docs'
import History from '../../../components/pages/marketplace/company-facts/History'
import Contacts from '../../../components/pages/marketplace/company-facts/Contacts'
import PhotoGallery from '../../../components/pages/marketplace/company-facts/PhotoGallery'

const CompanyFactsPage = () => {
  const { slug } = useParams()
  const [pageTitle, setPageTitle] = useState('Company Facts')
  const [data, setData] = useState(null)

  const getCompany = (slug) => {
    axiosRequest(`/api/v1/marketplace/company/${slug}`, null, 'get', true)
      .then((res) => {
        if (res.data) {
          const { company } = res.data
          setPageTitle(company.name)
          setData(company)
        }
      })
      .catch(() => {
        notification.error({
          message: 'Something goes wrong, please try again later',
          description: 'Server error',
        })
      })
  }

  useEffect(() => {
    getCompany(slug)
  }, [slug])

  return (
    <>
      <Helmet title={pageTitle} />
      <div className="container">
        {!data ? (
          <Skeleton active />
        ) : (
          <div className="row mb-5">
            <div className="col-12 col-lg-4 col-xxl-3">
              <div className="mb-4">
                <CompanyInfo data={data} />
              </div>
              {data.history && (
                <div className="mb-4">
                  <History data={data && data.history ? JSON.parse(data.history) : {}} />
                </div>
              )}
              <div className="sellerShowroom">
                <div className="sellerShowroom__left">
                  <Link
                    to={`/marketplace/showroom/${data.slug}`}
                    className="sellerShowroom__viewCompanyFacts"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <div>VIEW SELLER SHOWROOM</div>
                      <div className="ms-2">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.99984 6.66309L8.58984 8.07309L13.1698 12.6631L8.58984 17.2531L9.99984 18.6631L15.9998 12.6631L9.99984 6.66309Z"
                            fill="#004D40"
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8 col-xxl-9">
              <div className="mb-4">
                <SalesPresence data={data} />
              </div>
              <div className="row">
                <div className="col-12 col-lg-6 mb-4">
                  <Brands data={data.get_brands || []} />
                </div>
                <div className="col-12 col-lg-6 mb-4">
                  <Docs data={data.get_docs || []} />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-4">
                  <Contacts data={data} />
                </div>
                <div className="mb-4">
                  <PhotoGallery data={data.get_images || []} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CompanyFactsPage
