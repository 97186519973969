import moment from 'moment'
import { useCallback } from 'react'
import { Select, Form, Input, DatePicker } from 'antd'

import COUNTRIES_LIST from '../../../data/countries'

import {
  addAttribute,
  updateProductWeight,
} from '../../../util/functions/helpers/productUploadHelpers'

import AttributesSelect from './AttributesSelect'
import { SelectCategories } from './SelectCategories'
import { WeightController } from './components/WeightController/WeightController'

/**
 * @description ProductAttributes component is a form that allows the user to input the product's attributes.
 * @category Presentational Components/Pages/ProductUpload
 * @namespace Pages - ProductUpload - ProductAttributes
 *
 * @param {object} props
 * @param {object} props.form - The form object from Ant Design
 * @param {object} props.product - The product object
 * @param {function} props.updateProductData - The function to update the product object
 * @param {Array} props.categories - The categories array
 * @param {Array} props.attributes - The attributes array
 * @param {function} props.setProductData - The function to set the product object
 *
 *
 * @returns {JSX.Element} The ProductAttributes component
 *
 * @example
 * <ProductAttributes
 * form={form}
 * product={product}
 * updateProduct={updateProduct}
 * categories={categories}
 * attributes={attributes}
 * setProductData={setProductData}
 * />
 *
 * @requires antd
 * @requires moment
 *
 * @version 0.1.1
 * @since 0.1.0
 * @author Evgeny Tulikov
 *
 * @todo Add PropTypes
 * @todo Refactor the component to use hooks
 * @todo Refactor the component to use TypeScript
 * @todo Refactor the component for better modularity
 * @todo Remove usage of updateProductData and compose functions in hook with setProductData
 * @todo Create stories
 * @todo Create unit tests
 * @todo Create e2e tests
 *
 * @updated 03/04/2024 Rafael Rapizo Nery
 */
const ProductAttributes = ({
  form,
  product,
  updateProductData,
  categories = [],
  attributes = [],
  setProductData = () => null,
}) => {
  const { Item } = Form

  const handleWeightChange = useCallback(
    (val, key) => {
      setProductData((prev) => {
        return { ...updateProductWeight(prev, key, val) }
      })

      form.setFieldsValue({
        weight: {
          [key]: val,
        },
      })
    },
    [setProductData, form],
  )

  const handleSelectWeightAsVariant = useCallback(
    (e) => {
      if (product.attributes.find((att) => att.id === 14))
        return setProductData((prev) => ({
          ...prev,
          attributes: prev.attributes.filter((att) => att.id !== 14),
        }))
      else
        return setProductData((prev) => ({
          ...addAttribute(prev, {
            key: 14,
            id: 14,
            label: 'Weight',
            value: prev.weight.value,
            measure: prev.weight.measure,
            type: 'Mass',
          }),
        }))
    },
    [setProductData, product],
  )

  return (
    <div className="row product-upload__product-attributes">
      <div className="col-12 col-md-6">
        <SelectCategories
          product={product}
          categoryOpts={categories}
          updateProduct={updateProductData}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Form.Item label="Country of Origin" name="originCountry">
          <Select
            placeholder="Country of Origin"
            showSearch
            optionFilterProp="children"
            onSelect={(value) => updateProductData('originCountry', value)}
          >
            {COUNTRIES_LIST.map((country) => {
              return (
                <Select.Option value={country.name} key={country.code}>
                  {country.name}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Form.Item label="Year">
          <DatePicker
            mode="year"
            onChange={(date, dateString) => {
              updateProductData('productYear', dateString)
            }}
            picker="year"
            value={product.productYear ? moment(`01-01-${product.productYear}`) : null}
          />
        </Form.Item>
      </div>
      <div className="col-12 col-md-6 mt-3 mb-3">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <p className="mb-1">
              <strong>Dimension</strong>
            </p>
            <Item
              name={['dimensions', 'measure']}
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Select
                onChange={(val) => {
                  updateProductData('measure', {
                    measure: val,
                    width: 0,
                    height: 0,
                    depth: 0,
                  })
                  form.setFieldsValue({
                    dimensions: {
                      measure: val,
                      width: 0,
                      height: 0,
                      depth: 0,
                    },
                  })
                }}
              >
                <Select.Option value="Mm">Millimeter</Select.Option>
                <Select.Option value="Cm">Centimeter</Select.Option>
                <Select.Option value="M">Meter</Select.Option>
                <Select.Option value="Km">Kilometer</Select.Option>
                <Select.Option value="In">Inch</Select.Option>
                <Select.Option value="Ft">Foot/Feet</Select.Option>
                <Select.Option value="Yd">Yard</Select.Option>
                <Select.Option value="Ml">Mile</Select.Option>
              </Select>
            </Item>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <p className="mb-1">Width</p>
            <Item name={['dimensions', 'width']}>
              <Input
                onChange={(e) => updateProductData('dimensions.width', e.target.value)}
                placeholder="Width"
              />
            </Item>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <p className="mb-1">Height</p>
            <Item name={['dimensions', 'height']}>
              <Input
                onChange={(e) => updateProductData('dimensions.height', e.target.value)}
                placeholder="Width"
              />
            </Item>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <p className="mb-1">Depth</p>
            <Item name={['dimensions', 'depth']}>
              <Input
                onChange={(e) => updateProductData('dimensions.depth', e.target.value)}
                placeholder="Width"
              />
            </Item>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-3 mt-3 mb-3">
        <WeightController
          onSelectChange={(val) => handleWeightChange(val, 'measure')}
          onInputChange={(e) => handleWeightChange(e.target.value, 'value')}
          onCheckChange={handleSelectWeightAsVariant}
          isAttribute={product.attributes.find((att) => att.id === 14)}
          isDisabled={product.attributes.find((att) => att.id === 14)}
          value={product.weight}
        />
      </div>
      <AttributesSelect
        product={product}
        updateProduct={updateProductData}
        attributes={attributes}
        setProductData={setProductData}
      />
    </div>
  )
}

export default ProductAttributes
