import { useContext, useState, useCallback, useEffect } from 'react'
import axios from 'axios'

import { useStyles } from './useStyles'
import { httpToHttps } from '../../util/functions/httpToHttps'

/**
 * Custom React hook for managing font-related functionality, including fetching fonts from Google Fonts API
 * and handling font changes.
 *
 * @function
 * @returns {Object} - The font-related state and functions.
 * @property {Array} FONT_LIBRARY - The array containing font data fetched from Google Fonts API.
 * @property {Map} fonts - The map containing currently selected fonts.
 * @property {function} handleFontChange - The function to handle changes in the selected font.
 * @property {function} appendFontToDocument - The function to append font styles to the document.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const useFonts = () => {
  const { StylesContext, StylesReducerContext } = useStyles()
  const { fonts } = useContext(StylesContext)
  const dispatch = useContext(StylesReducerContext)
  let [fontLibrary, setFontLibrary] = useState([])

  /**
   * Check if the provided font is the default font.
   * @function
   * @param {string} fontFamily - The font family name.
   * @returns {boolean} - True if the font is the default font, otherwise false.
   */
  const isDefaultFont = (fontFamily) => {
    return fontFamily === 'Open Sans'
  }

  /**
   * Get URLs for a specific font from the font library.
   * @function
   * @param {string} fontFamily - The font family name.
   * @param {Array} library - The font library.
   * @returns {Object} - URLs for the specified font.
   */
  const getFontURLs = (fontFamily = '', library = fontLibrary) => {
    return library.find((f) => f.family === fontFamily).files
  }

  /**
   * Create a new style sheet.
   * @function
   * @returns {Object} - The created style sheet.
   */
  const createStyleSheet = () => {
    const sheet = document.createElement('style')
    return sheet
  }
  /**
   * Write content to a style sheet.
   * @function
   * @param {Object} sheet - The style sheet.
   * @param {string} content - The content to write to the style sheet.
   * @returns {Object} - The updated style sheet.
   */
  const writeToStyleSheet = (sheet, content) => {
    sheet.appendChild(document.createTextNode(content))
    return sheet
  }
  /**
   * Append a style sheet to the document head.
   * @function
   * @param {Object} sheet - The style sheet to append.
   */
  const appenStyleSheet = (sheet) => {
    document.head.appendChild(sheet)
  }

  /**
   * Create a font-face CSS declaration for a specified font and URL.
   * @function
   * @param {string} fontFamily - The font family name.
   * @param {string} url - The URL for the font file.
   * @returns {string} - The font-face declaration.
   */
  const createFontFace = (fontFamily, url) => {
    url = httpToHttps(url)
    const fontFace = `@font-face {
      font-family: '${fontFamily}';
      src: url('${url}') format('woff2');
    }`
    return fontFace
  }

  /**
   * Append font styles to the document for the specified font.
   * @function
   * @param {string} fontFamily - The font family name.
   */
  const appendFontToDocument = (fontFamily) => {
    if (isDefaultFont(fontFamily)) return null
    const urls = getFontURLs(fontFamily)
    const fontFace = createFontFace(fontFamily, urls.regular)
    let fontSheet = createStyleSheet()
    fontSheet = writeToStyleSheet(fontSheet, fontFace)
    appenStyleSheet(fontSheet)
  }

  /**
   * Fetch fonts from the Google Fonts API.
   * @function
   */
  const fetchFonts = useCallback(async () => {
    try {
      const res = await axios.get(
        `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_API_KEY}&capability=WOFF2`,
      )
      if (res.status === 200) {
        setFontLibrary([...res.data.items])
      } else {
        console.error(
          'Something went wrong contacting google services, please try again later or contact support.',
        )
      }
      console.log(res)
    } catch (error) {
      console.error('Something went wrong! Please, try again later or contact support.')
    }
  }, [])

  /**
   * Handle changes in the selected font and update the document styles.
   * @function
   * @param {string} value - The new font value.
   * @param {string} key - The key identifying the font property.
   */
  const handleFontChange = (value, key) => {
    appendFontToDocument(value)
    dispatch({
      type: 'SET_FONT',
      value,
      key,
    })
  }

  useEffect(() => {
    fetchFonts()
  }, [fetchFonts])

  return { FONT_LIBRARY: fontLibrary, fonts, handleFontChange, appendFontToDocument }
}
