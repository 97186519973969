import ProfileServicesCard from './ProfileServicesCard'
import SpinLoader from '../../../../../components/ui/loader/SpinLoader'

import { useProfileServices } from '../../../../../hooks/ui/useProfileServices/useProfileServices'

import './ProfileServices.scss'

const ProfileServices = () => {
  const { services, isLoading } = useProfileServices()

  return (
    <div>
      <h2 className="title">Edit Service Provider Profile</h2>
      <p className="info">Select which profile information would you like to edit:</p>
      <div className="lists-container">
        {isLoading ? (
          <SpinLoader />
        ) : (
          <ul className="services-list">
            {services.map((service) => (
              <ProfileServicesCard key={service.id} service={service} />
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default ProfileServices
