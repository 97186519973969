import { useCallback, useEffect, useState } from 'react'
import { ServiceTypeAPI } from '../../../models/Domains/API/ServiceTypeAPI.class'
import { message } from 'antd'
import { useSelector } from 'react-redux'
import { ServiceProviderAPI } from '../../../models/Domains/API/ServiceProviderAPI.class'

export const useServiceProviderProfileInfo = (isActive = false) => {
  const [services, setServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [isSelectorLoading, setIsSelectorLoading] = useState(false)
  const { user } = useSelector((state) => state.user)

  const getCurrentServices = useCallback(() => {
    let services = []
    if (user && user.serviceProvider) {
      const serviceProviderTypes = user.serviceProvider.service_provider_types
      services = serviceProviderTypes.map((type) => type.service_type)
    }
    setSelectedServices((prev) => [...prev, ...services])
  }, [user])

  const fetchServices = useCallback(async () => {
    setIsSelectorLoading(true)
    try {
      const services = await ServiceTypeAPI.index()
      setServices(services)
    } catch (error) {
      console.error(error)
      message.error('Failed to fetch services, try again later.')
    } finally {
      setIsSelectorLoading(false)
    }
  }, [])

  const handleSelectService = (serviceCode) => {
    if (selectedServices.find((s) => s.code === serviceCode)) return null
    const service = services.find((s) => s.code === serviceCode)
    setSelectedServices((prev) => [...prev, service])
  }

  const handleRemoveService = (service) => {
    setSelectedServices((prev) =>
      prev.filter((cService) => cService.code !== service.code),
    )
  }

  const saveServices = async () => {
    let res = null
    const serviceCodes = selectedServices.map((service) => service.code)
    try {
      const body = {
        logisticServiceData: {},
        id: user.serviceProvider.id,
        config: {
          managerType: 'generic_service_provider',
        },
        services: serviceCodes,
        updateType: 'select_services',
      }
      res = await ServiceProviderAPI.update(body)
    } catch (error) {
      console.error(error)
    } finally {
      return res
    }
  }

  useEffect(() => {
    if (isActive) {
      fetchServices()
      getCurrentServices()
    }
  }, [isActive, getCurrentServices, fetchServices])

  return {
    services,
    selectedServices,
    isSelectorLoading,
    handleSelectService,
    handleRemoveService,
    saveServices,
  }
}
