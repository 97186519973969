import { message } from 'antd'
import axiosRequest from '../../../hooks/useAxiosRequest'

export class ClientRole {
  title = ''
  btnText = ''
  roleType = ''
  className = ''
  description = ''

  constructor(
    config = {
      title: '',
      btnText: '',
      roleType: '',
      className: '',
      description: '',
    },
  ) {
    this.title = config.title
    this.btnText = config.btnText
    this.roleType = config.roleType
    this.className = config.className
    this.description = config.description
  }

  static getUnusedRoles(user) {
    const roles = []
    if (!user.is_buyer) {
      roles.push('BUYER')
    }
    if (!user.is_seller) {
      roles.push('SELLER')
    }
    if (!user.serviceProvider) {
      roles.push('SERVICE_PROVIDER')
    }
    return roles
  }

  async setNewRole(user) {
    message.loading('We are updating your profile...')
    await this.requestRoleUpdate(this.buildBody(user))
  }

  async requestRoleUpdate(body) {
    try {
      await axiosRequest('/api/role-controller/update-role', body, 'post', true).then(
        (res) => {
          if (res.status === 200) {
            message.destroy()
            message.success(`Your profile was updated successfully.`)
            // eslint-disable-next-line no-restricted-globals
            setTimeout(() => location.reload(), 2000)
          } else {
            message.destroy()
            message.error(
              `We were unable to update your profile of ${this.getRoleName()} at the moment, try again later.`,
            )
          }
        },
      )
    } catch (error) {
      message.destroy()
      message.error(
        `We were unable to update your profile of ${this.getRoleName()} at the moment, try again later.`,
      )
    }
  }

  buildBody(user) {
    const body = {
      id: user.id,
      is_buyer: user.is_buyer,
      is_seller: user.is_seller,
      is_partner: user.is_partner,
    }
    this.updateRole(body)
    return body
  }

  getRoleName() {
    return this.roleType.toLowerCase()
  }

  updateRole(body) {
    throw new Error('Method not implemented.')
  }
}
