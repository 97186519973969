const getVerificationSteps = (userType = 'seller') => {
  if ((userType === 'seller')) {
    return [
      {
        id: 'personalInfo',
        name: 'Personal Information',
        url: '/secured/verification/seller/profile-info',
        prevUrl: null,
        nextUrl: '/secured/verification/seller/company-info',
      },
      {
        id: 'companyInfo',
        name: 'Company Information',
        url: '/secured/verification/seller/company-info',
        prevUrl: '/secured/verification/seller/profile-info',
        nextUrl: '/secured/verification/seller/company-logo',
      },
      {
        id: 'companyLogo',
        name: 'Company Logo',
        url: '/secured/verification/seller/company-logo',
        prevUrl: '/secured/verification/seller/company-info',
        nextUrl: '/secured/verification/seller/company-industries',
      },
      {
        id: 'companyIndustries',
        name: 'Company Categories',
        url: '/secured/verification/seller/company-industries',
        prevUrl: '/secured/verification/seller/company-logo',
        nextUrl: '/secured/verification/seller/company-sub-industries',
      },
      {
        id: 'companySubIndustries',
        name: 'Company Sub-Categories',
        url: '/secured/verification/seller/company-sub-industries',
        prevUrl: '/secured/verification/seller/company-industries',
        nextUrl: '/secured/verification/seller/brands',
      },
      {
        id: 'brands',
        name: 'Brands',
        url: '/secured/verification/seller/brands',
        prevUrl: '/secured/verification/seller/company-sub-industries',
        nextUrl: '/secured/verification/seller/additional-info',
      },
      {
        id: 'historyData',
        name: 'History Data',
        url: '/secured/verification/seller/additional-info',
        prevUrl: '/secured/verification/seller/brands',
        nextUrl: '/secured/verification/seller/sales-presence',
      },
      {
        id: 'salesPresence',
        name: 'Sales & Presence',
        url: '/secured/verification/seller/sales-presence',
        prevUrl: '/secured/verification/seller/additional-info',
        nextUrl: '/secured/verification/seller/financial',
      },
      {
        id: 'setFinancialInfo',
        name: 'Financial',
        url: '/secured/verification/seller/financial',
        prevUrl: '/secured/verification/seller/sales-presence',
        nextUrl: '/secured/verification/seller/photos-docs',
      },
      {
        id: 'photosDocs',
        name: 'Photos & Documents',
        url: '/secured/verification/seller/photos-docs',
        prevUrl: '/secured/verification/seller/financial',
        nextUrl: null,
      },
    ]
  } else if (userType === 'buyer') {
    return [
      {
        id: 'personalInfo',
        name: 'Personal Information',
        url: '/secured/verification/buyer/profile-info',
        prevUrl: null,
        nextUrl: '/secured/verification/buyer/company-info',
      },
      {
        id: 'companyInfo',
        name: 'Company Information',
        url: '/secured/verification/buyer/company-info',
        prevUrl: '/secured/verification/buyer/profile-info',
        nextUrl: '/secured/verification/buyer/company-logo',
      },
      {
        id: 'companyLogo',
        name: 'Company Logo',
        url: '/secured/verification/buyer/company-logo',
        prevUrl: '/secured/verification/buyer/company-info',
        nextUrl: '/secured/verification/buyer/company-industries',
      },
      {
        id: 'companyIndustries',
        name: 'Interested Industries',
        url: '/secured/verification/buyer/company-industries',
        prevUrl: '/secured/verification/buyer/company-logo',
        nextUrl: '/secured/verification/buyer/company-sub-industries',
      },
      {
        id: 'companySubIndustries',
        name: 'Interested Sub-Industries',
        url: '/secured/verification/buyer/company-sub-industries',
        prevUrl: '/secured/verification/buyer/company-industries',
        nextUrl: null,
      },
    ]
  } else if (userType === 'service-provider') {
    return [
      {
        id: 'personalInfo',
        name: 'Personal Information',
        url: '/secured/verification/service-provider/profile-info',
        prevUrl: null,
        nextUrl: '/secured/verification/service-provider/company-info',
      },
      {
        id: 'companyInfo',
        name: 'Company Information',
        url: '/secured/verification/service-provider/company-info',
        prevUrl: '/secured/verification/service-provider/profile-info',
        nextUrl: '/secured/verification/service-provider/company-logo',
      },
      {
        id: 'historyData',
        name: 'Business Data',
        url: '/secured/verification/service-provider/additional-info',
        prevUrl: '/secured/verification/service-provider/company-info',
        nextUrl: '/secured/verification/service-provider/company-logo',
      },
      {
        id: 'companyLogo',
        name: 'Company Logo',
        url: '/secured/verification/service-provider/company-logo',
        prevUrl: '/secured/verification/service-provider/company-info',
        nextUrl: '/secured/verification/service-provider/company-industries',
      },
      {
        id: 'companyIndustries',
        name: 'Interested Industries',
        url: '/secured/verification/service-provider/company-industries',
        prevUrl: '/secured/verification/service-provider/company-logo',
        nextUrl: '/secured/verification/service-provider/company-sub-industries',
      },
      {
        id: 'companySubIndustries',
        name: 'Interested Sub-Industries',
        url: '/secured/verification/service-provider/company-sub-industries',
        prevUrl: '/secured/verification/service-provider/company-industries',
        nextUrl: '/secured/verification/service-provider/sales-presence',
      },
      {
        id: 'salesPresence',
        name: 'Geographical Data',
        url: '/secured/verification/service-provider/sales-presence',
        prevUrl: '/secured/verification/service-provider/company-sub-industries',
        nextUrl: null,
      },
    ]
  }
  return []
}

export { getVerificationSteps }
