import { FONT_CONTROLLERS } from '../../../data/styleControllers'

import { useFonts } from '../../../hooks/ui/useFonts'

import { FontController } from './FontController'

/**
 * React component for rendering font controllers based on the specified font controllers data.
 *
 * @component
 * @returns {JSX.Element} - The rendered Fonts component.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const Fonts = () => {
  const { FONT_LIBRARY, fonts, handleFontChange } = useFonts()

  return (
    <>
      {FONT_CONTROLLERS.map((f) => {
        console.log(fonts.get(f.getKey()))
        return (
          <FontController
            title={f.title}
            font={fonts.get(f.getKey())}
            callback={(value) => handleFontChange(value, f.getKey())}
            content={FONT_LIBRARY}
          />
        )
      })}
    </>
  )
}
