import { notification, Skeleton } from 'antd'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'

import axiosRequest from '../../hooks/useAxiosRequest'

import { useDashboard } from '../../hooks/ui/useDashboard/useDashboard'

import { NewRoleContainer } from '../../layouts/dashboard/NewRoleContainer'
import { ProfileStatusContainer } from '../../layouts/dashboard/ProfileStatusContainer'
import ServicesTypeModal from '../../components/ui/modals/ServicesTypeModal/ServicesTypeModal'
import { InterestedIndustriesModal } from '../../components/ui/modals/InterestedIndustriesModal'

const DashboardPage = () => {
  const user = useSelector((state) => state.user.user)
  const { activeUserType } = useSelector((state) => state.user)
  const [hasInudstries, setHasIndustries] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const { isServiceModalVisible, showServiceModal, hideServiceModal } = useDashboard()

  useEffect(() => {
    const fetchSubIndustries = async () => {
      await axiosRequest('/api/v1/on-boarding/company-industries', null, 'get', true)
        .then((res) => {
          if (res.data.industries.length > 0) {
            setHasIndustries(true)
          }
        })
        .finally(() => setIsLoading(false))
    }

    if (activeUserType === 'sales-partner') {
      notification.warn({
        duration: 0,
        key: 'sales-partner-redirect',
        message: (
          <>
            Our Sales Partner Dashboard is under development and can be accessed through:{' '}
            <span>
              <br />
              <a href="https://dashboard.tradeto.world/login">Sales Partner | Login</a>
            </span>
          </>
        ),
      })
    }

    fetchSubIndustries()
  }, [])

  if (isLoading) return <Skeleton />

  return (
    <>
      {isServiceModalVisible && (
        <ServicesTypeModal
          isVisible={isServiceModalVisible}
          show={showServiceModal}
          hide={hideServiceModal}
        />
      )}
      {user.is_buyer && user.get_user_company && !hasInudstries && (
        <InterestedIndustriesModal />
      )}
      <Helmet title="Dashboard" />
      <h1 className="page-title">Hello{user.first_name && `, ${user.first_name}`}!</h1>
      <ProfileStatusContainer />
      <NewRoleContainer />
    </>
  )
}

export default DashboardPage
