import axios from "axios"
import { message } from "antd"

import { RestAPI } from "./RestAPI.interface"

export class ServiceProviderAPI extends RestAPI {
  static BASE_URL = `${process.env.REACT_APP_SERVICE_PROVIDER_URL}/service-provider`

  static notifyError = (text) => {
    message.error(text)
  }

  static request = async (method, path, data = null) => {
    let response = null
    response = await axios[method](`${this.BASE_URL}${path}`, data)
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(response.data)
    } else {
      return response.data
    }
  }

  static create = async (userID) => {
    return this.request('post', `/`, { userID })
  }

  static show = async (id) => {
    return this.request('get', `/${id}`)
  }

  static put = async () => {

  }

  static update = async (serviceProvider = {
    updateType: '',
    id: 0,
    services: [],
    logisticServiceData: {},
    config: {
      managerType: ''
    }
  }) => {
    return this.request('put', `/${serviceProvider.id}`, serviceProvider)
  }

  static async getByUser(id) {
    return await this.request('get', `/user/${id}`)
  }
}


