import { Breadcrumb } from 'antd'

import { useHeaderBreadcrumb } from '../../../hooks/ui/useHeaderBreadcrumb/useHeaderBreadcrumb'

import './HeaderBreadcrumb.scss'
import { useSelector } from 'react-redux'

const HeaderBreadcrumb = () => {
  const { headPath, paths } = useHeaderBreadcrumb()
  const activeUserType = useSelector((state) => state.user.activeUserType)

  return (
    <Breadcrumb className="breadcrumb">
      <Breadcrumb.Item className="breadcrumb__item" href={`/#/${headPath}`}>
        {`${activeUserType.replace('-', ' ')} / ${headPath}`}
      </Breadcrumb.Item>
      {paths.map((breadcrumb, index) => (
        <Breadcrumb.Item
          key={index}
          className="breadcrumb__item"
          href={`/#/${headPath}/${breadcrumb}`}
        >
          {breadcrumb}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default HeaderBreadcrumb
