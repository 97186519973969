import { useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'

import { useModal } from '../Modal/useModal'

export const useDashboard = () => {
  const {
    user: { serviceProvider },
  } = useSelector((state) => state.user)
  const {
    isVisible: isServiceModalVisible,
    setIsVisible: setIsServiceModalVisible,
    show: showServiceModal,
    hide: hideServiceModal,
  } = useModal()

  const isUserServiceProvider = useCallback(() => !!serviceProvider, [serviceProvider])
  const isServicesEmpty = useCallback(() => serviceProvider.service_provider_types.length === 0, [
    serviceProvider,
  ])

  const allowVisibleServiceModal = useCallback(() => {
    let allow = false
    try {
      allow = isUserServiceProvider() && isServicesEmpty()
    } catch(error) {
      console.error(error)
    }
    setIsServiceModalVisible(allow)
  }, [setIsServiceModalVisible, isUserServiceProvider, isServicesEmpty])

  useEffect(() => {
    allowVisibleServiceModal()
  }, [allowVisibleServiceModal])

  return {
    showServiceModal,
    hideServiceModal,
    isServiceModalVisible,
  }
}
