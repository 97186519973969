import Form from 'antd/lib/form/Form'

import { useShippingAndServices } from '../../../../hooks/ui/ShippingAndServices/useShippingAndServices'

import Footer from '../../../../components/verification/Footer'
import ShippingAndServicesForm from './ShippingAndServicesForm'
import VerificationLayout from '../../../../components/verification/VerificationLayout'
import { ServiceFormSidebard } from '../../../../components/verification/ServicesFormSidebar'

export const ShippingAndServices = () => {
  const { progress, onSubmit } = useShippingAndServices()

  return (
    <Form onFinish={onSubmit} className="shipping-and-services-form">
      <VerificationLayout
        sidebarContent={
          <ServiceFormSidebard
            title="Logistic Company Profile"
            progress={progress}
            isLoading={false}
          />
        }
        footerContent={<Footer nextUrl={null} prevUrl={null} />}
      >
        <ShippingAndServicesForm />
      </VerificationLayout>
    </Form>
  )
}
