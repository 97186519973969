import React from 'react'

const SignUpPage = () => {
  return (
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad beatae commodi
      doloremque dolorum error esse et eveniet ex, harum impedit ipsa molestiae, nostrum
      odit officia quas quidem ut velit voluptatem.
    </p>
  )
}

export default SignUpPage
