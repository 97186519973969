import { Input } from 'antd'

import { hexToRGB, RGBToHex } from '../../../util/functions/colorConverter'

/**
 * Renders inputs for RGB color values.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.callback - The callback function to update the selected color.
 * @param {string} props.currentColor - The current selected color in the color picker.
 * @returns {JSX.Element} - The rendered RGBInput component.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export function RGBInput({ callback = () => null, currentColor }) {
  /**
   * Handle input change for RGB values and update the color using the callback.
   * @function
   * @param {Object} e - The input change event.
   * @param {string} type - The type of RGB value ('r', 'g', 'b').
   */
  const handleInputRGB = ({ e, type }) => {
    let RGBValue = parseInt(e.target.value)
    if (isNaN(RGBValue)) RGBValue = 0

    const currentRGB = hexToRGB(currentColor)
    currentRGB[type] = RGBValue
    const hex = RGBToHex(currentRGB)
    callback(hex)
  }

  return (
    <div className="row">
      <Input
        className="col-3"
        inputMode="numeric"
        prefix="r:"
        maxLength={3}
        value={hexToRGB(currentColor).r}
        onChange={(e) =>
          handleInputRGB({
            e,
            type: 'r',
          })
        }
      />
      <Input
        className="col-3"
        inputMode="numeric"
        prefix="g:"
        maxLength={3}
        value={hexToRGB(currentColor).g}
        onChange={(e) =>
          handleInputRGB({
            e,
            type: 'g',
          })
        }
      />
      <Input
        className="col-3"
        inputMode="numeric"
        prefix="b:"
        maxLength={3}
        value={hexToRGB(currentColor).b}
        onChange={(e) =>
          handleInputRGB({
            e,
            type: 'b',
          })
        }
      />
    </div>
  )
}
