import React from 'react'
import { Link } from 'react-router-dom'

import stockIcon from '../../../../assets/badges/stock.png'
import checkIcon from '../../../../assets/badges/check.png'
import tagsIcon from '../../../../assets/badges/tags.png'

const ProductItem = ({ product, view = 'grid' }) => {
  const renderPrices = () => {
    if (product.get_prices.length === 1) {
      return (
        <div className="prices">
          <span>{`$${parseFloat(product.get_prices[0].price)}`}</span>
        </div>
      )
    }
    let min = 99999999999999999999999999
    let max = 0
    product.get_prices.forEach((price) => {
      if (parseFloat(price.price) < min) {
        min = parseFloat(price.price)
      }
    })
    max = min
    product.get_prices.forEach((price) => {
      if (parseFloat(price.price) > max) {
        max = parseFloat(price.price)
      }
    })
    return (
      <div className="prices">
        <span>{`FF $${min} - EXW $${max}`}</span>
      </div>
    )
  }

  if (view === 'list') {
    return (
      <div className="product-item__list">
        <div className="d-flex justify-content-start">
          <div className="item-image">
            <Link to={`/marketplace/product/${product.slug}`}>
              <img
                src={product.get_main_image && product.get_main_image.medium}
                alt={product.name}
              />
            </Link>
          </div>
          <div className="product-data">
            <div className="item-supplier">
              <div className="d-flex justify-content-start align-items-center">
                <div className="supplier">
                  {product.get_supplier && product.get_supplier.name}
                </div>
                <div className="icons">
                  <ul>
                    <li>
                      <img src={stockIcon} alt="" />
                    </li>
                    <li>
                      <img src={checkIcon} alt="" />
                    </li>
                    <li>
                      <img src={tagsIcon} alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h2 className="product-name">
              <Link to={`/marketplace/product/${product.slug}`}>{product.name}</Link>
            </h2>
            <div className="description">
              <div dangerouslySetInnerHTML={{ __html: product.body }} />
            </div>
            <div className="learn-more">
              <Link to={`/marketplace/product/${product.slug}`}>Show more</Link>
            </div>
          </div>
          <div className="product-prices">
            <div>
              {renderPrices()}
              <p className="min-order">Minimum order 20 Unit</p>
            </div>
            <div className="choose-options">
              <Link to={`/marketplace/product/${product.slug}`}>Choose options</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`product-item ${view === 'list' ? 'product-item__list' : ''}`}>
      <div className="item-image">
        <Link to={`/marketplace/product/${product.slug}`}>
          <img
            src={product.get_main_image && product.get_main_image.medium}
            alt={product.name}
          />
        </Link>
      </div>
      <div className="item-supplier">
        <div className="d-flex justify-content-start align-items-center">
          <div className="supplier">
            {product.get_supplier && product.get_supplier.name}
          </div>
          <div className="icons">
            <ul>
              <li>
                <img src={stockIcon} alt="" />
              </li>
              <li>
                <img src={checkIcon} alt="" />
              </li>
              <li>
                <img src={tagsIcon} alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <h2 className="product-name">
        <Link to={`/marketplace/product/${product.slug}`}>{product.name}</Link>
      </h2>
      {renderPrices()}
      <p className="min-order">Minimum order 20 Unit</p>
    </div>
  )
}

export default ProductItem
