import { DeleteOutlined } from '@ant-design/icons'
import { useState } from 'react'

export const GalleryItem = ({ image, onRemove }) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <div
      className="gallery__item"
      key={image}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        className={`gallery__item__overlay ${isHover && 'gallery__item__overlay--active'}`}
      >
        <div className="gallery__item__remove" onClick={() => onRemove(image)}>
          <div className='fe fe-x'></div>
        </div>
      </div>
      <img src={image} alt="" />
    </div>
  )
}
