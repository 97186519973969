import { Modal } from 'antd'

import VideoPlayer from '../../VideoPlayer/VideoPlayer'
import TutorialListDrawer from '../../drawer/TutorialListDrawer/TutorialListDrawer'

import { useTutorialOverlay } from '../../../../hooks/ui/useTutorialOverlay/useTutorialOverlay'

import './TutorialOverlay.scss'

const TutorialOverlay = ({
  isModalOpen,
  handleOnCancel,
  tutorials = [],
  activeTutorial = null,
}) => {
  const { selectTutorial, selectedTutorial, unselectTutorial } = useTutorialOverlay(
    activeTutorial,
  )
  const onCancel = () => {
    unselectTutorial()
    handleOnCancel()
  }

  return (
    <>
      <Modal
        visible={isModalOpen}
        open={true}
        footer={null}
        centered
        onCancel={onCancel}
      >
          <VideoPlayer
            href={selectedTutorial ? selectedTutorial.videoUrl : null}
            videoAlt={selectedTutorial ? selectedTutorial.videoUrl : null}
          />
          </Modal>
      <TutorialListDrawer
        isDrawerVisible={isModalOpen}
        tutorials={tutorials}
        onClickTutorial={selectTutorial}
        selectedTutorial={selectedTutorial}
        onClickBack={onCancel}
      />
    </>
  )
}
export default TutorialOverlay
