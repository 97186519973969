import { Dropdown } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

export const InfoIcon = ({
  overlay = null,
  overlayType = '',
  placement = 'bottomCenter',
}) => (
  <Dropdown
    className="info-icon"
    overlay={overlay}
    overlayClassName={`info-icon__overlay info-icon__overlay--${overlayType}`}
    placement={placement}
  >
    <InfoCircleOutlined />
  </Dropdown>
)
