import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import { StickyContainer } from 'react-sticky'

import HorizontalView from '../../../components/pages/marketplace/product-details/layouts/HorizontalView'
import VerticalView from '../../../components/pages/marketplace/product-details/layouts/VerticalView'

import axiosRequest from '../../../hooks/useAxiosRequest'

const ProductDetailsMarketplacePage = () => {
  const { slug } = useParams()
  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState(null)
  const [mainImageSizes, setMainImageSizes] = useState(null)

  useEffect(() => {
    axiosRequest(`/api/v1/marketplace/product/${slug}`, null, 'get', true)
      .then((res) => {
        if (res && res.data.success) {
          setProduct(res.data.data)
          if (
            res.data.data &&
            res.data.data.product.get_all_images &&
            res.data.data.product.get_all_images[0]
          ) {
            const img = new Image()
            img.src = res.data.data.product.get_all_images[0].original
            img.addEventListener('load', function () {
              setMainImageSizes({ width: this.naturalWidth, height: this.naturalHeight })
            })
          } else {
            setMainImageSizes({ width: 1, height: 0 })
          }
          setLoading(false)
        }
      })
      .catch(() => {
        alert('Something goes wrong, try again later...')
      })
  }, [slug])

  if (loading || !product || !mainImageSizes) {
    return (
      <div className="container">
        <Skeleton active />
      </div>
    )
  }

  return (
    <StickyContainer>
      <HorizontalView product={product} />
    </StickyContainer>
  )
}

export default ProductDetailsMarketplacePage
