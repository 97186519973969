import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Form, message } from 'antd'

import VerificationLayout from '../../../components/verification/VerificationLayout'
import LayoutLoading from '../../../components/verification/LayoutLoading'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { getVerificationSteps } from '../../../data/verficationSteps'
import { history } from '../../../index'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'

import { ProfileProgressAbstractFactory } from '../../../models/Domains/ProfileProgress/ProfileProgressAbstractFactory.interface'

const CompanyLogo = () => {
  const pageTitle = 'Company Logo'
  const { userType } = useParams()
  const { user } = useSelector((state) => state.user)
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [company, setCompanyData] = useState({})
  const [logo, setLogo] = useState(
    `${process.env.REACT_APP_TTW_CDN_URL}/web/common/company-logo-placeholder.svg`,
  )
  const [isDragOn, setIsDragOn] = useState(false)

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/company-info', null, 'get', true).then((res) => {
      if (res.data.success) {
        setCompanyData(res.data.company)
        if (res.data.company.logo) {
          setLogo(res.data.company.logo)
        }
        const progressFactory = ProfileProgressAbstractFactory.getFactory('role')
        const progressBuilder = new progressFactory({
          userType:
            userType !== 'service-provider' ? userType : 'service-provider-general',
          user: { ...user, steps: res.data.steps },
        })
        const progress = progressBuilder.getProgress()
        setProgress(progress)

        setLoading(false)
      }
    })
  }, [])

  const onFinish = () => {
    message.loading({ content: 'Please, wait...', key: 'save' })
    axiosRequest('/api/v1/on-boarding/company-logo', { logo }, 'post', true)
      .then((res) => {
        if (res.data.success) {
          updateAccountStatus(3, user.id)
          history.push(`/secured/verification/${userType}/company-industries`)
          message.destroy()
        }
      })
      .catch(() => {
        message.error('Something goes wrong, contact support...')
        message.destroy()
      })
  }

  const onFinishFailed = () => {
    message.error('Something goes wrong, contact support...')
  }

  const imageUpload = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      message.loading({ content: 'Please, wait...', key: 'save' })
      const formData = new FormData()
      formData.append('image', file)
      axiosRequest('/api/v1/images/upload', formData, 'post', true).then((res) => {
        if (res.data.image) {
          const { original } = JSON.parse(res.data.image)
          setLogo(original)
          message.destroy()
        }
      })
      e.target.value = ''
    } else {
      message.error('Incorrect image')
    }
    setIsDragOn(false)
  }

  const handleDragEnter = (e) => {
    e.preventDefault()

    setIsDragOn(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()

    setIsDragOn(false)
  }

  if (loading || !company.id) return <LayoutLoading />

  return (
    <>
      <Helmet title={pageTitle} />
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <VerificationLayout progress={progress} currentStep="companyLogo">
          <div className="row company-logo">
            <div className="col-12">
              <h1>{pageTitle}</h1>
            </div>
            <div className="col-8 col-lg-6">
              <div
                className={`logo__upload ${isDragOn ? 'logo__upload--on' : ''}`}
                onDragEnter={(e) => handleDragEnter(e)}
                onDragLeave={(e) => handleDragLeave(e)}
              >
                <input type="file" onChange={(e) => imageUpload(e)} accept="image/*" />
                <p className="mb-0">
                  Drag&Drop file here <br /> or <br /> <span>Browse files</span>
                </p>
              </div>
            </div>
            <div className="col-4 col-lg-4">
              <div className="image-preview">
                <div>
                  <img src={logo} alt="" />
                </div>
                <p>
                  Image must be at least 800 x 800px.
                  <br />
                  File formats JPG, PNG, GIF.
                </p>
              </div>
            </div>
          </div>
        </VerificationLayout>
      </Form>
    </>
  )
}

export default CompanyLogo
