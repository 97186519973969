const verifyStepCompletion = (step, userSteps) => {
  if (userSteps.find((userStep) => userStep.step === step.id)) {
    return true
  }
  if (
    step.id === 'historyData' &&
    userSteps.find((userStep) => userStep.step === 'additionalInfo')
  ) {
    return true
  }
  return false
}

export default verifyStepCompletion
