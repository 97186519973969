import { BuyerProfileProgressBuilder } from './BuyerProfileProgressBuilder.class'
import { SellerProfileProgressBuilder } from './SellerProfileProgressBuilder.class'
import { ServiceProviderGeneralProfileProgressBuilder } from './ServiceProviderGeneralProfileProgressBuilder.class'
import { ServiceProviderProfileProgressBuilder } from './ServiceProviderProfileProgressBuilder.class'

export class RoleProfileProgressFactory {
  profileProgressBuilder

  constructor({ userType, user }) {
    switch (userType) {
      case 'buyer':
        this.profileProgressBuilder = new BuyerProfileProgressBuilder({ user })
        break
      case 'seller':
        this.profileProgressBuilder = new SellerProfileProgressBuilder({ user })
        break
      case 'service-provider':
        this.profileProgressBuilder = new ServiceProviderProfileProgressBuilder({ user })
        break
      case 'service-provider-general':
        this.profileProgressBuilder = new ServiceProviderGeneralProfileProgressBuilder({ user })
        break
      default:
        return null
    }
  }

  getProgress() {
    return this.profileProgressBuilder.build()
  }
}
