import { Link } from 'react-router-dom'

export const Header = () => {
  return (
    <div className="company-identity__header">
      <h2 className="company-identity__header__title">Company Identity</h2>
      <p className="company-identity__header__description">
        Select the key characteristics of your brand style to unify your company when
        creating a {/* TODO: IMP LINK FOR BROCHURE SERVICE */}
        <Link to="/dashboard" className="company-identity__header__description__link">
          PDF presentation
        </Link>{' '}
        and {/* TODO: IMP LINK FOR LANDING PAGE BUILDER SERVICE */}
        <Link to="/dashboard" className="company-identity__header__description__link">
          Landing Page
        </Link>
        .{' '}
      </p>
    </div>
  )
}
