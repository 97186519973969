import React from 'react'
import { Input, Select, Radio } from 'antd'
import { MinusOutlined } from '@ant-design/icons'

const Prices = ({ price, product, updateProduct, index, itemIndex, removePrice }) => {

  console.log(product.prices[index])
  const handlePriceInputs = (priceIndex, key, value) => {
    const prices = product.prices
    prices[index].prices[priceIndex][key] = value
    updateProduct('prices', prices)
  }

  const handleUpdateSalesTerms = (key, value) => {
    const prices = product.prices
    prices[index][key] = value
    updateProduct('prices', prices)
  }
  return (
    <div className="col-12 mb-3 mt-3">
      <div className="row">
        {itemIndex === 0 && (
          <div className="col-12 mb-2">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 col-lg-2">
                <p className="mb-1">
                  <strong>Price Option</strong>
                </p>
                <Select
                  defaultValue={price.type}
                  value={price.type}
                  className="w-100"
                  onChange={(val) => handlePriceInputs(itemIndex, 'type', val)}
                >
                  <Select.Option value="by_qty">By Quantity</Select.Option>
                  <Select.Option value="price_range">Price Range</Select.Option>
                  <Select.Option value="by_price_avg">Average</Select.Option>
                  <Select.Option value="on_demand">On Demand</Select.Option>
                </Select>
              </div>
              {price.type &&
              ['by_qty', 'price_range', 'by_price_avg'].includes(price.type) ? (
                <>
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                    <div className="row">
                      <div className="col-6 col-lg-9">
                        <p style={{ marginBottom: '0.8rem' }}>
                          <strong>Is product available now?</strong>
                        </p>
                        <Radio.Group
                          onChange={(e) =>
                            handleUpdateSalesTerms('isAvailable', e.target.value)
                          }
                          value={product.prices[index].isAvailable}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </div>
                      <div className="col-6 col-lg-3">
                        {product.prices[index].isAvailable && (
                          <>
                            <p className="mb-1">Quantity</p>
                            <Input
                              defaultValue={product.prices[index].availableQty || 0}
                              placeholder="Qty"
                              className="w-100"
                              onChange={(e) =>
                                handleUpdateSalesTerms('availableQty', e.target.value)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-5 offset-xl-1">
                    <p style={{ marginBottom: '0.8rem' }}>
                      <strong>Can you drop-ship this product to consumers?</strong>
                    </p>
                    <Radio.Group
                      onChange={(e) =>
                        handleUpdateSalesTerms('isDropShipping', e.target.value)
                      }
                      value={product.prices[index].isDropShipping}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </div>
                </>
              ) : null}
              <div className="col-12">
                <p className="mb-0 pt-4" style={{ fontSize: '14px', color: 'gray' }}>
                  <span className="pe-1">
                    <i className="fe fe-info" />
                  </span>
                  {price.type === 'by_qty' && 'By quantity price.'}
                  {price.type === 'price_range' &&
                    'Price changes according to available options. Your buyer will contact you for a precise quote.'}
                  {price.type === 'by_price_avg' &&
                    'Average price. Your buyer will contact you for more details.'}
                  {price.type === 'on_demand' &&
                    'Your buyer will contact you for customized quotation.'}
                </p>
              </div>
            </div>
          </div>
        )}
        {['by_qty'].includes(price.type) && (
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <p className="mb-1">
                  <strong>Quantity</strong>
                </p>
              </div>
              <div className="col-5">
                {/* <p className="mb-1">From</p> */}
                <Input
                  defaultValue={price.minQty}
                  placeholder="From"
                  onChange={(e) => handlePriceInputs(itemIndex, 'minQty', e.target.value)}
                />
              </div>
              <div className="col-1 flex align-content-center justify-content-center">
                <MinusOutlined />
              </div>
              <div className="col-5">
                {/* <p className="mb-1">To</p> */}
                <Input
                  defaultValue={price.maxQty}
                  placeholder="To"
                  onChange={(e) => handlePriceInputs(itemIndex, 'maxQty', e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
        <div className="col-12 col-md-6">
          <div className="row align-items-center">
            {price.type !== 'on_demand' && (
              <div className="col-5">
                <p className="mb-1">
                  <strong>{price.type === 'price_range' ? 'From' : 'Price'}</strong>
                </p>
                <Input
                  value={price.priceValue}
                  defaultValue={price.priceValue}
                  placeholder={price.type === 'price_range' ? 'From' : 'Price'}
                  onChange={(e) => handlePriceInputs(itemIndex, 'priceValue', e.target.value)}
                />
              </div>
            )}
            {price.type === 'price_range' && (
              <div className="col-5">
                <p className="mb-1">To</p>
                <Input
                  defaultValue={price.maxPrice || 0}
                  placeholder="Max. Price"
                  onChange={(e) =>
                    handlePriceInputs(itemIndex, 'maxPrice', e.target.value)
                  }
                />
              </div>
            )}
            {['by_qty'].includes(price.type) && (
              <>
                {/* DISCOUNT CONTROLLER */}
                <div className="col-12 col-md-5">
                  <p className="mb-1">
                    <strong>Discount</strong>
                  </p>
                  <Input
                    defaultValue={price.discount}
                    placeholder="0%"
                    suffix="%"
                    onChange={(e) => handlePriceInputs(itemIndex, 'discount', e.target.value)}
                  />
                </div>
                <div className="col-2 text-end">
                  <button
                    style={{
                      padding: 0,
                      fontSize: '18px',
                      margin: 0,
                      marginTop: '24px',
                      boxShadow: 'none',
                      background: 'transparent',
                      border: 0,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      removePrice(index, itemIndex)
                    }}
                  >
                    <i className="fe fe-trash" />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Prices
