import { RightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import ProgressBar from '../../../../../components/ui/bar/ProgressBar'

import './ProfileServicesItem.scss'

const ProfileServicesCard = ({ service }) => {
  return (
    <Link to={service.path}>
      <li key={service.id} className="services-list__item">
        <img className="services-list__item__icon" src={service.icon} alt={service.name} />
        <div className="services-list__item__body">
          <h3 className="services-list__item__body__title">{service.name}</h3>
          <ProgressBar percent={service.progress}/>
          <p className="services-list__item__body__profile-info">
            {/* Your profile completion is <span>{service.progress}%</span> */}
            Your profile completion is <span>{service.progress}%</span>
          </p>
        </div>
        <RightOutlined
          className="services-list__item__button"
          style={{ color: '#808080' }}
        />
      </li>
    </Link>
  )
}

export default ProfileServicesCard
