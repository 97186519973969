import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import MarketplaceCard from '../../../ui/cards/marketplace/MarketplaceCard'

import styles from './history.module.scss'

const History = ({ data }) => {
  if (!data || !data.history) {
    return null
  }

  return (
    <MarketplaceCard title="History">
      <PerfectScrollbar>
        <div style={{ overflow: 'auto', maxHeight: '544px' }}>
          <div className={styles.companyHistory}>
            {data.history.map((history, index) => (
              <div key={index} className={styles.historyItem}>
                <div className={styles.wrapper}>
                  <div className={styles.year}>{history.year}</div>
                  <div className={styles.description}>{history.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </PerfectScrollbar>
    </MarketplaceCard>
  )
}

export default History
