const ServicesTypeModalItem = ({ modalItem, onClick }) => {
  return (
    <li
      onClick={() => onClick(modalItem.code)}
      key={modalItem.id}
      className={`dashboard-modal__list__item${modalItem.isActive ? '--active' : ''}`}
    >
      <img
        className="dashboard-modal__list__item__icon"
        alt="icon"
        src={modalItem.icon}
      />
      <p className="dashboard-modal__list__item__text">{modalItem.name}</p>
    </li>
  )
}

export default ServicesTypeModalItem
