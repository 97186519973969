import React from 'react'
import _ from 'lodash'

import styles from './packages-variants.module.scss'

const PackagesVariantsList = ({
  list,
  packageData,
  packagingTypesData,
  editVariant,
  deleteVariant,
}) => {
  return (
    <div className={styles.packagesVariants}>
      {list.map((item) => (
        <div key={item.key} className={styles.packageVariant}>
          <div className={styles.headWrapper}>
            <div className={styles.titleWrapper}>
              <div className={`${styles.icon} me-3`}>
                <img
                  src={_.find(packagingTypesData, (data) => data.id === item.typeId).icon}
                  alt={_.find(packagingTypesData, (data) => data.id === item.typeId).name}
                />
              </div>
              <div className={styles.name}>
                {_.find(packagingTypesData, (data) => data.id === item.typeId).name}
              </div>
            </div>
            <div>
              <button
                className="me-2 ant-btn-link"
                onClick={(e) => {
                  e.preventDefault()
                  editVariant(item, packageData)
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 17.2485V20.9985H6.75L17.81 9.93848L14.06 6.18848L3 17.2485ZM20.71 7.03859C21.1 6.64859 21.1 6.01859 20.71 5.62859L18.37 3.28859C17.98 2.89859 17.35 2.89859 16.96 3.28859L15.13 5.11859L18.88 8.86859L20.71 7.03859Z"
                    fill="#808080"
                  />
                </svg>
              </button>
              <button
                className="me-0 ant-btn-link"
                onClick={(e) => {
                  e.preventDefault()
                  deleteVariant(item, packageData)
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                    fill="#808080"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className={styles.content}>
            <div className="row">
              <div className="col-6 col-md-4 mb-2">
                <p>
                  Units per pack: <strong>{item.productPerInnerPack || '-'}</strong>
                </p>
              </div>
              <div className="col-6 col-md-4 mb-2">
                <p>
                  Pack weight:{' '}
                  <strong>
                    {item.innerPackWeight.value
                      ? `${item.innerPackWeight.value} ${item.innerPackWeight.type}`
                      : '-'}
                  </strong>
                </p>
              </div>
              <div className="col-6 col-md-4 mb-2">
                <p>
                  Total Weight (product + packaging):{' '}
                  <strong>
                    {item.innerPackWeightTotal.value || '-'}{' '}
                    {item.innerPackWeightTotal.type || '-'}
                  </strong>
                </p>
              </div>
              {/* <div className="col-6 col-md-4 mb-2">
                <p>
                  Pack price: <strong>{item.innerPackPrice || '-'}</strong>
                </p>
              </div> */}
              <div className="col-6 col-md-4 mb-2">
                <p>
                  Pack barcode:{' '}
                  <strong>
                    {item.innerPackBarcode.value
                      ? `${item.innerPackBarcode.type}-${item.innerPackBarcode.value}`
                      : '-'}
                  </strong>
                </p>
              </div>
              <div className="col-6 col-md-4 mb-2">
                <p>
                  Pack dimensions:{' '}
                  <strong>
                    {item.innerPackDimensions.width || '-'}
                    {item.innerPackDimensions.height
                      ? `x${item.innerPackDimensions.height}`
                      : ''}
                    {item.innerPackDimensions.depth
                      ? `x${item.innerPackDimensions.depth}`
                      : ''}{' '}
                    {item.innerPackDimensions.width &&
                    item.innerPackDimensions.height &&
                    item.innerPackDimensions.depth
                      ? item.innerPackDimensions.type
                      : ''}
                  </strong>
                </p>
              </div>
              <div className="col-6 col-md-4 mb-2">
                <p>
                  Pack volume:{' '}
                  <strong>
                    {item.innerPackDimensions.width &&
                    item.innerPackDimensions.height &&
                    item.innerPackDimensions.depth
                      ? parseFloat(
                          item.innerPackDimensions.width *
                            item.innerPackDimensions.height *
                            item.innerPackDimensions.depth,
                        ).toFixed(0)
                      : '-'}{' '}
                    {item.innerPackDimensions.width &&
                    item.innerPackDimensions.height &&
                    item.innerPackDimensions.depth
                      ? item.innerPackDimensions.type + '³'
                      : '-'}
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PackagesVariantsList
