import { Button } from 'antd'

import { history } from '..'

export const Unavailable = ({
  title = 'Page Unavailable',
  button = false,
  redirect = '/',
  buttonTitle = 'Complete',
}) => {
  return (
    <>
      <p>
        <strong>{title}</strong>
      </p>
      {button ? (
        <Button
          onClick={() => history.push(redirect)}
          type="primary"
          className="ant-btn-ttu ant-btn--spread"
        >
          {buttonTitle} <i className="fe fe-chevron-right"></i>
        </Button>
      ) : null}
    </>
  )
}
