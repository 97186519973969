import { USPController } from './components/USPController'

import { Form, Radio, Input, Select, Checkbox } from 'antd'

const ProductDetails = ({ title, product, updateProduct }) => {
  const isCheckedWarrantyStart = (date, set) => {
    const startingDates = set
    if (startingDates.find((d) => d === date)) {
      return true
    }
    return false
  }

  const handleWarrantyStart = (val, set) => {
    let startingDates = set

    if (isCheckedWarrantyStart(val, set)) {
      startingDates = startingDates.filter((d) => d !== val)
    } else {
      startingDates.push(val)
    }

    updateProduct('warranty.period.start', startingDates)
  }

  return (
    <div className="row product-details">
      <div className="col-12 col-md-5">
        <p className="mb-1 product-details__section-title">
          <strong>Does your product have a barcode?</strong>
        </p>
        <Radio.Group
          className="product-details__radio-group"
          onChange={(e) => updateProduct('barcode.haveBarcode', e.target.value)}
          value={product.barcode.haveBarcode}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
        {product.barcode.haveBarcode ? (
          <div className="row">
            <div className="col-4 col-md-3">
              <Form.Item
                name={['barcode', 'barcodeType']}
                rules={[{ required: true, message: 'Field is required' }]}
              >
                <Select
                  onChange={(val) => updateProduct('barcode.barcodeType', val)}
                  disabled={!product.barcode.haveBarcode}
                >
                  <Select.Option value="EAN">EAN</Select.Option>
                  <Select.Option value="UPC">UPC</Select.Option>
                  <Select.Option value="ISBN">ISBN</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-8 col-md-9">
              <Form.Item
                name={['barcode', 'barcode']}
                rules={[{ required: true, message: 'Field is required' }]}
              >
                <Input
                  disabled={!product.barcode.haveBarcode}
                  placeholder="Barcode"
                  onChange={(e) => updateProduct('barcode.barcode', e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
        ) : null}
      </div>
      <div className="col-12 col-md-7">
        <USPController updateProduct={updateProduct} usp={product.uspCode} />
      </div>
      <div className="col-12 col-md-5">
        <Form.Item name="htsCode" label="HTS customs code">
          <Input
            placeholder="Customs code"
            onChange={(e) => updateProduct('htsCode', e.target.value)}
          />
        </Form.Item>
      </div>
      <div className="col-12 product-details__warranty">
        <div className="col-12 col-md-6">
          <p className="mb-1 product-details__section-title">
            <strong>Does the product come with a warranty?</strong>
          </p>
          <Radio.Group
            className="product-details__radio-group"
            onChange={(e) => updateProduct('warranty.isWarranty', e.target.value)}
            value={product.warranty.isWarranty}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        {product.warranty.isWarranty && (
          <div className="col-12 col-md-6 ">
            <p>
              <strong>Warranty time:</strong>
            </p>
            <div className="row">
              <div className="col-6">
                <Select
                  onChange={(val) => updateProduct('warranty.period.timeUnit', val)}
                  className="w-100"
                  placeholder="Time unit"
                  value={product.warranty.period.timeUnit || null}
                >
                  <Select.Option value={'year'}>Year</Select.Option>
                  <Select.Option value={'month'}>Month</Select.Option>
                  <Select.Option value={'day'}>Day</Select.Option>
                </Select>
              </div>
              <div className="col-6">
                <Form.Item>
                  <Input
                    value={product.warranty.period.length}
                    type="number"
                    min={1}
                    placeholder="Number"
                    onChange={(e) =>
                      updateProduct('warranty.period.length', e.target.value)
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <p>
                  <strong>Warranty starts from:</strong>
                </p>
                <div className="row">
                  <div className="col-4">
                    <Checkbox
                      onChange={(e) =>
                        handleWarrantyStart(e.target.value, product.warranty.period.start)
                      }
                      checked={isCheckedWarrantyStart(
                        'purchase',
                        product.warranty.period.start,
                      )}
                      value={'purchase'}
                    >
                      Purchase date
                    </Checkbox>
                  </div>
                  <div className="col-4">
                    <Checkbox
                      onChange={(e) =>
                        handleWarrantyStart(e.target.value, product.warranty.period.start)
                      }
                      checked={isCheckedWarrantyStart(
                        'shipping',
                        product.warranty.period.start,
                      )}
                      value={'shipping'}
                    >
                      Shipping date
                    </Checkbox>
                  </div>
                  <div className="col-4">
                    <Checkbox
                      onChange={(e) =>
                        handleWarrantyStart(e.target.value, product.warranty.period.start)
                      }
                      checked={isCheckedWarrantyStart(
                        'delivery',
                        product.warranty.period.start,
                      )}
                      value={'delivery'}
                    >
                      Delivery date
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductDetails
