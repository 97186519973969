import ProductsList from '../pages/products/ProductsList'
import ProductUpload from '../pages/products/ProductUpload'
import ProductVariants from '../pages/products/ProductVariants'

export default [
  {
    path: '/products/list/:tab',
    Component: ProductsList,
  },
  {
    path: '/products/:slug',
    Component: ProductUpload,
  },
  {
    path: '/products/:action/:slug',
    Component: ProductUpload,
  },
  {
    path: '/products/post-save/variants/:id',
    Component: ProductVariants,
  },
]
