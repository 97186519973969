import moment from 'moment'
import { Button, Popconfirm, message } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { ProductAPI } from '../../../models/Domains/API/ProductAPI.class'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { useProductInfo } from '../../../hooks/useProductInfo'

import { IncompleteData } from '../../../components/pages/product-upload/components/IncompleteData'

/**
 * @description ProductTableRow component to display product details in a table row
 * @category Pages - Dashboard - Products Page - Components
 *
 * @param {Object} product - product details
 * @param {Boolean} isMaster - is master product
 * @param {Boolean} isIncompleteRow - is incomplete row
 * @param {Function} loadProducts - function to load products
 * @returns {JSX}
 *
 * @version 1.0.0
 * @since 1.0.0
 * @author Evgeny Tulikov
 *
 * @updated 02/27/2024 Rafael Rapizo Nery
 */
export const ProductTableRow = ({
  product,
  isMaster = true,
  isIncompleteRow = false,
  loadProducts,
}) => {
  const { fieldsInfo } = useProductInfo()

  const deleteProduct = (id, callback = () => null) => {
    message.loading({ content: 'Loading...', key: 'delete' })
    axiosRequest(`/api/v1/products/${id}`, null, 'delete', true)
      .then((res) => {
        if (res.data.success) {
          message.success({ content: 'Product was deleted', key: 'delete' })
          callback()
        }
      })
      .catch(() => {
        message.destroy()
        message.error('Something goes wrong, try again later.')
      })
  }

  const handleCopyProduct = async (id) => {
    try {
      await ProductAPI.copy(id)
    } catch (error) {
      console.error(error)
    } finally {
      return await loadProducts()
    }
  }

  return (
    <tr key={product.id} className={isMaster ? 'master-tr' : 'variant-tr'}>
      <td>{product.id}</td>
      <td>
        {product.get_main_image && (
          <img
            src={product.get_main_image.original}
            alt=""
            style={{ maxWidth: '100px', height: 'auto' }}
          />
        )}
      </td>
      <td>
        <Link
          // href={`https://marketplace.tradeto.world/product/${product.slug}`}
          // target="_blank"
          // rel="noreferrer nofollow noopener"
          to={`/products/post-save/variants/${product.id}`}
        >
          {product.name} {!product.product_variant && <strong>(Master)</strong>}
        </Link>
      </td>
      <td>
        {!isIncompleteRow ? (
          `${fieldsInfo(product).progress.toFixed(0)}%`
        ) : (
          <IncompleteData fields={fieldsInfo(product).missingFields} />
        )}
      </td>{' '}
      {!isIncompleteRow && (
        <>
          <td>
            {product.get_sub_industries &&
            product.get_sub_industries.map((s) => s.name).join(', ').length > 40
              ? `${product.get_sub_industries
                  .map((s) => s.name)
                  .join(', ')
                  .substring(0, 40)}...`
              : product.get_sub_industries.map((s) => s.name).join(', ')}
          </td>
          <td>
            {(() => {
              if (product.get_brands) {
                if (product.get_brands.map((s) => s.name).join(', ').length >= 40) {
                  return `${product.get_brands
                    .map((s) => s.name)
                    .join(', ')
                    .substring(0, 40)}...`
                } else {
                  return product.get_brands.map((s) => s.name).join(', ')
                }
              }
            })()}
          </td>
          <td>Active</td>
          <td>{moment(product.created_at).format('DD.MM.YYYY HH:mm')}</td>
        </>
      )}
      <td className="actions-td">
        <div className="actions-td__wrapper">
          <Link
            to={`/products/post-save/variants/${product.id}`}
            className={`success-color ${isIncompleteRow && 'ms-3'}`}
          >
            <i className="fe fe-edit-2" />
          </Link>
          {isMaster && (
            <Link className="primary-color" onClick={() => handleCopyProduct(product.id)}>
              <img
                src={`${process.env.REACT_APP_TTW_CDN_URL}/icons/copy.svg`}
                alt="copy"
              />
            </Link>
          )}
          {!isIncompleteRow && (
            <>
              <Popconfirm
                title="Are you sure? All related products will be removed!"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={() => deleteProduct(product.id, () => loadProducts())}
                okText="Delete"
                okType="danger"
                cancelText="Cancel"
              >
                <Button>
                  <i className="fe fe-trash danger-color" />
                </Button>
              </Popconfirm>
            </>
          )}
        </div>
      </td>
    </tr>
  )
}
