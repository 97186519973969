export class SuccessMessage {
  static title = 'Success!'
  static product = {
    create: 'Product created'
  }
  static profile = {
    saved: 'Your profile has been saved and is now waiting for approval from our Sales Team.'
  }
  static styles = {
    saved: "Your company's identity has been successfully updated."
  }
}
