import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Helmet } from 'react-helmet-async'
import { Button, Form, Input, message, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import _ from 'lodash'

import { getVerificationSteps } from '../../../data/verficationSteps'

import { history } from '../../../index'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'
import { useServiceProviderCompanyAdditionalInformation } from '../../../hooks/ui/CompanyAdditionalInformation/useServiceProviderCompanyAdditionalInformation'

import LayoutLoading from '../../../components/verification/LayoutLoading'
import VerificationLayout from '../../../components/verification/VerificationLayout'
import { TimelineItemCard } from '../../../components/verification/steps/additional-info/TimelineItemCard'
import { ProfileProgressAbstractFactory } from '../../../models/Domains/ProfileProgress/ProfileProgressAbstractFactory.interface'

const CompanyAdditionalInformation = (props) => {
  const [form] = Form.useForm()
  const { userType } = useParams()
  const pageTitle = userType === 'service-provider' ? 'Business Data' : 'History Data'
  const { user } = useSelector((state) => state.user)
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState({})

  const {
    turnover,
    setTurnover,
    companyDescription,
    saveServiceProvider,
    setCompanyDescription,
    setCompanyInformation,
  } = useServiceProviderCompanyAdditionalInformation(userType === 'service-provider')

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/company-additional-info', null, 'get', true).then(
      (res) => {
        if (res.data.success) {
          let companyHistory = null
          if (res.data.company && res.data.company.history) {
            companyHistory = JSON.parse(res.data.company.history)
            if (
              companyHistory &&
              companyHistory.history &&
              Array.isArray(companyHistory.history) &&
              companyHistory.history.length > 0
            ) {
              companyHistory = companyHistory.history
            } else {
              companyHistory = [{ year: '', description: '', required: true }]
            }
          } else {
            companyHistory = [{ year: '', description: '', required: true }]
          }
          setInfo({ ...res.data.company, history: companyHistory })
          setCompanyInformation({ ...res.data.company, history: companyHistory })

          const progressFactory = ProfileProgressAbstractFactory.getFactory('role')
          const progressBuilder = new progressFactory({
            userType: userType !== 'service-provider' ? userType : 'service-provider-general',
            user: { ...user, steps: res.data.steps },
          })
          const progress = progressBuilder.getProgress()
          setProgress(progress)

          // setProgress(parseInt((res.data.steps.length * 100) / totalSteps.length))
          setLoading(false)
        }
      },
    )
  }, [])

  const onSubmit = (values) => {
    const data = {
      ...values,
    }
    message.loading({ content: 'Please, wait...', key: 'save' })

    axiosRequest('/api/v1/on-boarding/company-additional-info', data, 'post', true)
      .then(async (res) => {
        if (userType === 'service-provider') {
          await saveServiceProvider()
        }

        if (res.data.success) {
          updateAccountStatus(3, user.id)
          message.destroy()
          if (userType === 'service-provider')
            history.push(`/secured/verification/${userType}/company-logo`)
          else history.push(`/secured/verification/${userType}/sales-presence`)
        }
      })
      .catch(() => {
        message.error('Something goes wrong, try again or contact support...')
      })
  }

  const onFinishFail = () => {
    message.error('Something goes wrong, contact support...')
  }

  if (loading || !info.id) return <LayoutLoading />

  return (
    <>
      <Helmet title={pageTitle} />
      <Form
        form={form}
        onFinish={onSubmit}
        onFinishFailed={onFinishFail}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          employees: info && info.employees ? info.employees : '0-10',
          ownership: info && info.ownership ? info.ownership : null,
          history: info && info.history ? info.history : null,
          youtube_link: info && info.youtube_link ? info.youtube_link : null,
        }}
      >
        <VerificationLayout currentStep="historyData" progress={progress}>
          <h1>{pageTitle}</h1>
          <div className="row">
            {userType === 'service-provider' && (
              <>
                <div className="col-12 col-lg-6">
                  <Form.Item label="Turnover">
                    <Input
                      placeholder="Turnover"
                      value={turnover}
                      onChange={(e) => setTurnover(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6"></div>
              </>
            )}
            <div className="col-12 col-lg-6">
              <Form.Item
                label="Ownership"
                name="ownership"
                rules={[{ required: true, message: 'Field is required' }]}
              >
                <Select placeholder="Select a category">
                  <Select.Option value="Sole Proprietorship">
                    Sole Proprietorship
                  </Select.Option>
                  <Select.Option value="Partnership">Partnership</Select.Option>
                  <Select.Option value="Limited Liability Company">
                    Limited Liability Company
                  </Select.Option>
                  <Select.Option value="Corporation">Corporation</Select.Option>
                  <Select.Option value="Benefit Corporation">
                    Benefit Corporation
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-12 col-lg-6">
              <Form.Item
                label="Employees"
                name="employees"
                rules={[{ required: true, message: 'Field is required' }]}
              >
                <Select>
                  <Select.Option value="0-10">0-10</Select.Option>
                  <Select.Option value="10-50">10-50</Select.Option>
                  <Select.Option value="50-100">50-100</Select.Option>
                  <Select.Option value="100+">100+</Select.Option>
                </Select>
              </Form.Item>
            </div>
            {userType === 'service-provider' && (
              <div className="col-12">
                <div className="col-12">
                  <Form.Item
                  className="mb-2"
                  label="Company Description"
                  rules={[{ required: false }]}
                >
                  <Input.TextArea
                    placeholder="Company description"
                    rows={3}
                    minLength={50}
                    maxLength={252}
                    value={companyDescription}
                    onChange={(e) => setCompanyDescription(e.target.value)}
                  />
                </Form.Item>
                <span className="counter">{companyDescription.length} / 252</span>
                </div>
              </div>
            )}
            <div className="col-12 col-lg-6">
              <Form.Item
                label="YouTube Video Link"
                name="youtube_link"
                rules={[{ required: false, message: 'Field is required' }]}
              >
                <Input placeholder="Company promo video" />
              </Form.Item>
            </div>
            {userType !== 'service-provider' && (
              <div className="col-12 mt-4">
                <div className="brands-description mb-3">
                  <h3>History Timeline</h3>
                  <p className="mb-0">
                    Add the most important events in your company's history
                  </p>
                </div>
                <Form.List name="history" initialValue={info.companyHistory}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => {
                        return (
                          // rest field no iterable
                          <TimelineItemCard
                            fieldKey={fieldKey}
                            key={key}
                            name={name}
                            onRemove={remove}
                            description={
                              info.history[
                                _.findIndex(info.history, (n) => {
                                  return info.history.indexOf(n) === key
                                })
                              ].description
                            }
                            /* restField={[...restField]} */
                            isOptional={
                              !info.history[
                                _.findIndex(info.history, (n) => {
                                  return info.history.indexOf(n) === key
                                })
                              ].required
                            }
                          />
                        )
                      })}
                      <Form.Item className="add-unit mb-2">
                        <Button
                          type="dashed"
                          block
                          icon={<PlusOutlined />}
                          onClick={() => {
                            add({ year: '', description: '', required: false })
                            setInfo((prev) => ({
                              ...prev,
                              history: [
                                ...prev.history,
                                { year: '', description: '', required: false },
                              ],
                            }))
                          }}
                        >
                          Add new event
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            )}
          </div>
        </VerificationLayout>
      </Form>
    </>
  )
}

export default CompanyAdditionalInformation
