import { Button } from 'antd'

export const VerificationModalFooter = ({ onCancel, onSave, onLeave, saved }) => {
  return (
    <>
      <div className="row verification-modal__footer">
        {!saved && (
          <div className="col-6">
            <Button
              type="primary"
              onClick={onCancel}
              className="ant-btn-ttu ant-btn-primary__outlined w-100"
            >
              Cancel
            </Button>
          </div>
        )}
        <div className={!saved ? "col-6" : "col-12" }>
          <Button onClick={!saved ? onSave : onLeave} type="primary" className="ant-btn-ttu w-100">
            {!saved ? 'Save' : 'go to my profile'}
          </Button>
        </div>
      </div>
    </>
  )
}
