import { COLOR_CONTROLLERS } from '../../../data/styleControllers'

import { useColors } from '../../../hooks/ui/useColors'

import { ColorController } from './ColorController'

/**
 * React component for rendering color selection controls based on COLOR_CONTROLLERS.
 * @component
 * @returns {JSX.Element} - Map of color controllers.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const Colors = () => {
  const { colors, recent, handleColorChange } = useColors()

  return (
    <>
      {COLOR_CONTROLLERS.map((c) => (
        <div>
          <ColorController
            key={c.title}
            title={c.title}
            description={c.description}
            callback={handleColorChange}
            color={colors.get(c.getKey())}
            defaultColor={colors.get(c.getKey())}
            recentColors={recent}
            contextKey={c.getKey()}
          />
        </div>
      ))}
    </>
  )
}
