import { useEffect, useRef, useCallback } from 'react'
import videojs from 'video.js'

export const useVideoPlayer = (videoUrl) => {
  const videoRef = useRef(null)

  const isURLAvailable = (url) => {
    return url ? true : false
  }

  const mountPlayer = useCallback(() => {
    if (isURLAvailable(videoUrl)) {
      const player = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        preload: 'auto',
        // sources: [
        //   {
        //     src: videoUrl,
        //     type: 'video/mp4',
        //   },
        // ],
      })

      return player
    }
  }, [videoUrl, videoRef])

  const unmountPlayer = useCallback((player) => {
    // if (player) {
    //   player.dispose()
    // }
  }, [])

  // const unmountPlayer = useCallback(() => {
  //   const _el = videoRef.current
  //   if (!_el || _el.isDisposed()) return
  //   _el.dispose()
  //   videoRef.current = null
  // }, [videoRef])

  useEffect(() => {
    const player = mountPlayer()

    return () => {
      unmountPlayer(player)
    }
  }, [mountPlayer, unmountPlayer])

  useEffect(() => {
    const player = videojs.getPlayer('video-player')
    if (player) {
      player.src(videoUrl)
    }
  }, [videoUrl])

  return { videoRef, videoUrl }
}
