export class MenuItem {
  constructor(category, name, key, isAdmin = false) {
    this.category = category
    this.name = name
    this.key = key
    this.isAdmin = isAdmin
    this.url = null
    this.icon = null
    this.childs = []
    this.isEnable = true
    this.availableFor = { seller: true, buyer: true, serviceProvider: true }
    this.userTypes = ['any']
  }

  setAvailableFor(isSeller, isBuyer, isServiceProvider) {
    this.availableFor = {
      seller: isSeller,
      buyer: isBuyer,
      serviceProvider: isServiceProvider,
    }
  }

  setUserTypes(userTypes) {
    this.userTypes = userTypes
  }

  setIsEnable(enabled) {
    this.isEnable = enabled
  }

  setUrl(isExternal, url, isDynamic = false) {
    this.url = {
      isExternal,
      url,
      isDynamic,
    }
  }

  setIcon(isClass, icon) {
    this.icon = {
      isClass,
      icon,
    }
  }

  setChilds(menuItem) {
    this.childs.push(menuItem)
  }
}
