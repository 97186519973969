import { Button } from 'antd'

export const NewRoleCard = ({ role }) => {
  return (
    <div className={`new-role__card ${role.className}`}>
      <div>
        <h4>{role.title}</h4>
        <p>{role.description}</p>
      </div>
      <div>
        <Button
          onClick={role.setNewRole}
          type="primary"
          className={`ant-btn-ttu ${
            role === 'SERVICE_PROVIDER' && 'ant-btn-primary__outlined'
          }`}
        >
          {role.btnText}
        </Button>
      </div>
    </div>
  )
}
