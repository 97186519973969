import { message } from 'antd'
import { useLocation } from 'react-router'
import { useState, useEffect, useCallback } from 'react'

import { TutorialListAPI } from '../../../models/Domains/API/TutorialListAPI.class'

const Tutorials = {
  // TODO: Add tutorial IDs for each path
}

export const useTutorials = ({ tutorialListID }) => {
  const [tutorials, setTutorials] = useState([])
  const [activeTutorial, setActiveTutorial] = useState(null)
  const { pathname } = useLocation()

  const fetchTutorials = useCallback(async () => {
    try {
      const response = await new TutorialListAPI().show(tutorialListID);
      return response;
    } catch (error) {
      // message.error('Failed to fetch tutorials');
      console.error('Error fetching tutorials:', error);
    }
  }, [tutorialListID])

  const setActiveTutorialByPathname = useCallback(() => {
    const tutorialID = Tutorials[pathname]
    setActiveTutorial(tutorials.find((tutorial) => tutorial.id === tutorialID))
  }, [pathname, tutorials])

  useEffect(() => {
    fetchTutorials().then((response) => {
      if (response && response.data) {
        setTutorials(
          response.data.tutorialListRelations.map((relation) => relation.tutorial),
      )}
    })
  }, [fetchTutorials])

  useEffect(() => {
    setActiveTutorialByPathname()
  }, [pathname, setActiveTutorialByPathname])

  return { tutorials, activeTutorial }
}
