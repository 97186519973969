import { Form, message } from 'antd'
import _ from 'lodash'

import { MultiSelect } from '../../common/forms/MultiSelect'
import { useCallback } from 'react'

/**
 * @description SelectCategories component is a form item that allows the user to select the product's categories.
 * @category Presentational Components/Pages/ProductUpload
 *
 * @param {object} props
 * @param {Array} props.categoryOpts - The list of category options
 * @param {object} props.product - The product object
 * @param {function} props.updateProduct - The function to update the product object
 *
 * @returns {JSX.Element} The SelectCategories component
 *
 * @example
 * <SelectCategories
 * categoryOpts={categoryOpts}
 * product={product}
 * updateProduct={updateProduct}
 * />
 *
 * @requires react
 * @requires antd
 * @requires lodash
 * @requires MultiSelect
 *
 * @see MultiSelect
 *
 * @todo Add prop types
 * @todo Add tests
 * @todo Add storybook
 *
 * @version 1.0.0
 * @since 1.0.0
 * @author Rafael Rapizo Nery
 *
 */
export const SelectCategories = ({ categoryOpts, product, updateProduct }) => {
  const handleSelect = useCallback(
    (value) => {
      if (isNaN(value)) return null
      if (product['subIndustries'].includes(value))
        return message.warning('Category already added')

      let newCategories = [...product['subIndustries'], value]
      newCategories = _.uniq(newCategories)

      updateProduct('subIndustries', newCategories)
    },
    [product, updateProduct],
  )

  const handleDeselect = useCallback(
    (value) => {
      if (isNaN(value)) return null

      let newCategories = product['subIndustries'].filter(
        (category) => category !== value,
      )

      updateProduct('subIndustries', newCategories)
    },
    [product, updateProduct],
  )

  const validator = useCallback(() => {
    if (product['subIndustries'].length === 0) {
      return Promise.reject('Please select at least one category')
    }
    return Promise.resolve()
  }, [product])

  return (
    <Form.Item
      name="category"
      label="Categories"
      rules={[
        {
          validator,
          message: 'Select at least one category',
        },
      ]}
    >
      <MultiSelect
        options={categoryOpts}
        nameKey={'name'}
        valueKey={'id'}
        onSelectCallback={(value) => handleSelect(parseInt(value.id, 10))}
        onDeselectCallback={(value) => handleDeselect(parseInt(value.id, 10))}
        defaultValue={product['subIndustries']
          .filter((category) => categoryOpts.find((c) => c.id === category))
          .map((category) => categoryOpts.find((c) => c.id === category))}
        placeholder="Categories*"
      />
    </Form.Item>
  )
}
