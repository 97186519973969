import { Skeleton } from 'antd'
import { useStyles } from '../../../hooks/ui/useStyles'

import { Header, StyleForm, Footer } from './'
import { Unavailable } from '../../Unavailable'

export const CompanyIdentity = () => {
  const {
    styles,
    StylesContext,
    StylesReducerContext,
    dispatch,
    handleReset,
    handleSave,
    isLoading,
    isAvailable,
  } = useStyles()

  if (isLoading) return <Skeleton />

  if (!isAvailable) {
    return (
      <Unavailable
        title="Please, complete your profile to gain access to this service."
        button
        buttonTitle="Go to Dashboard"
        redirect="/dashboard"
      />
    )
  }

  return (
    <div className="company-identity">
      <Header />
      <StylesContext.Provider value={styles}>
        <StylesReducerContext.Provider value={dispatch}>
          <StyleForm type="fonts" />
          <StyleForm type="colors" />
          <Footer onSave={handleSave} onReset={handleReset} />
        </StylesReducerContext.Provider>
      </StylesContext.Provider>
    </div>
  )
}
