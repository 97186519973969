import { API } from './API.interface'

/**
 * @interface RestAPI
 */
export class RestAPI extends API {
  /**
   * @description Request
   * @method request
   * @param {string} method
   * @param {string} path
   * @param {any} data
   * @returns {Promise<any>}
   */
  request(method, path, data) {
    throw new Error('Method should be implemented in child class')
  }

  /**
   * @description Index
   * @method index
   * @param {string} path
   * @returns {Promise<any>}
   */
  index(path) {
    throw new Error('Method should be implemented in child class')
  }

  /**
   * @description Show
   * @method show
   * @param {string} id
   * @returns {Promise<any>}
   */
  show(id) {
    throw new Error('Method should be implemented in child class')
  }

  /**
   * @description Create
   * @method create
   * @param {any} data
   * @returns {Promise<any>}
   */
  create(data) {
    throw new Error('Method should be implemented in child class')
  }

  /**
   * @description Update
   * @method update
   * @param {string} id
   * @param {any} data
   * @returns {Promise<any>}
   */
  update(id, data) {
    throw new Error('Method should be implemented in child class')
  }

  /**
   * @description Delete
   * @method delete
   * @param {string} id
   * @returns {Promise<any>}
   */
  delete(id) {
    throw new Error('Method should be implemented in child class')
  }
}
