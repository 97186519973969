import { getPackages, formatWeight, getAllWeight, setWeight } from './'

export const getPackageTotalWeight = (
  product,
  packConfig = { type: null, weight: 0, units: 1 },
) => {
  const prdctWeigth = parseFloat(product.weight.value)
  let units = parseFloat(packConfig.units)
  let packWeight = parseFloat(packConfig.weight)
  let totalWeight = prdctWeigth
  const availablePackages = getPackages(product)

  let {
    primaryPackageWeight,
    displayPackageWeight,
    secondaryPackageWeight,
  } = getAllWeight(availablePackages)

  if (!packWeight || isNaN(packWeight)) packWeight = 0
  if (!units || isNaN(units)) units = 1
  if (isNaN(secondaryPackageWeight)) secondaryPackageWeight = 0
  if (isNaN(displayPackageWeight)) displayPackageWeight = 0

  totalWeight = setWeight({
    type: packConfig.type,
    units,
    total: totalWeight,
    packWeight,
    primaryPackageWeight,
    displayPackageWeight,
    secondaryPackageWeight,
  })

  return formatWeight(totalWeight)
}
