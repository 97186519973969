import React from 'react'

/**
 * @component
 * @category Pages - Marketplace - Product Details Page
 * @description ProductDetails component to display product details
 * @param {Object} product - product details
 * @returns JSX
 *
 * @exports ProductDetails
 *
 * @version 1.0.0
 * @since 1.0.0
 *
 * @deprecated
 *
 * TODO: Remove this component
 */
const ProductDetails = ({ product }) => {
  return (
    <div className="description-card mt-4">
      <h2 className="card-title">Product Details</h2>
      <table className="product-details__table">
        <tbody>
          <tr>
            <td>Category</td>
            <td>{product.product.get_category.name}</td>
          </tr>
          {product.product.product_data.dimension_width && (
            <tr>
              <td>Width</td>
              <td>
                {product.product.product_data.dimension_width}{' '}
                {product.product.product_data.dimension_measure}
              </td>
            </tr>
          )}
          {product.product.product_data.dimension_height && (
            <tr>
              <td>Height</td>
              <td>
                {product.product.product_data.dimension_height}{' '}
                {product.product.product_data.dimension_measure}
              </td>
            </tr>
          )}
          {product.product.product_data.dimension_depth && (
            <tr>
              <td>Depth</td>
              <td>
                {product.product.product_data.dimension_depth}{' '}
                {product.product.product_data.dimension_measure}
              </td>
            </tr>
          )}
          {product.product.product_data.weight_value && (
            <tr>
              <td>Weight</td>
              <td>
                {product.product.product_data.weight_value}{' '}
                {product.product.product_data.weight_measure}
              </td>
            </tr>
          )}
          {product.product.get_product_attributes &&
          product.product.get_product_attributes.length > 0
            ? product.product.get_product_attributes.map((attr) => (
                <tr key={attr.id}>
                  <td>{attr.get_attribute_details.name}</td>
                  <td>{attr.value}</td>
                </tr>
              ))
            : null}
          {product.product.product_data.product_have_barcode && (
            <tr>
              <td>Barcode</td>
              <td>
                {product.product.product_data.barcode_type}{' '}
                {product.product.product_data.barcode}
              </td>
            </tr>
          )}
          {product.product.product_data.usp && (
            <tr>
              <td>USP</td>
              <td>{product.product.product_data.usp}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ProductDetails
