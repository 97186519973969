import { BuyerRole, SellerRole, ServiceProviderRole } from './'

export class ClientRoleFactory {
  static create(roleType = '') {
    switch (roleType) {
      case 'BUYER':
        return new BuyerRole()
      case 'SELLER':
        return new SellerRole()
      case 'SERVICE_PROVIDER':
        return new ServiceProviderRole()
      default:
        return null
    }
  }

  static createRoles(roleTypeArray = []) {
    return roleTypeArray.map((roleType) => this.create(roleType))
  }
}
