import { NewRoleCard } from '../../components/pages/dashboard/NewRoleCard'
import { useNewRoleContainer } from '../../hooks/ui/useNewRoleContainer/useNewRoleContainer'

export const NewRoleContainer = () => {
  const { unusedRoles } = useNewRoleContainer()

  if (unusedRoles.length > 0) {
    return (
      <div className={'new-role__container'}>
        {unusedRoles.map((role) => (
          <NewRoleCard role={role} key={role} />
        ))}
      </div>
    )
  }

  return null
}
