import { Select } from 'antd'

/**
 * React component for rendering a font controller with a font preview and font selection dropdown.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the font controller.
 * @param {string} props.font - The currently selected font.
 * @param {Array} props.content - The array of font data to populate the selection dropdown.
 * @param {function} props.callback - The callback function to handle font changes.
 * @returns {JSX.Element} - The rendered FontController component.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const FontController = ({ title, font, content, callback }) => {
  return (
    <div className="company-identity__section__controller row">
      <div className="col-2 company-identity__section__controller__font-preview">
        <p style={{ fontFamily: font }}>Aa</p>
      </div>
      <div className="col-4 d-flex align-items-center">
        <p className="company-identity__section__controller__title company-identity__section__controller__title--font">
          {title}
        </p>
      </div>
      <div className="col-6">
        <Select className="w-100" showSearch value={font} onChange={callback}>
          {content.map((f) => (
            <Select.Option key={f.family} value={f.family}>
              {f.family}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
}
