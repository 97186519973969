import { Form, message } from 'antd'
import _ from 'lodash'

import { MultiSelect } from '../../../common/forms/MultiSelect'

/**
 * @description SelectBrand is a component that uses the MultiSelect component to create a multi-select dropdown for the product brand.
 * @param {Object} brandOpts - The list of brand options to be displayed in the dropdown
 * @param {Object} product - The product object
 * @param {Function} updateProduct - The function to update the product object
 * @returns {JSX.Element} A multi-select dropdown component for the product brand
 * @example
 * <SelectBrand brandOpts={brandOpts} product={product} updateProduct={updateProduct} />
 * @see MultiSelect
 * @see Form
 * @see message
 * @see lodash
 *
 * @todo Add prop types
 * @todo Add tests
 * @todo Add storybook
 *
 * @version 1.0.0
 * @since 1.0.0
 * @category Presentational Components/Common
 * @component
 *
 * @requires react
 * @requires antd
 * @requires lodash
 * @requires MultiSelect
 *
 * @author Rafael Rapizo Nery
 */
export const SelectBrand = ({ brandOpts, product, updateProduct }) => {

  const handleSelect = (value) => {
    if (isNaN(value)) return null
    if (product['brands'].includes(value)) return message.warning('Brand already added')

    let newBrands = [...product['brands'], value]
    newBrands = _.uniq(newBrands)

    updateProduct('brands', newBrands)
  }

  const handleDeselect = (value) => {
    if (isNaN(value)) return null

    let newBrands = product['brands'].filter((brand) => brand !== value)

    updateProduct('brands', newBrands)
  }

  return (

      <Form.Item name="brand" label="Product Brand">
        <MultiSelect
          options={brandOpts}
          nameKey={'name'}
          valueKey={'id'}
          // onChangeCallback={(value) => handleBrandChange(parseInt(value.id, 10), product)}
          onSelectCallback={(value) => handleSelect(parseInt(value.id, 10))}
          onDeselectCallback={(value) => handleDeselect(parseInt(value.id, 10))}
          // In default value, map ids in products brands to id of brands
          defaultValue={product['brands'].map((brand) =>
            brandOpts.find((b) => b.id === brand),
          )}
        />
      </Form.Item>
  )
}
