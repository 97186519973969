import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ServiceProviderAPI } from '../../../models/Domains/API/ServiceProviderAPI.class'
import { LogisticsServiceProfileProgressBuilder } from '../../../models/Domains/ProfileProgress/LogisticServiceProfileProgressBuilder.class'
import { message } from 'antd'
import { ErrorMessage } from '../../../models/Messages/ErrorMessage'
import { history } from '../../..'

export const useShippingAndServices = () => {
  const { user } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const createSubmitFormSchema = useCallback(
    (formValues) => {
      console.log(formValues)

      const {
        shipmentType,
        groupageShipments,
        businessType,
        palletType,
        shipmentQuantities,
        otherServices,
        dangerousGoods
      } = formValues

      return {
        managerType: 'logistic_service_provider',
        userID: user.id,
        id: user.serviceProvider.id,
        updateType: 'update_services_data',
        logisticServiceData: {
          ShipmentService: {
            air_dry_ship: shipmentType.includes('air-dry'),
            air_insulated_ship: shipmentType.includes('air-insulated'),
            air_reefer_ship: shipmentType.includes('air-reefer'),
            boat_dry_ship: shipmentType.includes('boat-dry'),
            groupage_ship: groupageShipments,
            dangerous_ship: dangerousGoods,
            manages_b2b: businessType.includes('b2b'),
            manages_b2c: businessType.includes('b2c'),
            manages_eur1: palletType.includes('manages_eur1'),
            manages_eur3: palletType.includes('manages_eur3'),
            manages_eur6: palletType.includes('manages_eur6'),
            min_container: shipmentQuantities.includes('fullLoadContainers'),
            min_pallets: shipmentQuantities.includes('pallets'),
            min_single_boxes: shipmentQuantities.includes('singleBoxes'),
          },
          ExtraLogisticService: {
            has_distribution: otherServices.includes('distribution'),
            has_invoicing: otherServices.includes('localInvoicing'),
            has_warehousing: otherServices.includes('warehousing'),
          },
        },
      }
    },
    [user],
  )

  const onSubmit = useCallback(
    async (formValues) => {
      message.loading({ content: 'Loading...', key: 'save' })
      try {
        const body = createSubmitFormSchema(formValues)
        console.log(body)
        const res = await ServiceProviderAPI.update(body)
        if (res) {
          message.success({
            content: 'Successfully save your Logistic Company information.',
            key: 'save',
          })

          dispatch({ type: 'user/LOAD_CURRENT_ACCOUNT' })

          history.push('/dashboard')
        }
      } catch (error) {
        console.error(error)
        message.error({ content: ErrorMessage.server.generic, key: 'save' })
      }
    },
    [createSubmitFormSchema, dispatch],
  )

  const getProgress = useCallback(() => {
    const builder = new LogisticsServiceProfileProgressBuilder({ user })
    return builder.build()
  }, [user])

  return {
    progress: getProgress(),
    onSubmit,
  }
}
