import { useState, useEffect, useCallback } from 'react'
import axiosRequest from '../../useAxiosRequest'
import { message } from 'antd'

/**
 * @description Hook to manage the order of products in the dashboard
 * @param {function} handleVisibility - function to handle the visibility of the modal
 * @returns {object} - object with the products, productOrder, setProductOrder, setProducts, createProductOrder, handleOrderChange, handleSubmit, handleReset
 * @example
 * const { products, productOrder, setProductOrder, setProducts, createProductOrder, handleOrderChange, handleSubmit, handleReset } = useOrderManager()
 *
 * @method handleVisibility - function to handle the visibility of the modal
 * @method createProductOrder - function to create a map of products and their order
 * @method fetchProducts - function to fetch all products
 * @method handleOrderChange - function to update the order of the products
 * @method handleReset - function to reset the order of the products
 * @method handleSubmit - function to submit the order of the products
 *
 * @require React, useState, useEffect, useCallback, axiosRequest, message
 *
 * @version 0.1.1
 * @since 0.1.1
 * @category Hooks
 * @namespace Hooks - UI - useOrderManager
 * @author Rafael Rapizo Nery
 */
const useOrderManager = (handleVisibility = () => null) => {
  const [productOrder, setProductOrder] = useState(new Map())
  const [products, setProducts] = useState([])

  /**
   * @description Function to create a map of products and their order
   * @param {array} products - array of products
   * @returns {Map} - map of products and their order
   * @example
   * const productOrder = createProductOrder(products)
   */
  const createProductOrder = (products) => {
    const productOrder = new Map()
    products.forEach((product, index) => {
      productOrder.set(product.id, index)
    })
    return productOrder
  }

  /**
   * @description Function to fetch all products from the API
   * @returns {void}
   * @example
   * fetchProducts()
   * @async
   */
  const fetchProducts = useCallback(async () => {
    try {
      const res = await axiosRequest(
        '/api/v1/products?page=1&perPage=100&&asMasterView=1',
        null,
        'get',
        true,
      )
      if (res.data.success) {
        setProducts(res.data.products.data)
        setProductOrder(createProductOrder(res.data.products.data))
      } else {
        console.error('Error fetching products')
        message.error('Error loading products, please, try again later.')
      }
    } catch (error) {
      console.error(error)
      message.error('Error loading products, please, try again later.')
    }
  }, [])

  /**
   * @description Function to update the order of the products
   * @param {array} products - array of products
   * @returns {void}
   * @example
   * handleOrderChange(products)
   * @requires createProductOrder
   */
  const handleOrderChange = (products = []) => {
    setProductOrder(createProductOrder(products))
  }
  /**
   * @description Function to reset the order of the products
   * @returns {void}
   * @example
   * handleReset()
   */
  const handleReset = useCallback(() => {
    setProductOrder(createProductOrder(products))
    setProducts([...products])
  }, [products])

  /**
   * @description Function to submit the order of the products
   * @returns {void}
   * @example
   * handleSubmit()
   * @async
   * @requires axiosRequest, message
   * @throws {error} Error saving order, please, try again later.
   * @throws {error} Error saving order
   */
  const handleSubmit = useCallback(async () => {
    try {
      const data = {
        order: Array.from(productOrder).map(([id, order]) => ({ id, order })),
      }
      console.log({ data })
      console.log(Array.from(productOrder).map(([id, order]) => ({ id, order })))
      const res = await axiosRequest('/api/v1/products/order', data, 'post', true)

      if (res.data.success) {
        message.success('Order saved successfully.')
        handleVisibility(false)
      } else {
        console.error('Error saving order')
        message.error('Error saving order, please, try again later.')
      }
    } catch (error) {
      console.error(error)
      message.error('Error saving order, please, try again later.')
    }
  }, [handleVisibility, productOrder])

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  return {
    products,
    productOrder,
    setProductOrder,
    setProducts,
    createProductOrder,
    handleOrderChange,
    handleSubmit,
    handleReset,
  }
}

export default useOrderManager
