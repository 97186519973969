import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, Redirect, useParams } from 'react-router-dom/cjs/react-router-dom'
import { Skeleton } from 'antd'
import _ from 'lodash'

import { getVerificationSteps } from '../../data/verficationSteps'
import { TutorialLists } from '../../data/TutorialLists.enum'
import { useTutorials } from '../../hooks/utils/Tutorials/useTutorials'
import TutorialButton from '../ui/button/TutorialButton/TutorialButton'

import Sidebar from './Sidebar'
import Footer from './Footer'

import './VerificationLayout.scss'

const VerificationLayout = ({
  children,
  currentStep,
  progress = 0,
  loading = false,
  withProgress = true,
  withFooter = true,
  sidebarContent = null,
  footerContent = null,
}) => {
  const user = useSelector((state) => state.user)
  const { userType } = useParams()
  const [steps, setSteps] = useState([])
  const currentStepData = _.find(steps, ['id', currentStep])

  const { tutorials } = useTutorials({ tutorialListID: TutorialLists.ON_BOARDING })
  // const [tutorials, setTutorials] = useState([])

  // const fetchTutorials = async () => {
  //   return await TutorialListAPI.index(TutorialLists.ON_BOARDING)
  // }

  // useEffect(() => {
  //   fetchTutorials().then((response) => {
  //     setTutorials(
  //       response.data.tutorialListRelations.map((relation) => relation.tutorial),
  //     )
  //   })
  // }, [])

  useEffect(() => {
    setSteps(getVerificationSteps(userType))
  }, [userType])

  if (
    (userType === 'seller' && !user.user.is_seller) ||
    (userType === 'buyer' && !user.user.is_buyer)
  ) {
    return <Redirect to="/" />
  }

  return (
    <div className="verification-layout">
      <div className="layout-content">
        <div className="main-content">
          <div className="content-container pt-4">
            <div className="content-container__profile-link">
              {!loading && (
                <Link to="/profile/company-profile">
                  <i className="fe fe-chevron-left" />
                  Back to My Profile
                </Link>
              )}
            </div>
            {loading ? <Skeleton active /> : <>{children}</>}
            <TutorialButton tutorials={tutorials}  />
          </div>
        </div>
        {!loading && withProgress ? (
          <>
            {sidebarContent ? (
              sidebarContent
            ) : (
              <Sidebar
                user={user.user || {}}
                currentStep={currentStep}
                progress={progress}
                loading={loading}
              />
            )}
          </>
        ) : (
          <div className="verification-nav"></div>
        )}
      </div>
      {!loading && withFooter && (
        <>
          {footerContent ? (
            footerContent
          ) : (
            <Footer
              currentStep={currentStep}
              nextUrl={currentStepData ? currentStepData.nextUrl : ''}
              prevUrl={currentStepData ? currentStepData.prevUrl : ''}
            />
          )}
        </>
      )}
    </div>
  )
}

export default VerificationLayout
