import React from 'react'

import { history } from '../../../../index'
import { Button, Divider, Popconfirm, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

/**
 * @component
 * @category Pages - Product Upload Page
 * @description ProductVariantItem component to display product variant details
 * @param {Object} product - product details
 * @param {String} variationId - variation id
 * @param {Function} deleteProduct - function to delete product
 * @returns JSX
 *
 * @exports ProductVariantItem
 * @version 1.0.0
 * @since 1.0.0
 * @author Evgeny Tulikov
 * @updated 02/27/2024 Rafael Rapizo Nery
 *
 * @todo Add prop types
 * @todo Add tests
 * @todo Add storybook
 * @todo Improve modularity
 *
 */
const ProductVariantItem = ({
  product,
  variationId,
  deleteProduct,
  isMaster = false,
  onCopy = () => null,
}) => {
  const handleClick = (id, clone = false, parentVariant = true) => {
    if (clone) {
      history.push(
        `/products/clone/${id}${parentVariant ? `?parentVariant=${variationId}` : ''}`,
      )
    } else {
      history.push(`/products/edit/${id}`)
    }
  }

  return (
    <div className={`product-variant mb-4 ${isMaster && 'product-variant--master'}`}>
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-start">
            <div className="variant-image">
              <img
                src={product.get_main_image.original}
                alt={`${product.name}`}
                style={{ maxWidth: '100%' }}
              />
            </div>
            <div className="variant-details">
              <div className="d-flex justify-content-start align-items-center flex-wrap">
                <h2 className="variant-name">{product.name}</h2>
                <div className="product-description mt-2 w-100">
                  <div className="row">
                    {/* TTW CODE MAPPING */}
                    <div className="col-12 col-md-6 mt-2">
                      <p className="mb-0">
                        <span>TTW Code: </span>{' '}
                        {product.product_data && product.product_data.ttw_code
                          ? product.product_data.ttw_code
                          : ''}
                      </p>
                    </div>
                    {/* CATEGORIES MAPPING */}
                    <div className="col-12 col-md-6 mt-2">
                      <p className="mb-0">
                        <span>Categories: </span>{' '}
                        {product.get_sub_industries
                          .map((category) => category.name)
                          .join(', ')}
                      </p>
                    </div>
                    {/* ATTRIBUTES MAPPING */}
                    {product.get_product_attributes &&
                      product.get_product_attributes.map((attribute) => (
                        <div className="col-12 col-md-6 mt-2" key={attribute.id}>
                          <p className="mb-0">
                            <span>{attribute.get_attribute_details.name}: </span>{' '}
                            {attribute.value}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-start align-items-center variant-controls"
              style={{ marginLeft: 'auto' }}
            >
              <Divider type="vertical" style={{ height: '100%' }} />
              <div className="variant-controls__wrapper">
                <div className="variant-price">
                  {product.sales_terms[0] && (
                    <p className="mb-0">
                      {product.sales_terms[0].price_value}{' '}
                      {product.sales_terms[0].currency}
                    </p>
                  )}
                </div>
                <div className="variant-controls__actions">
                  <Tooltip title="Edit product" placement="top">
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleClick(product.id, false)}
                    >
                      <i className="fe fe-edit-2 me-2 success-color" />
                      <span>Edit</span>
                    </div>
                  </Tooltip>
                  <Popconfirm
                    title="Are you sure? All related products will be removed!"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={() => deleteProduct(product.id)}
                    okText="Delete"
                    okType="danger"
                    cancelText="Cancel"
                  >
                    <Tooltip title="Delete product" placement="top">
                      <div style={{ cursor: 'pointer' }}>
                        <i className="fe fe-trash me-2 danger-color" />
                        <span>Delete</span>
                      </div>
                    </Tooltip>
                  </Popconfirm>
                  {!isMaster && (
                    <Button
                      className="ant-btn ant-btn-sm add-btn-link ant-btn-primary ant-btn-primary__outlined ant-btn-ttu"
                      onClick={onCopy}
                    >
                      copy variant
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {/* <Tooltip title="Clone product" placement="top">
                    <div
                      className="me-3"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleClick(product.id, true, false)}
                    >
                      <i className="fe fe-copy me-2" />
                      <span>Clone</span>
                    </div>
                  </Tooltip>
                  <Tooltip title="Edit product" placement="top">
                    <div
                      className="me-3"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleClick(product.id, false)}
                    >
                      <i className="fe fe-edit-2 me-2" />
                      <span>Edit</span>
                    </div>
                  </Tooltip>
                  <Popconfirm
                    title="Are you sure? All related products will be removed!"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={() => deleteProduct(product.id)}
                    okText="Delete"
                    okType="danger"
                    cancelText="Cancel"
                  >
                    <Tooltip title="Delete product" placement="top">
                      <div className="me-3" style={{ cursor: 'pointer' }}>
                        <i className="fe fe-trash me-2" />
                        <span>Delete</span>
                      </div>
                    </Tooltip>
                  </Popconfirm> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductVariantItem
