export const getPackages = (prdct) => {
  if(!prdct.packagingLogistic.havePackaging) return []

  const pckgTypes = []

  for (const pack of prdct.packagingLogistic.packages) {
    pckgTypes.push(pack)
  }

  return pckgTypes
}
