import React, {useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import { getVerificationSteps } from '../../data/verficationSteps'
import verifyStepCompletion from '../../util/functions/verifyStepCompletion'
import axiosRequest from '../../hooks/useAxiosRequest'

import { ProgressBar } from '../pages/dashboard/ProgressBar'

const Sidebar = ({ user, currentStep, progress = 0, loading = false }) => {
  const [userSteps, setUserSteps] = useState(null)
  const { userType } = useParams()
  const steps = getVerificationSteps(userType)
  console.log(steps)

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/personal-info', null, 'get', true).then((res) => {
      if (res.data.success) {
        //res.data.steps
        setUserSteps(res.data.steps)
      }
    })
  }, [])

 /*  const isStepCompleted = (step, user) => {
    return true;
  } */

  return (
    <div className="verification-nav">
      <div className="nav-content">
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <div className="nav-progress">
              <div className="d-flex flex-column justify-content-start gap-2">
                <h5>
                  {userType === "buyer" && 'Buyer Profile'}
                  {userType === "seller" && 'Seller Profile'}
                  {userType === "service-provider" && 'Service Provider Profile'}
                </h5>
                <div className="d-flex flex-column gap-2">
                  <ProgressBar completed={progress} />
                  <p>
                    {progress <= 100 ? (
                      <>
                        Your profile completion is <span>{progress}%</span>
                      </>
                    ) : (
                      'Your profile is complete!'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="nav-steps">
              <ul className="steps__list">
                {steps &&
                  steps.map((step, index) => {
                    return (
                      <li
                        key={step.id}
                        className={`${step.id === currentStep ? 'active' : ''} ${userSteps && verifyStepCompletion(step, userSteps) ? 'completed' : ''}`}
                      >
                        <div className="step__status">{/* {index + 1} */} {/* criar uma função que receba o step, e o usuário, para conferir os dados. */}
                          <i className='fe fe-check'></i>
                        </div>
                        <div className="step__name">
                          <Link to={step.url}>{step.name}</Link>
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Sidebar
