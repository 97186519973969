import { useReducer, useMemo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { message, notification } from 'antd'
import axios from 'axios'

import { history } from '../../index'
import { DEFAULT_COLORS, DEFAULT_FONTS } from '../../data/styles'

import { AsyncMessage, ErrorMessage, SuccessMessage } from '../../models/Messages'

import useApiToken from '../useApiToken'

import { stylesReducer } from '../../contexts/Styles/reducer'
import { StylesContext, StylesReducerContext } from '../../contexts/Styles/context'

/**
 * Custom React hook for managing styles and colors.
 *
 * @function
 * @returns {object} An object containing state and functions for managing styles.
 * @property {object} styles - The current styles state.
 * @property {function} dispatch - The dispatch function for updating the styles state.
 * @property {React.Context} StylesContext - The context for styles state.
 * @property {React.Context} StylesReducerContext - The context for styles reducer.
 * @property {function} handleReset - Function to reset the styles state to default.
 * @property {function} handleSave - Function to save or update styles data.
 * @property {boolean} isLoading - Indicates whether data is currently being loaded.
 * @property {boolean} isAvailable - Indicates service availability based on user profile completion.
 * @version 1.1.0
 * @author Rafael Rapizo Nery
 */
export const useStyles = () => {
  const TOKEN = useApiToken()
  const { user } = useSelector((state) => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const [saveMethod, setSaveMethod] = useState('post')
  const [defaultFonts, setDefaultFonts] = useState(DEFAULT_FONTS)
  const [defaultColors, setDefaultColors] = useState(DEFAULT_COLORS)
  const initialState = useMemo(() => ({ fonts: defaultFonts, colors: defaultColors }), [
    defaultFonts,
    defaultColors,
  ])
  const [styles, dispatch] = useReducer(stylesReducer, initialState)
  const isAvailable = !!user.get_user_company

  /**
   * Converts API response to internal state.
   *
   * @function
   * @private
   * @param {object} res - The API response object.
   * @returns {object} Internal state representation.
   */
  const dataToState = useCallback((res) => {
    const { colors: colorsData, fonts: fontsData } = res
    const colors = new Map()
      .set('primary', colorsData.primary)
      .set('secondary', colorsData.secondary)
      .set('text_1', colorsData.text.primary)
      .set('text_2', colorsData.text.secondary)
      .set('bg_1', colorsData.bg.primary)
      .set('bg_2', colorsData.bg.secondary)
      .set('accent', colorsData.accent)
      .set('additional', colorsData.additional)
    const fonts = new Map().set('title', fontsData.title).set('body', fontsData.body)

    return { colors, fonts }
  }, [])

  /**
   * Converts internal state to data for API.
   *
   * @function
   * @private
   * @param {string} id - Company ID.
   * @param {object} state - Internal state.
   * @returns {object} Data representation for API.
   */
  const stateToData = useCallback((id, state) => {
    const data = {
      company_id: id,
      fonts: {
        title: state.fonts.get('title'),
        body: state.fonts.get('body'),
      },
      colors: {
        primary: state.colors.get('primary'),
        secondary: state.colors.get('secondary'),
        text: {
          primary: state.colors.get('text_1'),
          secondary: state.colors.get('text_2'),
        },
        bg: {
          primary: state.colors.get('bg_1'),
          secondary: state.colors.get('bg_2'),
        },
        accent: state.colors.get('accent'),
        additional: state.colors.get('additional'),
      },
    }
    return data
  }, [])

  /**
   * Populates default fonts and colors based on API response.
   *
   * @function
   * @private
   * @param {object} colors - Default colors.
   * @param {object} fonts - Default fonts.
   */
  const populate = useCallback((colors, fonts) => {
    setDefaultColors(colors)
    setDefaultFonts(fonts)
    setSaveMethod('put')
    dispatch({
      type: 'SET_ALL_COLORS',
      colors,
    })
    dispatch({
      type: 'SET_ALL_FONTS',
      fonts,
    })
  }, [])

  /**
   * Fetches styles data from the API.
   *
   * @function
   * @private
   * @async
   * @param {string} companyId - Company ID.
   * @param {string} token - API token.
   * @returns {Promise<object|null>} Resolved API response or null if unsuccessful.
   */
  const fetchStyles = useCallback(async (companyId, token) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_STYLES_API_URL}?company_id=${companyId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )

      if (res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }, [])

  /**
   * Resets styles state to default.
   *
   * @function
   * @private
   */
  const handleReset = useCallback(
    () => dispatch({ type: 'RESET', colors: defaultColors, fonts: defaultFonts }),
    [defaultColors, defaultFonts],
  )

  /**
   * Saves or updates styles data to the API.
   *
   * @function
   * @private
   * @async
   */
  const handleSave = async () => {
    const body = stateToData(user.get_user_company.id, styles)
    try {
      message.loading(AsyncMessage.loading)
      const res = await axios({
        method: saveMethod,
        url: process.env.REACT_APP_STYLES_API_URL,
        data: body,
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })

      if (res.status === 200) {
        notification.success({
          message: SuccessMessage.title,
          description: SuccessMessage.styles.saved,
        })
      }
    } catch (error) {
      console.error(error)
      notification.error({
        message: ErrorMessage.title,
        description: ErrorMessage.styles.notSaved,
      })
    } finally {
      message.destroy()
    }
  }

  useEffect(() => {
    if (isAvailable) {
      setIsLoading(true)
      fetchStyles(user.get_user_company.id, TOKEN)
        .then((res) => {
          if (res) {
            const { colors, fonts } = dataToState(res)
            populate(colors, fonts)
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false))
    }
  }, [fetchStyles, populate, user, TOKEN, isAvailable])

  return {
    styles,
    dispatch,
    StylesContext,
    StylesReducerContext,
    handleReset,
    handleSave,
    isLoading,
    isAvailable,
  }
}
