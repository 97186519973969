import React from 'react'
import { Link } from 'react-router-dom'
import { Rate } from 'antd'

const ProductManufacturer = ({ product }) => {
  console.log(product)
  return (
    <div className="product-manufacturer">
      <div className="d-flex justify-content-start align-items-center">
        <div className="brand-logo">
          <img
            src={
              product.product.product_data.brand
                ? product.product.product_data.brand.logo
                : product.company.logo
            }
            alt={product.company.name}
          />
        </div>
        <div className="brand-info">
          <h3>
            <Link to={`/marketplace/showroom/${product.company.slug}`}>
              {product.product.product_data.brand
                ? product.product.product_data.brand.name
                : product.company.name}
            </Link>
          </h3>
          <p className="address">
            {product.product.product_data.brand
              ? null
              : `${product.company.city}, ${product.company.country}`}
          </p>
          <div className="brand-rating">
            <Rate disabled defaultValue={5} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductManufacturer
