import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'

const Header = ({
  title,
  view,
  setView,
  hideViewToggle = false,
  hideNavToggle = false,
  hideTitle = false,
}) => {
  const location = useLocation()

  return (
    <div className={`explore-header`}>
      {!hideTitle && <h1>{title}</h1>}
      {!hideNavToggle && (
        <div className="explore-nav">
          <ul>
            <li className={location.pathname === '/marketplace/explore' ? 'active' : ''}>
              <Link to="/marketplace/explore">Products</Link>
            </li>
            <li className={location.pathname === '/marketplace/sellers' ? 'active' : ''}>
              <Link to="/marketplace/sellers">Sellers</Link>
            </li>
            <li className={location.pathname === '/marketplace/brands' ? 'active' : ''}>
              <Link to="/marketplace/brands">Brands</Link>
            </li>
          </ul>
        </div>
      )}
      <div className={`grid-list ${hideTitle && hideNavToggle ? 'mt-0' : ''}`}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="order">
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item>
                    <span>Alphabetical</span>
                  </Menu.Item>
                  <Menu.Item>
                    <span>Lowest Price</span>
                  </Menu.Item>
                  <Menu.Item>
                    <span>Top Rated</span>
                  </Menu.Item>
                </Menu>
              }
            >
              <div className="d-flex justify-content-start align-items-center">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23 10.5718L11 10.5625V12.4375H23V10.5718ZM11 17.125H19V19H11V17.125ZM23 4H11V5.88438L23 5.875V4Z"
                      fill="#00968A"
                    />
                    <path
                      d="M6 15.2594L6 4L4 4L4 15.2594L1 15.2594L5 19L9 15.2594L6 15.2594Z"
                      fill="#00968A"
                    />
                  </svg>
                </div>
                <div className="text">
                  Lowest Price <CaretDownOutlined />
                </div>
              </div>
            </Dropdown>
          </div>
          {!hideViewToggle && (
            <ul>
              <li
                className={view === 'grid' ? 'active' : ''}
                onClick={() => setView('grid')}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 9V5H5V9H9ZM19 19V15H15V19H19ZM9 19V15H5V19H9ZM3 3H11V11H3V3ZM13 13H21V21H13V13ZM3 13H11V21H3V13ZM21 3H13V11H21V3ZM19 5H15V9H19V5Z"
                  />
                </svg>
              </li>
              <li
                className={view === 'list' ? 'active' : ''}
                onClick={() => setView('list')}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19 5V9H4V5H19ZM19 15V19H4V15H19ZM20 3H3C2.45 3 2 3.45 2 4V10C2 10.55 2.45 11 3 11H20C20.55 11 21 10.55 21 10V4C21 3.45 20.55 3 20 3ZM20 13H3C2.45 13 2 13.45 2 14V20C2 20.55 2.45 21 3 21H20C20.55 21 21 20.55 21 20V14C21 13.45 20.55 13 20 13Z" />
                </svg>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
