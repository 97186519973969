/* eslint import/no-cycle:0 */
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'

import IndexLayout from './layouts/IndexLayout'

import authRoutes from './routes/auth'
import dashboardRoutes from './routes/dashboard'
import myProfileRoutes from './routes/my-profile'
import productsRoutes from './routes/products'
import marketplaceRoutes from './routes/marketplace'

const routes = [
  ...authRoutes,
  ...dashboardRoutes,
  ...myProfileRoutes,
  ...productsRoutes,
  ...marketplaceRoutes,
]

const mapStateToProps = () => ({
  routerAnimation: 'slide-fadein-up',
})

const Router = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <Switch location={location} key={location.pathname}>
                <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                {routes.map(({ path, Component, exact }) => (
                  <Route
                    path={path}
                    key={path}
                    exact={exact || true}
                    render={() => {
                      return <Component />
                    }}
                  />
                ))}
                <Redirect to="/404" />
              </Switch>
            )
          }}
        />
      </IndexLayout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
