import React from 'react'
import MarketplaceCard from '../../../ui/cards/marketplace/MarketplaceCard'

import styles from './company-info.module.scss'

const CompanyInfo = ({ data }) => {
  return (
    <MarketplaceCard>
      {data.logo && (
        <div className={styles.companyLogo}>
          <img src={data.logo} alt={data.name} />
        </div>
      )}
      <h1 className={styles.companyName}>{data.name}</h1>
      <div>
        <div className={styles.companyDetails}>
          <p>Established in:</p>
          <h4>{data.establishment_date}</h4>
        </div>
        <div className={styles.companyDetails}>
          <p>Employees:</p>
          <div className="d-flex justify-content-center align-items-center">
            <div className="me-3">
              <svg
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5165 26.169C23.0554 26.169 25.1326 22.0658 25.1326 19.1422C25.1326 16.2187 23.0554 13.8594 20.5165 13.8594C17.9776 13.8594 15.9004 16.2187 15.9004 19.1422C15.9004 22.0658 17.9776 26.169 20.5165 26.169Z"
                  fill="#00968A"
                />
                <path
                  d="M12.823 12.8338C14.7976 12.8338 16.4133 9.75637 16.4133 7.57654C16.4133 5.37107 14.7976 3.60156 12.823 3.60156C10.8483 3.60156 9.23267 5.37107 9.23267 7.57654C9.23267 9.75637 10.8483 12.8338 12.823 12.8338Z"
                  fill="#00968A"
                />
                <path
                  d="M28.21 12.8338C30.1847 12.8338 31.8003 9.75637 31.8003 7.57654C31.8003 5.37107 30.1847 3.60156 28.21 3.60156C26.2353 3.60156 24.6197 5.37107 24.6197 7.57654C24.6197 9.75637 26.2353 12.8338 28.21 12.8338Z"
                  fill="#00968A"
                />
                <path
                  d="M30.3643 29.529C29.4667 28.7084 24.1325 27.1953 24.1325 27.1953L20.5166 29.7598L16.9006 27.1953C16.9006 27.1953 11.5665 28.7084 10.6689 29.529C9.72004 30.4009 9.36101 33.2988 9.23278 35.0427C9.18149 35.7864 9.79698 36.4019 10.592 36.4019H30.4412C31.2362 36.4019 31.8517 35.7864 31.8004 35.0427C31.6722 33.2988 31.3132 30.4009 30.3643 29.529Z"
                  fill="#00968A"
                />
                <path
                  d="M14.8746 19.1405C14.8746 17.1659 15.6952 15.422 16.9518 14.2424C16.2081 14.0115 15.6183 13.832 15.6183 13.832L12.823 15.858L10.0277 13.8577C10.0277 13.8577 5.89884 15.0373 5.20642 15.6785C4.48836 16.3452 4.20626 18.602 4.10368 19.9612C4.05239 20.551 4.53965 21.0383 5.15513 21.0383H15.1054C14.9515 20.3715 14.8746 19.7304 14.8746 19.1405Z"
                  fill="#00968A"
                />
                <path
                  d="M31.0053 13.8594L28.21 15.8597L25.4147 13.8594C25.4147 13.8594 24.8505 14.0132 24.0812 14.2697C25.3378 15.4237 26.1584 17.1932 26.1584 19.1423C26.1584 19.7321 26.0815 20.3732 25.9276 21.04H35.8779C36.4934 21.04 36.955 20.5527 36.9293 19.9629C36.8268 18.6037 36.5703 16.3469 35.8266 15.6802C35.1342 15.039 31.0053 13.8594 31.0053 13.8594Z"
                  fill="#00968A"
                />
              </svg>
            </div>
            <h4>{data.employees}</h4>
          </div>
        </div>
        <div className={styles.companyDetails}>
          <p>Country of origin:</p>
          <h4>{data.country}</h4>
        </div>
        <div className={styles.companyDetails}>
          <p>Company Industries:</p>
          {data.get_industries && data.get_industries.length > 0 ? (
            <div className={`row ${styles.industries}`}>
              {data.get_industries.map((industry) => (
                <div
                  key={industry.id}
                  className={`col-6 col-lg-6 text-center ${styles.industry}`}
                >
                  <div className={styles.icon}>
                    <img src={industry.industry.icon} alt={industry.industry.name} />
                  </div>
                  <h5 className={styles.name}>{industry.industry.name}</h5>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </MarketplaceCard>
  )
}

export default CompanyInfo
