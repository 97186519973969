import React, { useState } from 'react'
import { Button, Input } from 'antd'
import {
  HeartOutlined,
  SwapOutlined,
  ShareAltOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons'

import saveIcon from '../../../../assets/badges/content/discount_24px.png'

const AddToCart = ({ layout = 'horizontal', selectedPrice }) => {
  const [qty, setQty] = useState(1)

  return (
    <div className="add-to-cart">
      <div className="cart-nav">
        <div className="row align-items-center">
          <div className={layout === 'horizontal' ? 'col-12 col-md-6' : 'col-12'}>
            <div className="price">
              <div className="price-text">
                <span>
                  {selectedPrice &&
                  selectedPrice.price &&
                  selectedPrice.discount &&
                  parseFloat(selectedPrice.discount) !== 0.0
                    ? `${parseFloat(selectedPrice.price).toFixed(2)} ${
                        selectedPrice ? selectedPrice.currency_string : ''
                      }`
                    : null}
                </span>
                <div className="d-flex justify-content-start align-items-center">
                  <div>
                    {selectedPrice && selectedPrice.price && !selectedPrice.discount
                      ? parseFloat(selectedPrice.price).toFixed(2)
                      : null}{' '}
                    {selectedPrice && selectedPrice.discount
                      ? parseFloat(
                          selectedPrice.price * ((100 - selectedPrice.discount) / 100),
                        ).toFixed(2)
                      : null}{' '}
                    {selectedPrice ? selectedPrice.currency_string : ''}
                  </div>
                  <div className="save">
                    <img src={saveIcon} alt="" />
                    <span>Save 50%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={layout === 'horizontal' ? 'col-12 col-md-6' : 'col-12 mt-3'}>
            <div className="qty">
              <div className="row">
                <div className="col-5">
                  <div className="qty__wrapper">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="wrap">
                        <Button onClick={() => qty >= 1 && setQty(qty - 1)}>
                          <MinusOutlined />
                        </Button>
                      </div>
                      <div className="wrap">
                        <Input
                          value={qty}
                          onChange={(e) => setQty(parseInt(e.target.value, 10))}
                        />
                      </div>
                      <div className="wrap">
                        <Button onClick={() => setQty(qty + 1)}>
                          <PlusOutlined />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-7">
                  <div className="qty-info">
                    <p>530 in Stock</p>
                    <p>(Min. order 20 Items)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cart-btns">
        <div className="row">
          <div className={layout === 'horizontal' ? 'col-12 col-md-6' : 'col-12'}>
            <Button className="outlined">Add to cart</Button>
          </div>
          <div className={layout === 'horizontal' ? 'col-12 col-md-6' : 'col-12 mt-2'}>
            <Button className="filled">Start order</Button>
          </div>
        </div>
      </div>
      <div className="card-extras">
        <div className="row">
          <div
            className={`col-12 col-md-4 card-extra ${
              layout === 'horizontal' ? '' : 'text-center'
            }`}
          >
            <HeartOutlined /> {layout === 'horizontal' && <span>Add to favorite</span>}
          </div>
          <div
            className={`col-12 col-md-4 card-extra ${
              layout === 'horizontal' ? '' : 'text-center'
            }`}
          >
            <SwapOutlined /> {layout === 'horizontal' && <span>Add to compare</span>}
          </div>
          <div
            className={`col-12 col-md-4 card-extra ${
              layout === 'horizontal' ? '' : 'text-center'
            }`}
          >
            <ShareAltOutlined /> {layout === 'horizontal' && <span>Share product</span>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddToCart
