import React from 'react'
import { Radio } from 'antd'
import MainForm from './MainForm'

const SelectTypeForm = ({
  index,
  updateIndex,
  packageData,
  product,
  updateProduct,
  packagingTypesData,
  addVariantToProduct,
}) => {
  if (!index) {
    return null
  }

  return (
    <div className="products-upload-packaging-form">
      <div className="packaging-form">
        <Radio.Group>
          <div className="row">
            {packagingTypesData.map((packageTypeData) => (
              <div className="col-6 col-md-4 col-lg-3 col-xl-2" key={packageTypeData.id}>
                <Radio.Button
                  value={packageTypeData.id}
                  className="w-100"
                  onChange={(e) => {
                    updateIndex({ ...index, typeId: e.target.value, variantId: null })
                  }}
                >
                  <div className="packaging-form__radio">
                    <div className="radioIcon">
                      <img src={packageTypeData.icon} alt={packageTypeData.name} />
                    </div>
                    <div className="radioName">{packageTypeData.name}</div>
                  </div>
                </Radio.Button>
              </div>
            ))}
          </div>
        </Radio.Group>
      </div>
      {index.typeId && (
        <div className="packaging-mainForm">
          <MainForm
            product={product}
            index={index}
            packageData={packageData}
            updateIndex={updateIndex}
            packagingTypesData={packagingTypesData}
            addVariantToProduct={addVariantToProduct}
          />
        </div>
      )}
    </div>
  )
}

export default SelectTypeForm
