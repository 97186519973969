import React from 'react'
import moment from 'moment'

const MainFooter = () => {
  return (
    <footer className="ttw-footer">
      <p>Copyright © {moment().format('YYYY')} Trade To World</p>
    </footer>
  )
}

export default MainFooter
