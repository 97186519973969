import { ServiceType } from './ServiceType.interface'
import { LogisticsService } from './LogisticsService.class'

export class ServiceTypeFactory {
  static create(service = {}) {
    switch (service.code) {
      case 'logistic-services':
        return new LogisticsService({ ...service })
      default:
        return new ServiceType({ ...service, path: '/404' })
    }
  }

  static createServicesTypes(serviceTypeArray = [{}]) {
    return serviceTypeArray.map((serviceType) => this.create(serviceType))
  }
}
