import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import PerfectScrollbar from 'react-perfect-scrollbar'

import menuItems from '../../data/menuItems'
import { useSelector } from 'react-redux'

const getSubMenuKeys = (menuItems) => {
  const keys = []

  for (const i of menuItems) {
    if (i.childs) {
      keys.push(i.key)
    }
  }

  return keys
}

const MainSidebar = ({ user }) => {
  const activeUserType = useSelector((state) => state.user.activeUserType)
  const [openKeys, setOpenKeys] = useState([])
  const rootKeys = getSubMenuKeys(menuItems)

  const onOpenSubMenu = (keys) => {
    const latestSubMenu = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootKeys.indexOf(latestSubMenu) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestSubMenu ? [latestSubMenu] : [])
    }
  }

  const setUrl = (url, key) => {
    const slug = user.user.get_user_company ? user.user.get_user_company.slug : ''
    if (
      key === 'ss-catalogue' ||
      key === 'ss-facts' ||
      key === 'ss-profile' ||
      key === 'seller-shop'
    ) {
      return `${url}${slug}`
    } else return url
  }

  console.log(user)

  const generateMenu = () => {
    return menuItems.map((item) => {
      if (!item.userTypes.includes(activeUserType) && !item.userTypes.includes('any')) {
        return null
      }
      if (item.adminOnly && user.user.is_admin !== 3) {
        return null
      }
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />
      }

      if (
        (!user.user.is_buyer &&
          item.availableFor.buyer &&
          !item.availableFor.seller &&
          !item.availableFor.serviceProvider) ||
        (!user.user.is_seller &&
          !item.availableFor.buyer &&
          item.availableFor.seller &&
          !item.availableFor.serviceProvider) ||
        (!user.user.is_partner &&
          !item.availableFor.buyer &&
          !item.availableFor.seller &&
          item.availableFor.serviceProvider)
      ) {
        return null
      }

      if (item.childs && item.childs[0]) {
        return (
          <Menu.SubMenu
            title={item.name}
            icon={
              item.icon.isClass ? (
                <span
                  className={`${item.icon.icon} icon-collapsed-hidden ${
                    !item.isEnable && 'disabled'
                  }`}
                />
              ) : (
                <img
                  className="icon-collapsed-hidden"
                  src={item.icon.icon}
                  alt={`${item.name} Icon`}
                />
              )
            }
            key={item.key}
          >
            {item.childs && (
              <>
                {item.childs.map((subMenu) => {
                  if (!subMenu.userTypes.includes(activeUserType) && !subMenu.userTypes.includes('any')) {
                    return null
                  }
                  return (
                    <Menu.Item key={subMenu.key} disabled={!subMenu.isEnable}>
                      {subMenu.url.isExternal ? (
                        <a
                          href={
                            subMenu.url.isDynamic
                              ? setUrl(subMenu.url.url, subMenu.key)
                              : subMenu.url.url
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>{subMenu.name}</span>
                        </a>
                      ) : (
                        <Link to={subMenu.url.url}>
                          <span>{subMenu.name}</span>
                        </Link>
                      )}
                    </Menu.Item>
                  )
                })}
              </>
            )}
          </Menu.SubMenu>
        )
      }
      return (
        <Menu.Item key={item.key} disabled={!item.isEnable}>
          {item.url.isExternal ? (
            <a
              href={item.url.isDynamic ? setUrl(item.url.url, item.key) : item.url.url}
              target="_blank"
              rel="noreferrer"
            >
              {item.icon.isClass ? (
                <span
                  className={`${item.icon.icon} ${
                    !item.isEnable && 'disabled'
                  } icon-collapsed-hidden`}
                />
              ) : (
                <img
                  className="icon-collapsed-hidden"
                  src={item.icon.icon}
                  alt={`${item.name} Icon`}
                />
              )}
              <span>{item.name}</span>
            </a>
          ) : (
            <Link to={item.url.url}>
              <span
                className={`${item.icon.icon} ${
                  !item.isEnable && 'disabled'
                } icon-collapsed-hidden`}
              />
              <span>{item.name}</span>
            </Link>
          )}
        </Menu.Item>
      )
    })
  }

  return (
    <div className="ttw-sidebar">
      <div className="sidebar__logo">
        <img
          src="https://d17jozkkv5mv8m.cloudfront.net/web/common/ttw-logo.png"
          alt="TTW"
        />
      </div>
      <PerfectScrollbar>
        <Menu
          openKeys={openKeys}
          onOpenChange={onOpenSubMenu}
          mode="inline"
          inlineIndent="15"
          className="sidebar__menu"
        >
          {generateMenu()}
        </Menu>
      </PerfectScrollbar>
    </div>
  )
}

export default MainSidebar
