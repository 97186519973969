import { isArray, isBoolean, isObject, isString } from 'lodash'
import { formatProductField } from '../util/functions/formatProductField'

/**
 * @category Hooks and Utilities
 * @description Custom hook to analyze and provide information about the completeness
 * of fields in a product object.
 *
 * @returns {Object} An object containing the fieldsInfo function.
 */
export const useProductInfo = (product = null) => {
  /**
   * Helper function to analyze fields of an object.
   *
   * @param {Object} obj - The object to analyze.
   * @param {Array} ignoreList - List of fields to ignore during analysis.
   * @returns {Object} An object containing progress, all fields, filled fields,
   * and incomplete fields.
   * @author Rafael Rapizo Nery
   */
  const analyzeFields = (obj = {}, ignoreList = []) => {
    const allFields = []
    const filledFields = []
    const missingFields = []

    for (const key in obj) {
      if (ignoreList.includes(key)) continue

      if (isObject(obj[key]) && !isArray(obj[key])) {
        const { filledFields: filled, missingFields: missing } = analyzeFields(
          obj[key],
          ignoreList,
        )
        allFields.push(...filled, ...missing)
        filledFields.push(...filled)
        missingFields.push(...missing)
      } else if (isArray(obj[key]) && obj[key].length > 0) {
        if (key === 'get_packaging' || key === 'get_variants') {
          obj[key].forEach((packageInfo) => {
            const { filledFields: filled, missingFields: missing } = analyzeFields(
              packageInfo,
              ignoreList.includes('get_variants')
                ? [...ignoreList.filter((v) => v !== 'get_variants')]
                : ignoreList,
            )
            const packageKey = key === 'get_packaging' ? packageInfo.key : null
            allFields.push(
              ...filled.map(
                (field) => `${packageKey ? `${packageKey}_package` : ''}_${field}`,
              ),
              ...missing.map(
                (field) => `${packageKey ? `${packageKey}_package` : ''}_${field}`,
              ),
            )
            filledFields.push(
              ...filled.map(
                (field) => `${packageKey ? `${packageKey}_package` : ''}_${field}`,
              ),
            )
            missingFields.push(
              ...missing.map(
                (field) => `${packageKey ? `${packageKey}_package` : ''}_${field}`,
              ),
            )
          })
        }
      } else {
        allFields.push(key)

        if (
          (!isBoolean(obj[key]) && !!!obj[key]) ||
          (isString(obj[key]) && obj[key] === '0')
        ) {
          missingFields.push(key)
        } else {
          filledFields.push(key)
        }
      }
    }

    return {
      allFields: allFields,
      filledFields: filledFields,
      missingFields: missingFields.filter(
        (field, index, self) => self.indexOf(field) === index,
      ),
    }
  }

  /**
   * Analyzes the fields of a product object and provides information about
   * their completeness.
   *
   * @param {Object} product - The product object to be analyzed.
   * @returns {Object} An object containing progress, all fields, filled fields, and missing fields.
   * @author Rafael Rapizo Nery
   */
  const fieldsInfo = (product) => {
    const fieldsIgnore = [
      'category',
      'created_at',
      'id',
      'is_banned',
      'price_per',
      'slug',
      'updated_at',
      'get_category',
      'extra_small',
      'medium',
      'preview',
      'product_variant',
      'get_variants',
      'brand',
      'year',
      'price',
      'special_price',
      'capacity_value',
      'hts',
      'origin_country'
    ]

    if (product.warranty_period === null) {
      fieldsIgnore.push(
        'warranty_period',
        'warranty_limit',
        'warranty_from_shipping',
        'warranty_from_purchase',
        'warranty_from_delivery',
      )
    }

    const { allFields, filledFields, missingFields } = analyzeFields(
      product,
      fieldsIgnore,
    )


    if (product.product_data) {
      if(product.product_data.product_have_barcode && product.product_data.barcode === "No") {
        allFields.unshift('barcode')
        missingFields.unshift('barcode')
      }
    } else {
      allFields.unshift('Product Details')
      missingFields.unshift('Product Details')
    }


    for (const v of product.get_packaging) {
      if (v.get_variants.length < 1) {
        allFields.push('package_information')
        missingFields.push('package_information')
        break
      }
    }
    //

    const progress = (filledFields.length * 100) / allFields.length

    const fieldInfo = {
      progress,
      allFields,
      filledFields,
      missingFields,
    }

    for (const k in fieldInfo) {
      if (k === 'progress') continue
      else {
        fieldInfo[k] = formatProductField(fieldInfo[k])
      }
    }

    return fieldInfo
  }

  return {
    fieldsInfo: product ? fieldsInfo(product) : fieldsInfo,
  }
}
