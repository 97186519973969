export const removeHTMLTags = (html = "") => {
  let text = html

  text =text.replace(/<p>/ig, '')
  text =text.replace(/<\/p>/ig, '')
  text =text.replace(/<br>/ig, '')
  text =text.replace(/<\/br>/ig, '')

  return text
}
