import { message } from 'antd'

import { AsyncMessage } from '../../models/Messages'

import axiosRequest from '../../hooks/useAxiosRequest'

export const updateAccountStatus = async (status, userId) => {
  const data = { status, user_id: userId }

  message.loading(AsyncMessage.loading)

  try {
    await axiosRequest(`/api/v2/user/change-status`, data, 'put', true).then((res) => {
      if (res.status === 200) message.destroy()
    })
  } catch (error) {
    message.destroy()
    throw new Error('Unable to update user account status after data change.')
  }
}
