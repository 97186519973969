import { useState, useContext, useCallback } from 'react'

import { useStyles } from './useStyles'

/**
 * Manages color-related functionality.
 *
 * @returns {Object} - An object containing recent colors, current colors, and color change handler.
 * @property {Array} recent - An array of recently selected colors.
 * @property {Object} colors - The current colors state.
 * @property {function} handleColorChange - Function to handle color changes and update the state.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const useColors = () => {
  const { StylesContext, StylesReducerContext } = useStyles()
  const [recent, setRecent] = useState([])
  const { colors } = useContext(StylesContext)
  const dispatch = useContext(StylesReducerContext)

  /**
   * Check if a color is black (#000).
   * @function
   * @param {string} hex - The hex color code.
   * @returns {boolean} - Whether the color is black or not.
   */
  const isBlack = useCallback((hex = '') => {
    return hex.match(/^#000/gi)
  }, [])

  /**
   * Check if a color is a duplicate in the given array.
   * @function
   * @param {Array} colors - The array of colors to check.
   * @param {string} hex - The hex color code.
   * @returns {boolean} - Whether the color is a duplicate or not.
   */
  const isDuplicate = useCallback((colors = [], hex = '') => {
    return colors.includes(hex)
  }, [])

  /**
   * Handle duplicates in the array of colors.
   * @function
   * @param {Array} colors - The array of colors.
   * @param {string} hex - The hex color code.
   * @returns {Array} - The updated array of colors without duplicates.
   */
  const handleDuplicate = useCallback(
    (colors = [], hex = '') => {
      if (isDuplicate(hex)) return colors.filter((c) => c !== hex)
      else return colors
    },
    [isDuplicate],
  )

  /**
   * Limit the array of colors to a maximum length.
   * @function
   * @param {Array} colors - The array of colors.
   * @returns {Array} - The updated array of colors within the length limit.
   */
  const handleMaxLength = useCallback((colors = []) => {
    if (colors.length > 5) return colors.slice(0, 4)
    else return colors
  }, [])

  /**
   * Add a color to the array of recent colors.
   * @function
   * @param {string} hex - The hex color code.
   */
  const addRecent = (hex = '') => {
    let colors = [...recent]
    if (isBlack(hex)) return null
    colors = handleDuplicate(colors, hex)
    colors = handleMaxLength(colors)
    setRecent([hex, ...colors])
  }

  /**
   * Handle a change in color and update the state.
   * @function
   * @param {string} color - The new color value.
   * @param {string} key - The key associated with the color in styles state.
   */
  const handleColorChange = (color, key) => {
    addRecent(color)
    dispatch({
      type: 'SET_COLOR',
      value: color,
      key: key,
    })
  }

  return { recent, colors, handleColorChange }
}
