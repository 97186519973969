import React from 'react'
import { PageHeader } from 'antd'

import HeaderBreadcrumb from '../../components/ui/menu/HeaderBreadcrumb'
import HeaderExtrasMenu from '../../components/ui/menu/HeaderExtrasMenu'

import { useDashboardHeader } from '../../hooks/ui/useDashboardHeader/useDashboardHeader'

import './DashboardHeader.scss'

const DashboardHeader = () => {
  const { userInfo } = useDashboardHeader()

  return (
    <PageHeader className="header" title={<HeaderBreadcrumb />}>
      <HeaderExtrasMenu userInfo={userInfo} />
    </PageHeader>
  )
}

export default DashboardHeader
