import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Form, Input, Button, notification } from 'antd'
import { useParams } from 'react-router-dom'

import loginImg from '../../assets/onboarding/login.svg'
import axiosRequest from '../../hooks/useAxiosRequest'
import { history } from '../../index'

const RestorePassword = () => {
  const { slug } = useParams()
  const [loading, setLoading] = useState(false)

  const onFormSuccess = (values) => {
    setLoading(true)
    axiosRequest(`/api/v1/auth/restore-password`, { ...values, slug }, 'post', false)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'You password was changed',
        })
        history.push('/secured/login')
      })
      .catch((e) => {
        notification.error({
          message: 'Error',
          description: 'Something goes wrong, please check your inputs',
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Helmet title="Login" />
      <div className="secured-login">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-5 order-2 order-lg-1">
              <div className="login__form">
                <h1>Restore your password</h1>
                <Form
                  onFinish={onFormSuccess}
                  layout="vertical"
                  hideRequiredMark
                  className="mb-0"
                >
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input type="password" placeholder="New Password*" />
                  </Form.Item>
                  <Form.Item
                    name="confirmPassword"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input type="password" placeholder="Password Confirmation*" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="w-100 ant-btn-ttu"
                      htmlType="submit"
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div className="col-12 col-lg-7 order-1 order-lg-2 mb-4 mb-lg-0">
              <div className="login__image">
                <img src={loginImg} alt="TTW Login" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RestorePassword
