import { RestAPI } from './RestAPI.interface'

import axios from 'axios'

export class CustomerAPI extends RestAPI {
  BASE_URL = `${process.env.REACT_APP_CUSTOMER_API_URL}/`

  async request(method, path, data) {
    let response = null
    try {
      response = await axios({
        method,
        url: `${this.BASE_URL}${path}`,
        data,
        headers: {
          'X-Api-Key': process.env.REACT_APP_AWS_API_KEY,
        },
      })
    } catch (error) {
      console.error(error)
      throw new Error('An error occurred while processing your request.')
    } finally {
      return response.data
    }
  }

  async index() {
    return await this.request('GET', 'customer')
  }

  async show(id) {
    return await this.request('GET', `customer/${id}`)
  }

  async create(data) {
    return await this.request('POST', 'customer', data)
  }

  async update(id, data) {
    return await this.request('PUT', `customer/${id}`, data)
  }

  async delete(id) {
    return await this.request('DELETE', id)
  }

  async createCustomerSession({ registryID, priceLookupKey }) {
    return await this.request('POST', 'session', {
      registryID,
      priceLookupKey,
    })
  }

  async getSubscription(userID) {
    return await this.request('GET', `subscription/${userID}`)
  }
}
