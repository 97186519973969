import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import { Redirect } from '../../../models'
import logo from '../../../assets/onboarding/finish.png'
import { Button } from 'antd'

const EmailVerification = (props) => {
  const { verificationStatus } = useParams()
  const title = verificationStatus === 'verified' ? 'Verified' : 'Already Verified'

  if (verificationStatus !== 'verified' && verificationStatus !== 'already-verified') {
    Redirect.home()
  }

  const redirectLogin = () => {
    const redirect = new Redirect(null, '/secured/login')
    redirect.go()
  }

  const redirectMarketplace = () => {
    const redirect = new Redirect('https://tradeto.world/#/')
    redirect.go()
  }

  console.log(verificationStatus)
  return (
    <>
      <Helmet title={title} />
      <div className="success-screen__wrapper">
        <div className="success-screen">
          <div className="screen__logo">
            <img src={logo} alt="Success" />
          </div>
          <h1>{verificationStatus === 'verified' ? 'Congratulations' : 'Hi, there'}!</h1>
          <p className="display-4">
            You have{' '}
            {verificationStatus === 'already-verified' && <strong>already </strong>}
            successfully verified your email account!
          </p>
          <div className="d-flex gap-5 justify-content-center mt-lg-5 ">
            <Button
              onClick={redirectMarketplace}
              type="ghost"
              className="ant-btn-ttu ant-btn-primary__outlined"
            >
              Go to marketplace
            </Button>
            <Button onClick={redirectLogin} type="primary" className="ant-btn-ttu w-25">
              Login
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailVerification
