const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  SET_ACTIVE_USER_TYPE: 'user/SET_ACTIVE_USER_TYPE',
  SET_AVAILABLE_USER_TYPES: 'user/SET_AVAILABLE_USER_TYPES',
}

export default actions
