export class WarningMessage {
  static login = {
    suspended:
      'Your account has been suspended.\nYou may use your dashboard, but will experience limited visibility in the markeplace.\nPlease, contact support.',
    pendingApproval:
      'Your account is waiting for approval from our team.\nYou will experience limited visibility in the marketplace.\nPlease, contact support for further information.',
  }
  
  static profile = {
    pendingApproval: 'Your profile has changes pending for approval by our Sales Team. For further information, contact support.'

  }
}
