export class StyleController {
  title
  description
  type

  constructor(title) {
    this.title = title
  }

  setKey(key) {
    this.key = key
    return this
  }

  getKey() {
    return this.key
  }
}
