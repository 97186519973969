import { Button, Dropdown, Menu } from 'antd'
import { CaretDownFilled, CaretUpFilled, SwapOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { useHeaderDropdownMenu } from '../../../hooks/ui/useHeaderDropdownMenu/useHeaderDropdownMenu'

import Webicon from '../../../assets/icons/website.svg'
import ProfileIcon from '../../../assets/icons/user.svg'
import LogoutIcon from '../../../assets/icons/logout.svg'

import './HeaderDropdownMenu.scss'

const HeaderDropdownMenu = ({ userInfo }) => {
  const {
    handleLogout,
    isDropdownMenuOpen,
    setDropdownMenuOpen,
    isSubscriptionUpdatable,
    handleUpgrade,
    activeUserType,
    availableUserTypes,
    changeActiveUserType,
  } = useHeaderDropdownMenu()

  const userTypeMenu = (
    <Menu>
      {availableUserTypes.map((type) => (
        <Menu.Item
          key={type}
          onClick={() => changeActiveUserType(type)}
          className="text-capitalize"
        >
          {type === activeUserType ? `${type} (Active)` : type}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown
      trigger={['click']}
      onVisibleChange={(visible) => setDropdownMenuOpen(visible)}
      overlay={
        <Menu className="dropdown-menu">
          <Menu.Item className="dropdown-menu__header-item">
            <div className="dropdown-menu__header-item__head">
              <h4>{userInfo.userName}</h4>
              <span className={userInfo.userSubscription}>
                {userInfo.userSubscription}
              </span>
            </div>
            <p>{userInfo.userCompany}</p>
            {isSubscriptionUpdatable && (
              <Button
                className="dropdown-menu__header-item__button"
                onClick={handleUpgrade}
              >
                UPGRADE
              </Button>
            )}
          </Menu.Item>
          <Menu.Item className="dropdown-menu__item">
            <a
              className="dropdown-menu__item__link"
              target="_blank"
              rel="noreferrer"
              href="https://tradeto.world/#/explore"
            >
              <img
                className="dropdown-menu__item__link__icon"
                src={Webicon}
                alt="Go to Marketplace"
              />
              Marketplace
            </a>
          </Menu.Item>
          <Menu.Item className="dropdown-menu__item">
            <Link className="dropdown-menu__item__link" to="/profile/company-profile">
              <img
                className="dropdown-menu__item__link__icon"
                src={ProfileIcon}
                alt="Go to My Profile"
              />
              My Profile
            </Link>
          </Menu.Item>
          <Menu.Item className="dropdown-menu__item">
            <Dropdown overlay={userTypeMenu} className="dropdown-menu__item__link__icon">
              <span className="dropdown-menu__item__link">
                <SwapOutlined />
                Change User Type
              </span>
            </Dropdown>
          </Menu.Item>
          <Menu.Item className="dropdown-menu__item">
            <span
              className="dropdown-menu__item__link"
              target="_blank"
              rel="noreferrer"
              onClick={handleLogout}
            >
              <img
                className="dropdown-menu__item__link__icon"
                src={LogoutIcon}
                alt="Logout"
              />
              Log out
            </span>
          </Menu.Item>
        </Menu>
      }
    >
      {isDropdownMenuOpen ? (
        <CaretUpFilled
          style={{ color: '#AEAEAE' }}
          onClick={(e) => {
            e.preventDefault()
            setDropdownMenuOpen(false)
          }}
        />
      ) : (
        <CaretDownFilled
          style={{ color: '#AEAEAE' }}
          onClick={(e) => {
            e.preventDefault()
            setDropdownMenuOpen(true)
          }}
        />
      )}
    </Dropdown>
  )
}

export default HeaderDropdownMenu
