import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Helmet } from 'react-helmet-async'
import { Form, Checkbox, Skeleton, Select, message, Radio } from 'antd'
import _ from 'lodash'

import { ErrorMessage } from '../../../models'
import { AsyncMessage } from '../../../models/Messages'

import { history } from '../../../index'
import axiosRequest from '../../../hooks/useAxiosRequest'
import { getVerificationSteps } from '../../../data/verficationSteps'

import VerificationLayout from '../../../components/verification/VerificationLayout'
import LayoutLoading from '../../../components/verification/LayoutLoading'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'

import { ProfileProgressAbstractFactory } from '../../../models/Domains/ProfileProgress/ProfileProgressAbstractFactory.interface'

const IndustriesOnboarding = () => {
  const pageTitle = 'Company Categories'
  const { user } = useSelector((state) => state.user)
  const { userType } = useParams()
  const [loading, setLoading] = useState(true)
  const [isNotificationsOn, setIsNotificationsOn] = useState(false)
  const [progress, setProgress] = useState(0)
  const [industries, setIndustries] = useState([])
  const [selectedIndustries, setSelectedIndustries] = useState([])
  const [company, setCompanyData] = useState({})

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/company-industries', null, 'get', true).then(
      (res) => {
        if (res.data) {
          setIndustries(res.data.data)
          setCompanyData(res.data.company)
          const companyIndustries = []
          res.data.industries &&
            res.data.industries.forEach((value) => {
              companyIndustries.push(value.industry)
            })
          setSelectedIndustries([...companyIndustries])
          res.data.notifications.length > 0 && setIsNotificationsOn(true) // tmp

          const progressFactory = ProfileProgressAbstractFactory.getFactory('role')
          const progressBuilder = new progressFactory({
            userType:
              userType !== 'service-provider' ? userType : 'service-provider-general',
            user: { ...user, steps: res.data.steps },
          })
          const progress = progressBuilder.getProgress()
          setProgress(progress)

          setLoading(false)
        }
      },
    )
  }, [])

  const onCheckboxSelect = (target, value) => {
    const selected = selectedIndustries
    if (target.checked) {
      selected.push(value)
    } else {
      _.remove(selected, (n) => {
        return n === value
      })
    }
    setSelectedIndustries([...selected])
  }

  const getChannel = (userType, submit = false, values = { channel: 'tmp_channel' }) => {
    if(userType === 'service-provider') return 'Service Provider'
    if (userType === 'seller' && submit && values) return values.channel
    else if (userType === 'seller' && !submit) return company.channel
    else if (userType === 'buyer' && submit && company.channel) return company.channel
    else if (userType === 'buyer' && submit && !company.channel)
      return user.channel ? user.channel : 'Marketer'
  }

  const onSubmit = async (values) => {
    if (selectedIndustries.length < 1) {
      message.error('Please, select industries...')
    } else {
      message.loading({ content: AsyncMessage.loading, key: 'save' })

      try {
        axiosRequest(
          '/api/v1/on-boarding/company-industries',
          {
            channel: getChannel(userType, true, values),
            industries: selectedIndustries,
            notifications_on: values.industryNotifications,
          },
          'post',
          true,
        ).then((res) => {
          if (res.status === 500) {
            throw new Error()
          }
          if (res.data.success) {
            updateAccountStatus(3, user.id)
            history.push(`/secured/verification/${userType}/company-sub-industries`)
            message.destroy()
          }
        })
      } catch (error) {
        message.error(ErrorMessage.profile.submition.updateGeneric)
      }
    }
  }

  const onSubmitFail = () => {
    message.error('Something goes wrong, contact support...')
  }

  const getSubtitle = (userType) => {
    switch (userType) {
      case 'seller':
        return 'Select the industries you trade in'
      case 'buyer':
        return 'What are your preferred industries for sourcing?'
      case 'service-provider':
        return 'Which industries do your customers deal in?'
      default:
        return ''
    }
  }

  if (loading || industries.length === 0 || !company.id) return <LayoutLoading />

  return (
    <>
      <Helmet title={pageTitle} />
      {loading ? (
        <VerificationLayout progress={progress} currentStep="companyIndustries">
          <Skeleton active />
        </VerificationLayout>
      ) : (
        <Form
          onFinish={onSubmit}
          onFinishFailed={onSubmitFail}
          layout="vertical"
          hideRequiredMark
          className="mb-0"
          initialValues={{
            channel: company.channel,
            industryNotifications: isNotificationsOn,
          }}
        >
          <VerificationLayout progress={progress} currentStep="companyIndustries">
            <div className="row">
              <div className="col-12">
                <h1>
                  {userType === 'seller' ? 'Company Categories' : 'Interested Industries'}
                </h1>
              </div>
              <div className="col-12 col-lg-7">
                {userType === 'seller' && (
                  <Form.Item
                    name="channel"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Select placeholder="Channel player*">
                      <Select.Option value="Manufacturer">Manufacturer</Select.Option>
                      <Select.Option value="Marketer">Marketer</Select.Option>
                    </Select>
                  </Form.Item>
                )}
                {userType === 'buyer' && (
                  <div className="notifications-settings">
                    <p>
                      <strong>Notification preferences</strong>
                    </p>
                    <p>
                      Do you want to be alerted in upload new products from your favourite
                      products, brands or Sellers?
                    </p>
                    <Form.Item name={'industryNotifications'}>
                      <Radio.Group defaultValue={true}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false} className="ms-6">
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                )}
                <p className="mb-3">
                  <strong>{getSubtitle(userType)}</strong>
                </p>
              </div>
              <div className="col-12">
                <div className="company__industries">
                  {industries.length > 0 &&
                    industries.map((industry) => {
                      return (
                        <div className="text-center industry-item" key={industry.id}>
                          <Form.Item className="mb-0">
                            <Checkbox
                              value={industry.id}
                              onChange={(e) => onCheckboxSelect(e.target, industry.id)}
                              checked={selectedIndustries.includes(industry.id)}
                            >
                              <div className="industry__wrapper">
                                <div className="industry__icon">
                                  <img src={industry.icon} alt={industry.name} />
                                </div>
                                <h4 className="industry__name">{industry.name}</h4>
                              </div>
                            </Checkbox>
                          </Form.Item>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </VerificationLayout>
        </Form>
      )}
    </>
  )
}

export default IndustriesOnboarding
