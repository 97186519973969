import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Header from './marketplace/Header'
import Footer from './marketplace/Footer'

const MarketplaceLayout = ({ children }) => {
  const { pathname } = useLocation()
  const [disableMenu, setDisableMenu] = useState(false)

  useEffect(() => {
    console.log(pathname.indexOf('marketplace/showroom'))
    if (pathname.indexOf('marketplace/showroom') !== -1) {
      setDisableMenu(true)
    } else {
      setDisableMenu(false)
    }
  }, [pathname])

  return (
    <>
      <Header disableMenu={disableMenu} />
      <div style={{ minHeight: '1200px' }}>{children}</div>
      <Footer />
    </>
  )
}

export default MarketplaceLayout
