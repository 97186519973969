import { RoleProfileProgressFactory } from './RoleProfileProgressFactory.class'
import { ServiceTypeProfileProgressFactory } from './ServiceTypeProfileProgressFactory.class'

export class ProfileProgressAbstractFactory {
  static getFactory(type) {
    switch (type) {
      case 'service-type':
        return ServiceTypeProfileProgressFactory
      case 'role':
        return RoleProfileProgressFactory
      // case 'service-provider':
      //   return ServiceProviderProfileProgressFactory
      default:
        return null
    }
  }
}
