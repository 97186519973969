import { HexInput } from './HexInput'
import { RGBInput } from './RGBInput'

/**
 * JSX component for rendering the appropriate input based on the specified color format (Hex, RGB).
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.type - The color format type ('hex' or 'rgb').
 * @param {string} props.currentColor - The current selected color in the color picker.
 * @param {function} props.callback - The callback function to update the selected color.
 * @returns {JSX.Element} - The rendered FormatInput component based on the specified type.
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const FormatInput = ({ type, currentColor, callback = () => null }) => {
  switch (type) {
    case 'hex':
      return <HexInput currentColor={currentColor} callback={callback} />
    case 'rgb':
      return <RGBInput currentColor={currentColor} callback={callback} />
    default:
      console.warn("No type provided for style's input.")
      return null
  }
}
